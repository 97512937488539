import vtkOrientationMarkerWidget from 'vtk.js/Sources/Interaction/Widgets/OrientationMarkerWidget';
import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';
import vtkPLYReader from 'vtk.js/Sources/IO/Geometry/PLYReader';

import { useEffect, useRef } from 'react';
import { logDebug } from '../js/Common';

const isDebugging = false;

function log(msg) {
    logDebug("Visualization.AnatomyOrientation", isDebugging, msg);
}

/** @typedef {{ showOrientation: bool, orientationData: ArrayBuffer }} AnatomyOrientationProps */

/**
 * Shows an anatomy orientation marker widget
 *
 * @param parentContext
 * @param {bool} initialized
 * @param {AnatomyOrientationProps} anatomyOrientationProps
 */
export function useAnatomyOrientation(parentContext, initialized, anatomyOrientationProps) {
    const context = useRef(null);

    const { showOrientation, orientationData } = anatomyOrientationProps;

    // Update the widget orientation in case the viewports are synced.
    // OPTIMIZE: Only call this when it's needed instead of every render cycle
    if (context.current) {
        context.current.orientationWidget.updateMarkerOrientation();
    }

    // Side effect for showing orientation widget
    useEffect(() => {
        if (parentContext) {

            const { renderer, renderWindow } = parentContext;

            if (!context.current && orientationData) {

                log("Initializing");

                const reader = vtkPLYReader.newInstance();
                reader.parseAsArrayBuffer(orientationData);
                const dataSource = reader.getOutputData();

                const mapper = vtkMapper.newInstance();
                mapper.setInputData(dataSource);
                const orientationActor = vtkActor.newInstance();
                orientationActor.setMapper(mapper);

                const orientationWidget = vtkOrientationMarkerWidget.newInstance({
                    actor: orientationActor,
                    interactor: renderWindow.getInteractor(),
                });
                orientationWidget.setEnabled(true);
                orientationWidget.setViewportCorner(
                    vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT
                );
                orientationWidget.setViewportSize(0.25);

                renderer.resetCamera();
                renderWindow.render();

                context.current = {
                    orientationWidget
                };
            }

            if (context.current && context.current.orientationWidget) {
                context.current.orientationWidget.setEnabled(showOrientation);
            }
        }

        let ctx = context.current;
        return () => {
            // Dispose
            if (ctx) {
                log("Disposing anatomy orientation widget");

                const { orientationWidget } = ctx;
                orientationWidget.delete();

                context.current = {
                    orientationWidget
                };
            }
        };
    }, [initialized, showOrientation, orientationData]);
}
