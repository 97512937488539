import { mkToolReactButton } from "../Common/CommonView.js";
import { Shared_defaultButtonStyles } from "../VisualizationShared.js";
import { LocalMsg } from "./SlicePlaneTypes.js";
import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";

export function showSlicePlaneTool(isDisabled, model, dispatch) {
    const mkTool = (desc, ico) => mkToolReactButton(dispatch, Shared_defaultButtonStyles, ico, new LocalMsg(!model.IsVisible), false, isDisabled, desc);
    if (model.IsVisible) {
        return mkTool("Hide slice plane", new Fa_IconOption(11, ["fas fa-border-none"]));
    }
    else {
        return mkTool("Always show slice plane", new Fa_IconOption(11, ["fas fa-border-all"]));
    }
}

