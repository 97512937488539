import { bind, unwrap, toArray as toArray_1, map, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";
import { mkToolReactButton, mkDispatchLocal } from "./Common/CommonView.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { wrapInboundMsg, wrapOutboundMsg, wrapLocalMsg } from "../Common/InboundOutbound.js";
import { safeHash, equals } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { DicomData_Dicom__617D7B5F, Meshes_Previous_567A3FC6, Meshes_Current_567A3FC6, Compare_previousMeshTypes, Compare_previousStudyViewport, InteractionMsg, VisualizationViewMode_toInteractionMode_23C8A421, OutboundMsg, VisualizationType, DisplayMap_describe, LocalMsg as LocalMsg_1, VisualizationViewMode } from "./VisualizationTypes.js";
import { ModelCenterlineMeasurement_Props, LocalMsg } from "./CenterlineMeasurement/CenterlineMeasurementTypes.js";
import { CSSProp, HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { Controls_dropdownButton } from "../Common/General.js";
import { ModelCalipers_Props_69A04C54, LocalMsg as LocalMsg_2 } from "./Calipers/CalipersTypes.js";
import { deleteMeasurementModal, makeAllMeasurementCards } from "./MeasurementsView.js";
import { InteractionMode } from "./Common/CommonBindings.js";
import { TargetStudy, MeshViewportLocation, CardMode } from "./Common/CommonTypes.js";
import { notesModal, notesCard } from "./Notes/NotesView.js";
import { TableOption, table } from "../fable_modules/Fulma.3.0.0/Elements/Table.fs.js";
import { contains, toArray, empty as empty_1, ofArray, singleton as singleton_1 } from "../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";
import { content as content_1, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { Size_ISize, Color_IColor, Common_GenericOption } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Option, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Option as Option_1, icon as icon_9 } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { FormField_ReactSelectCallback$1, FormField_reactSelectSingle } from "../Common/Forms.js";
import { SelectPropsMulti$1 } from "../Common/ReactSelectBind.js";
import { lockUnlockTool as lockUnlockTool_1 } from "./Camera/CameraView.js";
import { Shared_onButtonStyles, Shared_defaultButtonStyles } from "./VisualizationShared.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { screenshotModal, takeScreenshotTool, cancelScreenshotTool } from "./Screenshot/ScreenshotView.js";
import { showSlicePlaneTool } from "./SlicePlane/SlicePlaneView.js";
import { Level_Option, level } from "../fable_modules/Fulma.3.0.0/Layouts/Level.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Option as Option_2, tag as tag_52 } from "../fable_modules/Fulma.3.0.0/Elements/Tag.fs.js";
import { InteractionModeProps } from "./VisualizationBindings.js";
import { ModelSlicePlane_Props_Z714BB56C, InboundMsg } from "./SlicePlane/SlicePlaneTypes.js";
import { FSharpMap__get_Item } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { NotesProps, StaticLabel } from "./Notes/NotesBindings.js";
import { ModelNotes__Props_25828286 } from "./Notes/NotesTypes.js";
import { append as append_1 } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { toString } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { ModelCamera_Props } from "./Camera/CameraTypes.js";
import { ModelScreenshot_Props_Z625F521 } from "./Screenshot/ScreenshotTypes.js";
import { ModelAnatomyOrientation_Props_Z550008EC } from "./AnatomyOrientation/AnatomyOrientationTypes.js";
import { ModelPickedMapValue_Props } from "./PickedMapValue/PickedMapValueTypes.js";
import MeshVisualization from "../../../src/RAWMap.Client/Visualization/js/MeshVisualization.js";
import DicomVisualization from "../../../src/RAWMap.Client/Visualization/js/DicomVisualization.js";

export function canUserViewCustomReport(model) {
    return defaultArg(map((user) => isAllowed(new Permission(18, []))(user.roles), model.MaybeCurrentUser), false);
}

export function canUserEditCustomReportData(model) {
    return defaultArg(map((user) => isAllowed(new Permission(19, []))(user.roles), model.MaybeCurrentUser), false);
}

export function allMeasurementsCards(model, dispatch) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    const allButtons = toList(delay(() => append(singleton((dispatch_2) => (new Controls_dropdownButton(new DOMAttr(40, [(_arg_1) => {
        dispatch_2(new LocalMsg_1(23, [wrapLocalMsg(new LocalMsg(8, [equals(model.ViewMode, new VisualizationViewMode(0, []))]))]));
    }]), "Centerline Distance"))), delay(() => append(singleton((dispatch_3) => (new Controls_dropdownButton(new DOMAttr(40, [(_arg_2) => {
        dispatch_3(new LocalMsg_1(23, [wrapLocalMsg(new LocalMsg(9, [equals(model.ViewMode, new VisualizationViewMode(0, []))]))]));
    }]), "Diameter Range"))), delay(() => append(singleton((dispatch_4) => (new Controls_dropdownButton(new DOMAttr(40, [(_arg_3) => {
        dispatch_4(new LocalMsg_1(23, [wrapLocalMsg(new LocalMsg(10, [equals(model.ViewMode, new VisualizationViewMode(0, []))]))]));
    }]), "Local Diameter"))), delay(() => append(singleton((dispatch_5) => (new Controls_dropdownButton(new DOMAttr(40, [(_arg_4) => {
        dispatch_5(new LocalMsg_1(23, [wrapLocalMsg(new LocalMsg(11, [equals(model.ViewMode, new VisualizationViewMode(0, []))]))]));
    }]), "Volume"))), delay(() => (!equals(model.ViewMode, new VisualizationViewMode(0, [])) ? singleton((dispatch_1) => (new Controls_dropdownButton(new DOMAttr(40, [(_arg) => {
        dispatch_1(new LocalMsg_1(22, [wrapLocalMsg(new LocalMsg_2(4, []))]));
    }]), "Caliper"))) : empty())))))))))));
    return makeAllMeasurementCards(model, model.Measurements, (equals(model.InteractionMode, new InteractionMode(5, [])) ? true : equals(model.InteractionMode, new InteractionMode(1, []))) ? (new CardMode(2, [])) : (!canUserEditCustomReportData(model) ? (new CardMode(1, [])) : (new CardMode(0, []))), allButtons, dispatchLocal);
}

export function staticNotesCard(model, dispatch) {
    return notesCard(model.NotesModel, !canUserEditCustomReportData(model) ? (new CardMode(1, [])) : (!equals(model.InteractionMode, new InteractionMode(0, [])) ? (new CardMode(2, [])) : (new CardMode(0, []))), dispatch);
}

export function helpToolTipsCardOption(model, dispatch) {
    let children_10, children_2, children_8, children_48, children_16, children_22, children_28, children_34, children_40, children_46, children_62, children_54, children_60, children_100, children_68, children_74, children_80, children_86, children_92, children_98;
    if (model.ShowHelpTooltip) {
        let dicomTips;
        const children_50 = [table(singleton_1(new TableOption(2, [])), ofArray([(children_10 = [(children_2 = [react.createElement("th", {
            colSpan: 2,
        }, "CT Slice Viewport")], react.createElement("tr", {}, ...children_2)), (children_8 = [react.createElement("th", {}, "Action"), react.createElement("th", {}, "Controls")], react.createElement("tr", {}, ...children_8))], react.createElement("thead", {}, ...children_10)), (children_48 = [(children_16 = [react.createElement("td", {}, "Pan"), react.createElement("td", {}, "Shift + Left Click + Drag")], react.createElement("tr", {}, ...children_16)), (children_22 = [react.createElement("td", {}, "Window"), react.createElement("td", {}, "Left Click + Drag Horizontally")], react.createElement("tr", {}, ...children_22)), (children_28 = [react.createElement("td", {}, "Level"), react.createElement("td", {}, "Left Click + Drag Vertically")], react.createElement("tr", {}, ...children_28)), (children_34 = [react.createElement("td", {}, "Scroll Slice"), react.createElement("td", {}, "Middle Mouse Wheel (when mouse wheel action is set to scroll)")], react.createElement("tr", {}, ...children_34)), (children_40 = [react.createElement("td", {}, "Scroll Slice (Fast)"), react.createElement("td", {}, "Ctrl + Left Click + Drag Vertically")], react.createElement("tr", {}, ...children_40)), (children_46 = [react.createElement("td", {}, "Zoom"), react.createElement("td", {}, "Shift + Alt + Left Click + Drag")], react.createElement("tr", {}, ...children_46))], react.createElement("tbody", {}, ...children_48))]))];
        dicomTips = react.createElement("div", {}, ...children_50);
        let mapTips;
        const children_102 = [table(singleton_1(new TableOption(2, [])), ofArray([(children_62 = [(children_54 = [react.createElement("th", {
            colSpan: 2,
        }, "Map Viewports")], react.createElement("tr", {}, ...children_54)), (children_60 = [react.createElement("th", {}, "Action"), react.createElement("th", {}, "Controls")], react.createElement("tr", {}, ...children_60))], react.createElement("thead", {}, ...children_62)), (children_100 = [(children_68 = [react.createElement("td", {}, "Free Rotate"), react.createElement("td", {}, "Left Click + Drag")], react.createElement("tr", {}, ...children_68)), (children_74 = [react.createElement("td", {}, "CC/CCW Rotate"), react.createElement("td", {}, "Ctrl + Left Click + Drag")], react.createElement("tr", {}, ...children_74)), (children_80 = [react.createElement("td", {}, "Pan"), react.createElement("td", {}, "Shift + Left Click + Drag")], react.createElement("tr", {}, ...children_80)), (children_86 = [react.createElement("td", {}, "Zoom"), react.createElement("td", {}, "Middle Mouse Wheel (when mouse wheel action is set to zoom)")], react.createElement("tr", {}, ...children_86)), (children_92 = [react.createElement("td", {}, "Add Point Measurement"), react.createElement("td", {}, "Right Click on Map")], react.createElement("tr", {}, ...children_92)), (children_98 = [react.createElement("td", {}, "Clear Point Measurement"), react.createElement("td", {}, "Right Click Outside Map")], react.createElement("tr", {}, ...children_98))], react.createElement("tbody", {}, ...children_100))]))];
        mapTips = react.createElement("div", {}, ...children_102);
        return singleton_1(card(empty_1(), ofArray([header(empty_1(), ofArray([Header_title(empty_1(), singleton_1("Tool Tips")), Header_icon(singleton_1(new Common_GenericOption(1, [singleton_1(["style", {
            color: "inherit ",
        }])])), singleton_1(button(ofArray([new Option(17, [singleton_1(new HTMLAttr(158, ["Close"]))]), new Option(17, [ofArray([new DOMAttr(40, [(_arg) => {
            dispatch(new LocalMsg_1(29, []));
        }]), new HTMLAttr(159, ["button"])])]), new Option(0, [new Color_IColor(2, [])])]), singleton_1(icon_9(singleton_1(new Option_1(4, [singleton_1(["style", {
            fontWeight: "1000",
        }])])), singleton_1("x"))))))])), content_1(singleton_1(new Common_GenericOption(1, [singleton_1(["style", {
            padding: "0.5rem 1rem",
            margin: "1rem 0",
        }])])), ofArray([dicomTips, mapTips]))])));
    }
    else {
        return undefined;
    }
}

function spinner(message) {
    let props, props_2;
    const children_4 = [(props = [["style", {
        minHeight: "10em",
    }], new HTMLAttr(64, ["spinner is-loading"])], react.createElement("div", keyValueList(props, 1))), (props_2 = [["style", {
        textAlign: "center",
    }]], react.createElement("div", keyValueList(props_2, 1), message))];
    return react.createElement("div", {}, ...children_4);
}

function mkMapSelect(dispatch, viewPort, viewMode, maps, selectedMap) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    const props = [["style", {
        minWidth: "150px",
    }]];
    const children = [FormField_reactSelectSingle(maps, [new SelectPropsMulti$1(10, [false]), new SelectPropsMulti$1(11, ["auto"]), new SelectPropsMulti$1(12, ["fixed"])], DisplayMap_describe, selectedMap, new FormField_ReactSelectCallback$1((maybeNewValue) => {
        if (maybeNewValue == null) {
        }
        else {
            dispatchLocal(new LocalMsg_1(5, [viewPort, maybeNewValue]));
        }
    }), "", [])];
    return react.createElement("div", keyValueList(props, 1), ...children);
}

function mkViewportToolPanel(children) {
    const props = [["style", {
        position: "absolute",
        top: "8px",
        left: "8px",
    }]];
    return react.createElement("div", keyValueList(props, 1), ...children);
}

function mkMainToolPanel(model, dispatch) {
    let isOn, isOn_1, _arg_2;
    const dispatchLocal = mkDispatchLocal(dispatch);
    const isToolPanelDisabled = !(model.InteractionMode.tag === 0);
    const lockUnlockTool = lockUnlockTool_1(isToolPanelDisabled, model.CameraModel, (arg) => {
        dispatchLocal(new LocalMsg_1(21, [arg]));
    });
    let viewModeTool;
    const matchValue_1 = model.ViewMode;
    viewModeTool = ((matchValue_1.tag === 2) ? mkToolReactButton(dispatch, Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-square"]), new LocalMsg_1(15, [new VisualizationViewMode(1, [new VisualizationType(0, [new MeshViewportLocation(0, [])])])]), false, isToolPanelDisabled, "Switch to single view") : ((matchValue_1.tag === 0) ? mkToolReactButton(dispatch, Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-square"]), new LocalMsg_1(15, [new VisualizationViewMode(1, [new VisualizationType(0, [new MeshViewportLocation(0, [])])])]), false, true, "Unable to switch views when comparing previous study") : mkToolReactButton(dispatch, Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-th-large"]), new LocalMsg_1(15, [new VisualizationViewMode(2, [])]), false, isToolPanelDisabled, "Switch to quad view")));
    let singleViewTool;
    const matchValue_2 = model.ViewMode;
    singleViewTool = ((matchValue_2.tag === 1) ? ((matchValue_2.fields[0].tag === 0) ? mkToolReactButton(dispatch, Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-brain"]), new LocalMsg_1(15, [new VisualizationViewMode(1, [new VisualizationType(1, [])])]), false, isToolPanelDisabled, "Show CT slice") : mkToolReactButton(dispatch, Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-cube"]), new LocalMsg_1(15, [new VisualizationViewMode(1, [new VisualizationType(0, [new MeshViewportLocation(0, [])])])]), false, isToolPanelDisabled, "Show single map")) : Helpers_nothing);
    const patternInput = (model.IsFollowupStudy && model.HasPreviousStudyMaps) ? [(isOn = model.DisplayPreviousStudyMaps, mkToolReactButton(dispatch, isOn ? Shared_onButtonStyles : Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-history"]), new LocalMsg_1(19, [!isOn]), false, isToolPanelDisabled, ((_arg) => {
        if (_arg) {
            return "Hide previous study maps";
        }
        else {
            return "Overlay previous study maps";
        }
    })(isOn))), (isOn_1 = equals(model.ViewMode, new VisualizationViewMode(0, [])), mkToolReactButton(dispatch, isOn_1 ? Shared_onButtonStyles : Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-clone"]), new LocalMsg_1(15, [(model.ViewMode.tag === 0) ? (new VisualizationViewMode(2, [])) : (new VisualizationViewMode(0, []))]), false, isToolPanelDisabled, ((_arg_1) => {
        if (_arg_1) {
            return "Stop comparing previous study maps";
        }
        else {
            return "Compare with previous study maps";
        }
    })(isOn_1)))] : [Helpers_nothing, Helpers_nothing];
    let isReportsButtonDisabled;
    const matchValue_5 = !canUserViewCustomReport(model);
    let matchResult;
    if (model.InteractionMode.tag === 0) {
        if (matchValue_5) {
            matchResult = 1;
        }
        else {
            matchResult = 0;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            isReportsButtonDisabled = false;
            break;
        }
        default:
            isReportsButtonDisabled = true;
    }
    let isScreenshotButtonDisabled;
    const matchValue_8 = !canUserEditCustomReportData(model);
    let matchResult_1;
    if (model.InteractionMode.tag === 0) {
        if (matchValue_8) {
            matchResult_1 = 1;
        }
        else {
            matchResult_1 = 0;
        }
    }
    else {
        matchResult_1 = 1;
    }
    switch (matchResult_1) {
        case 0: {
            isScreenshotButtonDisabled = false;
            break;
        }
        default:
            isScreenshotButtonDisabled = true;
    }
    const screenshotTool = (isScreenshotButtonDisabled && equals(model.InteractionMode, new InteractionMode(5, []))) ? cancelScreenshotTool(false, (arg_1) => {
        dispatchLocal(new LocalMsg_1(24, [arg_1]));
    }) : takeScreenshotTool(isScreenshotButtonDisabled, (arg_2) => {
        dispatchLocal(new LocalMsg_1(24, [arg_2]));
    });
    const slicePlaneTool = showSlicePlaneTool(false, model.SlicePlaneModel, (arg_3) => {
        dispatchLocal(new LocalMsg_1(25, [arg_3]));
    });
    const helpTool = mkToolReactButton(dispatch, model.ShowHelpTooltip ? Shared_onButtonStyles : Shared_defaultButtonStyles, new Fa_IconOption(11, ["fas fa-question-circle"]), new LocalMsg_1(29, []), false, false, "Help with controls");
    let zoomScrollToggle;
    let patternInput_1;
    const matchValue_10 = model.ViewMode;
    const matchValue_11 = model.IsSliceScroll;
    let matchResult_2;
    switch (matchValue_10.tag) {
        case 1: {
            if (matchValue_10.fields[0].tag === 1) {
                if (matchValue_11) {
                    matchResult_2 = 0;
                }
                else {
                    matchResult_2 = 1;
                }
            }
            else {
                matchResult_2 = 2;
            }
            break;
        }
        case 2: {
            if (matchValue_11) {
                matchResult_2 = 0;
            }
            else {
                matchResult_2 = 1;
            }
            break;
        }
        default:
            matchResult_2 = 2;
    }
    switch (matchResult_2) {
        case 0: {
            patternInput_1 = ["Switch mouse wheel action to Zoom", new Fa_IconOption(15, ["fas fa-magnifying-glass"]), false];
            break;
        }
        case 1: {
            patternInput_1 = ["Switch mouse wheel action to Scroll CT Slices", new Fa_IconOption(15, ["fas fa-arrow-down-up-across-line"]), false];
            break;
        }
        default:
            patternInput_1 = ["Unable to switch to slice scrolling when ", new Fa_IconOption(15, ["fas fa-arrow-down-up-across-line"]), true];
    }
    zoomScrollToggle = mkToolReactButton(dispatch, Shared_defaultButtonStyles, patternInput_1[1], new LocalMsg_1(16, [!model.IsSliceScroll]), false, patternInput_1[2], patternInput_1[0]);
    const group = (elements) => level(singleton_1(new Level_Option(0, [singleton_1(["style", {
        marginBottom: 0,
    }])])), elements);
    const children = [group(ofArray([button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [isToolPanelDisabled]), new Option(17, [ofArray([new HTMLAttr(99, ["backToStudy"]), new DOMAttr(40, [(_arg_3) => {
        dispatch(wrapOutboundMsg(new OutboundMsg(1, [])));
    }])])])]), singleton_1("Back to Study")), button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [isReportsButtonDisabled]), new Option(17, [ofArray([["style", {
        marginLeft: 5,
    }], new DOMAttr(40, [(_arg_4) => {
        dispatch(wrapOutboundMsg(new OutboundMsg(2, [model.SelectedStudy, model.CustomReportData])));
    }])])])]), singleton_1("Custom Report"))])), group(ofArray([lockUnlockTool, viewModeTool, singleViewTool, screenshotTool, slicePlaneTool, patternInput[1], helpTool, zoomScrollToggle])), group(singleton_1(defaultArg(map((desc_11) => {
        const message = toText(printf("Active Mode: %s"))(desc_11);
        return tag_52(ofArray([new Option_2(1, [new Color_IColor(7, [])]), new Option_2(0, [new Size_ISize(2, [])]), new Option_2(5, ["active-mode-indicator"]), new Option_2(4, [singleton_1(["style", {
            whiteSpace: "normal",
            height: "auto",
        }])])]), singleton_1(message));
    }, (_arg_2 = model.InteractionMode, (_arg_2.tag === 2) ? "Moving measurement note" : ((_arg_2.tag === 3) ? "Calipers measurement" : ((_arg_2.tag === 4) ? "Centerline distance measurement" : ((_arg_2.tag === 5) ? (equals(model.ViewMode, new VisualizationViewMode(2, [])) ? "Screenshot - Click on the viewport you want to capture" : undefined) : ((_arg_2.tag === 6) ? "Diameter range measurement" : ((_arg_2.tag === 7) ? "Local diameter measurement" : ((_arg_2.tag === 8) ? "Volume measurement" : ((_arg_2.tag === 0) ? undefined : "Moving notes"))))))))), Helpers_nothing)))];
    return react.createElement("div", {
        className: "visualization-toolbar",
    }, ...children);
}

export function interactiveView(model, dispatch) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    let viewportClassNames;
    const arg = equals(model.InteractionMode, new InteractionMode(5, [])) ? " screenshot-ready" : "";
    viewportClassNames = toText(printf("layout-view-view-container%s"))(arg);
    const interactionProps = (vt) => (new InteractionModeProps(model.InteractionMode, VisualizationViewMode_toInteractionMode_23C8A421(vt)(model.ViewMode)));
    const dispatchToggleHoverMsg = (h) => {
        if (model.InteractionMode.tag === 5) {
        }
        else {
            dispatchLocal(new LocalMsg_1(25, [wrapInboundMsg(new InboundMsg(2, [h]))]));
        }
    };
    const meshViewport = (toDisplay, meshViewportLocation, model_1, dispatch_1) => {
        let props_2;
        const dispatchLocal_1 = mkDispatchLocal(dispatch_1);
        const viewPortArgs = FSharpMap__get_Item(model_1.ViewportSelectedMap, meshViewportLocation);
        const onViewportResized = (meshViewportLocation.tag === 0) ? ((arg_2) => {
            dispatchLocal_1(new LocalMsg_1(17, [new InteractionMsg(arg_2)]));
        }) : ((value) => {
        });
        const showingPreviousOnly = equals(model_1.ViewMode, new VisualizationViewMode(0, [])) && equals(meshViewportLocation, Compare_previousStudyViewport);
        const target = showingPreviousOnly ? (new TargetStudy(1, [])) : (new TargetStudy(0, []));
        const measurementNotes = toArray(ofArray(toArray_1(map((entry) => {
            let arg_4;
            return new StaticLabel(entry.measurementIdUnified, (arg_4 = (!showingPreviousOnly ? entry.coreValues.currentValue : defaultArg(entry.coreValues.maybePreviousValue, "?")), toText(printf("%s: %s"))(entry.coreValues.name)(arg_4)), entry.coreValues.position[1], entry.coreValues.position[0].x, entry.coreValues.position[0].y, undefined, undefined, false, false);
        }, model_1.Measurements.activeMeasurement))));
        let staticNotesProps;
        const props = ModelNotes__Props_25828286(model_1.NotesModel, (arg_5) => {
            dispatchLocal_1(new LocalMsg_1(20, [arg_5]));
        });
        let matchResult;
        if (model_1.ViewMode.tag === 0) {
            if (contains(new VisualizationType(0, [meshViewportLocation]), Compare_previousMeshTypes, {
                Equals: equals,
                GetHashCode: safeHash,
            })) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                staticNotesProps = (new NotesProps(append_1([new StaticLabel("00000000-0000-0000-0000-000000000000", equals(meshViewportLocation, Compare_previousStudyViewport) ? "Previous Study" : "Current Study", 0, 0, 0, undefined, undefined, false, true)], props.staticLabels), props.wrapLines, props.worldLabels, props.onStaticLabelDragEnd));
                break;
            }
            default:
                staticNotesProps = props;
        }
        const props_3 = [new HTMLAttr(99, [toString(meshViewportLocation)]), new HTMLAttr(65, [viewportClassNames]), new DOMAttr(52, [(_arg) => {
            dispatchToggleHoverMsg(model_1.IsSliceScroll);
        }]), new DOMAttr(53, [(_arg_1) => {
            dispatchToggleHoverMsg(false);
        }]), ["style", keyValueList([toDisplay(new VisualizationType(0, [meshViewportLocation]))], 1)]];
        const children_1 = [(props_2 = {
            currentStudyMeshes: unwrap(map(Meshes_Current_567A3FC6, FSharpMap__get_Item(model_1.Meshes, meshViewportLocation))),
            previousStudyMeshes: unwrap(bind(Meshes_Previous_567A3FC6, FSharpMap__get_Item(model_1.Meshes, meshViewportLocation))),
            showPreviousStudyMapsOnly: showingPreviousOnly,
            selectedMap: viewPortArgs.map,
            showCenterline: model_1.DisplayCenterline,
            overlayPreviousStudyMaps: model_1.DisplayPreviousStudyMaps,
            interactionModeProps: interactionProps(new VisualizationType(0, [meshViewportLocation])),
            onViewportResized: onViewportResized,
            staticNotesProps: staticNotesProps,
            measurementNotesProps: new NotesProps(measurementNotes, (x_3) => x_3, new Array(0), (note) => {
                dispatchLocal_1(new LocalMsg_1(28, [note]));
            }),
            cameraProps: ModelCamera_Props(viewPortArgs.planeAdjustment, (arg_6) => {
                dispatchLocal_1(new LocalMsg_1(21, [arg_6]));
            }, model_1.CameraModel),
            calipersProps: ModelCalipers_Props_69A04C54(model_1.CalipersModel),
            centerlineMeasurementProps: ModelCenterlineMeasurement_Props(target, model_1.CenterlineMeasurementModel),
            screenshotProps: ModelScreenshot_Props_Z625F521((arg_7) => {
                dispatchLocal_1(new LocalMsg_1(24, [arg_7]));
            }),
            slicePlaneProps: ModelSlicePlane_Props_Z714BB56C(model_1.SlicePlaneModel),
            anatomyOrientationProps: ModelAnatomyOrientation_Props_Z550008EC(model_1.AnatomyOrientationModel),
            pickedMapValueProps: ModelPickedMapValue_Props(model_1.PickedMapValueModel, meshViewportLocation, (arg_8) => {
                dispatchLocal_1(new LocalMsg_1(27, [arg_8]));
            }),
            meshViewportLocation: meshViewportLocation,
            isSliceScroll: model_1.IsSliceScroll,
            onMeshSliceChange: (arg_11) => {
                dispatchLocal_1(new LocalMsg_1(25, [wrapInboundMsg(new InboundMsg(1, [arg_11]))]));
            },
        }, react.createElement(MeshVisualization, props_2)), mkViewportToolPanel([mkMapSelect(dispatch_1, meshViewportLocation, model_1.ViewMode, model_1.AvailableMaps, viewPortArgs.map)])];
        return react.createElement("div", keyValueList(props_3, 1), ...children_1);
    };
    const toDisplay_1 = (visualizationType) => {
        let matchValue_2;
        return new CSSProp(125, [(matchValue_2 = model.ViewMode, (matchValue_2.tag === 1) ? (equals(matchValue_2.fields[0], visualizationType) ? "block" : "none") : ((matchValue_2.tag === 0) ? (contains(visualizationType, Compare_previousMeshTypes, {
            Equals: equals,
            GetHashCode: safeHash,
        }) ? "block" : "none") : "block"))]);
    };
    let visualizationContainer;
    const matchValue_3 = model.ViewMode;
    switch (matchValue_3.tag) {
        case 1: {
            const props_8 = singleton_1(["style", {
                height: "100%",
            }]);
            visualizationContainer = ((children_6) => react.createElement("div", keyValueList(props_8, 1), ...children_6));
            break;
        }
        case 0: {
            const props_11 = ofArray([new HTMLAttr(65, ["layout-view-grid"]), ["style", {
                height: "100%",
                gridTemplateRows: "auto",
                gridTemplateColumns: "1fr 1fr",
            }]]);
            visualizationContainer = ((children_9) => react.createElement("div", keyValueList(props_11, 1), ...children_9));
            break;
        }
        default: {
            const props_5 = ofArray([new HTMLAttr(65, ["layout-view-grid"]), ["style", {
                gridTemplateRows: "1fr 1fr",
                gridTemplateColumns: "1fr 1fr",
            }]]);
            visualizationContainer = ((children_3) => react.createElement("div", keyValueList(props_5, 1), ...children_3));
        }
    }
    const children_21 = toList(delay(() => {
        let matchValue_4;
        return append((matchValue_4 = model.LoadingStatus, (matchValue_4.tag === 2) ? singleton(spinner("Extracting...")) : ((matchValue_4.tag === 0) ? singleton(Helpers_nothing) : ((matchValue_4.tag === 3) ? singleton(Helpers_nothing) : singleton(spinner("Downloading..."))))), delay(() => {
            let props_22, children_19, children_17, props_16, children_13, props_15, props_18;
            return singleton((props_22 = [new HTMLAttr(65, ["visualization-dynamic-height"]), ["style", {
                visibility: (model.LoadingStatus.tag === 3) ? "visible" : "hidden",
            }]], (children_19 = [mkMainToolPanel(model, dispatch), (children_17 = [visualizationContainer(ofArray([(props_16 = [new HTMLAttr(65, [viewportClassNames]), new DOMAttr(52, [(_arg_2) => {
                dispatchToggleHoverMsg(true);
            }]), new DOMAttr(53, [(_arg_3) => {
                dispatchToggleHoverMsg(false);
            }]), ["style", keyValueList([toDisplay_1(new VisualizationType(1, []))], 1)]], (children_13 = [(props_15 = {
                dicomFiles: defaultArg(map(DicomData_Dicom__617D7B5F, model.DicomData), new Array(0)),
                onSliceChange: (arg_14) => {
                    dispatchLocal(new LocalMsg_1(25, [wrapInboundMsg(new InboundMsg(0, [arg_14]))]));
                },
                onTouchStart: (e) => {
                    if (e.touches.length === 1) {
                        dispatchToggleHoverMsg(true);
                    }
                },
                onTouchEnd: (_arg_4) => {
                    dispatchToggleHoverMsg(false);
                },
                interactionModeProps: interactionProps(new VisualizationType(1, [])),
                screenshotProps: ModelScreenshot_Props_Z625F521((arg_15) => {
                    dispatchLocal(new LocalMsg_1(24, [arg_15]));
                }),
                isSliceScroll: model.IsSliceScroll,
                slicePlaneIndex: unwrap(model.SlicePlaneModel.Index),
            }, react.createElement(DicomVisualization, props_15))], react.createElement("div", keyValueList(props_16, 1), ...children_13))), meshViewport(toDisplay_1, new MeshViewportLocation(0, []), model, dispatch), meshViewport(toDisplay_1, new MeshViewportLocation(1, []), model, dispatch), meshViewport(toDisplay_1, new MeshViewportLocation(2, []), model, dispatch)])), (props_18 = [["style", keyValueList(toList(delay(() => append(singleton(new CSSProp(395, ["100%"])), delay(() => append(singleton(new CSSProp(189, ["100%"])), delay(() => append(singleton(new CSSProp(291, ["relative"])), delay(() => append(singleton(new CSSProp(83, ["100%"])), delay(() => ((equals(model.InteractionMode, new InteractionMode(5, [])) && !equals(model.ViewMode, new VisualizationViewMode(2, []))) ? singleton(new CSSProp(383, ["visible"])) : singleton(new CSSProp(383, ["hidden"]))))))))))))), 1)]], react.createElement("div", keyValueList(props_18, 1)))], react.createElement("div", {
                className: "viewports-height-correction",
            }, ...children_17))], react.createElement("div", keyValueList(props_22, 1), ...children_19))));
        }));
    }));
    return react.createElement("div", {}, ...children_21);
}

export function view(model, dispatch) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    const children = [interactiveView(model, dispatch), notesModal(model.NotesModel, (arg_1) => {
        dispatch(wrapLocalMsg(new LocalMsg_1(20, [arg_1])));
    }), deleteMeasurementModal(model, model.Measurements, dispatchLocal), screenshotModal(model.ScreenshotModel, (arg_3) => {
        dispatch(wrapLocalMsg(new LocalMsg_1(24, [arg_3])));
    }, !canUserEditCustomReportData(model))];
    return react.createElement("div", {}, ...children);
}

