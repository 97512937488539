import { isArrayLike, equals, createAtom } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { printf, toText } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { SaveReportArgs, OutboundMsg, Operation, LocalMsg, Operation_display_Z518F1114, ModelReportPrompt, Mode as Mode_1 } from "./ReportPromptTypes.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { PatientCreationViewModel_get_empty, HospitalPatientIdViewModel_get_institutionId_, PatientCreationViewModel_get_hospitalPatientIdVm_, PatientCreationViewModel_get_id_ } from "../../../RAWMap.Models/View/Patient.js";
import { Common_GeneratedReportType, Common_ReportType } from "../../../RAWMap.Models/Common.js";
import { Cmd_batch, Cmd_OfPromise_either, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { ErrorMessage, ErrorMessage_get_describe, Terminate_TerminateError } from "../../../RAWMap.Models/ErrorMessage.js";
import { some } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Toast_successToast, Toast_errorToast } from "../../../Common/General.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedApi } from "../../../Api.js";
import { S3_DeleteObjectRequestParams_get_create, S3_DeleteObjectRequestParams, S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create, S3_GetObjectRequestParams_get_create, S3_GetObjectRequestParams } from "../../../bindings/Fable.Helpers.Aws.js";
import { AsyncResult_ofError, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { AsyncResult_ofPromise } from "../../../Common/Extensions.js";
import { PDFDocument } from "pdf-lib";
import { makeAndPrependTitlePage as makeAndPrependTitlePage_1, mkReportSections } from "../ReportPdf/Sections/MakeReportSections.js";
import { makeReportArgs } from "./ReportGeneration.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { utcNow } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { CustomReportMetadataViewModel } from "../../../RAWMap.Models/View/CustomReport.js";
import { TelemetryAction, FileOperationContext } from "../../../RAWMap.Models/Api.js";
import { TitlePageArgsModule_TitlePageHelperArgs, TitlePageArgsModule_mkTitlePageArgs } from "../ReportPdf/Sections/TitlePageSection.js";
import { Shared_UniversalArgs } from "../ReportPdf/Sections/SharedSection.js";
import { update as update_1 } from "../../../Common/InboundOutbound.js";

export let pdfDocument = createAtom(undefined);

export function makeUploadDestinationPath(patientId, studyId) {
    return toText(printf("%O/%O/Reports/LastGeneratedReport/report.pdf"))(patientId)(studyId);
}

export function init(initArgs) {
    let l;
    return [new ModelReportPrompt(initArgs.selectedStudy, initArgs.studyAnalysis, initArgs.maybePreviousStudy, initArgs.geometryData, initArgs.growthData, initArgs.maybeCurrentStudyAcquisitionDate, initArgs.maybePreviousStudyAcquisitionDate, initArgs.promptType, new Mode_1(0, []), initArgs.initUserRoles, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), PatientCreationViewModel_get_id_())(initArgs.selectedPatient.id)(Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l = PatientCreationViewModel_get_hospitalPatientIdVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), HospitalPatientIdViewModel_get_institutionId_())(l)))(initArgs.patientInstitution.id)(PatientCreationViewModel_get_empty())), new Common_ReportType(1, []), new Common_GeneratedReportType(1, [])), Cmd_none()];
}

export function updateInbound(_args, _msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

function setMode(model, mode) {
    return new ModelReportPrompt(model.SelectedStudy, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.PromptType, mode, model.UserRoles, model.PatientCreationInfo, model.ReportTypeToGenerate, model.GeneratedReportType);
}

export function tryTerminate(model) {
    const matchValue = model.Mode;
    if (matchValue.tag === 0) {
        return new FSharpResult$2(0, [() => {
        }]);
    }
    else {
        return new FSharpResult$2(1, [new Terminate_TerminateError(2, [Operation_display_Z518F1114(matchValue.fields[0])])]);
    }
}

export function showError(model, message) {
    console.error(some(message));
    return [setMode(model, new Mode_1(0, [])), Toast_errorToast(message), Cmd_none()];
}

export function updateLocal(args, msg, model) {
    let arg_1, arg_6, arg_8, arg_13, arg_15, arg_18, arg_20;
    const model_1 = !equals(model.UserRoles, args.userRoles) ? (new ModelReportPrompt(model.SelectedStudy, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.PromptType, model.Mode, args.userRoles, model.PatientCreationInfo, model.ReportTypeToGenerate, model.GeneratedReportType)) : model;
    let matchResult, ex, setter, setter_1, e, path, saveArgs, e_1, pdfDoc, e_2, pdfDoc_1, meta, saveReportArgs, e_3, meta_1, saveReportArgs_1, reportStatus, saveReportArgs_2, e_4, saveReportArgs_3, saveReportArgs_4, e_5, path_1, path_2, saveReportArgs_6, e_6;
    switch (msg.tag) {
        case 1: {
            matchResult = 1;
            setter = msg.fields[0];
            break;
        }
        case 2: {
            matchResult = 2;
            setter_1 = msg.fields[0];
            break;
        }
        case 4: {
            matchResult = 3;
            break;
        }
        case 5: {
            matchResult = 4;
            break;
        }
        case 3: {
            if (msg.fields[0].tag === 0) {
                if (msg.fields[0].fields[0]) {
                    switch (model_1.PromptType.tag) {
                        case 0: {
                            matchResult = 12;
                            break;
                        }
                        case 2: {
                            matchResult = 32;
                            path_1 = model_1.PromptType.fields[0];
                            break;
                        }
                        default: {
                            matchResult = 7;
                            path = model_1.PromptType.fields[0];
                        }
                    }
                }
                else {
                    matchResult = 6;
                }
            }
            else {
                matchResult = 5;
                e = msg.fields[0].fields[0];
            }
            break;
        }
        case 6: {
            if (model_1.PromptType.tag === 1) {
                matchResult = 7;
                path = model_1.PromptType.fields[0];
            }
            else {
                matchResult = 8;
            }
            break;
        }
        case 7: {
            if (msg.fields[0].tag === 1) {
                matchResult = 10;
                e_1 = msg.fields[0].fields[0];
            }
            else if (model_1.Mode.tag === 1) {
                if (model_1.Mode.fields[0].tag === 0) {
                    matchResult = 9;
                    saveArgs = msg.fields[0].fields[0];
                }
                else {
                    matchResult = 11;
                }
            }
            else {
                matchResult = 11;
            }
            break;
        }
        case 8: {
            if (model_1.PromptType.tag === 0) {
                matchResult = 12;
            }
            else {
                matchResult = 13;
            }
            break;
        }
        case 9: {
            if (msg.fields[0].tag === 1) {
                matchResult = 15;
                e_2 = msg.fields[0].fields[0];
            }
            else if (model_1.Mode.tag === 1) {
                if (model_1.Mode.fields[0].tag === 1) {
                    matchResult = 14;
                    pdfDoc = msg.fields[0].fields[0];
                }
                else {
                    matchResult = 16;
                }
            }
            else {
                matchResult = 16;
            }
            break;
        }
        case 10: {
            if (model_1.Mode.tag === 1) {
                if (model_1.Mode.fields[0].tag === 1) {
                    matchResult = 17;
                    pdfDoc_1 = msg.fields[0];
                }
                else {
                    matchResult = 18;
                }
            }
            else {
                matchResult = 18;
            }
            break;
        }
        case 11: {
            if (msg.fields[0].tag === 1) {
                matchResult = 21;
                e_3 = msg.fields[0].fields[0];
            }
            else if (msg.fields[0].fields[0].metadata != null) {
                if (model_1.Mode.tag === 1) {
                    if (model_1.Mode.fields[0].tag === 2) {
                        matchResult = 19;
                        meta = msg.fields[0].fields[0].metadata;
                        saveReportArgs = msg.fields[0].fields[0];
                    }
                    else {
                        matchResult = 22;
                    }
                }
                else {
                    matchResult = 22;
                }
            }
            else if (model_1.Mode.tag === 1) {
                if (model_1.Mode.fields[0].tag === 2) {
                    matchResult = 20;
                }
                else {
                    matchResult = 22;
                }
            }
            else {
                matchResult = 22;
            }
            break;
        }
        case 12: {
            if (msg.fields[0].metadata != null) {
                if (model_1.Mode.tag === 1) {
                    if (model_1.Mode.fields[0].tag === 2) {
                        matchResult = 23;
                        meta_1 = msg.fields[0].metadata;
                        saveReportArgs_1 = msg.fields[0];
                    }
                    else {
                        matchResult = 24;
                    }
                }
                else {
                    matchResult = 24;
                }
            }
            else {
                matchResult = 24;
            }
            break;
        }
        case 13: {
            if (msg.fields[1].tag === 1) {
                matchResult = 26;
                e_4 = msg.fields[1].fields[0];
            }
            else if (model_1.Mode.tag === 1) {
                if (model_1.Mode.fields[0].tag === 3) {
                    matchResult = 25;
                    reportStatus = msg.fields[1].fields[0];
                    saveReportArgs_2 = msg.fields[0];
                }
                else {
                    matchResult = 27;
                }
            }
            else {
                matchResult = 27;
            }
            break;
        }
        case 14: {
            matchResult = 28;
            saveReportArgs_3 = msg.fields[0];
            break;
        }
        case 15: {
            if (msg.fields[1].tag === 1) {
                matchResult = 30;
                e_5 = msg.fields[1].fields[0];
            }
            else if (model_1.Mode.tag === 1) {
                if (model_1.Mode.fields[0].tag === 5) {
                    matchResult = 29;
                    saveReportArgs_4 = msg.fields[0];
                }
                else {
                    matchResult = 31;
                }
            }
            else {
                matchResult = 31;
            }
            break;
        }
        case 16: {
            if (model_1.PromptType.tag === 2) {
                matchResult = 33;
                path_2 = model_1.PromptType.fields[0];
            }
            else {
                matchResult = 34;
            }
            break;
        }
        case 17: {
            if (msg.fields[1].tag === 1) {
                matchResult = 36;
                e_6 = msg.fields[1].fields[0];
            }
            else if (model_1.Mode.tag === 1) {
                if (model_1.Mode.fields[0].tag === 6) {
                    matchResult = 35;
                    saveReportArgs_6 = msg.fields[0];
                }
                else {
                    matchResult = 37;
                }
            }
            else {
                matchResult = 37;
            }
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            const model_2 = setMode(model_1, new Mode_1(0, []));
            console.error(some("ReportPrompt exception:"), ex);
            return [model_2, Cmd_none(), Cmd_none()];
        }
        case 1:
            return [new ModelReportPrompt(model_1.SelectedStudy, model_1.StudyAnalysis, model_1.MaybePreviousStudy, model_1.GeometryData, model_1.GrowthData, model_1.MaybeCurrentStudyAcquisitionDate, model_1.MaybePreviousStudyAcquisitionDate, model_1.PromptType, model_1.Mode, model_1.UserRoles, setter(model_1.PatientCreationInfo), model_1.ReportTypeToGenerate, model_1.GeneratedReportType), Cmd_none(), Cmd_none()];
        case 2:
            return [setter_1(model_1), Cmd_none(), Cmd_none()];
        case 3: {
            const matchValue_1 = model_1.ReportTypeToGenerate;
            const matchValue_2 = model_1.PromptType;
            let matchResult_1;
            if (matchValue_1 != null) {
                if (matchValue_1.tag === 0) {
                    matchResult_1 = 2;
                }
                else {
                    switch (matchValue_2.tag) {
                        case 0: {
                            matchResult_1 = 1;
                            break;
                        }
                        case 2: {
                            matchResult_1 = 3;
                            break;
                        }
                        default:
                            matchResult_1 = 0;
                    }
                }
            }
            else {
                switch (matchValue_2.tag) {
                    case 0: {
                        matchResult_1 = 1;
                        break;
                    }
                    case 2: {
                        matchResult_1 = 3;
                        break;
                    }
                    default:
                        matchResult_1 = 0;
                }
            }
            switch (matchResult_1) {
                case 0:
                    return [model_1, singleton((dispatch) => {
                        dispatch(new LocalMsg(6, []));
                    }), Cmd_none()];
                case 1:
                    return [model_1, singleton((dispatch_1) => {
                        dispatch_1(new LocalMsg(8, []));
                    }), Cmd_none()];
                case 2:
                    return [new ModelReportPrompt(model_1.SelectedStudy, model_1.StudyAnalysis, model_1.MaybePreviousStudy, model_1.GeometryData, model_1.GrowthData, model_1.MaybeCurrentStudyAcquisitionDate, model_1.MaybePreviousStudyAcquisitionDate, model_1.PromptType, new Mode_1(1, [new Operation(4, [])]), model_1.UserRoles, model_1.PatientCreationInfo, model_1.ReportTypeToGenerate, model_1.GeneratedReportType), Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedApi(args.fileArgs.Token).verifyPatientInfo, model_1.PatientCreationInfo, (Item) => (new LocalMsg(3, [Item])), (Item_1) => (new LocalMsg(0, [Item_1]))), Cmd_none()];
                default:
                    return [model_1, singleton((dispatch_2) => {
                        dispatch_2(new LocalMsg(16, []));
                    }), Cmd_none()];
            }
        }
        case 4:
            return [model_1, Cmd_none(), singleton((dispatch_3) => {
                dispatch_3(new OutboundMsg(0, []));
            })];
        case 5:
            return showError(model_1, (arg_1 = ErrorMessage_get_describe()(e), toText(printf("Error: %s"))(arg_1)));
        case 6:
            return showError(model_1, "Patient info invalid");
        case 7:
            return [setMode(model_1, new Mode_1(1, [new Operation(0, [])])), Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, () => {
                let downloadPromise;
                const getParams = new S3_GetObjectRequestParams(args.fileArgs.Context.ActiveBucket, path);
                let download;
                const callback = S3_GetObjectRequestParams_get_create()(getParams);
                download = args.fileArgs.Context.S3Client.getObject(callback);
                downloadPromise = download.promise();
                return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((Item_2) => (new ErrorMessage(1, [Item_2])), AsyncResult_ofPromise()(downloadPromise)), (_arg) => {
                    let buffer, arg_2;
                    const matchValue_4 = _arg.Body;
                    return (matchValue_4 == null) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult(new FSharpResult$2(1, [new ErrorMessage(35, ["get request body empty"])]))) : (isArrayLike(matchValue_4) ? ((buffer = matchValue_4, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((Item_3) => (new ErrorMessage(1, [Item_3])), AsyncResult_ofPromise()((arg_2 = (buffer.buffer), PDFDocument.load(arg_2)))), (_arg_1) => {
                        pdfDocument(_arg_1);
                        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, new SaveReportArgs(undefined));
                    }))) : AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult(new FSharpResult$2(1, [new ErrorMessage(35, ["unexpected return type"])]))));
                }));
            }, undefined, (Item_6) => (new LocalMsg(7, [Item_6])), (arg_4) => (new LocalMsg(7, [new FSharpResult$2(1, [new ErrorMessage(1, [arg_4])])]))), Cmd_none()];
        case 8:
            return showError(model_1, "Error when downloading report: incorrect prompt type");
        case 9:
            return [model_1, singleton((dispatch_4) => {
                dispatch_4(new LocalMsg(14, [saveArgs]));
            }), Cmd_none()];
        case 10:
            return showError(model_1, (arg_6 = ErrorMessage_get_describe()(e_1), toText(printf("Error when downloading report: %s"))(arg_6)));
        case 11:
            return showError(model_1, "Error when downloading report: incorrect mode");
        case 12: {
            const makeReportCommand = Cmd_OfAsyncWith_either((x_6) => {
                Cmd_OfAsync_start(x_6);
            }, mkReportSections, makeReportArgs(args, model_1), (Item_10) => (new LocalMsg(9, [Item_10])), (Item_11) => (new LocalMsg(0, [Item_11])));
            return [setMode(model_1, new Mode_1(1, [new Operation(1, [])])), makeReportCommand, Cmd_none()];
        }
        case 13:
            return showError(model_1, "Error when generating report: incorrect prompt type");
        case 14:
            return [model_1, singleton((dispatch_5) => {
                dispatch_5(new LocalMsg(10, [pdfDoc]));
            }), Cmd_none()];
        case 15:
            return showError(model_1, (arg_8 = ErrorMessage_get_describe()(e_2), toText(printf("Error when generating report: %s"))(arg_8)));
        case 16:
            return showError(model_1, "Error when generating report: incorrect mode");
        case 17: {
            const uploadedFilePath = makeUploadDestinationPath(model_1.SelectedStudy.patientId, model_1.SelectedStudy.studyId);
            const blob = pdfDoc_1.output('blob');
            let upload;
            const params = S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, [args.fileArgs.Context.ActiveBucket]), new S3_PutObjectRequestParams(3, [uploadedFilePath]), new S3_PutObjectRequestParams(1, [blob])]));
            upload = args.fileArgs.Context.S3Client.upload(params);
            return [setMode(model_1, new Mode_1(1, [new Operation(2, [])])), Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let pr;
                return ((pr = upload.promise(), pr.then((value) => {
                }))).then(() => (PDFDocument.load(pdfDoc_1.output('arraybuffer')).then((_arg_3) => {
                    pdfDocument(_arg_3);
                    return Promise.resolve(new SaveReportArgs(new CustomReportMetadataViewModel(model_1.SelectedStudy.studyId, uploadedFilePath, utcNow(), false)));
                })));
            })), undefined, (arg_9) => (new LocalMsg(11, [new FSharpResult$2(0, [arg_9])])), (arg_11) => (new LocalMsg(11, [new FSharpResult$2(1, [new ErrorMessage(1, [arg_11])])]))), Cmd_none()];
        }
        case 18:
            return showError(model_1, "Error when uploading report: incorrect mode");
        case 19: {
            const filePath = makeUploadDestinationPath(model_1.SelectedStudy.patientId, model_1.SelectedStudy.studyId);
            return [model_1, Cmd_batch(ofArray([singleton((dispatch_6) => {
                dispatch_6(new LocalMsg(12, [saveReportArgs]));
            }), Cmd_OfAsyncWith_attempt((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, securedApi(args.fileArgs.Token).telemetry, new TelemetryAction(0, [new FileOperationContext(5, [model_1.SelectedStudy.studyId]), singleton(filePath)]), (Item_17) => (new LocalMsg(0, [Item_17])))])), Cmd_none()];
        }
        case 20:
            return showError(model_1, "Error when uploading report: missing metadata");
        case 21:
            return showError(model_1, (arg_13 = ErrorMessage_get_describe()(e_3), toText(printf("Error when uploading report: %s"))(arg_13)));
        case 22:
            return showError(model_1, "Error when uploading report: incorrect mode");
        case 23:
            return [setMode(model_1, new Mode_1(1, [new Operation(3, [])])), Cmd_OfAsyncWith_either((x_8) => {
                Cmd_OfAsync_start(x_8);
            }, securedApi(args.fileArgs.Token).editOrCreateCustomReportMetadata, meta_1, (r) => (new LocalMsg(13, [saveReportArgs_1, r])), (Item_18) => (new LocalMsg(0, [Item_18]))), Cmd_none()];
        case 24:
            return showError(model_1, "Error when updating report metadata: incorrect mode");
        case 25:
            return [model_1, singleton((dispatch_7) => {
                dispatch_7(new LocalMsg(14, [saveReportArgs_2]));
            }), singleton((dispatch_8) => {
                dispatch_8(new OutboundMsg(1, [reportStatus]));
            })];
        case 26:
            return showError(model_1, (arg_15 = ErrorMessage_get_describe()(e_4), toText(printf("Error when updating report metadata: %s"))(arg_15)));
        case 27:
            return showError(model_1, "Error when updating report metadata: incorrect mode");
        case 28: {
            const titlePageArgs = TitlePageArgsModule_mkTitlePageArgs(new TitlePageArgsModule_TitlePageHelperArgs(new Shared_UniversalArgs(args.fileArgs.Token, args.fileArgs.Context), model_1.SelectedStudy, args.fileArgs, model_1.ReportTypeToGenerate, model_1.PatientCreationInfo, model_1.GeneratedReportType));
            return [setMode(model_1, new Mode_1(1, [new Operation(5, [])])), Cmd_OfAsyncWith_either((x_9) => {
                Cmd_OfAsync_start(x_9);
            }, () => {
                if (pdfDocument() == null) {
                    return AsyncResult_ofError(new ErrorMessage(1, [new Error("Error when saving report: Report is None")]));
                }
                else {
                    return makeAndPrependTitlePage_1("report.pdf", pdfDocument(), titlePageArgs);
                }
            }, undefined, (r_1) => (new LocalMsg(15, [saveReportArgs_3, r_1])), (Item_22) => (new LocalMsg(0, [Item_22]))), Cmd_none()];
        }
        case 29: {
            let toast;
            const matchValue_5 = model_1.PromptType;
            toast = ((matchValue_5.tag === 0) ? Toast_successToast("Report created successfully") : ((matchValue_5.tag === 2) ? Cmd_none() : Toast_successToast("Report downloaded successfully")));
            const filePath_1 = makeUploadDestinationPath(model_1.SelectedStudy.patientId, model_1.SelectedStudy.studyId);
            return [setMode(model_1, new Mode_1(0, [])), Cmd_batch(ofArray([toast, Cmd_OfAsyncWith_attempt((x_10) => {
                Cmd_OfAsync_start(x_10);
            }, securedApi(args.fileArgs.Token).telemetry, new TelemetryAction(1, [new FileOperationContext(5, [model_1.SelectedStudy.studyId]), singleton(filePath_1)]), (Item_23) => (new LocalMsg(0, [Item_23])))])), singleton((dispatch_9) => {
                dispatch_9(new OutboundMsg(2, [saveReportArgs_4.metadata]));
            })];
        }
        case 30:
            return showError(model_1, (arg_18 = ErrorMessage_get_describe()(e_5), toText(printf("Error when saving report: %s"))(arg_18)));
        case 31:
            return showError(model_1, "Error when saving report: incorrect mode");
        case 32:
            return showError(model_1, "Error when verifying patient info: incorrect prompt type");
        case 33: {
            const clearedMetadata = new CustomReportMetadataViewModel(model_1.SelectedStudy.studyId, undefined, undefined, undefined);
            const saveReportArgs_5 = new SaveReportArgs(clearedMetadata);
            const deleteParams = new S3_DeleteObjectRequestParams(args.fileArgs.Context.ActiveBucket, path_2);
            let delete$;
            const callback_1 = S3_DeleteObjectRequestParams_get_create()(deleteParams);
            delete$ = args.fileArgs.Context.S3Client.deleteObject(callback_1);
            delete$.promise();
            return [setMode(model_1, new Mode_1(1, [new Operation(6, [])])), Cmd_OfAsyncWith_either((x_11) => {
                Cmd_OfAsync_start(x_11);
            }, securedApi(args.fileArgs.Token).editOrCreateCustomReportMetadata, clearedMetadata, (r_2) => (new LocalMsg(17, [saveReportArgs_5, r_2])), (Item_24) => (new LocalMsg(0, [Item_24]))), Cmd_none()];
        }
        case 34:
            return showError(model_1, "Error when deleting report: incorrect prompt type");
        case 35:
            return [setMode(model_1, new Mode_1(0, [])), Toast_successToast("Report deleted successfully"), singleton((dispatch_10) => {
                dispatch_10(new OutboundMsg(2, [saveReportArgs_6.metadata]));
            })];
        case 36:
            return showError(model_1, (arg_20 = ErrorMessage_get_describe()(e_6), toText(printf("Error when deleting report: %s"))(arg_20)));
        default:
            return showError(model_1, "Error when deleting report: incorrect mode");
    }
}

export function update(args, msg, model) {
    return update_1(updateLocal, updateInbound, args, msg, model);
}

