import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, lambda_type, unit_type, class_type, int32_type, string_type, record_type, option_type, list_type, union_type, float64_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { UploadSelection_$reflection, FileComponentSpecs_$reflection, FileType_$reflection } from "../../Shared.js";
import { ZipBatch_$reflection, ZipDirectory_$reflection } from "../../../Common/JsZipCommon.js";
import { S3Context_$reflection } from "../../../Common/AwsCommon.js";
import { S3ObjectViewModel_$reflection } from "../../../RAWMap.Models/Api.js";
import { FileData_$reflection } from "../../../Common/FileData.js";

export class ZipState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Waiting", "Zipping"];
    }
}

export function ZipState_$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.ZipState", [], ZipState, () => [[], [["percentComplete", float64_type]]]);
}

export class ZipBatchStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Queued", "ZippingBatch", "Complete"];
    }
}

export function ZipBatchStatus_$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.ZipBatchStatus", [], ZipBatchStatus, () => [[], [["percentComplete", float64_type]], []]);
}

export class FileSelectionMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AutoZipEnabledZipFile", "JustAutoZip", "OtherFile"];
    }
}

export function FileSelectionMode_$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.FileSelectionMode", [], FileSelectionMode, () => [[], [], [["maybeFileTypeRestrictions", option_type(list_type(FileType_$reflection()))]]]);
}

export class OngoingZip extends Record {
    constructor(directory, state) {
        super();
        this.directory = directory;
        this.state = state;
    }
}

export function OngoingZip_$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.OngoingZip", [], OngoingZip, () => [["directory", ZipDirectory_$reflection()], ["state", ZipState_$reflection()]]);
}

export class ActiveZipBatch extends Record {
    constructor(zipBatch, status) {
        super();
        this.zipBatch = zipBatch;
        this.status = status;
    }
}

export function ActiveZipBatch_$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.ActiveZipBatch", [], ActiveZipBatch, () => [["zipBatch", ZipBatch_$reflection()], ["status", ZipBatchStatus_$reflection()]]);
}

export class AutoZipFileSelectorUpdateArgs extends Record {
    constructor(token, numberOfAssociatedFiles, credentials, context) {
        super();
        this.token = token;
        this.numberOfAssociatedFiles = (numberOfAssociatedFiles | 0);
        this.credentials = credentials;
        this.context = context;
    }
}

export function AutoZipFileSelectorUpdateArgs_$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.AutoZipFileSelectorUpdateArgs", [], AutoZipFileSelectorUpdateArgs, () => [["token", string_type], ["numberOfAssociatedFiles", int32_type], ["credentials", class_type("Fable.Import.Aws.Credentials.Credentials")], ["context", S3Context_$reflection()]]);
}

export class Model extends Record {
    constructor(Specs, PathTestFiles, MaybeActiveZipBatch, OutstandingZips, MaybeCurrentZip) {
        super();
        this.Specs = Specs;
        this.PathTestFiles = PathTestFiles;
        this.MaybeActiveZipBatch = MaybeActiveZipBatch;
        this.OutstandingZips = OutstandingZips;
        this.MaybeCurrentZip = MaybeCurrentZip;
    }
}

export function Model_$reflection() {
    return record_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.Model", [], Model, () => [["Specs", FileComponentSpecs_$reflection()], ["PathTestFiles", list_type(S3ObjectViewModel_$reflection())], ["MaybeActiveZipBatch", option_type(ActiveZipBatch_$reflection())], ["OutstandingZips", list_type(OngoingZip_$reflection())], ["MaybeCurrentZip", option_type(OngoingZip_$reflection())]]);
}

export class LocalMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShowErrorToast", "UploadSelectionChanged", "ValidateFiles", "TriggerUploads", "StartZips", "CompleteZip", "ZipProgress", "UploadFailed", "MakeUploadObjects"];
    }
}

export function LocalMsg_$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.LocalMsg", [], LocalMsg, () => [[["Item", string_type]], [["Item", list_type(FileData_$reflection())]], [["onSuccess", tuple_type(Model_$reflection(), list_type(lambda_type(lambda_type(LocalMsg_$reflection(), unit_type), unit_type)), list_type(lambda_type(lambda_type(OutboundMsg_$reflection(), unit_type), unit_type)))]], [], [["Item", ActiveZipBatch_$reflection()]], [["Item", tuple_type(ZipDirectory_$reflection(), class_type("Browser.Types.File", undefined))]], [["Item", class_type("JSZipBindings.Metadata")]], [["fileName", string_type], ["Item2", class_type("System.Exception")]], [["Item", list_type(FileData_$reflection())]]]);
}

export class OutboundMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StartedZipping", "CompletedZipping", "SelectionMade", "AllUploadsComplete"];
    }
}

export function OutboundMsg_$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.OutboundMsg", [], OutboundMsg, () => [[], [], [["Item", UploadSelection_$reflection()]], []]);
}

export class InboundMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ClearFileList", "UploadCompleted"];
    }
}

export function InboundMsg_$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.Types.InboundMsg", [], InboundMsg, () => [[], []]);
}

export const labelText = {
    mainUpload: "Drag and drop or click here to select a DICOM directory to upload",
    zipCta: "Upload DICOM Directory",
};

