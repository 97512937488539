import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ofArray, map, forAll } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library-js.4.19.3/String.js";

export class SystemInformationViewModel extends Record {
    constructor(manufacturerName, manufacturerAddress, researchProductName, researchProductVersionNumber, researchUdi, clinicalProductName, clinicalProductVersionNumber, clinicalUdi, customerPortalLink, userManualLink, osRequirements, ramRequirements, chromeVersion, edgeVersion, firefoxVersion) {
        super();
        this.manufacturerName = manufacturerName;
        this.manufacturerAddress = manufacturerAddress;
        this.researchProductName = researchProductName;
        this.researchProductVersionNumber = researchProductVersionNumber;
        this.researchUdi = researchUdi;
        this.clinicalProductName = clinicalProductName;
        this.clinicalProductVersionNumber = clinicalProductVersionNumber;
        this.clinicalUdi = clinicalUdi;
        this.customerPortalLink = customerPortalLink;
        this.userManualLink = userManualLink;
        this.osRequirements = osRequirements;
        this.ramRequirements = ramRequirements;
        this.chromeVersion = chromeVersion;
        this.edgeVersion = edgeVersion;
        this.firefoxVersion = firefoxVersion;
    }
}

export function SystemInformationViewModel_$reflection() {
    return record_type("RAWMap.Models.View.SystemInformationViewModel", [], SystemInformationViewModel, () => [["manufacturerName", string_type], ["manufacturerAddress", string_type], ["researchProductName", string_type], ["researchProductVersionNumber", string_type], ["researchUdi", string_type], ["clinicalProductName", string_type], ["clinicalProductVersionNumber", string_type], ["clinicalUdi", string_type], ["customerPortalLink", string_type], ["userManualLink", string_type], ["osRequirements", string_type], ["ramRequirements", string_type], ["chromeVersion", string_type], ["edgeVersion", string_type], ["firefoxVersion", string_type]]);
}

export function SystemInformationViewModel_get_empty() {
    return new SystemInformationViewModel("", "", "", "", "", "", "", "", "", "", "", "", "", "", "");
}

export function SystemInformationViewModel_get_manufacturerName_() {
    return [(m) => m.manufacturerName, (v) => ((m_1) => (new SystemInformationViewModel(v, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_manufacturerAddress_() {
    return [(m) => m.manufacturerAddress, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, v, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_researchProductName_() {
    return [(m) => m.researchProductName, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, v, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_researchProductVersionNumber_() {
    return [(m) => m.researchProductVersionNumber, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, v, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_researchUdi_() {
    return [(m) => m.researchUdi, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, v, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_clinicalProductName_() {
    return [(m) => m.clinicalProductName, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, v, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_clinicalProductVersionNumber_() {
    return [(m) => m.clinicalProductVersionNumber, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, v, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_clinicalUdi_() {
    return [(m) => m.clinicalUdi, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, v, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_customerPortalLink_() {
    return [(m) => m.customerPortalLink, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, v, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_userManualLink_() {
    return [(m) => m.userManualLink, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, v, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_osRequirements_() {
    return [(m) => m.osRequirements, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, v, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_ramRequirements_() {
    return [(m) => m.ramRequirements, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, v, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_chromeVersion_() {
    return [(m) => m.chromeVersion, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, v, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_edgeVersion_() {
    return [(m) => m.edgeVersion, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, v, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_firefoxVersion_() {
    return [(m) => m.firefoxVersion, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.researchProductName, m_1.researchProductVersionNumber, m_1.researchUdi, m_1.clinicalProductName, m_1.clinicalProductVersionNumber, m_1.clinicalUdi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, v)))];
}

export function SystemInformationViewModel_isValid_41AC81A9(vm) {
    return forAll((x) => x, map((arg) => !isNullOrWhiteSpace(arg), ofArray([vm.manufacturerName, vm.manufacturerAddress, vm.researchProductName, vm.researchProductVersionNumber, vm.researchUdi, vm.clinicalProductName, vm.clinicalProductVersionNumber, vm.clinicalUdi, vm.customerPortalLink, vm.userManualLink, vm.osRequirements, vm.ramRequirements, vm.chromeVersion, vm.edgeVersion, vm.firefoxVersion])));
}

