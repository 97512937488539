import { singleton, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { LocalMsg, OutboundMsg, Model } from "./DragDropTypes.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { printf, toText } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { some } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Toast_errorToast } from "../../../Common/General.js";
import { validateFiles } from "../../Validation.js";
import { UploadSelection } from "../../Shared.js";
import { update as update_1 } from "../../../Common/InboundOutbound.js";

export function init(specs) {
    return [new Model(specs, empty(), empty()), Cmd_none()];
}

export function updateLocal(args, msg, model) {
    switch (msg.tag) {
        case 1: {
            console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
            return [model, Cmd_none(), Cmd_none()];
        }
        case 2:
            return [model, Toast_errorToast(msg.fields[0]), Cmd_none()];
        case 4:
            return validateFiles(msg.fields[0], msg.fields[1], model, model.Specs, (_model) => args.numberOfAssociatedFiles);
        case 3: {
            const files = msg.fields[0];
            return [model, singleton((dispatch_1) => {
                dispatch_1(new LocalMsg(4, [files, (model_1) => {
                    let msg_1;
                    return [new Model(model_1.Specs, model_1.PathTestFiles, files), Cmd_none(), (msg_1 = (new OutboundMsg(new UploadSelection(files, false))), singleton((dispatch) => {
                        dispatch(msg_1);
                    }))];
                }]));
            }), Cmd_none()];
        }
        default:
            return [model, Cmd_none(), Cmd_none()];
    }
}

export function updateInbound(_args, msg, model) {
    return [new Model(model.Specs, model.PathTestFiles, empty()), Cmd_none(), Cmd_none()];
}

export function update(args, msg, model) {
    return update_1(updateLocal, updateInbound, args, msg, model);
}

