import { content, Header_icon, header, card } from "../../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { singleton, ofArray, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Option, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { DOMAttr, HTMLAttr } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { Msg } from "./FileParentTypes.js";
import { append, singleton as singleton_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { h2 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { view as view_1 } from "../FileManagementView.js";
import { uploadControl } from "../FileUploadSelectors/AutoZipSelector/AutoZipView.js";
import { childAbsentStrategy } from "./FileParentState.js";
import { ChainedChildMsg$2 } from "../../Common/ElmishDebugger/ChainedChildMsg.js";
import { Helpers_nothing } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { FileComponentVisibility_get_fileSystemUpload } from "../Shared.js";
import { ChildMsg_ofMsg } from "../../Common/Terminate.js";
import * as react from "react";

export function view(model, dispatch) {
    return card(empty(), ofArray([header(empty(), ofArray([Header_icon(empty(), singleton(button(ofArray([new Option(0, [new Color_IColor(8, [])]), new Option(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg) => {
        dispatch(new Msg(3, []));
    }])])])]), singleton("Create File Upload")))), Header_icon(empty(), singleton(button(ofArray([new Option(0, [new Color_IColor(8, [])]), new Option(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_1) => {
        dispatch(new Msg(4, []));
    }])])])]), singleton("Terminate File Upload"))))])), content(empty(), toList(delay(() => {
        const matchValue_1 = model.fileUpload.child;
        return (matchValue_1.tag === 1) ? singleton_1("No file upload available.") : ((matchValue_1.tag === 2) ? singleton_1("File upload terminated.") : append(singleton_1(h2(empty())(singleton("Default view arrangement"))), delay(() => {
            let children;
            return singleton_1((children = [view_1((maybeFileTypeRestrictions) => {
                const matchValue = model.autoZip.child;
                if (matchValue.tag === 0) {
                    return uploadControl(matchValue.fields[0], (childMsg) => {
                        dispatch(new Msg(6, [new ChainedChildMsg$2(childMsg, childAbsentStrategy)]));
                    }, maybeFileTypeRestrictions);
                }
                else {
                    return Helpers_nothing;
                }
            }, FileComponentVisibility_get_fileSystemUpload(), matchValue_1.fields[0], (arg_1) => {
                dispatch(new Msg(2, [ChildMsg_ofMsg(childAbsentStrategy, arg_1)]));
            }, undefined)], react.createElement("div", {
                className: "block",
            }, ...children)));
        })));
    })))]));
}

