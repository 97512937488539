import { Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { lambda_type, union_type, list_type, record_type, string_type, option_type, class_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ErrorMessage_$reflection } from "../../../../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { cons, singleton as singleton_1, partition, ofArray, append as append_1, concat, empty, head as head_2, tail as tail_1, isEmpty, filter, map } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { map2, flatten, defaultArg, bind, map as map_1 } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { singleton, append, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { List_distinct } from "../../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { uncurry2, arrayHash, equalArrays } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { CenterlineMeasurementValueViewModel_tableDisplay_297A6D85, CenterlineMeasurementValueViewModel_comparePrevious, CenterlineMeasurementViewModel_$reflection } from "../../../../RAWMap.Models/View/CenterlineMeasurement.js";
import { CalipersMeasurementViewModel_viewComponents_491575AE, CalipersMeasurementViewModel_$reflection } from "../../../../RAWMap.Models/View/CalipersMeasurement.js";
import { securedApi } from "../../../../Api.js";
import { Csv_CsvRowMulti } from "../../../../RAWMap.Models/Common.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Basic_make, Pdf_Table_ofCsvRows } from "../ReportPdfHelpers.js";
import { TimeDelta_calculateTimeDelta } from "./SharedSection.js";

export const notAvailable = "N/A";

export class Args extends Record {
    constructor(currentStudyId, maybePreviousStudyId, maybeCurrentStudyAcquisitionDate, maybePreviousStudyAcquisitionDate) {
        super();
        this.currentStudyId = currentStudyId;
        this.maybePreviousStudyId = maybePreviousStudyId;
        this.maybeCurrentStudyAcquisitionDate = maybeCurrentStudyAcquisitionDate;
        this.maybePreviousStudyAcquisitionDate = maybePreviousStudyAcquisitionDate;
    }
}

export function Args_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.Args", [], Args, () => [["currentStudyId", class_type("System.Guid")], ["maybePreviousStudyId", option_type(class_type("System.Guid"))], ["maybeCurrentStudyAcquisitionDate", option_type(string_type)], ["maybePreviousStudyAcquisitionDate", option_type(string_type)]]);
}

class ComparisonMeasurementDisplay extends Record {
    constructor(valueDiff) {
        super();
        this.valueDiff = valueDiff;
    }
}

function ComparisonMeasurementDisplay_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.ComparisonMeasurementDisplay", [], ComparisonMeasurementDisplay, () => [["valueDiff", string_type]]);
}

class CoreMeasurementDisplay extends Record {
    constructor(name, measurementType, currentValue, maybePreviousValue) {
        super();
        this.name = name;
        this.measurementType = measurementType;
        this.currentValue = currentValue;
        this.maybePreviousValue = maybePreviousValue;
    }
}

function CoreMeasurementDisplay_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.CoreMeasurementDisplay", [], CoreMeasurementDisplay, () => [["name", string_type], ["measurementType", string_type], ["currentValue", string_type], ["maybePreviousValue", option_type(string_type)]]);
}

class BundledMeasurement$1 extends Record {
    constructor(measurement, coreDisplay) {
        super();
        this.measurement = measurement;
        this.coreDisplay = coreDisplay;
    }
}

function BundledMeasurement$1_$reflection(gen0) {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.BundledMeasurement`1", [gen0], BundledMeasurement$1, () => [["measurement", gen0], ["coreDisplay", CoreMeasurementDisplay_$reflection()]]);
}

function BundledMeasurement_areSame(a, b) {
    return a.coreDisplay.name === b.coreDisplay.name;
}

class ReportMeasurementDisplay extends Record {
    constructor(measurementType, measurementName, maybeCore, maybeComparisonValue) {
        super();
        this.measurementType = measurementType;
        this.measurementName = measurementName;
        this.maybeCore = maybeCore;
        this.maybeComparisonValue = maybeComparisonValue;
    }
}

function ReportMeasurementDisplay_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.ReportMeasurementDisplay", [], ReportMeasurementDisplay, () => [["measurementType", string_type], ["measurementName", string_type], ["maybeCore", option_type(CoreMeasurementDisplay_$reflection())], ["maybeComparisonValue", option_type(ComparisonMeasurementDisplay_$reflection())]]);
}

class ReportMeasurement$1 extends Record {
    constructor(apiCall, comparison, makeCore) {
        super();
        this.apiCall = apiCall;
        this.comparison = comparison;
        this.makeCore = makeCore;
    }
}

function ReportMeasurement$1_$reflection(gen0) {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.ReportMeasurement`1", [gen0], ReportMeasurement$1, () => [["apiCall", lambda_type(string_type, lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(gen0), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(gen0)]], [["ErrorValue", ErrorMessage_$reflection()]]])])))], ["comparison", lambda_type(gen0, option_type(ComparisonMeasurementDisplay_$reflection()))], ["makeCore", lambda_type(gen0, CoreMeasurementDisplay_$reflection())]]);
}

function ReportMeasurement_getMeasurements(uni, studyId, reportMeasurement) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, reportMeasurement.apiCall(uni.token, studyId), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg)));
}

function ReportMeasurement_bundleMeasurements(measurements, reportMeasurement) {
    return map((measurement) => (new BundledMeasurement$1(measurement, reportMeasurement.makeCore(measurement))), measurements);
}

function ReportMeasurement_makeDisplay(currentMeasurements, reportMeasurement, name, measurementType) {
    const mapCore = (option) => map_1((bundled) => bundled.coreDisplay, option);
    let patternInput;
    const _arg = filter((current) => (current.coreDisplay.name === name), currentMeasurements);
    patternInput = (!isEmpty(_arg) ? (isEmpty(tail_1(_arg)) ? [head_2(_arg), empty()] : [head_2(_arg), tail_1(_arg)]) : [undefined, empty()]);
    const maybeCurrent = patternInput[0];
    const maybeComparison = bind((x) => x, map_1((current_1) => reportMeasurement.comparison(current_1.measurement), maybeCurrent));
    const displayExtraCurrent = map((measurement) => (new ReportMeasurementDisplay(measurementType, name, mapCore(measurement), undefined)), patternInput[1]);
    return toList(delay(() => append(singleton(new ReportMeasurementDisplay(measurementType, name, mapCore(maybeCurrent), maybeComparison)), delay(() => displayExtraCurrent))));
}

function ReportMeasurement_parseMeasurement(uni, args, reportMeasurement) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_getMeasurements(uni, args.currentStudyId, reportMeasurement), (_arg) => {
        const bundledCurrent = ReportMeasurement_bundleMeasurements(_arg, reportMeasurement);
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, concat(map((tupledArg) => ReportMeasurement_makeDisplay(bundledCurrent, reportMeasurement, tupledArg[0], tupledArg[1]), List_distinct(map((bundled) => [bundled.coreDisplay.name, bundled.coreDisplay.measurementType], bundledCurrent), {
            Equals: equalArrays,
            GetHashCode: arrayHash,
        }))));
    }));
}

class AllMeasurements extends Record {
    constructor(centerlineDistances, diameterRanges, localDiameters, centerlineVolumes, calipers) {
        super();
        this.centerlineDistances = centerlineDistances;
        this.diameterRanges = diameterRanges;
        this.localDiameters = localDiameters;
        this.centerlineVolumes = centerlineVolumes;
        this.calipers = calipers;
    }
}

function AllMeasurements_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.MeasurementSection.AllMeasurements", [], AllMeasurements, () => [["centerlineDistances", ReportMeasurement$1_$reflection(CenterlineMeasurementViewModel_$reflection())], ["diameterRanges", ReportMeasurement$1_$reflection(CenterlineMeasurementViewModel_$reflection())], ["localDiameters", ReportMeasurement$1_$reflection(CenterlineMeasurementViewModel_$reflection())], ["centerlineVolumes", ReportMeasurement$1_$reflection(CenterlineMeasurementViewModel_$reflection())], ["calipers", ReportMeasurement$1_$reflection(CalipersMeasurementViewModel_$reflection())]]);
}

function AllMeasurements_makeMeasurements(uni, args, allMeasurements_1) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.centerlineDistances), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.diameterRanges), (_arg_1) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.localDiameters), (_arg_2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.centerlineVolumes), (_arg_3) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, ReportMeasurement_parseMeasurement(uni, args, allMeasurements_1.calipers), (_arg_4) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, append_1(_arg, append_1(_arg_1, append_1(_arg_2, append_1(_arg_3, _arg_4)))))))))));
}

const allMeasurements = (() => {
    const mkReportMeasurement = (getMeasurementsForStudy, tryCompare, getMeasurementTypeAndValue, getMeasurementName) => (new ReportMeasurement$1(uncurry2(getMeasurementsForStudy), (current) => map_1((tupledArg) => (new ComparisonMeasurementDisplay(tupledArg[1])), tryCompare(current)), (measurement) => {
        const patternInput = getMeasurementTypeAndValue(measurement);
        const maybePreviousValue = map_1((tuple) => tuple[0], tryCompare(measurement));
        return new CoreMeasurementDisplay(getMeasurementName(measurement), patternInput[0], patternInput[1], maybePreviousValue);
    }));
    const tryCompareCenterlineMeasurements = (current_1) => bind((previousVm) => CenterlineMeasurementValueViewModel_comparePrevious(current_1.currentStudyValue, previousVm), current_1.maybePreviousStudyValue);
    const getCenterlineMeasurementTypeAndValue = (measurement_1) => CenterlineMeasurementValueViewModel_tableDisplay_297A6D85(measurement_1.currentStudyValue);
    const getCenterlineMeasurementName = (measurement_2) => measurement_2.name;
    return new AllMeasurements(mkReportMeasurement((token) => ((studyId) => securedApi(token).getCenterlineDistanceMeasurementsForStudy(studyId)), tryCompareCenterlineMeasurements, getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_1) => ((studyId_1) => securedApi(token_1).getDiameterRangeMeasurementsForStudy(studyId_1)), tryCompareCenterlineMeasurements, getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_2) => ((studyId_2) => securedApi(token_2).getLocalDiameterMeasurementsForStudy(studyId_2)), tryCompareCenterlineMeasurements, getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_3) => ((studyId_3) => securedApi(token_3).getCenterlineVolumeMeasurementsForStudy(studyId_3)), tryCompareCenterlineMeasurements, getCenterlineMeasurementTypeAndValue, getCenterlineMeasurementName), mkReportMeasurement((token_4) => ((studyId_4) => securedApi(token_4).getCalipersMeasurementsForStudy(studyId_4)), (_arg) => undefined, CalipersMeasurementViewModel_viewComponents_491575AE, (measurement_3) => measurement_3.name));
})();

function convertToReportRows(measurements) {
    return map((measurement) => (new Csv_CsvRowMulti(measurement.measurementName, ofArray([measurement.measurementType, defaultArg(map_1((current) => current.currentValue, measurement.maybeCore), notAvailable), defaultArg(bind((previous) => previous.maybePreviousValue, measurement.maybeCore), notAvailable), defaultArg(map_1((previous_1) => previous_1.valueDiff, measurement.maybeComparisonValue), notAvailable)]))), measurements);
}

export function create(doc, uniArgs, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AllMeasurements_makeMeasurements(uniArgs, args, allMeasurements), (_arg) => {
        let doc_4, doc_1, doc_6;
        let patternInput;
        const tupledArg = partition((m) => (m.maybeComparisonValue != null), _arg);
        patternInput = [convertToReportRows(tupledArg[0]), convertToReportRows(tupledArg[1])];
        const previousRows = patternInput[0];
        const currentRows = patternInput[1];
        const patternInput_1 = Pdf_Table_ofCsvRows(ofArray(["Measurement Name", "Measurement Type", "Measurement Value"]), currentRows);
        const patternInput_2 = Pdf_Table_ofCsvRows(ofArray(["Measurement Name", "Measurement Type", "Current Study - Measurement Value", "Previous Study - Measurement Value", "Difference"]), previousRows);
        const patternInput_3 = Pdf_Table_ofCsvRows(ofArray(["Current Acquisition Date", "Previous Acquisition Date", "Time Delta"]), singleton_1(new Csv_CsvRowMulti(defaultArg(args.maybeCurrentStudyAcquisitionDate, ""), ofArray([defaultArg(args.maybePreviousStudyAcquisitionDate, ""), defaultArg(map_1((tupledArg_1) => (`${tupledArg_1[0]} years, ${tupledArg_1[1]} months`), flatten(map2(TimeDelta_calculateTimeDelta, args.maybeCurrentStudyAcquisitionDate, args.maybePreviousStudyAcquisitionDate))), "N/A")]))));
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, (doc_4 = ((doc_1 = doc, isEmpty(currentRows) ? doc_1 : Pdf_Table_Basic_make("Manual Measurements", patternInput_1[0], patternInput_1[1], Pdf_Spacing_topMargin, doc_1.addPage()))), isEmpty(previousRows) ? doc_4 : ((doc_6 = Pdf_Table_Basic_make("Acquisition Time", patternInput_3[0], patternInput_3[1], Pdf_Spacing_topMargin, doc_4.addPage()), Pdf_Table_Basic_make("Manual Comparison Measurements", patternInput_2[0], patternInput_2[1], Pdf_Spacing_topMargin + defaultArg(flatten(map_1((table) => table.finalY, doc_6.lastAutoTable)), 0), doc_6)))));
    }));
}

export function getAllMeasurementsCsvRows(uniArgs, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AllMeasurements_makeMeasurements(uniArgs, args, allMeasurements), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, cons(new Csv_CsvRowMulti("Measurement Name", ofArray(["Measurement Type", "Value", "Previous Study - Measurement Value", "Difference"])), convertToReportRows(_arg)))));
}

