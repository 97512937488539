import { OutboundMsg, LocalMsg, MsgAnalysis, InternalMsg } from "./AnalysisFormTypes.js";
import { card as card_1, content as content_3, Header_title, header } from "../../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Option, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_1, column } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { Forms_parseDateAsUtc, Forms_formFieldDate, Forms_formFieldText, Forms_formFieldReactSelect, Layout_modalCardLayout, Forms_formFieldTextArea, TextDisplay_nullShowBlank, TextDisplay_splitParagraphs, Controls_asCard } from "../../Common/General.js";
import { view } from "./AnalysisFormFileManagement/AnalysisFormFileView.js";
import { FileComponentVisibility_get_fileSystemUpload, FileComponentVisibility_downloadOnly_Z510FEC72 } from "../../FileManagement/Shared.js";
import { Button_visibilityControlled, ComponentVisibility } from "../../Common/Controls.js";
import { map, toArray } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { content as content_4 } from "../../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import * as react from "react";
import { box$0027 } from "../../fable_modules/Fulma.3.0.0/Elements/Box.fs.js";
import { Color_IColor, Common_GenericOption } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { button, Option as Option_2 } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { DOMAttr, HTMLAttr } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { Option as Option_3, div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { Common_StudyStatus, Common_ReportType_get_display, Common_ReportType, String_toOption, String_fromOption } from "../../RAWMap.Models/Common.js";
import { AnalysisViewModel } from "../../RAWMap.Models/View/Analysis.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { equals, curry3 } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Permission, isAllowed } from "../../RAWMap.Models/Security.js";
import { HospitalPatientIdViewModel_get_hospitalPatientId_, NameDobViewModel_get_dateOfBirth_, NameDobViewModel_get_lastName_, NameDobViewModel_get_firstName_ } from "../../RAWMap.Models/View/Patient.js";

export const localMsg = (arg) => (new MsgAnalysis(0, [new InternalMsg(0, [arg])]));

export function detailView(model, intermediateFileModel, studyNumber, uiDataFileModel, isValid, canEdit, vm, selectedStudy, dispatch) {
    const dispatchLocal = (arg) => {
        dispatch(localMsg(arg));
    };
    return ofArray([header(empty(), singleton(Header_title(empty(), singleton(toText(printf("Analysis for Study #%i Details"))(studyNumber))))), content_3(empty(), toList(delay(() => append(canEdit ? singleton_1(columns(singleton(new Option(2, [])), toList(delay(() => append(singleton_1(column(singleton(new Option_1(3, [singleton(["style", {
        flexBasis: "500px",
    }])])), singleton(Controls_asCard("Analysis Report", singleton(view(FileComponentVisibility_downloadOnly_Z510FEC72(new ComponentVisibility(0, [])), model.StudyDataFileManager, (arg_2) => {
        dispatchLocal(new LocalMsg(3, [arg_2]));
    })))))), delay(() => append(ofArray(toArray(map((fileModel) => column(singleton(new Option_1(3, [singleton(["style", {
        flexShrink: "1",
        flexGrow: "1",
        flexBasis: "500px",
    }])])), singleton(Controls_asCard("Analysis Results", singleton(view(FileComponentVisibility_downloadOnly_Z510FEC72(new ComponentVisibility(0, [])), fileModel, (arg_3) => {
        dispatchLocal(new LocalMsg(4, [arg_3]));
    }))))), intermediateFileModel))), delay(() => singleton_1(column(singleton(new Option_1(3, [singleton(["style", {
        flexShrink: "1",
        flexGrow: "1",
        flexBasis: "500px",
    }])])), singleton(Controls_asCard("Analysis UI Data", singleton(view(FileComponentVisibility_downloadOnly_Z510FEC72(new ComponentVisibility(0, [])), uiDataFileModel, (arg_4) => {
        dispatchLocal(new LocalMsg(6, [arg_4]));
    })))))))))))))) : empty_1(), delay(() => append(singleton_1(content_4(empty(), ofArray([react.createElement("b", {}, "Analysis Comments:"), box$0027(singleton(new Common_GenericOption(1, [singleton(["style", {
        maxHeight: "300px",
        overflowY: "scroll",
    }])])), TextDisplay_splitParagraphs(TextDisplay_nullShowBlank(vm.analysisInfo)))]))), delay(() => {
        const visibility = !isValid ? (new ComponentVisibility(2, ["The report has been removed by an Analyst."])) : (new ComponentVisibility(0, []));
        const launchVisualizationButton = Button_visibilityControlled(undefined, visibility, ofArray([new Option_2(16, [!isValid]), new Option_2(0, [new Color_IColor(6, [])]), new Option_2(17, [ofArray([new HTMLAttr(99, ["visualizeData"]), new DOMAttr(40, [(_arg) => {
            dispatch(new MsgAnalysis(1, [new OutboundMsg(4, [selectedStudy])]));
        }])])])]), singleton("Visualize Data"));
        return singleton_1(div(singleton(new Option_3(4, [])), singleton(div_1(empty(), toList(delay(() => (!canEdit ? singleton_1(div(ofArray([new Option_3(4, []), new Option_3(7, [])]), ofArray([Button_visibilityControlled(undefined, visibility, ofArray([new Option_2(16, [!isValid]), new Option_2(0, [new Color_IColor(6, [])]), new Option_2(17, [singleton(new DOMAttr(40, [(_arg_1) => {
            dispatchLocal(new LocalMsg(17, []));
        }]))])]), singleton("ViTAA Report")), launchVisualizationButton]))) : singleton_1(div(ofArray([new Option_3(4, []), new Option_3(7, [])]), ofArray([Button_visibilityControlled(undefined, new ComponentVisibility(0, []), ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(17, [singleton(new DOMAttr(40, [(_arg_2) => {
            dispatchLocal(new LocalMsg(10, []));
        }]))])]), singleton("Edit")), launchVisualizationButton]))))))))));
    })))))))]);
}

export function studyForm(model, isValid, intermediateFileModel, _patientGuid, uiDataFileModel, vm, cancelCmd, dispatch) {
    const dispatchLocal = (arg_1) => {
        dispatch(new MsgAnalysis(0, [new InternalMsg(0, [arg_1])]));
    };
    return toList(delay(() => {
        let children;
        return singleton_1((children = [columns(singleton(new Option(2, [])), ofArray([column(singleton(new Option_1(3, [singleton(["style", {
            flexBasis: "500px",
        }])])), singleton(Controls_asCard("Analysis Report", singleton(view(FileComponentVisibility_get_fileSystemUpload(), model.StudyDataFileManager, (arg_2) => {
            dispatchLocal(new LocalMsg(3, [arg_2]));
        }))))), column(singleton(new Option_1(3, [singleton(["style", {
            flexShrink: "1",
            flexGrow: "1",
            flexBasis: "500px",
        }])])), singleton(Controls_asCard("Analysis Results", singleton(view(FileComponentVisibility_get_fileSystemUpload(), intermediateFileModel, (arg_3) => {
            dispatchLocal(new LocalMsg(4, [arg_3]));
        }))))), column(singleton(new Option_1(3, [singleton(["style", {
            flexShrink: "1",
            flexGrow: "1",
            flexBasis: "500px",
        }])])), singleton(Controls_asCard("Analysis UI Data", singleton(view(FileComponentVisibility_get_fileSystemUpload(), uiDataFileModel, (arg_4) => {
            dispatchLocal(new LocalMsg(6, [arg_4]));
        })))))])), react.createElement("hr", {}), columns(empty(), singleton(column(empty(), singleton(Forms_formFieldTextArea("Analysis Info", String_fromOption(vm.analysisInfo), singleton(new DOMAttr(9, [(e) => {
            dispatchLocal(new LocalMsg(8, [(pvm) => ((pvm_1, value) => (new AnalysisViewModel(pvm_1.studyId, String_toOption(value), pvm_1.associatedAnalyst, pvm_1.analysisCreationDate)))(pvm, e.target.value)]));
        }]))))))), div(singleton(new Option_3(5, [])), ofArray([div_1(empty(), toList(delay(() => {
            const _vmToValidate = new AnalysisViewModel((vm.studyId === "00000000-0000-0000-0000-000000000000") ? newGuid() : vm.studyId, vm.analysisInfo, vm.associatedAnalyst, vm.analysisCreationDate);
            return singleton_1(button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [!isValid]), new Option_2(17, [ofArray([new DOMAttr(40, [(_arg_1) => {
                dispatchLocal(new LocalMsg(11, []));
            }]), new HTMLAttr(159, ["button"])])])]), singleton("Save")));
        }))), div_1(empty(), singleton(button(ofArray([new Option_2(0, [new Color_IColor(8, [])]), new Option_2(17, [ofArray([new DOMAttr(40, [(_arg_2) => {
            dispatchLocal(cancelCmd);
        }]), new HTMLAttr(159, ["button"])])])]), singleton("Cancel"))))]))], react.createElement("form", {}, ...children)));
    }));
}

function reportSelectionModal(userRoles, reportType, identifiedPatientInfo, reportInfoIsValid, dispatch) {
    let children;
    const setVmProp = (dispatch_1, setMessage, vmChange) => (new DOMAttr(9, [(e) => {
        dispatch_1(setMessage((pvm) => {
            let value;
            return ((value = e.target.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), vmChange)(value)))(pvm);
        }));
    }]));
    const nameDobSetter = curry3(setVmProp)(dispatch)((arg_2) => localMsg(new LocalMsg(14, [arg_2])));
    const hospitalPatientIdSetter = curry3(setVmProp)(dispatch)((arg_3) => localMsg(new LocalMsg(15, [arg_3])));
    const reportOptions = isAllowed(new Permission(28, []))(userRoles) ? ofArray([new Common_ReportType(1, []), new Common_ReportType(0, [])]) : singleton(new Common_ReportType(1, []));
    return Layout_modalCardLayout("Select the Type of Report to Generate", empty(), singleton((children = toList(delay(() => append(singleton_1(Forms_formFieldReactSelect([], "Report Type", reportType, reportOptions, Common_ReportType_get_display(), (arg_5) => {
        dispatch(localMsg(new LocalMsg(21, [arg_5])));
    })), delay(() => {
        let l;
        return equals(reportType, new Common_ReportType(0, [])) ? singleton(columns(ofArray([new Option(1, []), new Option(2, [])]), ofArray([column(singleton(new Option_1(3, [singleton(["style", {
            overflowX: "auto",
        }])])), ofArray([Forms_formFieldText("First Name", identifiedPatientInfo.nameDobVm.firstName, singleton(nameDobSetter(NameDobViewModel_get_firstName_())), false), Forms_formFieldText("Last Name", identifiedPatientInfo.nameDobVm.lastName, singleton(nameDobSetter(NameDobViewModel_get_lastName_())), false)])), column(singleton(new Option_1(3, [singleton(["style", {
            overflowX: "auto",
        }])])), ofArray([Forms_formFieldDate("Date of Birth", identifiedPatientInfo.nameDobVm.dateOfBirth, singleton(nameDobSetter((l = NameDobViewModel_get_dateOfBirth_(), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), [(_arg) => "", Forms_parseDateAsUtc])(l))))), Forms_formFieldText("Institution Patient ID", "", singleton(hospitalPatientIdSetter(HospitalPatientIdViewModel_get_hospitalPatientId_())), false)]))]))) : empty();
    })))), react.createElement("form", {}, ...children))), singleton(div(singleton(new Option_3(5, [])), ofArray([div_1(empty(), singleton(button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [!reportInfoIsValid]), new Option_2(17, [ofArray([new DOMAttr(40, [(_arg_1) => {
        dispatch(localMsg(new LocalMsg(18, [])));
    }]), new HTMLAttr(159, ["button"])])])]), singleton("Generate Report")))), div_1(empty(), singleton(button(ofArray([new Option_2(0, [new Color_IColor(8, [])]), new Option_2(17, [ofArray([new DOMAttr(40, [(_arg_2) => {
        dispatch(localMsg(new LocalMsg(23, [])));
    }]), new HTMLAttr(159, ["button"])])])]), singleton("Cancel"))))]))));
}

export function card(model, disableButton, dispatch) {
    const action = (model.Mode.tag === 2) ? "Edit" : "Create";
    const analysisEditEnabled = !disableButton(new Permission(24, []));
    const matchValue_1 = model.Mode;
    const matchValue_2 = model.IntermediateZipFileManager;
    let matchResult, intermediateUpload;
    switch (matchValue_1.tag) {
        case 1: {
            if (matchValue_2 == null) {
                matchResult = 2;
            }
            else {
                matchResult = 1;
                intermediateUpload = matchValue_2;
            }
            break;
        }
        case 2: {
            if (matchValue_2 == null) {
                matchResult = 2;
            }
            else {
                matchResult = 1;
                intermediateUpload = matchValue_2;
            }
            break;
        }
        case 3: {
            if (matchValue_2 == null) {
                matchResult = 2;
            }
            else {
                matchResult = 1;
                intermediateUpload = matchValue_2;
            }
            break;
        }
        case 5: {
            matchResult = 3;
            break;
        }
        case 4: {
            matchResult = 4;
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return undefined;
        case 1:
            return Controls_asCard(toText(printf("%s Analysis"))(action), studyForm(model, model.IsValid, intermediateUpload, model.ActivePatientInfo.patientInfoId, model.UiDataFileManager, model.EditAnalysisVm, new LocalMsg(23, []), dispatch));
        case 2:
            return "Unable to find file upload component.";
        case 3:
            return reportSelectionModal(model.UserRoles, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid, dispatch);
        default:
            return card_1(empty(), detailView(model, model.IntermediateZipFileManager, model.SelectedStudy.studyNumber, model.UiDataFileManager, equals(model.SelectedStudy.status, new Common_StudyStatus(4, [])), analysisEditEnabled, model.SelectedStudyAnalysis, model.SelectedStudy, dispatch));
    }
}

