import { ModelSlicePlane } from "./SlicePlaneTypes.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { update as update_1 } from "../../Common/InboundOutbound.js";

export function init() {
    return [new ModelSlicePlane(undefined, undefined, false, false, undefined), Cmd_none()];
}

export function updateInbound(unitVar, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new ModelSlicePlane(map((idx) => (idx + msg.fields[0]), model.Index), model.MaxIndex, model.IsVisible, model.IsHovering, model.Bounds), Cmd_none(), Cmd_none()];
        case 2:
            return [new ModelSlicePlane(model.Index, model.MaxIndex, model.IsVisible, msg.fields[0], model.Bounds), Cmd_none(), Cmd_none()];
        default: {
            const value = msg.fields[0];
            return [new ModelSlicePlane(value.index, value.maxIndex, model.IsVisible, model.IsHovering, value.bounds), Cmd_none(), Cmd_none()];
        }
    }
}

export function updateLocal(unitVar, msg, model) {
    return [new ModelSlicePlane(model.Index, model.MaxIndex, msg.fields[0], model.IsHovering, model.Bounds), Cmd_none(), Cmd_none()];
}

export function update(msg, model) {
    return update_1((arg00$0040, msg_1, model_1) => updateLocal(undefined, msg_1, model_1), (arg00$0040_1, msg_2, model_2) => updateInbound(undefined, msg_2, model_2), undefined, msg, model);
}

