import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { option_type, union_type, unit_type, lambda_type, class_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { UserData_$reflection, UserRole_$reflection } from "../RAWMap.Models/Security.js";
import { Security_UserInfo$2_$reflection } from "../fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { SystemInformationViewModel_$reflection } from "../RAWMap.Models/View/SystemInformation.js";
import { ErrorMessage_$reflection } from "../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";

export class UpdateArgs extends Record {
    constructor(token) {
        super();
        this.token = token;
    }
}

export function UpdateArgs_$reflection() {
    return record_type("Client.SystemInformation.Types.UpdateArgs", [], UpdateArgs, () => [["token", string_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "EditSystemInformation", "SaveSystemInformation", "Cancel", "SetSystemInformationVm", "EditSystemInformationResult", "GetSystemInformation", "GetSystemInformationResult"];
    }
}

export function Msg_$reflection() {
    return union_type("Client.SystemInformation.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Security_UserInfo$2_$reflection(UserRole_$reflection(), UserData_$reflection())]], [], [], [], [["Item", lambda_type(SystemInformationViewModel_$reflection(), SystemInformationViewModel_$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SystemInformationViewModel_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(SystemInformationViewModel_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]]]);
}

export class Mode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Display", "Edit"];
    }
}

export function Mode_$reflection() {
    return union_type("Client.SystemInformation.Types.Mode", [], Mode, () => [[], []]);
}

export class Model extends Record {
    constructor(MaybeCurrentUser, maybeCurrentSystemInformation, editSystemInformationVm, mode) {
        super();
        this.MaybeCurrentUser = MaybeCurrentUser;
        this.maybeCurrentSystemInformation = maybeCurrentSystemInformation;
        this.editSystemInformationVm = editSystemInformationVm;
        this.mode = mode;
    }
}

export function Model_$reflection() {
    return record_type("Client.SystemInformation.Types.Model", [], Model, () => [["MaybeCurrentUser", option_type(Security_UserInfo$2_$reflection(UserRole_$reflection(), UserData_$reflection()))], ["maybeCurrentSystemInformation", option_type(SystemInformationViewModel_$reflection())], ["editSystemInformationVm", SystemInformationViewModel_$reflection()], ["mode", Mode_$reflection()]]);
}

