import { wrapLocalMsg } from "../../../Common/InboundOutbound.js";
import { ModeModule_$007CDisableButtons$007CEnableButtons$007C, ModelReportPrompt_get_reportTypeToGenerate_, ModeModule_$007CSpinner$007CNoSpinner$007C, LocalMsg } from "./ReportPromptTypes.js";
import { Permission, isAllowed } from "../../../RAWMap.Models/Security.js";
import { Common_ReportType_get_display, Common_ReportType } from "../../../RAWMap.Models/Common.js";
import { empty, singleton, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { DOMAttr, HTMLAttr } from "../../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { FormField_onChangeDateOptic, FormField_date, FormField_onChangeStringOpticTrimmed, FormField_text, FormField_onChangeReactSelect, FormField_reactSelectSingle } from "../../../Common/Forms.js";
import { equals, uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Option, columns } from "../../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_1, column } from "../../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { PatientCreationViewModel_isValid_7D62DD9A, HospitalPatientIdViewModel_get_hospitalPatientId_, PatientCreationViewModel_get_hospitalPatientIdVm_, NameDobViewModel_get_dateOfBirth_, NameDobViewModel_get_lastName_, NameDobViewModel_get_firstName_, PatientCreationViewModel_get_nameDobVm_ } from "../../../RAWMap.Models/View/Patient.js";
import { Compose_Lens_op_GreaterMinusGreater_2536FC39, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { DateTimeOffset_toUtcOption_, String_toOption_ } from "../../../Common/Extensions.js";
import * as react from "react";
import { keyValueList } from "../../../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { Button_visibilityControlled, ComponentVisibility } from "../../../Common/Controls.js";
import { Option as Option_2, div } from "../../../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { Color_IColor } from "../../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Option as Option_3 } from "../../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { printf, toText } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Layout_modalCardLayout } from "../../../Common/General.js";

function reportSelectionModal(model, dispatch) {
    let activePatternResult_1;
    const dispatchPatientCreation = (arg_1) => {
        dispatch(wrapLocalMsg(new LocalMsg(1, [arg_1])));
    };
    const reportOptions = isAllowed(new Permission(28, []))(model.UserRoles) ? ofArray([new Common_ReportType(1, []), new Common_ReportType(0, [])]) : singleton(new Common_ReportType(1, []));
    const loadingProps = (ModeModule_$007CSpinner$007CNoSpinner$007C(model.Mode).tag === 1) ? empty() : singleton(new HTMLAttr(64, ["spinner is-loading"]));
    let body;
    const props = toList(delay(() => loadingProps));
    const children = toList(delay(() => {
        let tupledArg;
        return append(singleton_1(FormField_reactSelectSingle(reportOptions, [], Common_ReportType_get_display(), model.ReportTypeToGenerate, (tupledArg = ModelReportPrompt_get_reportTypeToGenerate_(), FormField_onChangeReactSelect((arg_3) => {
            dispatch(wrapLocalMsg(new LocalMsg(2, [arg_3])));
        }, tupledArg[0], uncurry2(tupledArg[1]))), "Report Type", [])), delay(() => {
            let tupledArg_1, l_2, l, tupledArg_2, l_6, l_4, tupledArg_3, l_10, l_8, tupledArg_4, l_14, l_12;
            return equals(model.ReportTypeToGenerate, new Common_ReportType(0, [])) ? singleton(columns(ofArray([new Option(1, []), new Option(2, [])]), ofArray([column(singleton(new Option_1(3, [singleton(["style", {
                overflowX: "auto",
            }])])), ofArray([FormField_text(model.PatientCreationInfo.nameDobVm.firstName, empty(), [], (tupledArg_1 = ((l_2 = ((l = PatientCreationViewModel_get_nameDobVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), NameDobViewModel_get_firstName_())(l))), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), String_toOption_)(l_2))), FormField_onChangeStringOpticTrimmed(dispatchPatientCreation, tupledArg_1[0], uncurry2(tupledArg_1[1]))), "First Name", []), FormField_text(model.PatientCreationInfo.nameDobVm.lastName, empty(), [], (tupledArg_2 = ((l_6 = ((l_4 = PatientCreationViewModel_get_nameDobVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), NameDobViewModel_get_lastName_())(l_4))), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), String_toOption_)(l_6))), FormField_onChangeStringOpticTrimmed(dispatchPatientCreation, tupledArg_2[0], uncurry2(tupledArg_2[1]))), "Last Name", [])])), column(singleton(new Option_1(3, [singleton(["style", {
                overflowX: "auto",
            }])])), ofArray([FormField_date(model.PatientCreationInfo.nameDobVm.dateOfBirth, [], [], (tupledArg_3 = ((l_10 = ((l_8 = PatientCreationViewModel_get_nameDobVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), NameDobViewModel_get_dateOfBirth_())(l_8))), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), DateTimeOffset_toUtcOption_)(l_10))), FormField_onChangeDateOptic(dispatchPatientCreation, tupledArg_3[0], uncurry2(tupledArg_3[1]))), "Date of Birth", []), FormField_text(model.PatientCreationInfo.hospitalPatientIdVm.hospitalPatientId, empty(), [], (tupledArg_4 = ((l_14 = ((l_12 = PatientCreationViewModel_get_hospitalPatientIdVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), HospitalPatientIdViewModel_get_hospitalPatientId_())(l_12))), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), String_toOption_)(l_14))), FormField_onChangeStringOpticTrimmed(dispatchPatientCreation, tupledArg_4[0], uncurry2(tupledArg_4[1]))), "Institution Patient ID", [])]))]))) : empty();
        }));
    }));
    body = react.createElement("div", keyValueList(props, 1), ...children);
    const buttonVisibility = (activePatternResult_1 = ModeModule_$007CDisableButtons$007CEnableButtons$007C(model.Mode), (activePatternResult_1.tag === 1) ? (new ComponentVisibility(0, [])) : (new ComponentVisibility(2, [activePatternResult_1.fields[0]])));
    const generateButtonVisibility = (!equals(model.ReportTypeToGenerate, new Common_ReportType(0, [])) ? true : PatientCreationViewModel_isValid_7D62DD9A(model.PatientCreationInfo)) ? buttonVisibility : (new ComponentVisibility(2, ["Patient info is invalid"]));
    const footer = (button) => singleton(div(singleton(new Option_2(5, [])), ofArray([div_1(empty(), singleton(Button_visibilityControlled(undefined, generateButtonVisibility, ofArray([new Option_3(0, [new Color_IColor(6, [])]), new Option_3(17, [ofArray([new DOMAttr(40, [(_arg) => {
        dispatch(wrapLocalMsg(new LocalMsg(4, [])));
    }]), new HTMLAttr(159, ["button"])])])]), singleton(toText(printf("%s Report"))(button))))), div_1(empty(), singleton(Button_visibilityControlled(undefined, buttonVisibility, ofArray([new Option_3(0, [new Color_IColor(8, [])]), new Option_3(17, [ofArray([new DOMAttr(40, [(_arg_1) => {
        dispatch(wrapLocalMsg(new LocalMsg(5, [])));
    }]), new HTMLAttr(159, ["button"])])])]), singleton("Cancel"))))])));
    const matchValue_2 = model.PromptType;
    switch (matchValue_2.tag) {
        case 0:
            return Layout_modalCardLayout("Select the Type of Report to Generate", empty(), singleton(body), footer("Generate"));
        case 2:
            return Layout_modalCardLayout("Are you sure?", empty(), singleton("Are you sure you want to delete this report? This action cannot be undone."), footer("Delete"));
        default:
            return Layout_modalCardLayout("Select the Type of Report to Download", empty(), singleton(body), footer("Download"));
    }
}

export function view(model, dispatch) {
    return reportSelectionModal(model, dispatch);
}

