import { printf, toText, join, isNullOrWhiteSpace } from "../fable-library-js.4.19.3/String.js";
import { compare, structuralHash, equals, curry2, defaultOf } from "../fable-library-js.4.19.3/Util.js";
import { toArray, defaultArg, map, value as value_1 } from "../fable-library-js.4.19.3/Option.js";
import { sort, contains, exists, map as map_1, tryFind, choose, ofArray, tryHead, ofSeq, cons, singleton, head, tail, isEmpty, reverse } from "../fable-library-js.4.19.3/List.js";
import { Async_retn } from "../AsyncResult.0.3.0/Result.fs.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../Fable.Aether.1.0.2/Aether.fs.js";
import { List_distinct } from "../fable-library-js.4.19.3/Seq2.js";
import { getTicks } from "../fable-library-js.4.19.3/Date.js";
import { create, addSeconds, toUnixTimeSeconds, offset, fromTicks } from "../fable-library-js.4.19.3/DateOffset.js";
import { toFloat64, fromInt32, op_Modulus, op_Subtraction, toInt64 } from "../fable-library-js.4.19.3/BigInt.js";

export function String_toOption(str) {
    if (isNullOrWhiteSpace(str)) {
        return undefined;
    }
    else {
        return str;
    }
}

export function String_fromOption(_arg) {
    if (_arg == null) {
        return defaultOf();
    }
    else {
        return value_1(_arg);
    }
}

export function String_pluralize(n) {
    if (n === 1) {
        return "";
    }
    else {
        return "s";
    }
}

/**
 * <para>Converts a list of strings into a comma-separated list ending in "or {last-item}".</para>
 * Examples:
 * <code>
 * [ "x" ] |> String.ofListWithOxfordComma // "x"
 * [ "x"; "y" ] |> String.ofListWithOxfordComma // "x or y"
 * [ "x"; "y"; "z" ] |> String.ofListWithOxfordComma // "x, y, or z"
 * </code>
 */
export function String_ofListWithOxfordComma(strings) {
    let _arg;
    return join(", ", (_arg = reverse(strings), !isEmpty(_arg) ? (!isEmpty(tail(_arg)) ? (isEmpty(tail(tail(_arg))) ? singleton(toText(printf("%s or %s"))(head(tail(_arg)))(head(_arg))) : reverse(cons(toText(printf("%s, or %s"))(head(tail(_arg)))(head(_arg)), tail(tail(_arg))))) : strings) : strings));
}

export const String_toOption_ = [String_toOption, String_fromOption];

export const String_fromOption_ = [String_fromOption, String_toOption];

export function Option_mapString(mapping) {
    return (arg) => String_fromOption(map(mapping, arg));
}

export function Option_defaultValueAsync(d) {
    const value = Async_retn(d);
    return (option) => defaultArg(option, value);
}

export function List_toSeq_() {
    return [(list) => list, ofSeq];
}

export function List_ofSeq_() {
    return [ofSeq, (list) => list];
}

export function List_toOption_() {
    return [tryHead, (option) => ofArray(toArray(option))];
}

export function List_mapLens_(lens_, lens__1, source) {
    const lens = [lens_, curry2(lens__1)];
    return [(list_1) => choose((value) => tryFind((container) => equals(Optic_Get_op_HatDot_21762A61(new Optic_Get(), lens)(container), value), source), list_1), (list_2) => map_1(lens[0], list_2)];
}

export function List_overlaps(list1, list2) {
    return exists((elem) => contains(elem, list1, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), list2);
}

/**
 * Tests if the list contins at least one of the specified elements
 */
export function List_containsOneOf(values, list) {
    return exists((x) => contains(x, values, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), list);
}

/**
 * Tests if both lists contain the same items regardless of order or duplicates
 */
export function List_hasSameItems(list1, list2) {
    const normalize = (arg) => sort(List_distinct(arg, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), {
        Compare: compare,
    });
    return equals(normalize(list1), normalize(list2));
}

export function DateTimeOffset_truncateToMicrosecond(dateTime) {
    const originalTicks = getTicks(dateTime);
    return fromTicks(toInt64(op_Subtraction(originalTicks, toInt64(op_Modulus(originalTicks, toInt64(fromInt32(10)))))), offset(dateTime));
}

export function DateTimeOffset_toUnixSeconds(dto) {
    return toUnixTimeSeconds(dto);
}

export function DateTimeOffset_fromUnixSeconds(secs) {
    return addSeconds(create(1970, 1, 1, 0, 0, 0, 0), toFloat64(secs));
}

