import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, class_type, option_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { OutboundMsg_$reflection as OutboundMsg_$reflection_1, InboundMsg_$reflection as InboundMsg_$reflection_1, LocalMsg_$reflection as LocalMsg_$reflection_1, Model_$reflection as Model_$reflection_1 } from "../FileManagement/FileManagementTypes.js";
import { OutboundMsg_$reflection as OutboundMsg_$reflection_2, InboundMsg_$reflection as InboundMsg_$reflection_2, LocalMsg_$reflection as LocalMsg_$reflection_2, Model_$reflection as Model_$reflection_2 } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { Msg$3_$reflection } from "../Common/InboundOutbound.js";
import { S3ObjectViewModel_$reflection } from "../RAWMap.Models/Api.js";

export class ImageInfo extends Record {
    constructor(fileName, url) {
        super();
        this.fileName = fileName;
        this.url = url;
    }
}

export function ImageInfo_$reflection() {
    return record_type("RAWMap.Client.ImageManager.Types.ImageInfo", [], ImageInfo, () => [["fileName", string_type], ["url", string_type]]);
}

export function ImageInfo_getFileName_Z54611DEE(imageInfo) {
    return imageInfo.fileName;
}

export class Model extends Record {
    constructor(fileManagementModel, dragDropModel, maybeActiveImage) {
        super();
        this.fileManagementModel = fileManagementModel;
        this.dragDropModel = dragDropModel;
        this.maybeActiveImage = maybeActiveImage;
    }
}

export function Model_$reflection() {
    return record_type("RAWMap.Client.ImageManager.Types.Model", [], Model, () => [["fileManagementModel", Model_$reflection_1()], ["dragDropModel", Model_$reflection_2()], ["maybeActiveImage", option_type(ImageInfo_$reflection())]]);
}

export class LocalMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "ClearFileList", "FileManagementMsg", "DragDropMsg", "GetImage", "GotImage"];
    }
}

export function LocalMsg_$reflection() {
    return union_type("RAWMap.Client.ImageManager.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Msg$3_$reflection(LocalMsg_$reflection_1(), InboundMsg_$reflection_1(), OutboundMsg_$reflection_1())]], [["Item", Msg$3_$reflection(LocalMsg_$reflection_2(), InboundMsg_$reflection_2(), OutboundMsg_$reflection_2())]], [["Item", S3ObjectViewModel_$reflection()]], [["Item1", string_type], ["Item2", string_type]]]);
}

export class OutboundMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DownloadableFileUpdated", "StartedZipping", "UploadStarted", "UploadCompleted", "UploadFailed", "FileDeleted"];
    }
}

export function OutboundMsg_$reflection() {
    return union_type("RAWMap.Client.ImageManager.Types.OutboundMsg", [], OutboundMsg, () => [[], [], [], [], [], []]);
}

export class InboundMsg extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["RefreshFileList"];
    }
}

export function InboundMsg_$reflection() {
    return union_type("RAWMap.Client.ImageManager.Types.InboundMsg", [], InboundMsg, () => [[]]);
}

