import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { LocalMsg, ModelAnatomyOrientation } from "./AnatomyOrientationTypes.js";
import { update as update_1, wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { Cmd_OfPromise_either, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { some } from "../../fable_modules/fable-library-js.4.19.3/Option.js";

function fetchAnatomyOrientationData() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$("human-anatomy-m.ply", empty()).then((_arg) => {
        let pr;
        const resp = _arg;
        return (resp.ok) ? (((pr = resp.arrayBuffer(), pr.then((ResultValue) => (new FSharpResult$2(0, [ResultValue])))))) : (Promise.resolve(new FSharpResult$2(1, [resp.status])));
    }))));
}

export function init() {
    let msg;
    return [new ModelAnatomyOrientation(undefined, true), (msg = wrapLocalMsg(new LocalMsg(1, [])), singleton((dispatch) => {
        dispatch(msg);
    }))];
}

export function updateInbound(token, msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

export function updateLocal(token, msg, model) {
    switch (msg.tag) {
        case 1:
            return [model, Cmd_OfPromise_either(fetchAnatomyOrientationData, undefined, (Item) => (new LocalMsg(2, [Item])), (Item_1) => (new LocalMsg(0, [Item_1]))), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                console.error(some(toText(printf("Unexpected return code for orientation data: %O"))(msg.fields[0].fields[0])));
                return [model, Cmd_none(), Cmd_none()];
            }
            else {
                return [new ModelAnatomyOrientation(msg.fields[0].fields[0], model.ShowAnatomyOrientation), Cmd_none(), Cmd_none()];
            }
        case 3:
            return [new ModelAnatomyOrientation(model.MaybeAnatomyOrientationData, msg.fields[0]), Cmd_none(), Cmd_none()];
        default: {
            console.error(some(toText(printf("Orientation exception: %O"))(msg.fields[0])));
            return [model, Cmd_none(), Cmd_none()];
        }
    }
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

