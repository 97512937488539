import { substring, printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { FileComponentSpecs, MaximumFiles, FileType } from "../../FileManagement/Shared.js";
import { empty as empty_1, isEmpty, ofSeq, ofArray, map, append, sortDescending, head, singleton } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { wrapLocalMsg, InboundMsg as InboundMsg_2, MsgCore, StudyCsvProgressStages, PatientInfo_get_patientInstitution_, ModelCore_get_activePatientInfo_, DeidentificationTimerConfig, WizardModifier, fieldsInvalidNotice, InternalMsg, WizardStep, CancelConfirmationOutcome, OutboundMsg, LocalMsg, WizardStep_get_describe, WizardStep_get_sequence, ModelCore, DeidentificationState as DeidentificationState_2, PatientInfo, ModeCore } from "./CoreFormTypes.js";
import { StudyViewModelModule_makeCsvRows, StudyWithDesignation, StudyViewModelModule_ValidatedFieldsModule_isValid, StudyViewModelModule_ValidatedFieldsModule_create, StudyViewModel, StudyViewModelModule_studyNumber_, StudyViewModelModule_empty } from "../../RAWMap.Models/View/Study.js";
import { forceTerminate, update, tryTerminate as tryTerminate_1, init } from "./CoreFormFileManagement/CoreFormFileState.js";
import { TelemetryAction, FileOperationContext } from "../../RAWMap.Models/Api.js";
import { map as map_1, toArray, value as value_2, some, bind } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_OfFunc_either, Cmd_ofEffect, Cmd_map, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5, Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { equals, comparePrimitives } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { updateInbound, init as init_1 } from "../../Wizard/WizardState.js";
import { Toast_ofErrorMessage, Urls_Page, Urls_PatientPage, Urls_pageHash, Toast_successToast, Toast_errorToast } from "../../Common/General.js";
import { ErrorMessage, ErrorMessage_get_describe, Terminate_TerminateError_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { Csv_makeCsvLink, Common_StudyStatus, Common_ActiveBucketState_getBucketState_Z58682287, Common_DeidentificationStatus } from "../../RAWMap.Models/Common.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { InboundMsg } from "./CoreFormFileManagement/CoreFormFileTypes.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { securedApi } from "../../Api.js";
import { S3Context } from "../../Common/AwsCommon.js";
import { FileManagementUpdateArgs } from "../../FileManagement/FileManagementTypes.js";
import { InboundMsg as InboundMsg_1, wrapInboundMsg as wrapInboundMsg_1, Model$1_getActiveStep_Z2F6B8DDE } from "../../Wizard/WizardTypes.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { DeidentificationViewModel } from "../../RAWMap.Models/View/DeidentificationViewModel.js";
import { iterate, empty, singleton as singleton_1, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Navigation_modifyUrl } from "../../fable_modules/Fable.Elmish.Browser.4.0.3/navigation.fs.js";
import { InstitutionClinicalDesignation_get_describe, InstitutionClinicalDesignation, InstitutionViewModel_get_clinicalDesignation_ } from "../../RAWMap.Models/View/Institution.js";
import { Progress_ProgressStatus$1, Progress_ProgressTracking } from "../../Common/JsInteropGeneral.js";
import { nonSeeded } from "../../fable_modules/fable-library-js.4.19.3/Random.js";
import { clientVersion } from "../../Version.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";

export function makeUploadDestinationPathGuid(patientGuid, studyGuid) {
    return toText(printf("%O/%O/StudyData/"))(patientGuid)(studyGuid);
}

export function makeUploadDestinationPath(studyVm) {
    return makeUploadDestinationPathGuid(studyVm.patientId, studyVm.studyId);
}

export function fileSpecs(uploadPath) {
    return new FileComponentSpecs(singleton(new FileType(0, [])), uploadPath, new MaximumFiles(0, []));
}

export function initWithDisplay(maybeExistingModel, selectedStudy, patientInstitution, userRole) {
    return [new ModelCore(new ModeCore(3, []), StudyViewModelModule_empty, undefined, selectedStudy, undefined, init(fileSpecs(makeUploadDestinationPath(selectedStudy)), new FileOperationContext(3, []), selectedStudy.status)[0], new PatientInfo(selectedStudy.patientId, patientInstitution), undefined, new DeidentificationState_2(0, []), bind((m) => m.DeidentificationRequestInfo, maybeExistingModel), undefined, userRole), Cmd_none()];
}

export function initWithCreate(patientInfo, studies, userRole) {
    const studyNumber = (1 + head(sortDescending(append(singleton(0), map((target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(), StudyViewModelModule_studyNumber_)(target), studies)), {
        Compare: comparePrimitives,
    }))) | 0;
    const studyVm = new StudyViewModel(newGuid(), patientInfo.patientInfoId, StudyViewModelModule_empty.studyCreationDate, studyNumber, StudyViewModelModule_empty.createdBy, StudyViewModelModule_empty.sex, StudyViewModelModule_empty.smokingHistory, StudyViewModelModule_empty.aaaFamilyHistory, StudyViewModelModule_empty.weight, StudyViewModelModule_empty.height, StudyViewModelModule_empty.postEvarEndoleakDetected, StudyViewModelModule_empty.heartRate, StudyViewModelModule_empty.systolicPressure, StudyViewModelModule_empty.diastolicPressure, StudyViewModelModule_empty.patientHistory, StudyViewModelModule_empty.hypertension, StudyViewModelModule_empty.copd, StudyViewModelModule_empty.diabetesMellitus, StudyViewModelModule_empty.connectiveTissueDisorder, StudyViewModelModule_empty.atherosclerosis, StudyViewModelModule_empty.isPostTreatment, StudyViewModelModule_empty.postTreatTreatmentType, StudyViewModelModule_empty.postTreatDate, StudyViewModelModule_empty.postEndoleakEndoleakType, StudyViewModelModule_empty.postEvarEndoleakRepair, StudyViewModelModule_empty.postEvarGraftExplant, StudyViewModelModule_empty.aorticRupture, StudyViewModelModule_empty.limbOcclusion, StudyViewModelModule_empty.otherComments, StudyViewModelModule_empty.status);
    const patternInput = init(fileSpecs(makeUploadDestinationPath(studyVm)), new FileOperationContext(3, []), studyVm.status);
    return [new ModelCore(new ModeCore(1, [init_1(WizardStep_get_sequence(), WizardStep_get_describe()), undefined]), studyVm, undefined, studyVm, undefined, patternInput[0], patientInfo, undefined, new DeidentificationState_2(0, []), undefined, undefined, userRole), Cmd_none()];
}

export function returnToDetail(m, c, model) {
    return [new ModelCore(new ModeCore(3, []), StudyViewModelModule_empty, undefined, model.SelectedStudy, model.FileUpdateArgs, m, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, undefined, model.UserRole), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new LocalMsg(23, [model.MaybeDeidentificationTimerConfig]));
    }))), singleton((dispatch_1) => {
        dispatch_1(new OutboundMsg(1, [model.SelectedStudy]));
    })];
}

export function tryReturnToDetail(model) {
    let arg;
    const tryTerminate = tryTerminate_1(model.StudyDataFileManagement);
    if (tryTerminate.tag === 1) {
        return [model, Toast_errorToast((arg = Terminate_TerminateError_get_describe()(tryTerminate.fields[0]), toText(printf("Unable to return to detail: %s"))(arg))), Cmd_none()];
    }
    else {
        tryTerminate.fields[0]();
        const newFileComponent = init(fileSpecs(makeUploadDestinationPath(model.SelectedStudy)), new FileOperationContext(3, []), model.SelectedStudy.status);
        return returnToDetail(newFileComponent[0], newFileComponent[1], model);
    }
}

export function stopDeidentificationTimer(timerConfig) {
    window.clearInterval(timerConfig.timerId);
}

export function computeDeidentificationState(svm) {
    const matchValue = svm.status;
    switch (matchValue.tag) {
        case 0:
            return new DeidentificationState_2(0, []);
        case 1:
            return new DeidentificationState_2(4, [new Common_DeidentificationStatus(1, [])]);
        default:
            return new DeidentificationState_2(4, [new Common_DeidentificationStatus(0, [])]);
    }
}

export function updateDeidentificationState(model, state) {
    let matchValue;
    return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, (matchValue = model.Mode, (matchValue.tag === 1) ? state : ((matchValue.tag === 2) ? state : model.DeidentificationState)), model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
}

export function updateCoreInbound(_token, msg, model) {
    let msg_2;
    if (msg.tag === 1) {
        return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, [msg.fields[0], msg.fields[1]], model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(singleton((msg_2 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg(0, []))])), singleton((dispatch) => {
            dispatch(msg_2);
        })))), Cmd_none()];
    }
    else {
        const svm = msg.fields[0];
        const model$0027 = new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, svm, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole);
        const matchValue = model$0027.Mode;
        switch (matchValue.tag) {
            case 3:
            case 2:
                return [new ModelCore(model$0027.Mode, model$0027.EditStudyVm, model$0027.ValidatedEditStudyVm, model$0027.SelectedStudy, model$0027.FileUpdateArgs, model$0027.StudyDataFileManagement, model$0027.ActivePatientInfo, model$0027.MaybeDeidentificationTimerConfig, computeDeidentificationState(svm), model$0027.DeidentificationRequestInfo, model$0027.CancellationOutcome, model$0027.UserRole), Cmd_none(), Cmd_none()];
            default:
                return [model$0027, Cmd_none(), Cmd_none()];
        }
    }
}

export function updateStudyStatus(token, id, status) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, securedApi(token).updateStudyStatus([id, status]), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [id, status])));
}

export function asyncPassthrough(toReturn, apiCall, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, apiCall(args), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, toReturn)));
}

export function updateCoreLocal(token, msg, model) {
    let msg_2, matchValue_4, matchValue_5, text, apiCall, msg_24, msg_17, msg_43, bind$0040, l_2, l, arg_10;
    let matchResult, ex, activeStep, activeStep_1, msg_8, msg_9, msg_10, setter, queueLength_1, requestId_1, queueLength_2, requestId_2, _queueLength, requestId_3, timerConfig_1, requestId_4, maybeDeidentificationStatus, e, maybeConfig, ex_3, wizardModel_8, wizardModel_9, study_2, wizardModel_10, ex_4, wizardModel_11, svm, wizardModel_12, status_2, studyId, outcome_1, e_2, wizardModel_13, e_4, wizardModel_14, e_6, _institution, _svm, svm_2, stage, _stage, fileName, result_3;
    switch (msg.tag) {
        case 1: {
            if (msg.fields[0].tag === 0) {
                matchResult = 3;
                msg_8 = msg.fields[0].fields[0].fields[0];
            }
            else if (msg.fields[0].fields[0].tag === 0) {
                matchResult = 2;
                activeStep_1 = msg.fields[0].fields[0].fields[0];
            }
            else {
                matchResult = 1;
                activeStep = msg.fields[0].fields[0].fields[0];
            }
            break;
        }
        case 2: {
            if (msg.fields[0].tag === 1) {
                switch (msg.fields[0].fields[0].tag) {
                    case 1: {
                        matchResult = 7;
                        break;
                    }
                    case 2: {
                        matchResult = 8;
                        break;
                    }
                    case 4: {
                        matchResult = 9;
                        break;
                    }
                    case 5: {
                        matchResult = 10;
                        break;
                    }
                    case 3: {
                        matchResult = 11;
                        break;
                    }
                    default:
                        matchResult = 6;
                }
            }
            else if (model.FileUpdateArgs != null) {
                matchResult = 4;
                msg_9 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
                msg_10 = msg.fields[0].fields[0];
            }
            break;
        }
        case 3: {
            matchResult = 12;
            setter = msg.fields[0];
            break;
        }
        case 4: {
            matchResult = 13;
            break;
        }
        case 5: {
            matchResult = 14;
            break;
        }
        case 6: {
            matchResult = 15;
            break;
        }
        case 20: {
            if (msg.fields[1].tag === 1) {
                matchResult = 36;
                e_6 = msg.fields[1].fields[0];
            }
            else {
                matchResult = 16;
                queueLength_1 = msg.fields[1].fields[0];
                requestId_1 = msg.fields[0];
            }
            break;
        }
        case 26: {
            matchResult = 17;
            queueLength_2 = msg.fields[1];
            requestId_2 = msg.fields[0];
            break;
        }
        case 21: {
            matchResult = 18;
            _queueLength = msg.fields[1];
            requestId_3 = msg.fields[0];
            break;
        }
        case 22: {
            matchResult = 19;
            timerConfig_1 = msg.fields[0];
            break;
        }
        case 24: {
            matchResult = 20;
            requestId_4 = msg.fields[0];
            break;
        }
        case 25: {
            if (msg.fields[0].tag === 1) {
                matchResult = 22;
                e = msg.fields[0].fields[0];
            }
            else {
                matchResult = 21;
                maybeDeidentificationStatus = msg.fields[0].fields[0];
            }
            break;
        }
        case 23: {
            matchResult = 23;
            maybeConfig = msg.fields[0];
            break;
        }
        case 8: {
            matchResult = 24;
            ex_3 = msg.fields[0];
            wizardModel_8 = msg.fields[1];
            break;
        }
        case 7: {
            if (msg.fields[0].tag === 0) {
                matchResult = 26;
                study_2 = msg.fields[0].fields[0];
                wizardModel_10 = msg.fields[1];
            }
            else if (msg.fields[0].fields[0].tag === 26) {
                matchResult = 25;
                wizardModel_9 = msg.fields[1];
            }
            else {
                matchResult = 34;
                e_2 = msg.fields[0].fields[0];
                wizardModel_13 = msg.fields[1];
            }
            break;
        }
        case 10: {
            matchResult = 27;
            ex_4 = msg.fields[0];
            wizardModel_11 = msg.fields[1];
            break;
        }
        case 9: {
            if (msg.fields[0].tag === 1) {
                matchResult = 35;
                e_4 = msg.fields[0].fields[0];
                wizardModel_14 = msg.fields[1];
            }
            else {
                matchResult = 28;
                svm = msg.fields[0].fields[0];
                wizardModel_12 = msg.fields[1];
            }
            break;
        }
        case 11: {
            if (msg.fields[0].tag === 1) {
                matchResult = 36;
                e_6 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 29;
                status_2 = msg.fields[0].fields[0][1];
                studyId = msg.fields[0].fields[0][0];
            }
            break;
        }
        case 12: {
            matchResult = 30;
            outcome_1 = msg.fields[0];
            break;
        }
        case 14: {
            matchResult = 31;
            break;
        }
        case 13: {
            matchResult = 32;
            break;
        }
        case 27: {
            matchResult = 33;
            break;
        }
        case 29: {
            if (msg.fields[0].tag === 0) {
                matchResult = 43;
                result_3 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 36;
                e_6 = msg.fields[0].fields[0];
            }
            break;
        }
        case 15: {
            matchResult = 37;
            _institution = msg.fields[0];
            break;
        }
        case 16: {
            matchResult = 38;
            _svm = msg.fields[0];
            break;
        }
        case 17: {
            matchResult = 39;
            svm_2 = msg.fields[0];
            break;
        }
        case 19: {
            matchResult = 40;
            stage = msg.fields[0];
            break;
        }
        case 18: {
            matchResult = 41;
            _stage = msg.fields[0][0];
            fileName = msg.fields[0][1];
            break;
        }
        case 28: {
            matchResult = 42;
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none(), Cmd_none()];
        }
        case 1:
            return [model, (msg_2 = (new LocalMsg(12, [(model.Mode.tag === 1) ? ((activeStep.tag === 0) ? (new CancelConfirmationOutcome(0, [])) : (new CancelConfirmationOutcome(1, []))) : (new CancelConfirmationOutcome(1, []))])), singleton((dispatch) => {
                dispatch(msg_2);
            })), Cmd_none()];
        case 2:
            if (activeStep_1.tag === 1) {
                const matchValue_2 = model.DeidentificationState;
                let matchResult_1;
                if (matchValue_2.tag === 4) {
                    if (matchValue_2.fields[0].tag === 0) {
                        matchResult_1 = 0;
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0:
                        return tryReturnToDetail(model);
                    default:
                        return [model, singleton((dispatch_2) => {
                            dispatch_2(new LocalMsg(12, [new CancelConfirmationOutcome(1, [])]));
                        }), Cmd_none()];
                }
            }
            else {
                return [model, singleton((dispatch_1) => {
                    dispatch_1(new LocalMsg(6, []));
                }), Cmd_none()];
            }
        case 3: {
            const handleCreateOrEdit = (mkMode, tupledArg) => [new ModelCore(mkMode([updateInbound(msg_8, tupledArg[0]), tupledArg[1]]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
            const matchValue_3 = model.Mode;
            switch (matchValue_3.tag) {
                case 1:
                    return handleCreateOrEdit((tupledArg_1) => (new ModeCore(1, [tupledArg_1[0], tupledArg_1[1]])), [matchValue_3.fields[0], matchValue_3.fields[1]]);
                case 2:
                    return handleCreateOrEdit((tupledArg_2) => (new ModeCore(2, [tupledArg_2[0], tupledArg_2[1]])), [matchValue_3.fields[0], matchValue_3.fields[1]]);
                default:
                    return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 4: {
            const context = value_2(model.FileUpdateArgs)[1];
            const context_1 = new S3Context(context.S3Config, context.S3Client, context.S3Expiry, (matchValue_4 = Common_ActiveBucketState_getBucketState_Z58682287(model.SelectedStudy.status), (matchValue_4.tag === 1) ? context.S3Config.PermanentBucket : context.S3Config.TempBucket));
            const patternInput = update(new FileManagementUpdateArgs(token, value_2(model.FileUpdateArgs)[0], context_1), msg_9, model.StudyDataFileManagement);
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, patternInput[0], model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_map((Item_4) => (new LocalMsg(2, [Item_4])), patternInput[1]), Cmd_none()];
        }
        case 5:
            return [model, Toast_errorToast("No S3 creds found"), Cmd_none()];
        case 6:
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, (matchValue_5 = model.Mode, (matchValue_5.tag === 3) ? computeDeidentificationState(model.SelectedStudy) : ((matchValue_5.tag === 2) ? (equals(Model$1_getActiveStep_Z2F6B8DDE(matchValue_5.fields[0]), new WizardStep(0, [])) ? computeDeidentificationState(model.SelectedStudy) : model.DeidentificationState) : model.DeidentificationState)), model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        case 7:
            return updateDeidentificationState(model, new DeidentificationState_2(1, []));
        case 8:
            return updateDeidentificationState(model, new DeidentificationState_2(2, []));
        case 9:
            return updateDeidentificationState(model, new DeidentificationState_2(0, []));
        case 10: {
            let patternInput_1;
            const matchValue_6 = model.Mode;
            switch (matchValue_6.tag) {
                case 1:
                case 2: {
                    patternInput_1 = [new DeidentificationState_2(0, []), Cmd_batch(ofArray([singleton((dispatch_3) => {
                        dispatch_3(new LocalMsg(23, [model.MaybeDeidentificationTimerConfig]));
                    }), Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, (tupledArg_3) => updateStudyStatus(tupledArg_3[0], tupledArg_3[1], tupledArg_3[2]), [token, model.EditStudyVm.studyId, new Common_StudyStatus(0, [])], (Item_5) => (new LocalMsg(11, [Item_5])), (Item_6) => (new LocalMsg(0, [Item_6])))]))];
                    break;
                }
                default:
                    patternInput_1 = [model.DeidentificationState, Cmd_none()];
            }
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, patternInput_1[0], model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), patternInput_1[1], Cmd_none()];
        }
        case 11: {
            const deIdRequest = new DeidentificationViewModel(newGuid(), model.EditStudyVm.patientId, model.EditStudyVm.studyId);
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).enqueueForDeidentification, deIdRequest, (result) => (new LocalMsg(20, [deIdRequest.requestId, result])), (Item_7) => (new LocalMsg(0, [Item_7]))), Cmd_none()];
        }
        case 12:
            return [new ModelCore(model.Mode, setter(model.EditStudyVm), model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        case 13:
            return [model, Cmd_none(), singleton((dispatch_5) => {
                dispatch_5(new OutboundMsg(2, [[model.SelectedStudy.studyId, (__svm) => singleton((dispatch_4) => {
                    dispatch_4(new InternalMsg(0, [new LocalMsg(5, [])]));
                })]]));
            })];
        case 14: {
            const matchValue_7 = model.Mode;
            const matchValue_8 = model.SelectedStudy.status;
            let matchResult_2;
            switch (matchValue_8.tag) {
                case 3: {
                    matchResult_2 = 0;
                    break;
                }
                case 4: {
                    matchResult_2 = 1;
                    break;
                }
                default:
                    switch (matchValue_7.tag) {
                        case 1:
                        case 2: {
                            matchResult_2 = 2;
                            break;
                        }
                        default:
                            matchResult_2 = 3;
                    }
            }
            switch (matchResult_2) {
                case 0:
                    return [model, (text = "Unable to edit the study while analysis is in progress. Please contact ViTAA Medical at <support@vitaamedical.com> to unlock the study.", Toast_errorToast(text)), Cmd_none()];
                case 1:
                    return [model, Toast_errorToast("Unable to edit study when analysis is completed."), Cmd_none()];
                case 2:
                    return [model, Cmd_none(), Cmd_none()];
                default:
                    return [new ModelCore(new ModeCore(2, [init_1(WizardStep_get_sequence(), WizardStep_get_describe()), undefined]), model.SelectedStudy, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
            }
        }
        case 15: {
            const matchValue_10 = model.Mode;
            const matchValue_11 = model.ActivePatientInfo.patientInstitution;
            if (matchValue_11.clinicalDesignation != null) {
                switch (matchValue_10.tag) {
                    case 2: {
                        const designation = matchValue_11.clinicalDesignation;
                        const validated = StudyViewModelModule_ValidatedFieldsModule_create(designation, model.EditStudyVm);
                        if (!StudyViewModelModule_ValidatedFieldsModule_isValid(validated)) {
                            return [new ModelCore(model.Mode, model.EditStudyVm, validated, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_errorToast(fieldsInvalidNotice), Cmd_none()];
                        }
                        else if (!equals(model.EditStudyVm, model.SelectedStudy)) {
                            const study = new StudyWithDesignation(model.EditStudyVm, designation, matchValue_11.id);
                            return [new ModelCore(new ModeCore(2, [matchValue_10.fields[0], new WizardModifier()]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfAsyncWith_either((x_2) => {
                                Cmd_OfAsync_start(x_2);
                            }, (apiCall = securedApi(token).editStudy, (args) => asyncPassthrough(model.EditStudyVm, apiCall, args)), study, (result_1) => (new LocalMsg(9, [result_1, matchValue_10.fields[0]])), (ex_1) => (new LocalMsg(10, [ex_1, matchValue_10.fields[0]]))), Cmd_none()];
                        }
                        else {
                            return [model, Cmd_batch(toList(delay(() => {
                                let msg_20;
                                return append_1(singleton_1((msg_20 = (new LocalMsg(1, [wrapInboundMsg_1(new InboundMsg_1())])), singleton((dispatch_7) => {
                                    dispatch_7(msg_20);
                                }))), delay(() => {
                                    let msg_22, tupledArg_4;
                                    return (equals(model.DeidentificationState, new DeidentificationState_2(3, [])) && (model.DeidentificationRequestInfo != null)) ? singleton_1((msg_22 = ((tupledArg_4 = value_2(model.DeidentificationRequestInfo), new LocalMsg(26, [tupledArg_4[0], tupledArg_4[1]]))), singleton((dispatch_8) => {
                                        dispatch_8(msg_22);
                                    }))) : empty();
                                }));
                            }))), Cmd_none()];
                        }
                    }
                    case 1: {
                        const designation_1 = matchValue_11.clinicalDesignation;
                        const validated_1 = StudyViewModelModule_ValidatedFieldsModule_create(designation_1, model.EditStudyVm);
                        if (!StudyViewModelModule_ValidatedFieldsModule_isValid(validated_1)) {
                            return [new ModelCore(model.Mode, model.EditStudyVm, validated_1, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_errorToast(fieldsInvalidNotice), Cmd_none()];
                        }
                        else {
                            const study_1 = new StudyWithDesignation(model.EditStudyVm, designation_1, matchValue_11.id);
                            return [new ModelCore(new ModeCore(1, [matchValue_10.fields[0], new WizardModifier()]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfAsyncWith_either((x_3) => {
                                Cmd_OfAsync_start(x_3);
                            }, securedApi(token).createStudy, study_1, (result_2) => (new LocalMsg(7, [result_2, matchValue_10.fields[0]])), (ex_2) => (new LocalMsg(8, [ex_2, matchValue_10.fields[0]]))), Cmd_none()];
                        }
                    }
                    default:
                        return [model, (msg_24 = (new LocalMsg(0, [new Error("Unable to save study")])), singleton((dispatch_9) => {
                            dispatch_9(msg_24);
                        })), Cmd_none()];
                }
            }
            else {
                return [model, (msg_17 = (new LocalMsg(0, [new Error("No clinical designation for institution")])), singleton((dispatch_6) => {
                    dispatch_6(msg_17);
                })), Cmd_none()];
            }
        }
        case 16: {
            const matchValue_13 = model.Mode;
            switch (matchValue_13.tag) {
                case 1:
                case 2:
                    return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, new DeidentificationState_2(3, []), [requestId_1, queueLength_1], model.CancellationOutcome, model.UserRole), singleton((dispatch_10) => {
                        dispatch_10(new LocalMsg(21, [requestId_1, queueLength_1]));
                    }), Cmd_none()];
                default:
                    return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 17:
            return [model, Cmd_batch(ofArray([singleton((dispatch_11) => {
                dispatch_11(new LocalMsg(21, [requestId_2, queueLength_2]));
            }), singleton((dispatch_12) => {
                dispatch_12(new LocalMsg(24, [requestId_2]));
            })])), Cmd_none()];
        case 18:
            return [model, Cmd_ofEffect((dispatch_13) => {
                dispatch_13(new LocalMsg(22, [new DeidentificationTimerConfig(window.setInterval((_arg) => {
                    dispatch_13(new LocalMsg(24, [requestId_3]));
                }, 10 * 1000), requestId_3)]));
            }), Cmd_none()];
        case 19:
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, timerConfig_1, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        case 20: {
            const deidRequest = new DeidentificationViewModel(requestId_4, model.ActivePatientInfo.patientInfoId, model.SelectedStudy.studyId);
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(token).getDeidentificationStatus, deidRequest, (Item_12) => (new LocalMsg(25, [Item_12])), (Item_13) => (new LocalMsg(0, [Item_13]))), Cmd_none()];
        }
        case 21: {
            let matchResult_3;
            if (model.DeidentificationState.tag === 3) {
                if (maybeDeidentificationStatus == null) {
                    matchResult_3 = 2;
                }
                else {
                    matchResult_3 = 0;
                }
            }
            else if (maybeDeidentificationStatus == null) {
                matchResult_3 = 2;
            }
            else {
                matchResult_3 = 1;
            }
            switch (matchResult_3) {
                case 0:
                    return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, new DeidentificationState_2(4, [maybeDeidentificationStatus]), model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), singleton((dispatch_14) => {
                        dispatch_14(new LocalMsg(23, [model.MaybeDeidentificationTimerConfig]));
                    }), singleton((dispatch_15) => {
                        dispatch_15(new OutboundMsg(1, [model.SelectedStudy]));
                    })];
                case 1:
                    return [model, singleton((dispatch_16) => {
                        dispatch_16(new LocalMsg(23, [model.MaybeDeidentificationTimerConfig]));
                    }), Cmd_none()];
                default:
                    return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 22:
            return [model, Cmd_batch(ofArray([Toast_errorToast(ErrorMessage_get_describe()(e)), singleton((dispatch_17) => {
                dispatch_17(new LocalMsg(23, [model.MaybeDeidentificationTimerConfig]));
            })])), Cmd_none()];
        case 23:
            if (maybeConfig == null) {
                return [model, Cmd_none(), Cmd_none()];
            }
            else {
                stopDeidentificationTimer(maybeConfig);
                return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, undefined, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
            }
        case 24:
            return [new ModelCore(new ModeCore(1, [wizardModel_8, undefined]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), singleton((dispatch_18) => {
                dispatch_18(new LocalMsg(0, [ex_3]));
            }), Cmd_none()];
        case 25:
            return [new ModelCore(new ModeCore(1, [wizardModel_9, undefined]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Toast_errorToast("Error saving study. Please try again.")];
        case 26:
            return [new ModelCore(new ModeCore(1, [wizardModel_10, undefined]), model.EditStudyVm, model.ValidatedEditStudyVm, study_2, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(ofArray([Toast_successToast(toText(printf("Created study %O"))(study_2.studyId)), Navigation_modifyUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(2, [[study_2.patientId, study_2.studyId]])]))), (msg_43 = (new LocalMsg(1, [wrapInboundMsg_1(new InboundMsg_1())])), singleton((dispatch_19) => {
                dispatch_19(msg_43);
            }))])), singleton((dispatch_20) => {
                dispatch_20(new OutboundMsg(0, [study_2.studyId]));
            })];
        case 27:
            return [new ModelCore(new ModeCore(2, [wizardModel_11, undefined]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), singleton((dispatch_21) => {
                dispatch_21(new LocalMsg(0, [ex_4]));
            }), Cmd_none()];
        case 28:
            return [new ModelCore(new ModeCore(2, [wizardModel_12, undefined]), model.EditStudyVm, model.ValidatedEditStudyVm, svm, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(toList(delay(() => append_1(singleton_1(Toast_successToast(toText(printf("Study updated")))), delay(() => {
                let msg_50;
                return append_1(singleton_1((msg_50 = (new LocalMsg(1, [wrapInboundMsg_1(new InboundMsg_1())])), singleton((dispatch_22) => {
                    dispatch_22(msg_50);
                }))), delay(() => {
                    let msg_52, tupledArg_5;
                    return (equals(model.DeidentificationState, new DeidentificationState_2(3, [])) && (model.DeidentificationRequestInfo != null)) ? singleton_1((msg_52 = ((tupledArg_5 = value_2(model.DeidentificationRequestInfo), new LocalMsg(26, [tupledArg_5[0], tupledArg_5[1]]))), singleton((dispatch_23) => {
                        dispatch_23(msg_52);
                    }))) : empty();
                }));
            }))))), Cmd_none()];
        case 29:
            if (model.SelectedStudy.studyId === studyId) {
                const model$0027 = new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, (bind$0040 = model.SelectedStudy, new StudyViewModel(bind$0040.studyId, bind$0040.patientId, bind$0040.studyCreationDate, bind$0040.studyNumber, bind$0040.createdBy, bind$0040.sex, bind$0040.smokingHistory, bind$0040.aaaFamilyHistory, bind$0040.weight, bind$0040.height, bind$0040.postEvarEndoleakDetected, bind$0040.heartRate, bind$0040.systolicPressure, bind$0040.diastolicPressure, bind$0040.patientHistory, bind$0040.hypertension, bind$0040.copd, bind$0040.diabetesMellitus, bind$0040.connectiveTissueDisorder, bind$0040.atherosclerosis, bind$0040.isPostTreatment, bind$0040.postTreatTreatmentType, bind$0040.postTreatDate, bind$0040.postEndoleakEndoleakType, bind$0040.postEvarEndoleakRepair, bind$0040.postEvarGraftExplant, bind$0040.aorticRupture, bind$0040.limbOcclusion, bind$0040.otherComments, status_2)), model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole);
                return [model$0027, Cmd_none(), singleton((dispatch_24) => {
                    dispatch_24(new OutboundMsg(1, [model$0027.SelectedStudy]));
                })];
            }
            else {
                return [model, Toast_errorToast("Wrong study from status."), Cmd_none()];
            }
        case 30:
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, outcome_1, model.UserRole), Cmd_none(), Cmd_none()];
        case 31:
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, undefined, model.UserRole), Cmd_none(), Cmd_none()];
        case 32: {
            const matchValue_17 = model.CancellationOutcome;
            if (matchValue_17 == null) {
                return [model, Toast_errorToast("Unable to confirm cancel with no outcome"), Cmd_none()];
            }
            else if (matchValue_17.tag === 1) {
                return tryReturnToDetail(model);
            }
            else {
                return [model, singleton((dispatch_25) => {
                    dispatch_25(new LocalMsg(27, []));
                }), Cmd_none()];
            }
        }
        case 33: {
            iterate((timerConfig_3) => {
                stopDeidentificationTimer(timerConfig_3);
            }, toArray(model.MaybeDeidentificationTimerConfig));
            const coreFileTerminate = tryTerminate_1(model.StudyDataFileManagement);
            return [model, (coreFileTerminate.tag === 1) ? ((forceTerminate(model.StudyDataFileManagement), Toast_errorToast(Terminate_TerminateError_get_describe()(coreFileTerminate.fields[0])))) : ((coreFileTerminate.fields[0](), Cmd_none())), singleton((dispatch_26) => {
                dispatch_26(new OutboundMsg(3, []));
            })];
        }
        case 34:
            return [new ModelCore(new ModeCore(1, [wizardModel_13, undefined]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_ofErrorMessage(e_2), Cmd_none()];
        case 35:
            return [new ModelCore(new ModeCore(2, [wizardModel_14, undefined]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_ofErrorMessage(e_4), Cmd_none()];
        case 36:
            return [model, Toast_ofErrorMessage(e_6), Cmd_none()];
        case 37:
            return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l_2 = ((l = ModelCore_get_activePatientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), PatientInfo_get_patientInstitution_())(l))), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), InstitutionViewModel_get_clinicalDesignation_())(l_2)))(new InstitutionClinicalDesignation(0, []))(model), Toast_ofErrorMessage(new ErrorMessage(6, ["clinicalDesignation", (arg_10 = InstitutionClinicalDesignation_get_describe()(new InstitutionClinicalDesignation(0, [])), toText(printf("be set. Defaulting to %O"))(arg_10))])), Cmd_none()];
        case 38:
            return [new ModelCore(new ModeCore(4, [new Progress_ProgressStatus$1(new StudyCsvProgressStages(0, []), new Progress_ProgressTracking(0, []))]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), singleton((dispatch_28) => {
                dispatch_28(new OutboundMsg(2, [[model.SelectedStudy.studyId, (svm_1) => singleton((dispatch_27) => {
                    dispatch_27(new InternalMsg(0, [new LocalMsg(17, [svm_1])]));
                })]]));
            })];
        case 39: {
            const random = nonSeeded();
            const institutionDesignation = map_1(InstitutionClinicalDesignation_get_describe(), model.ActivePatientInfo.patientInstitution.clinicalDesignation);
            const csvFileName = `${substring(svm_2.patientId, 0, 8)}_${svm_2.studyId}.csv`;
            const successStage = new Progress_ProgressStatus$1(new StudyCsvProgressStages(2, []), new Progress_ProgressTracking(0, []));
            const matchValue_18 = model.SelectedStudy.status;
            const matchValue_19 = ofSeq(model.StudyDataFileManagement.fileUpload.DownloadableFiles);
            let matchResult_4;
            switch (matchValue_18.tag) {
                case 0:
                case 1: {
                    matchResult_4 = 1;
                    break;
                }
                case 3: {
                    if (isEmpty(matchValue_19)) {
                        matchResult_4 = 2;
                    }
                    else {
                        matchResult_4 = 0;
                    }
                    break;
                }
                case 4: {
                    if (isEmpty(matchValue_19)) {
                        matchResult_4 = 2;
                    }
                    else {
                        matchResult_4 = 0;
                    }
                    break;
                }
                default:
                    if (isEmpty(matchValue_19)) {
                        matchResult_4 = 2;
                    }
                    else {
                        matchResult_4 = 0;
                    }
            }
            switch (matchResult_4) {
                case 0:
                    return [new ModelCore(new ModeCore(4, [new Progress_ProgressStatus$1(new StudyCsvProgressStages(1, []), new Progress_ProgressTracking(0, []))]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfFunc_either((vm) => {
                        const csvLink = Csv_makeCsvLink(csvFileName, StudyViewModelModule_makeCsvRows(model.ActivePatientInfo.patientInstitution.name, clientVersion, institutionDesignation, vm));
                        csvLink.click();
                    }, svm_2, () => (new LocalMsg(18, [[successStage, csvFileName]])), (Item_18) => (new LocalMsg(0, [Item_18]))), Cmd_none()];
                case 1:
                    return [model, Toast_errorToast("Unable to download info of incomplete study."), Cmd_none()];
                default:
                    return [model, Toast_errorToast("Unable to download due to inconsistent study state."), Cmd_none()];
            }
        }
        case 40:
            return [new ModelCore(new ModeCore(4, [stage]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        case 41:
            return [new ModelCore(new ModeCore(3, []), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(ofArray([(model.SelectedStudy.status.tag === 2) ? ((model.UserRole.tag === 3) ? Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, (tupledArg_6) => updateStudyStatus(tupledArg_6[0], tupledArg_6[1], tupledArg_6[2]), [token, model.SelectedStudy.studyId, new Common_StudyStatus(3, [])], (Item_19) => (new LocalMsg(11, [Item_19])), (Item_20) => (new LocalMsg(0, [Item_20]))) : Cmd_none()) : Cmd_none(), Cmd_OfAsyncWith_attempt((x_6) => {
                Cmd_OfAsync_start(x_6);
            }, securedApi(token).telemetry, new TelemetryAction(1, [new FileOperationContext(3, []), singleton(fileName)]), (Item_21) => (new LocalMsg(0, [Item_21])))])), Cmd_none()];
        case 42:
            return [model, (model.SelectedStudy.status.tag === 3) ? ((model.UserRole.tag === 3) ? Cmd_OfAsyncWith_either((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, (tupledArg_7) => updateStudyStatus(tupledArg_7[0], tupledArg_7[1], tupledArg_7[2]), [token, model.SelectedStudy.studyId, new Common_StudyStatus(2, [])], (Item_22) => (new LocalMsg(29, [Item_22])), (Item_23) => (new LocalMsg(0, [Item_23]))) : Cmd_none()) : Cmd_none(), Cmd_none()];
        default:
            return [model, Cmd_batch(ofArray([Toast_successToast("Study unlock successful. Please delete all previously downloaded copies of the study."), singleton((dispatch_30) => {
                dispatch_30(new LocalMsg(11, [new FSharpResult$2(0, [result_3])]));
            })])), Cmd_none()];
    }
}

export function updateCore(token, msg, model) {
    const lastModel = model;
    const patternInput = (msg.tag === 0) ? updateCoreLocal(token, msg.fields[0], model) : updateCoreInbound(token, msg.fields[0], model);
    const model_1 = patternInput[0];
    const outboundCmd_1 = append(singleton(patternInput[2]), !equals(lastModel.SelectedStudy, model_1.SelectedStudy) ? ((msg.tag === 1) ? ((msg.fields[0].tag === 0) ? empty_1() : singleton(singleton((dispatch) => {
        dispatch(new OutboundMsg(1, [model_1.SelectedStudy]));
    }))) : singleton(singleton((dispatch) => {
        dispatch(new OutboundMsg(1, [model_1.SelectedStudy]));
    }))) : empty_1());
    return [model_1, Cmd_batch(toList(delay(() => {
        let matchValue, creds, context;
        return append_1((!equals(lastModel.SelectedStudy.status, model_1.SelectedStudy.status) ? true : !equals(lastModel.DeidentificationState, model_1.DeidentificationState)) ? ((matchValue = model_1.FileUpdateArgs, (matchValue != null) ? ((creds = matchValue[0], (context = matchValue[1], singleton(Cmd_map((arg) => (new MsgCore(0, [new InternalMsg(1, [arg])])), singleton((dispatch_1) => {
            dispatch_1(new InboundMsg_2(1, [creds, context]));
        })))))) : singleton(Toast_errorToast("No credentials found")))) : empty_1(), delay(() => append_1(singleton_1(Cmd_map(wrapLocalMsg, patternInput[1])), delay(() => singleton_1(Cmd_map((Item_2) => (new MsgCore(1, [Item_2])), Cmd_batch(outboundCmd_1)))))));
    })))];
}

