import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Terminate_TerminateError } from "../../../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { filter, map as map_1, isEmpty, head, collect, partition, choose, singleton, append, tryHead, ofArray, length, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { ActiveZipBatch, ZipBatchStatus, OutboundMsg, LocalMsg, OngoingZip, ZipState, Model } from "./AutoZipTypes.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { some, map, toArray } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { List_except } from "../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { safeHash, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { nonSeeded } from "../../../fable_modules/fable-library-js.4.19.3/Random.js";
import { Cmd_ofCallbackPromise } from "../../../Common/JsInteropGeneral.js";
import { FileWithRawPath, createZipBatches, zipDirectory as zipDirectory_1 } from "../../../Common/JsZipCommon.js";
import { Toast_errorToast } from "../../../Common/General.js";
import { isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { FileData_createFromFile } from "../../../Common/FileData.js";
import { UploadSelection } from "../../Shared.js";
import { update as update_1 } from "../../../Common/InboundOutbound.js";

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (model.MaybeCurrentZip != null) ? (new FSharpResult$2(1, [new Terminate_TerminateError(2, ["File Zip"])])) : (new FSharpResult$2(0, [undefined])), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, () => {
    }))));
}

export function init(specs) {
    return [new Model(specs, empty(), undefined, empty(), undefined), Cmd_none()];
}

export function numberOfAssociatedFiles(baseCount, model) {
    return (length(model.OutstandingZips) + length(ofArray(toArray(model.MaybeCurrentZip)))) + baseCount;
}

export function tryGetOngoingZip(model) {
    return tryHead(append(ofArray(toArray(model.MaybeCurrentZip)), model.OutstandingZips));
}

export function startZipping(model, zipDirectory) {
    const model_1 = new Model(model.Specs, model.PathTestFiles, model.MaybeActiveZipBatch, List_except([new OngoingZip(zipDirectory, new ZipState(0, []))], model.OutstandingZips, {
        Equals: equals,
        GetHashCode: safeHash,
    }), new OngoingZip(zipDirectory, new ZipState(1, [0])));
    const random = nonSeeded();
    return [model_1, Cmd_ofCallbackPromise(zipDirectory_1, zipDirectory, (dispatch) => ((meta) => {
        if (random.NextDouble() > 0.8) {
            dispatch(new LocalMsg(6, [meta]));
        }
    }), (zippedFile) => (new LocalMsg(5, [[zipDirectory, zippedFile]])), (e) => (new LocalMsg(7, [zipDirectory.directoryName, e]))), singleton((dispatch_1) => {
        dispatch_1(new OutboundMsg(0, []));
    })];
}

export function updateLocal(args, msg, model) {
    let msg_2, msg_4, msg_6;
    switch (msg.tag) {
        case 2:
            if (numberOfAssociatedFiles(args.numberOfAssociatedFiles, model) > 0) {
                return [model, Toast_errorToast("Can only have one file uploaded. Please delete unneeded files."), Cmd_none()];
            }
            else {
                return msg.fields[0];
            }
        case 1: {
            let matchValue;
            const zipBatches = createZipBatches(250 * 1000000, choose((fd) => map((path) => (new FileWithRawPath(fd.file, path)), fd.maybePath), msg.fields[0]));
            matchValue = ((length(zipBatches) > 1) ? (new FSharpResult$2(1, ["Only one directory can be selected at a time"])) : ((length(partition((dir) => isNullOrEmpty(dir.directoryName), collect((zb) => zb.ZipDirectories, zipBatches))[0]) > 0) ? (new FSharpResult$2(1, ["Individual file selection not supported. Please select a DICOM directory."])) : (new FSharpResult$2(0, [head(zipBatches)]))));
            if (matchValue.tag === 0) {
                const onSuccess = [new Model(model.Specs, model.PathTestFiles, new ActiveZipBatch(matchValue.fields[0], new ZipBatchStatus(0, [])), model.OutstandingZips, model.MaybeCurrentZip), Cmd_none(), Cmd_none()];
                return [model, singleton((dispatch) => {
                    dispatch(new LocalMsg(2, [onSuccess]));
                }), Cmd_none()];
            }
            else {
                return [model, Toast_errorToast(matchValue.fields[0]), Cmd_none()];
            }
        }
        case 3: {
            const matchValue_1 = model.MaybeActiveZipBatch;
            if (matchValue_1 == null) {
                return [model, Toast_errorToast("Select a DICOM directory to upload"), Cmd_none()];
            }
            else {
                return [model, (msg_2 = (new LocalMsg(4, [matchValue_1])), singleton((dispatch_1) => {
                    dispatch_1(msg_2);
                })), Cmd_none()];
            }
        }
        case 4: {
            const activeZipBatch_1 = msg.fields[0];
            const zipDirs = activeZipBatch_1.zipBatch.ZipDirectories;
            const canStartZipping = isEmpty(model.OutstandingZips) && !isEmpty(zipDirs);
            const model_1 = new Model(model.Specs, model.PathTestFiles, new ActiveZipBatch(activeZipBatch_1.zipBatch, new ZipBatchStatus(1, [0])), append(model.OutstandingZips, map_1((zipDir) => (new OngoingZip(zipDir, new ZipState(0, []))), zipDirs)), model.MaybeCurrentZip);
            if (canStartZipping) {
                return startZipping(model_1, head(zipDirs));
            }
            else {
                return [model_1, Cmd_none(), Cmd_none()];
            }
        }
        case 5:
            return [new Model(model.Specs, model.PathTestFiles, model.MaybeActiveZipBatch, filter((toZip) => !equals(toZip.directory, msg.fields[0][0]), model.OutstandingZips), undefined), (msg_4 = (new LocalMsg(8, [singleton(FileData_createFromFile(undefined, msg.fields[0][1]))])), singleton((dispatch_2) => {
                dispatch_2(msg_4);
            })), Cmd_none()];
        case 6: {
            const metadata = msg.fields[0];
            const matchValue_2 = model.MaybeCurrentZip;
            if (matchValue_2 == null) {
                console.error(some("Received ZipProgress when there is no active file"));
                return [model, Cmd_none(), Cmd_none()];
            }
            else {
                const ongoingZip = matchValue_2;
                let matchResult, percentComplete_1;
                if (ongoingZip.state.tag === 1) {
                    if ((metadata.percent - ongoingZip.state.fields[0]) > 1) {
                        matchResult = 0;
                        percentComplete_1 = ongoingZip.state.fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return [new Model(model.Specs, model.PathTestFiles, model.MaybeActiveZipBatch, model.OutstandingZips, new OngoingZip(ongoingZip.directory, new ZipState(1, [metadata.percent]))), Cmd_none(), Cmd_none()];
                    default:
                        return [model, Cmd_none(), Cmd_none()];
                }
            }
        }
        case 8:
            return [model, Cmd_none(), (msg_6 = (new OutboundMsg(2, [new UploadSelection(msg.fields[0], true)])), singleton((dispatch_3) => {
                dispatch_3(msg_6);
            }))];
        case 7: {
            console.error(some(msg.fields[1]));
            return [model, Toast_errorToast(`Zip failed for file: ${msg.fields[0]}, please try again.`), Cmd_none()];
        }
        default:
            return [model, Toast_errorToast(msg.fields[0]), Cmd_none()];
    }
}

export function updateInbound(_args, msg, model) {
    if (msg.tag === 1) {
        const matchValue = tryHead(model.OutstandingZips);
        if (matchValue != null) {
            return startZipping(model, matchValue.directory);
        }
        else {
            return [model, Cmd_none(), singleton((dispatch) => {
                dispatch(new OutboundMsg(3, []));
            })];
        }
    }
    else {
        return [new Model(model.Specs, model.PathTestFiles, undefined, model.OutstandingZips, model.MaybeCurrentZip), Cmd_none(), Cmd_none()];
    }
}

export function update(args, msg, model) {
    return update_1(updateLocal, updateInbound, args, msg, model);
}

