import { FSharpResult$2, Result_Bind, Result_MapError, Result_Map } from "../fable-library-js.4.19.3/Result.js";
import { singleton, append, empty, cons as cons_1, foldBack } from "../fable-library-js.4.19.3/List.js";
import { getEnumerator, disposeSafe, defaultOf, equals, curry4, curry3, curry2 } from "../fable-library-js.4.19.3/Util.js";
import { some, value as value_1 } from "../fable-library-js.4.19.3/Option.js";
import { class_type } from "../fable-library-js.4.19.3/Reflection.js";
import { singleton as singleton_1 } from "../fable-library-js.4.19.3/AsyncBuilder.js";
import { sleep, catchAsync, startChild } from "../fable-library-js.4.19.3/Async.js";

/**
 * Pass in a function to handle each case of `Result`
 */
export function Result_bimap(onSuccess, onError, xR) {
    if (xR.tag === 1) {
        return onError(xR.fields[0]);
    }
    else {
        return onSuccess(xR.fields[0]);
    }
}

export function Result_map() {
    return (mapping) => ((result) => Result_Map(mapping, result));
}

export function Result_mapError() {
    return (mapping) => ((result) => Result_MapError(mapping, result));
}

export function Result_bind() {
    return (binder) => ((result) => Result_Bind(binder, result));
}

export function Result_iter(f, result) {
    Result_map()(f)(result);
}

/**
 * Apply a Result<fn> to a Result<x> monadically
 */
export function Result_apply(fR, xR) {
    const copyOfStruct = fR;
    if (copyOfStruct.tag === 1) {
        if (xR.tag === 1) {
            return new FSharpResult$2(1, [copyOfStruct.fields[0]]);
        }
        else {
            return new FSharpResult$2(1, [copyOfStruct.fields[0]]);
        }
    }
    else {
        const copyOfStruct_2 = xR;
        if (copyOfStruct_2.tag === 1) {
            return new FSharpResult$2(1, [copyOfStruct_2.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [copyOfStruct.fields[0](copyOfStruct_2.fields[0])]);
        }
    }
}

export function Result_sequence(aListOfResults) {
    return foldBack((headR, tailR) => Result_apply(Result_map()((head) => ((tail) => cons_1(head, tail)))(headR), tailR), aListOfResults, new FSharpResult$2(0, [empty()]));
}

/**
 * Lift a two parameter function to use Result parameters
 */
export function Result_lift2(f, x1, x2) {
    return Result_apply(Result_map()(curry2(f))(x1), x2);
}

/**
 * Lift a three parameter function to use Result parameters
 */
export function Result_lift3(f, x1, x2, x3) {
    const op_LessMultiplyGreater = Result_apply;
    return op_LessMultiplyGreater(op_LessMultiplyGreater(Result_map()(curry3(f))(x1), x2), x3);
}

/**
 * Lift a four parameter function to use Result parameters
 */
export function Result_lift4(f, x1, x2, x3, x4) {
    const op_LessMultiplyGreater = Result_apply;
    return op_LessMultiplyGreater(op_LessMultiplyGreater(op_LessMultiplyGreater(Result_map()(curry4(f))(x1), x2), x3), x4);
}

/**
 * Apply a monadic function with two parameters
 */
export function Result_bind2(f, x1, x2) {
    return Result_bind()((x) => x)(Result_lift2(f, x1, x2));
}

/**
 * Apply a monadic function with three parameters
 */
export function Result_bind3(f, x1, x2, x3) {
    return Result_bind()((x) => x)(Result_lift3(f, x1, x2, x3));
}

/**
 * Predicate that returns true on success
 */
export function Result_isOk(_arg) {
    if (_arg.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

/**
 * Predicate that returns true on failure
 */
export function Result_isError(xR) {
    return !Result_isOk(xR);
}

/**
 * Lift a given predicate into a predicate that works on Results
 */
export function Result_filter(pred, _arg) {
    if (_arg.tag === 1) {
        return true;
    }
    else {
        return pred(_arg.fields[0]);
    }
}

/**
 * On success, return the value. On error, return a default value
 */
export function Result_ifError(defaultVal, _arg) {
    if (_arg.tag === 1) {
        return defaultVal;
    }
    else {
        return _arg.fields[0];
    }
}

/**
 * Apply a monadic function to an Result<x option>
 */
export function Result_bindOption(f, xR) {
    if (xR == null) {
        return new FSharpResult$2(0, [undefined]);
    }
    else {
        const x = value_1(xR);
        return Result_map()(some)(f(x));
    }
}

/**
 * Convert an Option into a Result. If none, use the passed-in errorValue
 */
export function Result_ofOption(errorValue, opt) {
    if (opt == null) {
        return new FSharpResult$2(1, [errorValue]);
    }
    else {
        return new FSharpResult$2(0, [value_1(opt)]);
    }
}

/**
 * Convert a Result into an Option
 */
export function Result_toOption(xR) {
    if (xR.tag === 1) {
        return undefined;
    }
    else {
        return some(xR.fields[0]);
    }
}

/**
 * Convert the Error case into an Option
 * (useful with List.choose to find all errors in a list of Results)
 */
export function Result_toErrorOption(_arg) {
    if (_arg.tag === 1) {
        return some(_arg.fields[0]);
    }
    else {
        return undefined;
    }
}

export class ResultComputationExpression_ResultBuilder {
    constructor() {
    }
}

export function ResultComputationExpression_ResultBuilder_$reflection() {
    return class_type("ResultComputationExpression.ResultBuilder", undefined, ResultComputationExpression_ResultBuilder);
}

export function ResultComputationExpression_ResultBuilder_$ctor() {
    return new ResultComputationExpression_ResultBuilder();
}

export function ResultComputationExpression_ResultBuilder__Return_1505(__, x) {
    return new FSharpResult$2(0, [x]);
}

export function ResultComputationExpression_ResultBuilder__Bind_764BA1D3(__, x, f) {
    return Result_bind()(f)(x);
}

export function ResultComputationExpression_ResultBuilder__ReturnFrom_1505(__, x) {
    return x;
}

export function ResultComputationExpression_ResultBuilder__Zero(this$) {
    return ResultComputationExpression_ResultBuilder__Return_1505(this$, undefined);
}

export function ResultComputationExpression_ResultBuilder__Delay_1505(__, f) {
    return f;
}

export function ResultComputationExpression_ResultBuilder__Run_FCFD9EF(__, f) {
    return f();
}

export function ResultComputationExpression_ResultBuilder__While_Z746783BA(this$, guard, body) {
    if (!guard()) {
        return ResultComputationExpression_ResultBuilder__Zero(this$);
    }
    else {
        return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(this$, body(), () => ResultComputationExpression_ResultBuilder__While_Z746783BA(this$, guard, body));
    }
}

export function ResultComputationExpression_ResultBuilder__TryWith_Z570AC55B(this$, body, handler) {
    try {
        return ResultComputationExpression_ResultBuilder__ReturnFrom_1505(this$, body());
    }
    catch (e) {
        return handler(e);
    }
}

export function ResultComputationExpression_ResultBuilder__TryFinally_33907399(this$, body, compensation) {
    try {
        return ResultComputationExpression_ResultBuilder__ReturnFrom_1505(this$, body());
    }
    finally {
        compensation();
    }
}

export function ResultComputationExpression_ResultBuilder__Using_Z50D0D9AD(this$, disposable, body) {
    return ResultComputationExpression_ResultBuilder__TryFinally_33907399(this$, () => body(disposable), () => {
        if (equals(disposable, defaultOf())) {
        }
        else {
            let copyOfStruct = disposable;
            disposeSafe(copyOfStruct);
        }
    });
}

export function ResultComputationExpression_ResultBuilder__For_Z4A06DEED(this$, sequence, body) {
    return ResultComputationExpression_ResultBuilder__Using_Z50D0D9AD(this$, getEnumerator(sequence), (enum$) => ResultComputationExpression_ResultBuilder__While_Z746783BA(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), ResultComputationExpression_ResultBuilder__Delay_1505(this$, () => body(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export function ResultComputationExpression_ResultBuilder__Combine_71B5E353(this$, a, b) {
    return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(this$, a, b);
}

export const ResultComputationExpression_result = ResultComputationExpression_ResultBuilder_$ctor();

/**
 * Alias for Result.Map
 */
export function Validation_map() {
    return Result_map();
}

/**
 * Apply a Validation<fn> to a Validation<x> applicatively
 */
export function Validation_apply(fV, xV) {
    const copyOfStruct = fV;
    if (copyOfStruct.tag === 1) {
        const copyOfStruct_1 = xV;
        if (copyOfStruct_1.tag === 1) {
            return new FSharpResult$2(1, [append(copyOfStruct.fields[0], copyOfStruct_1.fields[0])]);
        }
        else {
            return new FSharpResult$2(1, [copyOfStruct.fields[0]]);
        }
    }
    else {
        const copyOfStruct_2 = xV;
        if (copyOfStruct_2.tag === 1) {
            return new FSharpResult$2(1, [copyOfStruct_2.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [copyOfStruct.fields[0](copyOfStruct_2.fields[0])]);
        }
    }
}

export function Validation_sequence(aListOfValidations) {
    return foldBack((headR, tailR) => Validation_apply(Result_map()((head) => ((tail) => cons_1(head, tail)))(headR), tailR), aListOfValidations, new FSharpResult$2(0, [empty()]));
}

export function Validation_ofResult(xR) {
    return Result_mapError()(singleton)(xR);
}

export function Validation_toResult(xV) {
    return xV;
}

/**
 * Lift a function to Async
 */
export function Async_map(f, xA) {
    return singleton_1.Delay(() => singleton_1.Bind(xA, (_arg) => singleton_1.Return(f(_arg))));
}

/**
 * Lift a value to Async
 */
export function Async_retn(x) {
    return singleton_1.Return(x);
}

/**
 * Apply an Async function to an Async value
 */
export function Async_apply(fA, xA) {
    return singleton_1.Delay(() => singleton_1.Bind(startChild(fA), (_arg) => singleton_1.Bind(xA, (_arg_1) => singleton_1.Bind(_arg, (_arg_2) => singleton_1.Return(_arg_2(_arg_1))))));
}

/**
 * Apply a monadic function to an Async value
 */
export function Async_bind(f, xA) {
    return singleton_1.Bind(xA, f);
}

/**
 * Lift a function to AsyncResult
 */
export function AsyncResult_map(f, x) {
    return Async_map(Result_map()(f), x);
}

/**
 * Lift a function to AsyncResult
 */
export function AsyncResult_mapError(f, x) {
    return Async_map(Result_mapError()(f), x);
}

/**
 * Apply ignore to the internal value
 */
export function AsyncResult_ignore(x) {
    return AsyncResult_map((value) => {
    }, x);
}

/**
 * Lift a value to AsyncResult
 */
export function AsyncResult_retn(x) {
    return Async_retn(new FSharpResult$2(0, [x]));
}

/**
 * Handles asynchronous exceptions and maps them into Failure cases using the provided function
 */
export function AsyncResult_catch(f, x) {
    return Async_map((_arg) => {
        if (_arg.tag === 1) {
            return new FSharpResult$2(1, [f(_arg.fields[0])]);
        }
        else {
            const copyOfStruct = _arg.fields[0];
            if (copyOfStruct.tag === 1) {
                return new FSharpResult$2(1, [copyOfStruct.fields[0]]);
            }
            else {
                return new FSharpResult$2(0, [copyOfStruct.fields[0]]);
            }
        }
    }, catchAsync(x));
}

/**
 * Apply an AsyncResult function to an AsyncResult value, monadically
 */
export function AsyncResult_applyM(fAsyncResult, xAsyncResult) {
    return Async_bind((fResult) => Async_map((xResult) => Result_apply(fResult, xResult), xAsyncResult), fAsyncResult);
}

/**
 * Apply an AsyncResult function to an AsyncResult value, applicatively
 */
export function AsyncResult_applyA(fAsyncResult, xAsyncResult) {
    return Async_bind((fResult) => Async_map((xResult) => Validation_apply(fResult, xResult), xAsyncResult), fAsyncResult);
}

/**
 * Apply a monadic function to an AsyncResult value
 */
export function AsyncResult_bind(f, xAsyncResult) {
    return singleton_1.Delay(() => singleton_1.Bind(xAsyncResult, (_arg) => {
        const xResult = _arg;
        return (xResult.tag === 1) ? singleton_1.Return(new FSharpResult$2(1, [xResult.fields[0]])) : singleton_1.ReturnFrom(f(xResult.fields[0]));
    }));
}

/**
 * Convert a list of AsyncResult into a AsyncResult<list> using monadic style.
 * Only the first error is returned. The error type need not be a list.
 */
export function AsyncResult_sequenceM(resultList) {
    return foldBack((headR, tailR) => AsyncResult_applyM(AsyncResult_map((head) => ((tail) => cons_1(head, tail)), headR), tailR), resultList, AsyncResult_retn(empty()));
}

/**
 * Convert a list of AsyncResult into a AsyncResult<list> using applicative style.
 * All the errors are returned. The error type must be a list.
 */
export function AsyncResult_sequenceA(resultList) {
    return foldBack((headR, tailR) => AsyncResult_applyA(AsyncResult_map((head) => ((tail) => cons_1(head, tail)), headR), tailR), resultList, AsyncResult_retn(empty()));
}

/**
 * Lift a value into an Ok inside a AsyncResult
 */
export function AsyncResult_ofSuccess(x) {
    return Async_retn(new FSharpResult$2(0, [x]));
}

/**
 * Lift a value into an Error inside a AsyncResult
 */
export function AsyncResult_ofError(x) {
    return Async_retn(new FSharpResult$2(1, [x]));
}

/**
 * Lift a Result into an AsyncResult
 */
export function AsyncResult_ofResult(x) {
    return Async_retn(x);
}

/**
 * Lift a Async into an AsyncResult
 */
export function AsyncResult_ofAsync(x) {
    return Async_map((ResultValue) => (new FSharpResult$2(0, [ResultValue])), x);
}

export function AsyncResult_sleep(ms) {
    return AsyncResult_ofAsync(sleep(ms));
}

export class AsyncResultComputationExpression_AsyncResultBuilder {
    constructor() {
    }
}

export function AsyncResultComputationExpression_AsyncResultBuilder_$reflection() {
    return class_type("AsyncResultComputationExpression.AsyncResultBuilder", undefined, AsyncResultComputationExpression_AsyncResultBuilder);
}

export function AsyncResultComputationExpression_AsyncResultBuilder_$ctor() {
    return new AsyncResultComputationExpression_AsyncResultBuilder();
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(__, x) {
    return AsyncResult_retn(x);
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(__, x, f) {
    return AsyncResult_bind(f, x);
}

export function AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(__, x) {
    return x;
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Zero(this$) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(this$, undefined);
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(__, f) {
    return singleton_1.Delay(() => singleton_1.ReturnFrom(f()));
}

export function AsyncResultComputationExpression_AsyncResultBuilder__While_Z69EA5D96(this$, guard, body) {
    if (!guard()) {
        return AsyncResultComputationExpression_AsyncResultBuilder__Zero(this$);
    }
    else {
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(this$, body(), () => AsyncResultComputationExpression_AsyncResultBuilder__While_Z69EA5D96(this$, guard, body));
    }
}

export function AsyncResultComputationExpression_AsyncResultBuilder__TryWith_Z83E8051(this$, body, handler) {
    return singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.ReturnFrom(AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(this$, body))), (_arg) => singleton_1.ReturnFrom(handler(_arg))));
}

export function AsyncResultComputationExpression_AsyncResultBuilder__TryFinally_Z1F7894D1(this$, body, compensation) {
    return singleton_1.Delay(() => singleton_1.TryFinally(singleton_1.Delay(() => singleton_1.ReturnFrom(AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(this$, body))), () => {
        compensation();
    }));
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Using_Z497109F1(this$, disposable, body) {
    return AsyncResultComputationExpression_AsyncResultBuilder__TryFinally_Z1F7894D1(this$, body(disposable), () => {
        if (equals(disposable, defaultOf())) {
        }
        else {
            let copyOfStruct = disposable;
            disposeSafe(copyOfStruct);
        }
    });
}

export function AsyncResultComputationExpression_AsyncResultBuilder__For_Z37455EC1(this$, sequence, body) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Using_Z497109F1(this$, getEnumerator(sequence), (enum$) => AsyncResultComputationExpression_AsyncResultBuilder__While_Z69EA5D96(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), () => AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(this$, () => body(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7(this$, a, b) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(this$, a, () => b);
}

export const AsyncResultComputationExpression_asyncResult = AsyncResultComputationExpression_AsyncResultBuilder_$ctor();

