import { Msg$3, InternalMsg$2 } from "../../../Common/InboundOutbound.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option, modal } from "../../../fable_modules/Fulma.3.0.0/Components/Modal.fs.js";
import { ofArray, empty, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { FormField_onChangeStringOpticTrimmed, FormField_textArea } from "../../../Common/Forms.js";
import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { DOMAttr, HTMLAttr } from "../../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { LocalMsg, AdditionalDataModel_get_maybeConclusionBuffer_ } from "./AdditionalDataTypes.js";
import { uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import * as react from "react";
import { Option as Option_1, button } from "../../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Size_ISize, Color_IColor } from "../../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Helpers_nothing } from "../../../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { Option as Option_2, icon } from "../../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { keyValueList } from "../../../fable_modules/fable-library-js.4.19.3/MapUtil.js";

export function editConclusionModal(model, customReportViewModel, dispatch) {
    let children_2, children;
    const internalDispatch = (arg_1) => {
        dispatch(new Msg$3(0, [new InternalMsg$2(0, [arg_1])]));
    };
    if (model.ShowConclusionModal) {
        return modal(singleton(new Option(1, [model.ShowConclusionModal])), singleton((children_2 = [background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton("Edit Assessment")))), Card_body(empty(), singleton((children = toList(delay(() => {
            let tupledArg;
            return singleton_1(FormField_textArea("", defaultArg(customReportViewModel.maybeConclusion, ""), singleton(new HTMLAttr(79, [model.IsSaving])), [], (tupledArg = AdditionalDataModel_get_maybeConclusionBuffer_(), FormField_onChangeStringOpticTrimmed((arg_2) => {
                internalDispatch(new LocalMsg(1, [arg_2]));
            }, tupledArg[0], uncurry2(tupledArg[1]))), "Assessment", []));
        })), react.createElement("form", {}, ...children)))), Card_foot(empty(), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(6, [])]), new Option_1(16, [model.IsSaving]), new Option_1(13, [model.IsSaving]), new Option_1(17, [ofArray([new DOMAttr(40, [(_arg) => {
            internalDispatch(new LocalMsg(4, []));
        }]), new HTMLAttr(159, ["button"])])])]), singleton("Save")), button(ofArray([new Option_1(0, [new Color_IColor(2, [])]), new Option_1(16, [model.IsSaving]), new Option_1(17, [ofArray([new DOMAttr(40, [(_arg_1) => {
            internalDispatch(new LocalMsg(3, []));
        }]), new HTMLAttr(159, ["button"])])])]), singleton("Cancel"))]))]))], react.createElement("div", {}, ...children_2))));
    }
    else {
        return Helpers_nothing;
    }
}

export function additionalDataView(model, customReportViewModel, dispatch, readOnly) {
    let children_2, children_10, props_8, children_8, props_6;
    const props_12 = [["style", {
        margin: "3rem 0",
    }]];
    const children_12 = [(children_2 = toList(delay(() => append(singleton_1("Assessment"), delay(() => {
        let props, children;
        return !readOnly ? singleton_1((props = [["style", {
            cursor: "Pointer",
            margin: "0 0 0 0.5em",
        }], new DOMAttr(40, [(_arg) => {
            dispatch(new Msg$3(0, [new InternalMsg$2(0, [new LocalMsg(2, [])])]));
        }])], (children = [icon(ofArray([new Option_2(0, [new Size_ISize(0, [])]), new Option_2(1, [])]), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-edit"])), [])))], react.createElement("span", keyValueList(props, 1), ...children)))) : empty_1();
    })))), react.createElement("b", {}, ...children_2)), (children_10 = [(props_8 = [["style", {
        padding: "0.5em 0.75em",
    }]], (children_8 = [defaultArg(map((con) => {
        const props_4 = [["style", {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            fontFamily: "Open Sans",
            fontSize: "1em",
            padding: "0",
            background: "none",
        }]];
        return react.createElement("pre", keyValueList(props_4, 1), con);
    }, customReportViewModel.maybeConclusion), (props_6 = [["style", {
        color: new Color_IColor(2, []),
    }]], react.createElement("div", keyValueList(props_6, 1), "No assessment")))], react.createElement("div", keyValueList(props_8, 1), ...children_8)))], react.createElement("div", {}, ...children_10))];
    return react.createElement("div", keyValueList(props_12, 1), ...children_12);
}

