import { map as map_2, change, ofList } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { DisplayMap_parse_Z721C83C5, DisplayMap } from "../Common/CommonBindings.js";
import { MeshViewportLocation_parse_Z721C83C5, MeshViewportLocation_get_all } from "../Common/CommonTypes.js";
import { equals, compare } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { ModelPickedMapValue } from "./PickedMapValueTypes.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { map as map_1 } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { update as update_1 } from "../../Common/InboundOutbound.js";

export function init() {
    return [new ModelPickedMapValue(ofList(map((location) => [location, [new DisplayMap(0, []), undefined]], MeshViewportLocation_get_all()), {
        Compare: compare,
    }), newGuid()), Cmd_none()];
}

export function updateInbound(token, msg, model) {
    if (msg.tag === 1) {
        return [new ModelPickedMapValue(change(msg.fields[0], (option) => map_1((tupledArg) => [tupledArg[0], undefined], option), model.PickedMapValues), model.LabelId), Cmd_none(), Cmd_none()];
    }
    else {
        const args = msg.fields[0];
        const meshViewportLocation = MeshViewportLocation_parse_Z721C83C5(args.viewportLocation);
        return [new ModelPickedMapValue(map_2((key, value) => {
            if (equals(key, meshViewportLocation)) {
                return [DisplayMap_parse_Z721C83C5(args.selectedMap), args.maybePickedValue];
            }
            else {
                return value;
            }
        }, model.PickedMapValues), model.LabelId), Cmd_none(), Cmd_none()];
    }
}

export function updateLocal(token, msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

