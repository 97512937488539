import { SessionManagementMsg, SessionManagement_update, SessionManagement_SessionManagementModel_token__39A60A33, InactivityConfig_create_Z5C7ECD40, SessionManagement_init } from "../Session.js";
import { ResetPasswordModel, InactivityModel_get_isActive_, Model_get_inactivity_, InactivityModel_get_loggedOut, InactivityModel_get_warning, Msg, Model, InactivityModel_get_init, ResetPasswordModel_get_empty, InactivityModel_get_timeUntilLogout, InactivityModel_get_timeUntilWarning } from "./AuthTypes.js";
import { Security_ResetPasswordViewModel, Security_ResetPasswordViewModel_get_empty, Security_LoginViewModel, Security_AuthErrorMessage_get_describe, Security_RegisterViewModel_get_empty, Security_LoginViewModel_get_empty } from "../fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Toast_successToast, Urls_Page, Urls_AuthPage, Urls_pageHash, Toast_errorToast } from "../Common/General.js";
import { some, map, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedAuthApi, publicAuthApi } from "../Api.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.4.0.3/navigation.fs.js";

export function init() {
    const patternInput = SessionManagement_init(undefined, InactivityConfig_create_Z5C7ECD40(InactivityModel_get_timeUntilWarning(), InactivityModel_get_timeUntilLogout()));
    return [new Model(Security_LoginViewModel_get_empty(), Security_RegisterViewModel_get_empty(), ResetPasswordModel_get_empty(), undefined, patternInput[0], InactivityModel_get_init()), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(7, []));
    }), Cmd_map((Item) => (new Msg(18, [Item])), patternInput[1])]))];
}

const errorToast = (() => {
    const f1 = Security_AuthErrorMessage_get_describe();
    return (arg) => Toast_errorToast(f1(arg));
})();

function maybeWithToken(f) {
    return (arg_1) => defaultArg(map(f, SessionManagement_SessionManagementModel_token__39A60A33(arg_1)), Cmd_none());
}

export function update(isInactivityDisabled, msg, model) {
    let l, bind$0040, bind$0040_2, bind$0040_3;
    switch (msg.tag) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
            return [model, Cmd_none()];
        }
        case 18: {
            const patternInput = SessionManagement_update(isInactivityDisabled, msg.fields[0], model.sessionManagement);
            const patternInput_1 = (msg.fields[0].tag === 7) ? [InactivityModel_get_warning(), singleton((dispatch) => {
                dispatch(new Msg(19, [true]));
            })] : ((msg.fields[0].tag === 8) ? [InactivityModel_get_loggedOut(), singleton((dispatch_1) => {
                dispatch_1(new Msg(6, []));
            })] : [model.inactivity, Cmd_none()]);
            return [new Model(model.loginVm, model.registerVm, model.resetPasswordModel, model.userInfo, patternInput[0], patternInput_1[0]), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(18, [Item])), patternInput[1]), patternInput_1[1]]))];
        }
        case 19:
            return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l = Model_get_inactivity_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), InactivityModel_get_isActive_())(l)))(msg.fields[0])(model), Cmd_none()];
        case 1:
            return [new Model(model.loginVm, msg.fields[0](model.registerVm), model.resetPasswordModel, model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
        case 2:
            return [new Model(msg.fields[0](model.loginVm), model.registerVm, model.resetPasswordModel, model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
        case 3:
            return [new Model(model.loginVm, model.registerVm, (bind$0040 = model.resetPasswordModel, new ResetPasswordModel(msg.fields[0](model.resetPasswordModel.resetPasswordVm), bind$0040.resetRequestEmail, bind$0040.hideResetEmail)), model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
        case 4:
            return [new Model(new Security_LoginViewModel(model.loginVm.email, ""), model.registerVm, model.resetPasswordModel, model.userInfo, model.sessionManagement, model.inactivity), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, publicAuthApi.login, model.loginVm, (Item_1) => (new Msg(5, [Item_1])), (Item_2) => (new Msg(0, [Item_2])))];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [model, errorToast(msg.fields[0].fields[0])];
            }
            else if (msg.fields[0].fields[0].tag === 1) {
                return [new Model(model.loginVm, model.registerVm, new ResetPasswordModel((bind$0040_2 = Security_ResetPasswordViewModel_get_empty(), new Security_ResetPasswordViewModel(model.loginVm.email, msg.fields[0].fields[0].fields[0], bind$0040_2.newPassword, bind$0040_2.confirmPassword)), "", true), undefined, model.sessionManagement, model.inactivity), Cmd_batch(ofArray([singleton((dispatch_4) => {
                    dispatch_4(new Msg(18, [new SessionManagementMsg(2, [])]));
                }), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, [new Urls_AuthPage(1, [undefined])])))]))];
            }
            else {
                return [model, Cmd_batch(ofArray([singleton((dispatch_2) => {
                    dispatch_2(new Msg(18, [new SessionManagementMsg(1, [msg.fields[0].fields[0].fields[0]])]));
                }), singleton((dispatch_3) => {
                    dispatch_3(new Msg(7, []));
                })]))];
            }
        case 7:
            return [model, maybeWithToken((t_2) => Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedAuthApi(t_2).getUserInfo, undefined, (Item_4) => (new Msg(8, [Item_4])), (Item_5) => (new Msg(0, [Item_5]))))(model.sessionManagement)];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [model, errorToast(msg.fields[0].fields[0])];
            }
            else {
                const userInfo_1 = msg.fields[0].fields[0];
                return [new Model(model.loginVm, model.registerVm, model.resetPasswordModel, userInfo_1, model.sessionManagement, model.inactivity), singleton((dispatch_5) => {
                    dispatch_5(new Msg(16, [userInfo_1]));
                })];
            }
        case 6:
            return [new Model(model.loginVm, model.registerVm, model.resetPasswordModel, undefined, model.sessionManagement, model.inactivity), Cmd_batch(ofArray([maybeWithToken((t_3) => Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedAuthApi(t_3).logout, undefined, (Item_6) => (new Msg(9, [Item_6])), (Item_7) => (new Msg(0, [Item_7]))))(model.sessionManagement), singleton((dispatch_6) => {
                dispatch_6(new Msg(18, [new SessionManagementMsg(2, [])]));
            }), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, [new Urls_AuthPage(0, [])])))]))];
        case 9:
            if (msg.fields[0].tag === 1) {
                return [model, Cmd_none()];
            }
            else {
                document.location.reload();
                return [model, Cmd_none()];
            }
        case 10:
            return [model, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, publicAuthApi.register, model.registerVm, (Item_8) => (new Msg(11, [Item_8])), (Item_9) => (new Msg(0, [Item_9])))];
        case 11:
            if (msg.fields[0].tag === 1) {
                return [model, errorToast(msg.fields[0].fields[0])];
            }
            else {
                return [model, Cmd_batch(ofArray([Toast_successToast("Success"), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, [new Urls_AuthPage(0, [])])))]))];
            }
        case 12:
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, publicAuthApi.resetPasswordWithToken, model.resetPasswordModel.resetPasswordVm, (Item_10) => (new Msg(13, [Item_10])), (Item_11) => (new Msg(0, [Item_11])))];
        case 13:
            if (msg.fields[0].tag === 1) {
                return [model, errorToast(msg.fields[0].fields[0])];
            }
            else {
                return [model, Cmd_batch(ofArray([Toast_successToast("Success, please log in with your new password"), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, [new Urls_AuthPage(0, [])])))]))];
            }
        case 14:
            return [new Model(model.loginVm, model.registerVm, (bind$0040_3 = model.resetPasswordModel, new ResetPasswordModel(bind$0040_3.resetPasswordVm, msg.fields[0], bind$0040_3.hideResetEmail)), model.userInfo, model.sessionManagement, model.inactivity), Cmd_none()];
        case 15:
            return [new Model(model.loginVm, model.registerVm, ResetPasswordModel_get_empty(), model.userInfo, model.sessionManagement, model.inactivity), Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, publicAuthApi.requestPasswordResetTokenEmail, model.resetPasswordModel.resetRequestEmail, (Item_12) => (new Msg(0, [Item_12]))), Toast_successToast("An email has been sent to the specified address"), Navigation_newUrl(Urls_pageHash(new Urls_Page(0, [new Urls_AuthPage(0, [])])))]))];
        case 17:
            if (msg.fields[0].tag === 2) {
                return [model, singleton((dispatch_7) => {
                    dispatch_7(new Msg(7, []));
                })];
            }
            else {
                return [model, Cmd_none()];
            }
        default:
            return [model, Cmd_none()];
    }
}

