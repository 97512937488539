import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, int32_type, lambda_type, string_type, list_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { item } from "../fable_modules/fable-library-js.4.19.3/List.js";

export class InboundMsg extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["NextStep"];
    }
}

export function InboundMsg_$reflection() {
    return union_type("Client.Wizard.Types.InboundMsg", [], InboundMsg, () => [[]]);
}

export class OutboundMsg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NextStepRequested", "CancelRequested"];
    }
}

export function OutboundMsg$1_$reflection(gen0) {
    return union_type("Client.Wizard.Types.OutboundMsg`1", [gen0], OutboundMsg$1, () => [[["Item", gen0]], [["Item", gen0]]]);
}

export class InternalMsg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Inbound"];
    }
}

export function InternalMsg_$reflection() {
    return union_type("Client.Wizard.Types.InternalMsg", [], InternalMsg, () => [[["Item", InboundMsg_$reflection()]]]);
}

export class Msg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Internal", "External"];
    }
}

export function Msg$1_$reflection(gen0) {
    return union_type("Client.Wizard.Types.Msg`1", [gen0], Msg$1, () => [[["Item", InternalMsg_$reflection()]], [["Item", OutboundMsg$1_$reflection(gen0)]]]);
}

export function wrapInboundMsg(msg) {
    return new Msg$1(0, [new InternalMsg(msg)]);
}

export class Model$1 extends Record {
    constructor(steps, describeStep, activeStepIndex) {
        super();
        this.steps = steps;
        this.describeStep = describeStep;
        this.activeStepIndex = (activeStepIndex | 0);
    }
}

export function Model$1_$reflection(gen0) {
    return record_type("Client.Wizard.Types.Model`1", [gen0], Model$1, () => [["steps", list_type(gen0)], ["describeStep", lambda_type(gen0, string_type)], ["activeStepIndex", int32_type]]);
}

export function Model$1_getActiveStep_Z2F6B8DDE(model) {
    return item(model.activeStepIndex, model.steps);
}

