import 'vtk.js/Sources/Rendering/Profiles/Geometry';
import "vtk.js/Sources/Rendering/Profiles/Glyph";

import vtkGenericRenderWindow from 'vtk.js/Sources/Rendering/Misc/GenericRenderWindow';
import readPolyDataArrayBuffer from 'itk/readPolyDataArrayBuffer';
import vtkITKPolyDataReader from 'vtk.js/Sources/IO/Misc/ITKPolyDataReader';


import vtkWidgetManager from "vtk.js/Sources/Widgets/Core/WidgetManager";

import React, { useEffect, useRef, useState } from 'react';

import { displayMapTypes, ignore, logDebug } from './Common';

import { useNotes } from '../Notes/Notes';
import { useAnatomyScalarBar } from '../AnatomyScalarBar/AnatomyScalarBar';
import { useCamera } from '../Camera/Camera';
import { useCalipers } from '../Calipers/Calipers';
import { useCenterlineMeasurement } from '../CenterlineMeasurement/CenterlineMeasurement';
import { useScreenshot } from '../Screenshot/Screenshot';
import { useInteraction } from './Interaction';
import { useSlicePlane } from '../SlicePlane/SlicePlane';
import customTrackballInteractor from "./CustomTrackballInteractor";
import { removeDisplayedMeshes, updateSelectedMap } from '../Meshes/Meshes';
import { useAnatomyOrientation } from '../AnatomyOrientation/AnatomyOrientationWidget';

vtkITKPolyDataReader.setReadPolyDataArrayBufferFromITK(readPolyDataArrayBuffer);

const isDebugging = false;

function log(msg) {
    logDebug("MeshVisualization", isDebugging, msg);
}


/**
 * @typedef {object} MapValuePickedArgs
 * @property {string} meshViewportLocation
 * @property {number?} maybePickedValue
 */

/**
 * @callback onMapValuePicked
 * @param {MapValuePickedArgs} mapValuePickedArgs
 * @returns {void}
 */

/**
 * @callback clearPickedValues
 * @param {void}
 * @returns {void}
 */

/**
 * @typedef {object} PickedMapValueProps
 * @property {onMapValuePicked} onMapValuePicked
 * @property notesProps
 * @property {clearPickedValues} clearPickedValues
 */

/**
 * MeshVisualization React component.
 * @param currentStudyMeshes
 * @param previousStudyMeshes
 * @param slicePlaneProps
 * @param staticNotesProps
 * @param measurementNotesProps
 * @param cameraProps
 * @param centerlineMeasurementProps
 * @param calipersProps
 * @param screenshotProps
 * @param selectedMap
 * @param showCenterline
 * @param overlayPreviousStudyMaps
 * @param interactionModeProps
 * @param onViewportResized
 * @param anatomyOrientationProps
 * @param showPreviousStudyMapsOnly
 * @param {PickedMapValueProps} pickedMapValueProps
 * @param meshViewportLocation
 * @param isSliceScroll
 * @param onMeshSliceChange
 */
const MeshVisualization = (
    {
        currentStudyMeshes,
        previousStudyMeshes,
        slicePlaneProps,
        staticNotesProps,
        measurementNotesProps,
        cameraProps,
        centerlineMeasurementProps,
        calipersProps,
        screenshotProps,
        selectedMap,
        showCenterline,
        overlayPreviousStudyMaps,
        interactionModeProps,
        onViewportResized,
        anatomyOrientationProps,
        showPreviousStudyMapsOnly = false,
        pickedMapValueProps,
        meshViewportLocation,
        isSliceScroll,
        onMeshSliceChange
    }) => {

    const containerRef = useRef(null);
    const context = useRef(null);

    // Internal state used to trigger a re-render for hooks that have a dependency on the parent context
    const [initialized, setInitialized] = useState(false);

    selectedMap = selectedMap.name ?? selectedMap;

    const render = () => {
        if (context.current) {
            log("Rendering");

            // OPTIMIZE:
            // This is a quick fix for the resolution issue when switching between single/quad view.
            // A better fix would be to depend on a visibility prop.
            context.current.genericRenderWindow.resize();
        }
    };

    render();

    useEffect(() => {
        if (!context.current && currentStudyMeshes) {

            log("Reading mesh data");
            const handleResize = () => {
                if (containerRef.current && onViewportResized) {
                    const dims = containerRef.current.getBoundingClientRect();
                    const bounds = { width: dims.width, height: dims.height };
                    onViewportResized(bounds);

                    if (context.current?.updatePickedIndicator) {
                        context.current.updatePickedIndicator();
                    }

                    render();
                }
            };

            // Render on container resizes
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(containerRef.current);

            // We use the wrapper here to abstract out manual RenderWindow/Renderer/OpenGLRenderWindow setup
            const genericRenderWindow = vtkGenericRenderWindow.newInstance({
                background: [0.0, 0.0, 0.0]
            });
            genericRenderWindow.setContainer(containerRef.current);
            genericRenderWindow.resize();

            const renderer = genericRenderWindow.getRenderer();
            const renderWindow = genericRenderWindow.getRenderWindow();

            const istyle = customTrackballInteractor.newInstance();
            istyle.setMouseWheelIsSliceScroll(isSliceScroll);
            istyle.setUpdateSliceMethod(function(incVal) {
                onMeshSliceChange(incVal);
            });
            renderWindow.getInteractor().setInteractorStyle(istyle);

            const widgetManager = vtkWidgetManager.newInstance();
            widgetManager.setRenderer(renderer);

            // ----------------------------------------------------------------------------
            // Set up the data
            // ----------------------------------------------------------------------------

            context.current = {
                genericRenderWindow,
                renderWindow,
                renderer,
                resizeObserver,
                widgetManager,
                istyle,
                pickMapValueSubscription: null,
                deletePickedIndicator: ignore,
                updatePickedIndicator: ignore,
                cameraModifiedSubscription: null
            };

            updateSelectedMap(
                meshViewportLocation,
                context.current,
                selectedMap,
                renderer,
                currentStudyMeshes,
                interactionModeProps.interactionMode,
                pickedMapValueProps);

            renderer.resetCamera();
            // TIP: If the picked indicator isn't being updated in some cases, try also subscribing to renderWindow.onModified.
            context.current.cameraModifiedSubscription = renderer.getActiveCamera().onModified(() => {
                log("renderer.getActiveCamera().onModified");
                if (context.current.updatePickedIndicator) {
                    context.current.updatePickedIndicator();
                    renderWindow.render();
                }
            });

            renderWindow.render();

            setInitialized(true);
            log("Component has been initialized");
        }

        const container = containerRef.current;
        const ctx = context.current;
        return () => {
            if (ctx) {
                log("Destroying component");
                const {
                    genericRenderWindow,
                    resizeObserver,
                    widgetManager,
                    pickMapValueSubscription,
                    cameraModifiedSubscription
                } = ctx;

                if (pickMapValueSubscription) {
                    pickMapValueSubscription.unsubscribe();
                }

                if (cameraModifiedSubscription) {
                    cameraModifiedSubscription.unsubscribe();
                }

                // TODO VRM-1536 - Do we need to dispose here? Who should be responsible for currentStudyMeshes's lifecycle?
                // This also causes a crash if the DOM location of the vtk.js visualization ever changes
                //if (currentStudyMeshes) {
                //    currentStudyMeshes.disposeMeshes(currentStudyMeshes);
                //}
                genericRenderWindow.delete();
                resizeObserver.unobserve(container);
                widgetManager.delete();

                context.current = null;
            }
        };
    }, [currentStudyMeshes]);

    // Side effect for updating visualization with selected map
    useEffect(() => {
        if (context.current && currentStudyMeshes) {
            const { renderer, renderWindow } = context.current;

            if (previousStudyMeshes && showPreviousStudyMapsOnly === true) {
                log(`Changing previous study selected map to ${selectedMap}`);
                removeDisplayedMeshes(renderer, currentStudyMeshes);
                updateSelectedMap(
                    meshViewportLocation,
                    context.current,
                    selectedMap,
                    renderer,
                    previousStudyMeshes,
                    interactionModeProps.interactionMode,
                    pickedMapValueProps);
            } else {
                log(`Changing current study selected map to ${selectedMap}`);
                if (previousStudyMeshes) {
                    removeDisplayedMeshes(renderer, previousStudyMeshes);
                }
                updateSelectedMap(
                    meshViewportLocation,
                    context.current,
                    selectedMap,
                    renderer,
                    currentStudyMeshes,
                    interactionModeProps.interactionMode,
                    pickedMapValueProps);
            }

            renderWindow.render();
        }
    }, [selectedMap, currentStudyMeshes, previousStudyMeshes, showPreviousStudyMapsOnly]);

    // Side effect for previous study overlay
    useEffect(() => {
        if (previousStudyMeshes) {
            const { renderer, renderWindow, } = context.current;

            if (showPreviousStudyMapsOnly === false) {
                removeDisplayedMeshes(renderer, previousStudyMeshes);

                // Don't overlay when showing previous study
                if (overlayPreviousStudyMaps && showPreviousStudyMapsOnly === false) {
                    log("Showing previous map overlay");
                    updateSelectedMap(
                        meshViewportLocation,
                        context.current,
                        selectedMap,
                        renderer,
                        previousStudyMeshes,
                        interactionModeProps.interactionMode,
                        pickedMapValueProps,
                        true);
                }
                renderWindow.render();
            }
        }
    }, [selectedMap, overlayPreviousStudyMaps, previousStudyMeshes, showPreviousStudyMapsOnly]);

    useEffect(() => {
        if (context.current) {
            context.current.istyle.setMouseWheelIsSliceScroll(isSliceScroll);
        }
    }, [isSliceScroll]);

    // Side effect for setting the plane index
    useSlicePlane(context.current, slicePlaneProps);

    // Camera side effect
    useCamera(containerRef.current, context.current, initialized, cameraProps);

    // Notes side effect
    const [redrawMeasurementNotes, ...notesInteractionMeasurement] = useNotes("measurement", containerRef.current,
        context.current,
        measurementNotesProps);
    const [redrawStaticNotes, ...notesInteractionStatic] = useNotes("static", containerRef.current,
        context.current,
        staticNotesProps);
    const [redrawPickedMapValueNotes, ...notesInteractionPickedMapValue] = useNotes("pickedMapValue", containerRef.current,
        context.current,
        pickedMapValueProps.notesProps);


    // Anatomy Scalar bar side effect
    const showAnatomyScalarBar = selectedMap === displayMapTypes.ANATOMY;
    const [redrawAnatomyScalarBar] = useAnatomyScalarBar(containerRef, initialized, showAnatomyScalarBar);

    const redrawCanvases = (canvas, dims) => {
        redrawMeasurementNotes(canvas, dims);
        redrawStaticNotes(canvas, dims);
        redrawAnatomyScalarBar(canvas, dims);
        redrawPickedMapValueNotes(canvas, dims);
    };

    // Centerline Measurement side effect
    useCenterlineMeasurement(containerRef.current, context.current, centerlineMeasurementProps);

    // Calipers side effect
    useCalipers(context.current, initialized, calipersProps, currentStudyMeshes);

    // Screenshot side effect
    const [captureScreenshot, ...screenshotInteraction] = useScreenshot(containerRef.current, context.current, redrawCanvases, screenshotProps);

    // Interaction side effect
    useInteraction(
        context.current,
        interactionModeProps,
        notesInteractionStatic,
        notesInteractionMeasurement,
        screenshotInteraction,
        captureScreenshot,
        pickedMapValueProps,
        currentStudyMeshes,
        previousStudyMeshes,
        showPreviousStudyMapsOnly,
        meshViewportLocation,
        selectedMap);

    // Orientation marker side effect
    useAnatomyOrientation(context.current, initialized, anatomyOrientationProps);

    useEffect(() => {
        if (context.current && currentStudyMeshes) {
            log("Toggling centerline");

            const { renderer, renderWindow } = context.current;

            if (showCenterline) {
                renderer.addActor(currentStudyMeshes.lumenCenterline.actor);
            } else {
                renderer.removeActor(currentStudyMeshes.lumenCenterline.actor);
            }

            renderWindow.render();
        }
    }, [showCenterline]);

    return <div className="layout-view-view-container" ref={containerRef}/>;
};

export default MeshVisualization;

