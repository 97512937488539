import { CalipersMeasurement_init, CalipersMeasurement_MeasurementId_11348511, Mode_get_isActive, OutboundMsg, CalipersMeasurement, LocalMsg, ModelCalipers, Mode as Mode_4 } from "./CalipersTypes.js";
import { filter, ofArray, cons, head, tail, isEmpty, tryFind, tryHead, tryPick, map, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { update as update_1, wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { map as map_1, some, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Common_floatToMm, Visualization_Point3d_toTuple_395A7FEE, Visualization_Point3d_ofTuple_Z7AD9E565 } from "../../RAWMap.Models/Common.js";
import { CalipersMeasurementViewModel } from "../../RAWMap.Models/View/CalipersMeasurement.js";
import state_3 from "../../../../src/RAWMap.Client/Visualization/Calipers/CalipersWidget/state";
import { CalipersStateArgs_init_244AC511, CalipersStateArgs } from "./CalipersBindings.js";
import { Cmd_batch, Cmd_ofEffect, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Toast_errorToast } from "../../Common/General.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { makeMeasurementEntries } from "./CalipersMeasurementSplitEntry.js";
import { EditMode, MeasurementUpdatedOutbound$2, SelectionAction$2 } from "../MeasurementsView.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedApi } from "../../Api.js";
import { ErrorMessage_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { InteractionMode } from "../Common/CommonBindings.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { Result_map } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";

export function init(selectedStudyId) {
    let msg_1;
    return [new ModelCalipers(selectedStudyId, new Mode_4(0, []), empty(), false, empty(), false), (msg_1 = wrapLocalMsg(new LocalMsg(8, [undefined])), singleton((dispatch) => {
        dispatch(msg_1);
    }))];
}

function subscribeToOnDistanceMeasured(state, dispatch) {
    state.setOnDistanceMeasured((mid, dist) => {
        dispatch(new LocalMsg(18, [mid, dist]));
    });
}

function calipersMeasurementToViewModel(sid, calipersMeasurement) {
    let tupledArg, tupledArg_1;
    return new CalipersMeasurementViewModel(calipersMeasurement.State.getMeasurementId(), sid, calipersMeasurement.Label, defaultArg(calipersMeasurement.Distance, 0), (tupledArg = calipersMeasurement.State.getFirstPoint().getOrigin(), Visualization_Point3d_ofTuple_Z7AD9E565(tupledArg[0], tupledArg[1], tupledArg[2])), (tupledArg_1 = calipersMeasurement.State.getSecondPoint().getOrigin(), Visualization_Point3d_ofTuple_Z7AD9E565(tupledArg_1[0], tupledArg_1[1], tupledArg_1[2])), calipersMeasurement.LabelPosition);
}

function calipersMeasurementFromViewModel(vm) {
    return new CalipersMeasurement(state_3(new CalipersStateArgs(vm.measurementId, Visualization_Point3d_toTuple_395A7FEE(vm.firstPoint), Visualization_Point3d_toTuple_395A7FEE(vm.secondPoint), true)), vm.name, vm.value, vm.labelPosition);
}

export function updateInbound(args, msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

export function updateLocal(args, msg, model) {
    let msg_4, matchValue, vm, vm_1, msg_5, msg_9, matchValue_5, msg_15, f1_1, msg_20;
    let matchResult, ex, d, mid, midToSelect, measurementVms, midToSelect_1, e, mid_1, setter, updatedPosition, mid_2, mode, status, mid_4, deleted, status_1, e_1;
    switch (msg.tag) {
        case 18: {
            matchResult = 1;
            d = msg.fields[1];
            mid = msg.fields[0];
            break;
        }
        case 8: {
            matchResult = 2;
            midToSelect = msg.fields[0];
            break;
        }
        case 9: {
            if (msg.fields[1].tag === 1) {
                matchResult = 4;
                e = msg.fields[1].fields[0];
            }
            else {
                matchResult = 3;
                measurementVms = msg.fields[1].fields[0];
                midToSelect_1 = msg.fields[0];
            }
            break;
        }
        case 1: {
            matchResult = 5;
            break;
        }
        case 3: {
            matchResult = 6;
            break;
        }
        case 2: {
            matchResult = 7;
            break;
        }
        case 5: {
            matchResult = 8;
            mid_1 = msg.fields[0];
            break;
        }
        case 6: {
            matchResult = 9;
            break;
        }
        case 14: {
            matchResult = 10;
            setter = msg.fields[0];
            break;
        }
        case 15: {
            matchResult = 11;
            break;
        }
        case 16: {
            matchResult = 12;
            updatedPosition = msg.fields[0];
            break;
        }
        case 7: {
            matchResult = 13;
            mid_2 = msg.fields[1];
            mode = msg.fields[0];
            break;
        }
        case 4: {
            matchResult = 14;
            break;
        }
        case 10: {
            if (msg.fields[0].tag === 1) {
                matchResult = 19;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 15;
                status = msg.fields[0].fields[0][0];
            }
            break;
        }
        case 11: {
            if (msg.fields[0].tag === 1) {
                matchResult = 19;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 15;
                status = msg.fields[0].fields[0];
            }
            break;
        }
        case 12: {
            matchResult = 16;
            break;
        }
        case 13: {
            matchResult = 17;
            mid_4 = msg.fields[0];
            break;
        }
        case 17: {
            if (msg.fields[0].tag === 1) {
                matchResult = 19;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 18;
                deleted = msg.fields[0].fields[0][1];
                status_1 = msg.fields[0].fields[0][0];
            }
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(ex));
            return [new ModelCalipers(model.SelectedStudyId, model.Mode, model.ActiveMeasurements, false, model.Measurements, model.IsEditingLabels), Toast_errorToast("An error has occured"), Cmd_none()];
        }
        case 1: {
            const measurements = map((m) => {
                if (m.measurementId === mid) {
                    return new CalipersMeasurementViewModel(m.measurementId, m.studyId, m.name, Common_floatToMm(d), m.firstPoint, m.secondPoint, m.labelPosition);
                }
                else {
                    return m;
                }
            }, model.Measurements);
            const maybeLastDistance = tryPick((m_1) => {
                if (m_1.State.getMeasurementId() === mid) {
                    return m_1.Distance;
                }
                else {
                    return undefined;
                }
            }, model.ActiveMeasurements);
            const activeMeasurements = map((m_2) => {
                if (m_2.State.getMeasurementId() === mid) {
                    return new CalipersMeasurement(m_2.State, m_2.Label, Common_floatToMm(d), m_2.LabelPosition);
                }
                else {
                    return m_2;
                }
            }, model.ActiveMeasurements);
            let outMsg;
            if (!equals(d * 1, maybeLastDistance)) {
                const msg_1 = new OutboundMsg(1, [new MeasurementUpdatedOutbound$2(makeMeasurementEntries(map((m_3) => {
                    if (m_3.measurementId === mid) {
                        return defaultArg(map_1((active) => (new CalipersMeasurementViewModel(m_3.measurementId, m_3.studyId, m_3.name, m_3.value, m_3.firstPoint, m_3.secondPoint, active.LabelPosition)), tryHead(activeMeasurements)), m_3);
                    }
                    else {
                        return m_3;
                    }
                }, measurements)), new SelectionAction$2(0, []))]);
                outMsg = singleton((dispatch) => {
                    dispatch(msg_1);
                });
            }
            else {
                outMsg = Cmd_none();
            }
            return [new ModelCalipers(model.SelectedStudyId, model.Mode, activeMeasurements, model.IsSaving, measurements, model.IsEditingLabels), Cmd_none(), outMsg];
        }
        case 2:
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(args.token).getCalipersMeasurementsForStudy, model.SelectedStudyId, (r) => (new LocalMsg(9, [midToSelect, r])), (Item) => (new LocalMsg(0, [Item]))), Cmd_none()];
        case 3:
            return [new ModelCalipers(model.SelectedStudyId, model.Mode, model.ActiveMeasurements, model.IsSaving, measurementVms, model.IsEditingLabels), defaultArg(map_1((arg_1) => singleton((dispatch_1) => {
                dispatch_1(new LocalMsg(5, [arg_1]));
            }), midToSelect_1), Cmd_none()), (msg_4 = (new OutboundMsg(1, [new MeasurementUpdatedOutbound$2(makeMeasurementEntries(measurementVms), new SelectionAction$2(0, []))])), singleton((dispatch_2) => {
                dispatch_2(msg_4);
            }))];
        case 4:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e)), Cmd_none()];
        case 5: {
            const maybeVm = map_1((calipersMeasurement) => calipersMeasurementToViewModel(model.SelectedStudyId, calipersMeasurement), tryHead(model.ActiveMeasurements));
            return [new ModelCalipers(model.SelectedStudyId, model.Mode, model.ActiveMeasurements, true, model.Measurements, model.IsEditingLabels), (matchValue = model.Mode, (matchValue.tag === 1) ? ((maybeVm != null) ? ((vm = maybeVm, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(args.token).createCalipersMeasurement, vm, (Item_1) => (new LocalMsg(10, [Item_1])), (Item_2) => (new LocalMsg(0, [Item_2]))))) : Toast_errorToast("Not in compatible mode")) : ((matchValue.tag === 2) ? ((maybeVm != null) ? ((vm_1 = maybeVm, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(args.token).editCalipersMeasurement, vm_1, (Item_3) => (new LocalMsg(11, [Item_3])), (Item_4) => (new LocalMsg(0, [Item_4]))))) : Toast_errorToast("Not in compatible mode")) : Toast_errorToast("Not in compatible mode"))), Cmd_none()];
        }
        case 6:
            return [model, Cmd_none(), Cmd_none()];
        case 7:
            return [new ModelCalipers(model.SelectedStudyId, new Mode_4(0, []), empty(), model.IsSaving, model.Measurements, model.IsEditingLabels), (msg_5 = (new LocalMsg(8, [map_1((m_4) => m_4.State.getMeasurementId(), tryHead(model.ActiveMeasurements))])), singleton((dispatch_3) => {
                dispatch_3(msg_5);
            })), singleton((dispatch_4) => {
                dispatch_4(new OutboundMsg(2, [new InteractionMode(0, [])]));
            })];
        case 8: {
            const matchValue_2 = model.ActiveMeasurements;
            let matchResult_1, m_7;
            if (!isEmpty(matchValue_2)) {
                if (isEmpty(tail(matchValue_2))) {
                    if (CalipersMeasurement_MeasurementId_11348511(head(matchValue_2)) === mid_1) {
                        matchResult_1 = 1;
                        m_7 = head(matchValue_2);
                    }
                    else {
                        matchResult_1 = 2;
                    }
                }
                else {
                    matchResult_1 = 2;
                }
            }
            else {
                matchResult_1 = 0;
            }
            switch (matchResult_1) {
                case 0: {
                    let patternInput;
                    const matchValue_3 = tryFind((m_6) => (m_6.measurementId === mid_1), model.Measurements);
                    if (matchValue_3 == null) {
                        patternInput = [model, Cmd_none()];
                    }
                    else {
                        const active_1 = calipersMeasurementFromViewModel(matchValue_3);
                        patternInput = [new ModelCalipers(model.SelectedStudyId, model.Mode, singleton(active_1), model.IsSaving, model.Measurements, model.IsEditingLabels), Cmd_ofEffect((dispatch_5) => {
                            subscribeToOnDistanceMeasured(active_1.State, dispatch_5);
                        })];
                    }
                    return [patternInput[0], patternInput[1], Cmd_none()];
                }
                case 1:
                    return [!Mode_get_isActive()(model.Mode) ? (new ModelCalipers(model.SelectedStudyId, model.Mode, empty(), model.IsSaving, model.Measurements, model.IsEditingLabels)) : model, Cmd_none(), Cmd_none()];
                default:
                    return [new ModelCalipers(model.SelectedStudyId, model.Mode, empty(), model.IsSaving, model.Measurements, model.IsEditingLabels), singleton((dispatch_6) => {
                        dispatch_6(msg);
                    }), Cmd_none()];
            }
        }
        case 9:
            return [!Mode_get_isActive()(model.Mode) ? (new ModelCalipers(model.SelectedStudyId, model.Mode, empty(), model.IsSaving, model.Measurements, model.IsEditingLabels)) : model, Cmd_none(), Cmd_none()];
        case 10: {
            const matchValue_4 = model.ActiveMeasurements;
            let matchResult_2, caliper;
            if (!isEmpty(matchValue_4)) {
                if (isEmpty(tail(matchValue_4))) {
                    matchResult_2 = 0;
                    caliper = head(matchValue_4);
                }
                else {
                    matchResult_2 = 1;
                }
            }
            else {
                matchResult_2 = 1;
            }
            switch (matchResult_2) {
                case 0: {
                    const updatedCaliper = setter(caliper);
                    const measurements_1 = map((m_8) => {
                        if (m_8.measurementId === CalipersMeasurement_MeasurementId_11348511(caliper)) {
                            return new CalipersMeasurementViewModel(m_8.measurementId, m_8.studyId, updatedCaliper.Label, m_8.value, m_8.firstPoint, m_8.secondPoint, m_8.labelPosition);
                        }
                        else {
                            return m_8;
                        }
                    }, model.Measurements);
                    const activeMeasurements_1 = map((m_9) => {
                        if (CalipersMeasurement_MeasurementId_11348511(m_9) === CalipersMeasurement_MeasurementId_11348511(caliper)) {
                            return new CalipersMeasurement(m_9.State, updatedCaliper.Label, m_9.Distance, updatedCaliper.LabelPosition);
                        }
                        else {
                            return m_9;
                        }
                    }, model.ActiveMeasurements);
                    const updatedMeasurements_1 = map((m_10) => {
                        if (m_10.measurementId === CalipersMeasurement_MeasurementId_11348511(caliper)) {
                            return defaultArg(map_1((active_2) => (new CalipersMeasurementViewModel(m_10.measurementId, m_10.studyId, m_10.name, m_10.value, m_10.firstPoint, m_10.secondPoint, active_2.LabelPosition)), tryHead(activeMeasurements_1)), m_10);
                        }
                        else {
                            return m_10;
                        }
                    }, measurements_1);
                    return [new ModelCalipers(model.SelectedStudyId, model.Mode, activeMeasurements_1, model.IsSaving, measurements_1, model.IsEditingLabels), Cmd_none(), (msg_9 = (new OutboundMsg(1, [new MeasurementUpdatedOutbound$2(makeMeasurementEntries(updatedMeasurements_1), new SelectionAction$2(0, []))])), singleton((dispatch_7) => {
                        dispatch_7(msg_9);
                    }))];
                }
                default:
                    return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 11:
            return [new ModelCalipers(model.SelectedStudyId, model.Mode, model.ActiveMeasurements, model.IsSaving, model.Measurements, true), Cmd_none(), singleton((dispatch_8) => {
                dispatch_8(new OutboundMsg(2, [new InteractionMode(2, [])]));
            })];
        case 12: {
            const model_1 = new ModelCalipers(model.SelectedStudyId, model.Mode, model.ActiveMeasurements, model.IsSaving, model.Measurements, false);
            return [(matchValue_5 = model_1.ActiveMeasurements, !isEmpty(matchValue_5) ? (isEmpty(tail(matchValue_5)) ? (new ModelCalipers(model_1.SelectedStudyId, model_1.Mode, singleton(new CalipersMeasurement(head(matchValue_5).State, head(matchValue_5).Label, head(matchValue_5).Distance, updatedPosition)), model_1.IsSaving, model_1.Measurements, model_1.IsEditingLabels)) : model_1) : model_1), Cmd_none(), singleton((dispatch_9) => {
                dispatch_9(new OutboundMsg(2, [new InteractionMode(4, [])]));
            })];
        }
        case 13:
            return [new ModelCalipers(model.SelectedStudyId, mode, model.ActiveMeasurements, model.IsSaving, model.Measurements, model.IsEditingLabels), singleton((dispatch_10) => {
                dispatch_10(new LocalMsg(5, [mid_2]));
            }), singleton((dispatch_11) => {
                dispatch_11(new OutboundMsg(2, [new InteractionMode(3, [])]));
            })];
        case 14: {
            const newMeasurementId = newGuid();
            const initialState = CalipersMeasurement_init("", state_3(CalipersStateArgs_init_244AC511(newMeasurementId)));
            const sub_1 = Cmd_ofEffect((dispatch_12) => {
                subscribeToOnDistanceMeasured(initialState.State, dispatch_12);
            });
            const measurements_2 = cons(calipersMeasurementToViewModel(model.SelectedStudyId, initialState), model.Measurements);
            const allEntries = makeMeasurementEntries(measurements_2);
            return [new ModelCalipers(model.SelectedStudyId, new Mode_4(1, []), singleton(initialState), model.IsSaving, measurements_2, model.IsEditingLabels), sub_1, Cmd_batch(ofArray([singleton((dispatch_13) => {
                dispatch_13(new OutboundMsg(2, [new InteractionMode(3, [])]));
            }), (msg_15 = (new OutboundMsg(1, [new MeasurementUpdatedOutbound$2(allEntries, defaultArg(map_1((measurement_1) => (new SelectionAction$2(3, [measurement_1, new EditMode(1, [])])), tryFind((entry) => (entry.measurementEntryIdSplit === newMeasurementId), allEntries)), new SelectionAction$2(0, [])))])), singleton((dispatch_14) => {
                dispatch_14(msg_15);
            }))]))];
        }
        case 15: {
            const maybeActiveId = map_1(CalipersMeasurement_MeasurementId_11348511, tryHead(model.ActiveMeasurements));
            const allEntries_1 = makeMeasurementEntries(map((m_11) => {
                if (equals(m_11.measurementId, maybeActiveId)) {
                    return defaultArg(map_1((active_4) => (new CalipersMeasurementViewModel(m_11.measurementId, m_11.studyId, m_11.name, m_11.value, m_11.firstPoint, m_11.secondPoint, active_4.LabelPosition)), tryHead(model.ActiveMeasurements)), m_11);
                }
                else {
                    return m_11;
                }
            }, model.Measurements));
            const updatedMeasurements_2 = new MeasurementUpdatedOutbound$2(allEntries_1, defaultArg(map_1((measurement_2) => (new SelectionAction$2(2, [measurement_2, new EditMode(0, [])])), tryFind((entry_1) => equals(entry_1.measurementEntryIdSplit, map_1(CalipersMeasurement_MeasurementId_11348511, tryHead(model.ActiveMeasurements))), allEntries_1)), new SelectionAction$2(0, [])));
            return [new ModelCalipers(model.SelectedStudyId, new Mode_4(0, []), model.ActiveMeasurements, false, defaultArg(map_1((vm_2) => map((m_12) => {
                if (m_12.measurementId === vm_2.measurementId) {
                    return vm_2;
                }
                else {
                    return m_12;
                }
            }, model.Measurements), map_1((calipersMeasurement_1) => calipersMeasurementToViewModel(model.SelectedStudyId, calipersMeasurement_1), tryHead(model.ActiveMeasurements))), model.Measurements), model.IsEditingLabels), Cmd_none(), Cmd_batch(ofArray([singleton((dispatch_15) => {
                dispatch_15(new OutboundMsg(2, [new InteractionMode(0, [])]));
            }), singleton((dispatch_16) => {
                dispatch_16(new OutboundMsg(1, [updatedMeasurements_2]));
            }), singleton((dispatch_17) => {
                dispatch_17(new OutboundMsg(0, [status]));
            })]))];
        }
        case 16:
            return [model, Cmd_none(), Cmd_none()];
        case 17:
            return [model, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(args.token).deleteCalipersMeasurement, [mid_4, model.SelectedStudyId], (f1_1 = Result_map()((r_1) => [r_1, mid_4]), (arg_4) => (new LocalMsg(17, [f1_1(arg_4)]))), (Item_6) => (new LocalMsg(0, [Item_6]))), Cmd_none()];
        case 18: {
            const newMeasurements = filter((m_13) => (m_13.measurementId !== deleted), model.Measurements);
            const newActiveMeasurements = filter((m_14) => (m_14.State.getMeasurementId() !== deleted), model.ActiveMeasurements);
            const allEntries_2 = makeMeasurementEntries(newMeasurements);
            return [new ModelCalipers(model.SelectedStudyId, new Mode_4(0, []), newActiveMeasurements, model.IsSaving, newMeasurements, model.IsEditingLabels), Cmd_none(), Cmd_batch(ofArray([(msg_20 = (new OutboundMsg(1, [new MeasurementUpdatedOutbound$2(allEntries_2, new SelectionAction$2(0, []))])), singleton((dispatch_18) => {
                dispatch_18(msg_20);
            })), singleton((dispatch_19) => {
                dispatch_19(new OutboundMsg(0, [status_1]));
            })]))];
        }
        default:
            return [new ModelCalipers(model.SelectedStudyId, model.Mode, model.ActiveMeasurements, false, model.Measurements, model.IsEditingLabels), Toast_errorToast(ErrorMessage_get_describe()(e_1)), Cmd_none()];
    }
}

export function update(args, msg, model) {
    return update_1(updateLocal, updateInbound, args, msg, model);
}

