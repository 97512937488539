import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { Visualization_Centerline_$reflection, Visualization_UnifiedTransitionBranch_$reflection, Visualization_UnifiedDistanceBranch_$reflection } from "../../RAWMap.Models/Common.js";
import { array_type, record_type, class_type, int32_type, union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Bindings_CenterlinePointMeasurement_$reflection, Bindings_PlaneIdentifier_$reflection } from "../../RAWMap.Models/View/CenterlineMeasurement.js";

export class Branch extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UnifiedDistanceBranch", "UnifiedTransitionBranch"];
    }
}

export function Branch_$reflection() {
    return union_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.Branch", [], Branch, () => [[["Item", Visualization_UnifiedDistanceBranch_$reflection()]], [["Item", Visualization_UnifiedTransitionBranch_$reflection()]]]);
}

export class CenterlinePlane extends Record {
    constructor(centerlineType, branchSelection, index, callbackIdentifier, planeIdentifier) {
        super();
        this.centerlineType = centerlineType;
        this.branchSelection = branchSelection;
        this.index = (index | 0);
        this.callbackIdentifier = callbackIdentifier;
        this.planeIdentifier = planeIdentifier;
    }
}

export function CenterlinePlane_$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.CenterlinePlane", [], CenterlinePlane, () => [["centerlineType", Visualization_Centerline_$reflection()], ["branchSelection", Branch_$reflection()], ["index", int32_type], ["callbackIdentifier", class_type("System.Guid")], ["planeIdentifier", Bindings_PlaneIdentifier_$reflection()]]);
}

export class CenterlinePlaneRange extends Record {
    constructor(planeA, planeB) {
        super();
        this.planeA = planeA;
        this.planeB = planeB;
    }
}

export function CenterlinePlaneRange_$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.CenterlinePlaneRange", [], CenterlinePlaneRange, () => [["planeA", CenterlinePlane_$reflection()], ["planeB", CenterlinePlane_$reflection()]]);
}

export class PlanePipeline extends Record {
    constructor(centerlinePlane, centerlinePlaneSource) {
        super();
        this.centerlinePlane = centerlinePlane;
        this.centerlinePlaneSource = centerlinePlaneSource;
    }
}

export function PlanePipeline_$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.PlanePipeline", [], PlanePipeline, () => [["centerlinePlane", CenterlinePlane_$reflection()], ["centerlinePlaneSource", class_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.ICenterlinePlaneSource")]]);
}

export class RangeResult extends Record {
    constructor(pipelines, rangeValues) {
        super();
        this.pipelines = pipelines;
        this.rangeValues = rangeValues;
    }
}

export function RangeResult_$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.RangeResult", [], RangeResult, () => [["pipelines", array_type(PlanePipeline_$reflection())], ["rangeValues", array_type(Bindings_CenterlinePointMeasurement_$reflection())]]);
}

export class CenterlineMeasurementProps extends Record {
    constructor(planePipelines) {
        super();
        this.planePipelines = planePipelines;
    }
}

export function CenterlineMeasurementProps_$reflection() {
    return record_type("RAWMap.Client.Visualization.CenterlineMeasurement.Bindings.CenterlineMeasurementProps", [], CenterlineMeasurementProps, () => [["planePipelines", array_type(PlanePipeline_$reflection())]]);
}

