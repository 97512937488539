import { Union, Record } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { option_type, bool_type, class_type, union_type, record_type, string_type } from "./fable_modules/fable-library-js.4.19.3/Reflection.js";
import { parse } from "./fable_modules/fable-library-js.4.19.3/Int32.js";
import { substring } from "./fable_modules/fable-library-js.4.19.3/String.js";
import style from "../../src/RAWMap.Client/style.scss";
import { FSharpChoice$2 } from "./fable_modules/fable-library-js.4.19.3/Choice.js";
import { Urls_Page_$reflection } from "./Common/General.js";
import { Model_$reflection as Model_$reflection_2, Msg_$reflection as Msg_$reflection_1 } from "./Auth/AuthTypes.js";
import { S3Configuration_$reflection, DeveloperIdentity_$reflection } from "./RAWMap.Models/Api.js";
import { ErrorMessage_$reflection } from "./RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.19.3/Result.js";
import { S3Context_$reflection } from "./Common/AwsCommon.js";
import { Model_$reflection as Model_$reflection_3, Msg_$reflection as Msg_$reflection_2 } from "./Institution/InstitutionTypes.js";
import { Model_$reflection as Model_$reflection_4, Msg_$reflection as Msg_$reflection_3 } from "./User/UserTypes.js";
import { Model_$reflection as Model_$reflection_5, Msg_$reflection as Msg_$reflection_4 } from "./Study/StudyTypes.js";
import { Model_$reflection as Model_$reflection_6, Msg_$reflection as Msg_$reflection_5 } from "./FileManagement/FileUploadParentExample/FileParentTypes.js";
import { Model_$reflection as Model_$reflection_1, Msg_$reflection as Msg_$reflection_6 } from "./Notifications/Types.js";
import { Model_$reflection as Model_$reflection_7, Msg_$reflection as Msg_$reflection_7 } from "./Patient/PatientTypes.js";
import { Model_$reflection as Model_$reflection_8, Msg_$reflection as Msg_$reflection_8 } from "./SystemInformation/SystemInformationTypes.js";
import { ofArray, forAll } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { analysisIsBeingModified, coreStudyIsBeingDownloaded, coreIsBeingModified } from "./Study/StudyView.js";
import { equals } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { value as value_2 } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { DeidentificationState } from "./Study/CoreForm/CoreFormTypes.js";

export class ScssVariables extends Record {
    constructor(tabletBreak, desktopBreak, widescreenBreak, fullHdBreak) {
        super();
        this.tabletBreak = tabletBreak;
        this.desktopBreak = desktopBreak;
        this.widescreenBreak = widescreenBreak;
        this.fullHdBreak = fullHdBreak;
    }
}

export function ScssVariables_$reflection() {
    return record_type("Client.Types.ScssVariables", [], ScssVariables, () => [["tabletBreak", string_type], ["desktopBreak", string_type], ["widescreenBreak", string_type], ["fullHdBreak", string_type]]);
}

export class ScreenWidths_ScreenWidth extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Mobile", "Tablet", "Desktop", "Widescreen", "FullHd"];
    }
}

export function ScreenWidths_ScreenWidth_$reflection() {
    return union_type("Client.Types.ScreenWidths.ScreenWidth", [], ScreenWidths_ScreenWidth, () => [[], [], [], [], []]);
}

export function ScreenWidths_trimPx(width) {
    return parse(substring(width, 0, width.length - 2), 511, false, 32);
}

export function ScreenWidths_getClientWidth() {
    return window.document.body.clientWidth;
}

export const ScreenWidths_tabletWidth = ScreenWidths_trimPx(style.tabletBreak);

export const ScreenWidths_desktopWidth = ScreenWidths_trimPx(style.desktopBreak);

export const ScreenWidths_widescreenWidth = ScreenWidths_trimPx(style.widescreenBreak);

export const ScreenWidths_fullHdWidth = ScreenWidths_trimPx(style.fullHdBreak);

export function ScreenWidths_getScreenWidth() {
    const screenWidth = ScreenWidths_getClientWidth();
    if (screenWidth < ScreenWidths_tabletWidth) {
        return new ScreenWidths_ScreenWidth(0, []);
    }
    else if (screenWidth < ScreenWidths_desktopWidth) {
        return new ScreenWidths_ScreenWidth(1, []);
    }
    else if (screenWidth < ScreenWidths_widescreenWidth) {
        return new ScreenWidths_ScreenWidth(2, []);
    }
    else if (screenWidth < ScreenWidths_fullHdWidth) {
        return new ScreenWidths_ScreenWidth(3, []);
    }
    else {
        return new ScreenWidths_ScreenWidth(4, []);
    }
}

export class Sidebar_CollapseState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Expanded", "Collapsed"];
    }
}

export function Sidebar_CollapseState_$reflection() {
    return union_type("Client.Types.Sidebar.CollapseState", [], Sidebar_CollapseState, () => [[], []]);
}

export class Sidebar_ModeSidebar extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AlwaysVisible", "Collapsible"];
    }
}

export function Sidebar_ModeSidebar_$reflection() {
    return union_type("Client.Types.Sidebar.ModeSidebar", [], Sidebar_ModeSidebar, () => [[], [["Item", Sidebar_CollapseState_$reflection()]]]);
}

export function Sidebar_$007CShowSidebar$007CHideSidebar$007C(mode) {
    let matchResult;
    if (mode.tag === 1) {
        if (mode.fields[0].tag === 1) {
            matchResult = 1;
        }
        else {
            matchResult = 0;
        }
    }
    else {
        matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return new FSharpChoice$2(0, [undefined]);
        default:
            return new FSharpChoice$2(1, [undefined]);
    }
}

export function Sidebar_$007CShowBurger$007CHideBurger$007C(mode) {
    if (mode.tag === 1) {
        return new FSharpChoice$2(0, [undefined]);
    }
    else {
        return new FSharpChoice$2(1, [undefined]);
    }
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Navigate", "AuthMsg", "CreateIdentityResult", "SetCredentialExpiration", "InstitutionMsg", "UserMsg", "StudyMsg", "FileManagementMsg", "NotificationsMsg", "GetS3Configuration", "GotS3Configuration", "PatientMsg", "SystemInformationMsg", "MenuToggled"];
    }
}

export function Msg_$reflection() {
    return union_type("Client.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Urls_Page_$reflection()]], [["Item", Msg_$reflection_1()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DeveloperIdentity_$reflection(), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", DeveloperIdentity_$reflection()]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item1", class_type("Fable.Import.Aws.CognitoCredentials.CognitoIdentityCredentials")], ["Item2", S3Context_$reflection()]], [["Item", Msg_$reflection_2()]], [["Item", Msg_$reflection_3()]], [["Item", Msg_$reflection_4()]], [["Item", Msg_$reflection_5()]], [["Item", Msg_$reflection_6()]], [], [["Item", S3Configuration_$reflection()]], [["Item", Msg_$reflection_7()]], [["Item", Msg_$reflection_8()]], [["Item", bool_type]]]);
}

export class Model extends Record {
    constructor(NotificationsModel, CurrentPage, AuthModel, InstitutionsModel, UsersModel, StudyModel, FileManagementModel, S3Context, AwsToken, AwsConfig, Credentials, PatientsModel, SystemInformationModel, MenuIsExpanded, RedirectPage, IsDebug) {
        super();
        this.NotificationsModel = NotificationsModel;
        this.CurrentPage = CurrentPage;
        this.AuthModel = AuthModel;
        this.InstitutionsModel = InstitutionsModel;
        this.UsersModel = UsersModel;
        this.StudyModel = StudyModel;
        this.FileManagementModel = FileManagementModel;
        this.S3Context = S3Context;
        this.AwsToken = AwsToken;
        this.AwsConfig = AwsConfig;
        this.Credentials = Credentials;
        this.PatientsModel = PatientsModel;
        this.SystemInformationModel = SystemInformationModel;
        this.MenuIsExpanded = MenuIsExpanded;
        this.RedirectPage = RedirectPage;
        this.IsDebug = IsDebug;
    }
}

export function Model_$reflection() {
    return record_type("Client.Types.Model", [], Model, () => [["NotificationsModel", Model_$reflection_1()], ["CurrentPage", Urls_Page_$reflection()], ["AuthModel", Model_$reflection_2()], ["InstitutionsModel", Model_$reflection_3()], ["UsersModel", Model_$reflection_4()], ["StudyModel", Model_$reflection_5()], ["FileManagementModel", Model_$reflection_6()], ["S3Context", option_type(S3Context_$reflection())], ["AwsToken", option_type(DeveloperIdentity_$reflection())], ["AwsConfig", option_type(S3Configuration_$reflection())], ["Credentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))], ["PatientsModel", Model_$reflection_7()], ["SystemInformationModel", Model_$reflection_8()], ["MenuIsExpanded", bool_type], ["RedirectPage", option_type(Urls_Page_$reflection())], ["IsDebug", bool_type]]);
}

export function AppState_canNavigate(model) {
    return forAll((value) => !value, ofArray([coreIsBeingModified(model.StudyModel), coreStudyIsBeingDownloaded(model.StudyModel), analysisIsBeingModified(model.StudyModel)]));
}

export function AppState_canStayLoggedIn(model) {
    const matchValue = model.StudyModel.CoreFormComponent;
    let matchResult, cfc;
    if (matchValue != null) {
        switch (matchValue.Mode.tag) {
            case 1: {
                matchResult = 0;
                cfc = matchValue;
                break;
            }
            case 2: {
                matchResult = 0;
                cfc = matchValue;
                break;
            }
            case 4: {
                matchResult = 1;
                break;
            }
            default:
                matchResult = 2;
        }
    }
    else {
        matchResult = 2;
    }
    switch (matchResult) {
        case 0:
            if (equals(value_2(cfc).DeidentificationState, new DeidentificationState(1, []))) {
                return true;
            }
            else {
                return equals(value_2(cfc).DeidentificationState, new DeidentificationState(2, []));
            }
        case 1:
            return true;
        default:
            return false;
    }
}

export function AppState_sidebarMode(model) {
    const page = model.CurrentPage;
    const screenWidth = ScreenWidths_getScreenWidth();
    let matchResult;
    switch (screenWidth.tag) {
        case 0:
        case 1: {
            matchResult = 0;
            break;
        }
        default:
            if (model.StudyModel.VisualizationComponent != null) {
                if (page.tag === 6) {
                    if (page.fields[0].tag === 2) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
    }
    switch (matchResult) {
        case 0:
            return new Sidebar_ModeSidebar(1, [model.MenuIsExpanded ? (new Sidebar_CollapseState(0, [])) : (new Sidebar_CollapseState(1, []))]);
        default:
            return new Sidebar_ModeSidebar(0, []);
    }
}

export const AppState_canLogout = (arg) => !AppState_canStayLoggedIn(arg);

