import { toString } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { MeshViewportLocation } from "../Common/CommonTypes.js";
import { InteractionMode, Bounds } from "../Common/CommonBindings.js";
import { some, map, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { OutboundMsg, VisualizationNoteViewModel_convertFromLabelCoordinates, LabelData_setStaticLabels, LocalMsg, ModelNotes, Mode as Mode_10, LabelData as LabelData_2, StaticLabel_ofVisualizationNoteViewModel } from "./NotesTypes.js";
import { append, exists, ofArray, choose, toArray, map as map_3, singleton, empty, tryFind } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { ofArray as ofArray_1, find, toList, add, map as map_2, empty as empty_1 } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { safeHash, equals, comparePrimitives } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { update as update_1, wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { contains as contains_1, choose as choose_1, zip, map as map_1 } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { VisualizationNoteViewModel } from "../../RAWMap.Models/View/VisualizationNote.js";
import { StaticLabel } from "./NotesBindings.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Toast_errorToast } from "../../Common/General.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Result_map, Result_isError, Result_isOk, Async_map } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { parallel } from "../../fable_modules/fable-library-js.4.19.3/Async.js";
import { securedApi } from "../../Api.js";
import { CustomReportStatus } from "../../RAWMap.Models/View/CustomReport.js";
import { ErrorMessage_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { List_except } from "../../fable_modules/fable-library-js.4.19.3/Seq2.js";

function getMeshViewportBounds() {
    const rect = document.getElementById(toString(new MeshViewportLocation(0, []))).getBoundingClientRect();
    return new Bounds(rect.width, rect.height);
}

function adjustStaticLabelCoordinates(model, bounds, label) {
    return defaultArg(map((noteVm) => StaticLabel_ofVisualizationNoteViewModel(bounds, noteVm), tryFind((note) => (note.noteId === label.id), model.Notes)), label);
}

export function init(selectedStudyId, maybeCurrentUser) {
    let msg_1;
    return [new ModelNotes(maybeCurrentUser, selectedStudyId, new LabelData_2([], []), empty(), empty_1({
        Compare: comparePrimitives,
    }), new Mode_10(0, []), false), (msg_1 = wrapLocalMsg(new LocalMsg(1, [])), singleton((dispatch) => {
        dispatch(msg_1);
    }))];
}

export function setStaticLabels(model, notes) {
    return LabelData_setStaticLabels(getMeshViewportBounds(), model.LabelData, notes);
}

export function updateInbound(token, msg, model) {
    switch (msg.tag) {
        case 1: {
            const bounds = msg.fields[0];
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, new LabelData_2(model.LabelData.worldLabels, map_1((label_1) => adjustStaticLabelCoordinates(model, bounds, label_1), model.LabelData.staticLabels)), model.Notes, map_2((labelId, label_2) => adjustStaticLabelCoordinates(model, bounds, label_2), model.ModifiedStaticLabels), model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 2:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(1, []), model.IsSaving), Cmd_none(), Cmd_none()];
        case 3:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(0, []), model.IsSaving), Cmd_none(), Cmd_none()];
        case 4: {
            const hiddenNoteVms = map_3((noteVm) => (new VisualizationNoteViewModel(noteVm.studyId, noteVm.noteId, noteVm.content, noteVm.x, noteVm.y, true)), model.Notes);
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, hiddenNoteVms), hiddenNoteVms, model.ModifiedStaticLabels, model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
        default: {
            const label = msg.fields[0];
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, new LabelData_2(model.LabelData.worldLabels, map_1((oldLabel) => {
                if (oldLabel.id === label.id) {
                    return new StaticLabel(label.id, label.content, label.moves + 1, label.xPercentage, label.yPercentage, label.x, label.y, label.isHidden, label.isHeader);
                }
                else {
                    return oldLabel;
                }
            }, model.LabelData.staticLabels)), model.Notes, add(label.id, label, model.ModifiedStaticLabels), model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
    }
}

export function updateLocal(token, msg, model) {
    let f1, f1_1, msg_5;
    let matchResult, ex, noteIdResults_1, noteVms, e, noteVm_3, noteVm_4, noteVm_5, deletedNoteVm, status_1, setter, noteVm_10, status_2, e_1, noteId_5;
    switch (msg.tag) {
        case 15: {
            matchResult = 1;
            break;
        }
        case 11: {
            matchResult = 2;
            break;
        }
        case 12: {
            matchResult = 3;
            noteIdResults_1 = msg.fields[0];
            break;
        }
        case 1: {
            matchResult = 4;
            break;
        }
        case 2: {
            if (msg.fields[0].tag === 1) {
                matchResult = 6;
                e = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
                noteVms = msg.fields[0].fields[0];
            }
            break;
        }
        case 3: {
            matchResult = 7;
            noteVm_3 = msg.fields[0];
            break;
        }
        case 8: {
            matchResult = 8;
            noteVm_4 = msg.fields[0];
            break;
        }
        case 9: {
            matchResult = 9;
            noteVm_5 = msg.fields[0];
            break;
        }
        case 10: {
            if (msg.fields[0].tag === 1) {
                matchResult = 14;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 10;
                deletedNoteVm = msg.fields[0].fields[0][1];
                status_1 = msg.fields[0].fields[0][0];
            }
            break;
        }
        case 4: {
            matchResult = 11;
            setter = msg.fields[0];
            break;
        }
        case 5: {
            matchResult = 12;
            break;
        }
        case 6: {
            if (msg.fields[0].tag === 1) {
                matchResult = 14;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 13;
                noteVm_10 = msg.fields[0].fields[0][1];
                status_2 = msg.fields[0].fields[0][0];
            }
            break;
        }
        case 7: {
            if (msg.fields[0].tag === 1) {
                matchResult = 14;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 13;
                noteVm_10 = msg.fields[0].fields[0][1];
                status_2 = msg.fields[0].fields[0][0];
            }
            break;
        }
        case 13: {
            matchResult = 15;
            break;
        }
        case 14: {
            matchResult = 16;
            break;
        }
        case 16: {
            matchResult = 17;
            noteId_5 = msg.fields[0];
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Notes exception: %O"))(ex)));
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, false), Toast_errorToast("An error has occured"), Cmd_none()];
        }
        case 1:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(3, [new VisualizationNoteViewModel(model.SelectedStudyId, newGuid(), "", 0.05, 0.25, false)]), model.IsSaving), Cmd_none(), Cmd_none()];
        case 2:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (notes) => {
                const noteIds = toArray(map_3((note_1) => note_1.noteId, notes));
                return Async_map((results) => zip(noteIds, results), parallel(map_3(securedApi(token).editVisualizationNote, notes)));
            }, choose((tupledArg) => {
                let bounds;
                const option = tryFind((note) => (note.noteId === tupledArg[0]), model.Notes);
                return map((bounds = getMeshViewportBounds(), (noteVm) => VisualizationNoteViewModel_convertFromLabelCoordinates(bounds, tupledArg[1], noteVm)), option);
            }, toList(model.ModifiedStaticLabels)), (noteIdResults) => (new LocalMsg(12, [noteIdResults])), (Item) => (new LocalMsg(0, [Item]))), Cmd_none()];
        case 3: {
            const updatedNotes = ofArray(choose_1((noteId_2) => {
                let bounds_1;
                const label_1 = find(noteId_2, model.ModifiedStaticLabels);
                const option_1 = tryFind((note_2) => (note_2.noteId === noteId_2), model.Notes);
                return map((bounds_1 = getMeshViewportBounds(), (noteVm_1) => VisualizationNoteViewModel_convertFromLabelCoordinates(bounds_1, label_1, noteVm_1)), option_1);
            }, map_1((tuple) => tuple[0], noteIdResults_1.filter((tupledArg_1) => Result_isOk(tupledArg_1[1])))));
            const revisedNotes = map_3((note_3) => defaultArg(tryFind((updatedNote) => (updatedNote.noteId === note_3.noteId), updatedNotes), note_3), model.Notes);
            const failedUpdates = noteIdResults_1.filter((tupledArg_2) => Result_isError(tupledArg_2[1]));
            const remainingModifiedStaticLabels = ofArray_1(map_1((tupledArg_3) => {
                const noteId_4 = tupledArg_3[0];
                return [noteId_4, find(noteId_4, model.ModifiedStaticLabels)];
            }, failedUpdates), {
                Compare: comparePrimitives,
            });
            const cmd = (failedUpdates.length > 0) ? Toast_errorToast("One or more note positions could not be saved") : Cmd_none();
            const revisedLabelData = setStaticLabels(model, revisedNotes);
            const status = contains_1(new CustomReportStatus(0, []), choose_1((tupledArg_4) => {
                const result_2 = tupledArg_4[1];
                if (result_2.tag === 1) {
                    return undefined;
                }
                else {
                    return result_2.fields[0];
                }
            }, noteIdResults_1), {
                Equals: equals,
                GetHashCode: safeHash,
            }) ? (new CustomReportStatus(0, [])) : (new CustomReportStatus(1, []));
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, revisedLabelData, revisedNotes, remainingModifiedStaticLabels, new Mode_10(0, []), false), cmd, Cmd_batch(ofArray([singleton((dispatch) => {
                dispatch(new OutboundMsg(1, [new InteractionMode(0, [])]));
            }), singleton((dispatch_1) => {
                dispatch_1(new OutboundMsg(0, [status]));
            })]))];
        }
        case 4:
            return [model, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(token).getVisualizationNotesForStudy, model.SelectedStudyId, (Item_1) => (new LocalMsg(2, [Item_1])), (Item_2) => (new LocalMsg(0, [Item_2]))), Cmd_none()];
        case 5: {
            const visibilityUpdatedNoteVms = map_3((noteVm_2) => (new VisualizationNoteViewModel(noteVm_2.studyId, noteVm_2.noteId, noteVm_2.content, noteVm_2.x, noteVm_2.y, true)), noteVms);
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, visibilityUpdatedNoteVms), visibilityUpdatedNoteVms, model.ModifiedStaticLabels, model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
        case 6:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e)), Cmd_none()];
        case 7:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(4, [noteVm_3]), model.IsSaving), Cmd_none(), Cmd_none()];
        case 8:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(5, [noteVm_4]), model.IsSaving), Cmd_none(), Cmd_none()];
        case 9:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(token).deleteVisualizationNote, [noteVm_5.noteId, noteVm_5.studyId], (f1 = Result_map()((r) => [r, noteVm_5]), (arg_3) => (new LocalMsg(10, [f1(arg_3)]))), (Item_4) => (new LocalMsg(0, [Item_4]))), Cmd_none()];
        case 10: {
            const newNotes = List_except([deletedNoteVm], model.Notes, {
                Equals: equals,
                GetHashCode: safeHash,
            });
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, newNotes), newNotes, model.ModifiedStaticLabels, new Mode_10(0, []), false), Cmd_none(), singleton((dispatch_2) => {
                dispatch_2(new OutboundMsg(0, [status_1]));
            })];
        }
        case 11: {
            const matchValue = model.Mode;
            switch (matchValue.tag) {
                case 3:
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(3, [setter(matchValue.fields[0])]), model.IsSaving), Cmd_none(), Cmd_none()];
                case 4:
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(4, [setter(matchValue.fields[0])]), model.IsSaving), Cmd_none(), Cmd_none()];
                default: {
                    console.error(some(toText(printf("Unexpected SetVisualizationNoteVm message while Mode is %A"))(model.Mode)));
                    return [model, Cmd_none(), Cmd_none()];
                }
            }
        }
        case 12: {
            const matchValue_1 = model.Mode;
            switch (matchValue_1.tag) {
                case 3:
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x_6) => {
                        Cmd_OfAsync_start(x_6);
                    }, securedApi(token).createVisualizationNote, matchValue_1.fields[0], (Item_5) => (new LocalMsg(6, [Item_5])), (Item_6) => (new LocalMsg(0, [Item_6]))), Cmd_none()];
                case 4: {
                    const noteVm_9 = matchValue_1.fields[0];
                    return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, true), Cmd_OfAsyncWith_either((x_7) => {
                        Cmd_OfAsync_start(x_7);
                    }, securedApi(token).editVisualizationNote, noteVm_9, (f1_1 = Result_map()((r_1) => [r_1, noteVm_9]), (arg_7) => (new LocalMsg(7, [f1_1(arg_7)]))), (Item_8) => (new LocalMsg(0, [Item_8]))), Cmd_none()];
                }
                default:
                    return [model, (msg_5 = (new LocalMsg(0, [new Error("Unable to save note")])), singleton((dispatch_3) => {
                        dispatch_3(msg_5);
                    })), Cmd_none()];
            }
        }
        case 13: {
            const newNotes_1 = exists((note_4) => (note_4.noteId === noteVm_10.noteId), model.Notes) ? map_3((note_5) => {
                if (note_5.noteId === noteVm_10.noteId) {
                    return noteVm_10;
                }
                else {
                    return note_5;
                }
            }, model.Notes) : append(model.Notes, singleton(noteVm_10));
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, newNotes_1), newNotes_1, model.ModifiedStaticLabels, new Mode_10(0, []), false), Cmd_none(), singleton((dispatch_4) => {
                dispatch_4(new OutboundMsg(0, [status_2]));
            })];
        }
        case 14:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, model.Mode, false), Toast_errorToast(ErrorMessage_get_describe()(e_1)), Cmd_none()];
        case 15:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(0, []), model.IsSaving), Cmd_none(), Cmd_none()];
        case 16:
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, model.LabelData, model.Notes, model.ModifiedStaticLabels, new Mode_10(2, []), model.IsSaving), Cmd_none(), singleton((dispatch_5) => {
                dispatch_5(new OutboundMsg(1, [new InteractionMode(1, [])]));
            })];
        default: {
            const updatedNotes_1 = map_3((n) => {
                if (n.noteId === noteId_5) {
                    return new VisualizationNoteViewModel(n.studyId, n.noteId, n.content, n.x, n.y, !n.isHidden);
                }
                else {
                    return n;
                }
            }, model.Notes);
            return [new ModelNotes(model.MaybeCurrentUser, model.SelectedStudyId, setStaticLabels(model, updatedNotes_1), updatedNotes_1, model.ModifiedStaticLabels, model.Mode, model.IsSaving), Cmd_none(), Cmd_none()];
        }
    }
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

