import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { lambda_type, unit_type, record_type, option_type, float64_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { NotesProps_$reflection } from "../Notes/NotesBindings.js";

export class MapValuePickedArgs extends Record {
    constructor(viewportLocation, selectedMap, maybePickedValue) {
        super();
        this.viewportLocation = viewportLocation;
        this.selectedMap = selectedMap;
        this.maybePickedValue = maybePickedValue;
    }
}

export function MapValuePickedArgs_$reflection() {
    return record_type("RAWMap.Client.Visualization.PickedMapValue.Bindings.MapValuePickedArgs", [], MapValuePickedArgs, () => [["viewportLocation", string_type], ["selectedMap", string_type], ["maybePickedValue", option_type(float64_type)]]);
}

export class PickedMapValueProps extends Record {
    constructor(onMapValuePicked, notesProps, clearPickedValues) {
        super();
        this.onMapValuePicked = onMapValuePicked;
        this.notesProps = notesProps;
        this.clearPickedValues = clearPickedValues;
    }
}

export function PickedMapValueProps_$reflection() {
    return record_type("RAWMap.Client.Visualization.PickedMapValue.Bindings.PickedMapValueProps", [], PickedMapValueProps, () => [["onMapValuePicked", lambda_type(MapValuePickedArgs_$reflection(), unit_type)], ["notesProps", NotesProps_$reflection()], ["clearPickedValues", lambda_type(string_type, unit_type)]]);
}

