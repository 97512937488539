import { ListDisplayModification_applyListModifications, Mode, Msg } from "./PatientTypes.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { value as value_5 } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option, modal } from "../fable_modules/Fulma.3.0.0/Components/Modal.fs.js";
import { uncurry2, curry2, equals } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { choose, isEmpty, map, tryFind, ofArray, empty, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Forms_parseDateAsUtc, Layout_modalCardLayout, Controls_itemListSortable, Controls_dropdownButton, Controls_buttonDropdownIcon, Forms_formFieldText, Forms_formFieldDate, Forms_formFieldNumeric, Forms_formFieldReactSelect } from "../Common/General.js";
import { Common_YesNo_get_display, Common_YesNo_get_all, Common_YesNo_get_tryParse, Common_Treatment_get_display, Common_Treatment_get_all, Common_Treatment_tryParse_Z721C83C5, Common_Endoleak_get_display, Common_Endoleak_get_all, Common_Endoleak_tryParse_Z721C83C5, Common_Sex_get_display, Common_Sex_get_all, Common_Sex_get_tryParse, String_toOption, PatientFilters_FilterComparison_get_dateDisplay, PatientFilters_FilterComparison_get_numberDisplay, PatientFilters_FilterComparison_get_all, PatientFilters_Field_get_viewDisplay, PatientFilters_Field_get_all } from "../RAWMap.Models/Common.js";
import * as react from "react";
import { Option as Option_1, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Common_GenericOption, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { NameDobViewModel_get_dateOfBirth_, NameDobViewModel_get_lastName_, NameDobViewModel_get_firstName_, PartialInternalPatientIdViewModel_get_InternalPatientId_, HospitalPatientIdViewModel_get_hospitalPatientId_, HospitalPatientIdViewModel, PatientViewModel_get_dateCreated_, PatientViewModel_get_institutionId_, PatientViewModel_get_id_ } from "../RAWMap.Models/View/Patient.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39, Optic_Set, Optic_Set_op_HatEquals_2170E4F5, Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { toString } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { toLocalTime } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";
import { columns as columns_1 } from "../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_3, column } from "../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { content, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Option as Option_2 } from "../fable_modules/Fulma.3.0.0/Components/Dropdown.fs.js";
import { InstitutionClinicalDesignation_get_all, InstitutionClinicalDesignation_get_describe } from "../RAWMap.Models/View/Institution.js";
import { Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, lambda_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SelectPropsMulti$1 } from "../Common/ReactSelectBind.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";

export function filterPatients(model, dispatch) {
    let children;
    const onChange = new DOMAttr(9, [(e) => {
        dispatch(new Msg(38, [e.currentTarget.value]));
    }]);
    const fromTypeOnChange = (dispFunc, value_1) => {
        dispatch(new Msg(38, [(value_1 == null) ? "" : dispFunc(value_5(value_1))]));
    };
    return modal(singleton(new Option(1, [equals(model.Mode, new Mode(5, []))])), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton("Filter Patients")))), Card_body(empty(), singleton((children = toList(delay(() => append(singleton_1(Forms_formFieldReactSelect([], "Filter On", model.Filter.filterField, PatientFilters_Field_get_all(), PatientFilters_Field_get_viewDisplay(), (value_2) => {
        dispatch(new Msg(37, [value_2]));
    })), delay(() => {
        const matchValue = model.Filter.filterType;
        return (matchValue.tag === 1) ? append(singleton_1(Forms_formFieldReactSelect([], "", model.Filter.comparison, PatientFilters_FilterComparison_get_all(), PatientFilters_FilterComparison_get_numberDisplay(), (value_3) => {
            dispatch(new Msg(40, [value_3]));
        })), delay(() => singleton_1(Forms_formFieldNumeric("", model.Filter.filter, ofArray([onChange, new HTMLAttr(119, ["1"])]), false)))) : ((matchValue.tag === 2) ? append(singleton_1(Forms_formFieldReactSelect([], "", model.Filter.comparison, PatientFilters_FilterComparison_get_all(), PatientFilters_FilterComparison_get_dateDisplay(), (value_4) => {
            dispatch(new Msg(40, [value_4]));
        })), delay(() => singleton_1(Forms_formFieldDate("", model.Filter.dateFilter, singleton(new DOMAttr(9, [(e_1) => {
            dispatch(new Msg(39, [equals(String_toOption(e_1.target.value), undefined) ? undefined : e_1.target.valueAsDate]));
        }])))))) : ((matchValue.tag === 3) ? singleton_1(Forms_formFieldReactSelect([], "Sex", Common_Sex_get_tryParse()(model.Filter.filter), Common_Sex_get_all(), Common_Sex_get_display(), curry2(fromTypeOnChange)(Common_Sex_get_display()))) : ((matchValue.tag === 4) ? singleton_1(Forms_formFieldReactSelect([], "Endoleak Type", Common_Endoleak_tryParse_Z721C83C5(model.Filter.filter), Common_Endoleak_get_all(), Common_Endoleak_get_display(), curry2(fromTypeOnChange)(Common_Endoleak_get_display()))) : ((matchValue.tag === 5) ? singleton_1(Forms_formFieldReactSelect([], "Treatment Type", Common_Treatment_tryParse_Z721C83C5(model.Filter.filter), Common_Treatment_get_all(), Common_Treatment_get_display(), curry2(fromTypeOnChange)(Common_Treatment_get_display()))) : ((matchValue.tag === 6) ? singleton_1(Forms_formFieldText("", model.Filter.filter, singleton(onChange), false)) : singleton_1(Forms_formFieldReactSelect([], "", Common_YesNo_get_tryParse()(model.Filter.filter), Common_YesNo_get_all(), Common_YesNo_get_display(), curry2(fromTypeOnChange)(Common_YesNo_get_display()))))))));
    })))), react.createElement("div", {}, ...children)))), Card_foot(empty(), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(6, [])]), new Option_1(16, [!model.FilterFieldsValid]), new Option_1(18, [(_arg) => {
        dispatch(new Msg(19, [model.Filter]));
    }])]), singleton("Apply Filter")), button(ofArray([new Option_1(0, [new Color_IColor(14, [])]), new Option_1(18, [(_arg_1) => {
        dispatch(new Msg(15, []));
    }])]), singleton("Cancel"))]))]))]));
}

function listPatients(model, dispatch) {
    let f1, optic, f1_1, optic_2, f1_2, optic_4, user, children;
    const columns = ofArray([["Internal Patient ID", (f1 = ((optic = PatientViewModel_get_id_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(), optic)(target))), f1)], ["Institution", (f1_1 = ((optic_2 = PatientViewModel_get_institutionId_(), (target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(), optic_2)(target_2))), (arg_4) => {
        const instId = f1_1(arg_4);
        const _arg = tryFind((i) => (i.id === instId), model.UserInstitutions);
        return (_arg == null) ? "" : _arg.name;
    })], ["Date Created", (f1_2 = ((optic_4 = PatientViewModel_get_dateCreated_(), (target_4) => Optic_Get_op_HatDot_21762A61(new Optic_Get(), optic_4)(target_4))), (arg_6) => toString(toLocalTime(f1_2(arg_6)), "yyyy-MM-dd | HH:mm"))]]);
    let disableButton;
    const matchValue = model.MaybeCurrentUser;
    let matchResult, user_1;
    if (matchValue != null) {
        if ((user = matchValue, isAllowed(new Permission(16, []))(user.roles))) {
            matchResult = 0;
            user_1 = matchValue;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            disableButton = false;
            break;
        }
        default:
            disableButton = true;
    }
    const children_2 = [columns_1(empty(), singleton(column(empty(), singleton(card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Patients")), Header_icon(empty(), singleton(Controls_buttonDropdownIcon(new Fa_IconOption(11, ["fas fa-search"]))(empty())(singleton(new Option_2(1, [])))(toList(delay(() => append(singleton_1(new Controls_dropdownButton(new DOMAttr(40, [(_arg_3) => {
        dispatch(new Msg(23, []));
    }]), "Search by Institution Patient ID")), delay(() => append(singleton_1(new Controls_dropdownButton(new DOMAttr(40, [(_arg_4) => {
        dispatch(new Msg(24, []));
    }]), "Search by Internal Patient ID")), delay(() => append(singleton_1(new Controls_dropdownButton(new DOMAttr(40, [(_arg_5) => {
        dispatch(new Msg(25, []));
    }]), "Search by Name and Date of Birth")), delay(() => append(singleton_1(new Controls_dropdownButton(new DOMAttr(40, [(_arg_6) => {
        dispatch(new Msg(26, []));
    }]), "Filter patients based on study info")), delay(() => map((designation) => (new Controls_dropdownButton(new DOMAttr(40, [(_arg_7) => {
        dispatch(new Msg(18, [designation]));
    }]), `View ${InstitutionClinicalDesignation_get_describe()(designation)} Institution Patients`)), InstitutionClinicalDesignation_get_all())))))))))))))), Header_icon(singleton(new Common_GenericOption(1, [singleton(["style", {
        paddingLeft: "0px",
    }])])), singleton(button(toList(delay(() => append(singleton_1(new Option_1(4, [])), delay(() => append(singleton_1(new Option_1(16, [isEmpty(model.ListDisplayModifications)])), delay(() => append(isEmpty(model.ListDisplayModifications) ? empty() : singleton(new Option_1(0, [new Color_IColor(8, [])])), delay(() => singleton_1(new Option_1(17, [singleton(new DOMAttr(40, [(_arg_8) => {
        dispatch(new Msg(33, []));
    }]))])))))))))), singleton("Reset List")))), Header_icon(empty(), singleton(button(ofArray([new Option_1(4, []), new Option_1(16, [disableButton]), new Option_1(17, [singleton(new DOMAttr(40, [(_arg_9) => {
        dispatch(new Msg(17, []));
    }]))])]), singleton("Create Patient"))))])), content(empty(), singleton((children = [Controls_itemListSortable(columns, (item_1, _arg_2) => {
        dispatch(new Msg(7, [item_1]));
    }, ListDisplayModification_applyListModifications(model.ListDisplayModifications, model.Patients), (item) => empty_1(), model.SortInfo, (updatedInfo, _arg_1) => {
        dispatch(new Msg(35, [updatedInfo]));
    })], react.createElement("div", {
        className: "table-container",
    }, ...children))))]))))))];
    return react.createElement("div", {}, ...children_2);
}

export class FormField$1 extends Record {
    constructor(fieldFunc, baseLabel) {
        super();
        this.fieldFunc = fieldFunc;
        this.baseLabel = baseLabel;
    }
}

export function FormField$1_$reflection(gen0) {
    return record_type("Client.Patient.View.FormField`1", [gen0], FormField$1, () => [["fieldFunc", lambda_type(gen0, lambda_type(string_type, class_type("Fable.React.ReactElement")))], ["baseLabel", string_type]]);
}

export function FormField_fieldFunc(_arg) {
    return curry2(_arg.fieldFunc);
}

export function FormField_baseLabel(_arg) {
    return _arg.baseLabel;
}

export function FormField_makeProp(vm, formField) {
    return FormField_fieldFunc(formField)(vm)(formField.baseLabel);
}

export function FormField_setVmProp(dispatch, setMessage, vmChange_, vmChange__1) {
    const vmChange = [vmChange_, curry2(vmChange__1)];
    return new DOMAttr(9, [(e) => {
        dispatch(setMessage((pvm) => {
            let value;
            return ((value = e.target.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), vmChange)(value)))(pvm);
        }));
    }]);
}

function patientFormModal(model, actionText, buttonText, completionAction, cancelAction, dispatch) {
    let children_1;
    const completionButton = button(ofArray([new Option_1(0, [new Color_IColor(6, [])]), new Option_1(16, [!model.CreateEditFieldsValid]), new Option_1(17, [ofArray([new DOMAttr(40, [(_arg) => {
        dispatch(completionAction);
    }]), new HTMLAttr(159, ["button"])])])]), singleton(buttonText));
    const hospitalPatientIdForm = (canEditInstitution, hospitalPatientIdVm) => {
        let _arg_2, tupledArg;
        let idText;
        const matchValue_1 = model.CreateEditPatientVm.id;
        idText = ((matchValue_1 !== "00000000-0000-0000-0000-000000000000") ? matchValue_1 : ((model.Mode.tag === 1) ? "Create patient to generate" : "Search for patient to retrieve"));
        const userInstitutions = map((userInst) => userInst.name, model.UserInstitutions);
        return column(singleton(new Option_3(3, [singleton(["style", {
            overflowX: "unset",
        }])])), ofArray([Forms_formFieldReactSelect([new SelectPropsMulti$1(9, [!canEditInstitution])], "Institution", (_arg_2 = tryFind((i) => (i.id === hospitalPatientIdVm.institutionId), model.UserInstitutions), (_arg_2 == null) ? undefined : _arg_2.name), userInstitutions, (value_1) => value_1, (value) => {
            dispatch(new Msg(10, [(pvm) => ((pvm_1, value_2) => {
                let instName, n, _arg_1;
                return new HospitalPatientIdViewModel(pvm_1.hospitalPatientId, (instName = value_2, (instName == null) ? "00000000-0000-0000-0000-000000000000" : ((n = instName, (_arg_1 = tryFind((inst) => (inst.name === n), model.UserInstitutions), (_arg_1 == null) ? "00000000-0000-0000-0000-000000000000" : _arg_1.id)))));
            })(pvm, value)]));
        }), Forms_formFieldText("Institution Patient ID", hospitalPatientIdVm.hospitalPatientId, singleton((tupledArg = HospitalPatientIdViewModel_get_hospitalPatientId_(), FormField_setVmProp(dispatch, (Item) => (new Msg(10, [Item])), tupledArg[0], uncurry2(tupledArg[1])))), false)]));
    };
    return Layout_modalCardLayout(actionText, empty(), singleton((children_1 = [columns_1(empty(), choose((item) => item, toList(delay(() => {
        let matchValue_3;
        return append((matchValue_3 = model.Mode, (matchValue_3.tag === 2) ? singleton_1(hospitalPatientIdForm(true, model.SearchForHospitalPatientIdVm)) : ((matchValue_3.tag === 1) ? singleton_1(hospitalPatientIdForm(true, model.SearchForHospitalPatientIdVm)) : ((matchValue_3.tag === 6) ? ((matchValue_3.fields[0].tag === 0) ? singleton_1(hospitalPatientIdForm(true, model.SearchForHospitalPatientIdVm)) : singleton_1(undefined)) : singleton_1(undefined)))), delay(() => {
            let tupledArg_1;
            return append((model.Mode.tag === 3) ? singleton_1(column(singleton(new Option_3(3, [singleton(["style", {
                overflowX: "unset",
            }])])), singleton(Forms_formFieldText("Internal Patient ID", model.SearchForInternalPatientIdVm.internalPatientId, singleton((tupledArg_1 = PartialInternalPatientIdViewModel_get_InternalPatientId_(), FormField_setVmProp(dispatch, (Item_1) => (new Msg(11, [Item_1])), tupledArg_1[0], uncurry2(tupledArg_1[1])))), false)))) : singleton_1(undefined), delay(() => {
                let nameDobVm, setter_2, l;
                const matchValue_5 = model.Mode;
                let matchResult;
                switch (matchValue_5.tag) {
                    case 4:
                    case 1: {
                        matchResult = 0;
                        break;
                    }
                    case 6: {
                        if (matchValue_5.fields[0].tag === 1) {
                            matchResult = 0;
                        }
                        else {
                            matchResult = 1;
                        }
                        break;
                    }
                    default:
                        matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return singleton_1((nameDobVm = model.SearchByNameDobVm, (setter_2 = ((tupledArg_2) => FormField_setVmProp(dispatch, (Item_2) => (new Msg(12, [Item_2])), tupledArg_2[0], uncurry2(tupledArg_2[1]))), column(singleton(new Option_3(3, [singleton(["style", {
                            overflowX: "unset",
                        }])])), ofArray([Forms_formFieldText("First Name", nameDobVm.firstName, singleton(setter_2(NameDobViewModel_get_firstName_())), false), Forms_formFieldText("Last Name", nameDobVm.lastName, singleton(setter_2(NameDobViewModel_get_lastName_())), false), Forms_formFieldDate("Date of Birth", nameDobVm.dateOfBirth, singleton(setter_2((l = NameDobViewModel_get_dateOfBirth_(), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), [(_arg_3) => "", Forms_parseDateAsUtc])(l)))))])))));
                    default:
                        return singleton_1(undefined);
                }
            }));
        }));
    }))))], react.createElement("form", {}, ...children_1))), ofArray([completionButton, button(ofArray([new Option_1(0, [new Color_IColor(2, [])]), new Option_1(17, [ofArray([new DOMAttr(40, [(_arg_4) => {
        dispatch(cancelAction);
    }]), new HTMLAttr(159, ["button"])])])]), singleton("Cancel"))]));
}

function editCreatePatient(model, dispatch) {
    let patternInput;
    const matchValue = model.Mode;
    patternInput = ((matchValue.tag === 6) ? ((matchValue.fields[0].tag === 1) ? ["Update Patient Name and Date of Birth", "Save", new Msg(13, []), new Msg(14, [])] : ["Update Institution Patient ID", "Save", new Msg(13, []), new Msg(14, [])]) : ((matchValue.tag === 4) ? ["Search", "Search", new Msg(31, []), new Msg(15, [])] : ((matchValue.tag === 2) ? ["Search", "Search", new Msg(27, []), new Msg(15, [])] : ((matchValue.tag === 3) ? ["Search", "Search", new Msg(29, []), new Msg(15, [])] : ["Create New Patient", "Create", new Msg(13, []), new Msg(15, [])]))));
    return patientFormModal(model, patternInput[0], patternInput[1], patternInput[2], patternInput[3], dispatch);
}

export function view(model, dispatch) {
    const children = toList(delay(() => append(singleton_1(listPatients(model, dispatch)), delay(() => {
        const matchValue = model.Mode;
        switch (matchValue.tag) {
            case 6:
            case 2:
            case 3:
            case 4:
            case 1:
                return singleton_1(editCreatePatient(model, dispatch));
            case 5:
                return singleton_1(filterPatients(model, dispatch));
            default:
                return singleton_1(Helpers_nothing);
        }
    }))));
    return react.createElement("div", {}, ...children);
}

