import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ErrorMessage } from "../ErrorMessage.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";

export class DeidentificationViewModel extends Record {
    constructor(requestId, patientId, studyId) {
        super();
        this.requestId = requestId;
        this.patientId = patientId;
        this.studyId = studyId;
    }
}

export function DeidentificationViewModel_$reflection() {
    return record_type("RAWMap.Models.View.DeidentificationViewModel", [], DeidentificationViewModel, () => [["requestId", class_type("System.Guid")], ["patientId", class_type("System.Guid")], ["studyId", class_type("System.Guid")]]);
}

export function DeidentificationViewModel_validate_CEC4D4B(vm) {
    const validateGuid = (name, value) => {
        if (value === "00000000-0000-0000-0000-000000000000") {
            return new FSharpResult$2(1, [new ErrorMessage(6, [name, "not be empty"])]);
        }
        else {
            return new FSharpResult$2(0, [undefined]);
        }
    };
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateGuid("requestId", vm.requestId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateGuid("patientId", vm.patientId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateGuid("studyId", vm.studyId), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, undefined))))));
}

