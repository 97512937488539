import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { uint8_type, bool_type, array_type, union_type, class_type, record_type, obj_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.3/MapUtil.js";

export class CredentialsParams extends Record {
    constructor(IdentityPoolId, IdentityId, Logins, region) {
        super();
        this.IdentityPoolId = IdentityPoolId;
        this.IdentityId = IdentityId;
        this.Logins = Logins;
        this.region = region;
    }
}

export function CredentialsParams_$reflection() {
    return record_type("Fable.Helpers.Aws.CredentialsParams", [], CredentialsParams, () => [["IdentityPoolId", string_type], ["IdentityId", string_type], ["Logins", obj_type], ["region", string_type]]);
}

export function CredentialsParams_get_create() {
    return (value) => value;
}

export class CognitoSetupParams extends Record {
    constructor(region, accessKeyId, secretAccessKey) {
        super();
        this.region = region;
        this.accessKeyId = accessKeyId;
        this.secretAccessKey = secretAccessKey;
    }
}

export function CognitoSetupParams_$reflection() {
    return record_type("Fable.Helpers.Aws.CognitoSetupParams", [], CognitoSetupParams, () => [["region", string_type], ["accessKeyId", string_type], ["secretAccessKey", string_type]]);
}

export function CognitoSetupParams_get_create() {
    return (value) => value;
}

export class Config extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Region", "Credentials", "AccessKeyId", "SecretAccessKey"];
    }
}

export function Config_$reflection() {
    return union_type("Fable.Helpers.Aws.Config", [], Config, () => [[["Item", string_type]], [["Item", class_type("Fable.Import.Aws.Credentials.Credentials")]], [["Item", string_type]], [["Item", string_type]]]);
}

export function Config_get_create() {
    return (arg) => keyValueList(arg, 1);
}

export class S3_ApiParams extends Record {
    constructor(ApiVersion, Bucket) {
        super();
        this.ApiVersion = ApiVersion;
        this.Bucket = Bucket;
    }
}

export function S3_ApiParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ApiParams", [], S3_ApiParams, () => [["ApiVersion", string_type], ["Bucket", string_type]]);
}

export function S3_ApiParams_get_create() {
    return (value) => value;
}

export class S3_GetObjectRequestParams extends Record {
    constructor(Bucket, Key) {
        super();
        this.Bucket = Bucket;
        this.Key = Key;
    }
}

export function S3_GetObjectRequestParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.GetObjectRequestParams", [], S3_GetObjectRequestParams, () => [["Bucket", string_type], ["Key", obj_type]]);
}

export function S3_GetObjectRequestParams_get_create() {
    return (value) => value;
}

export class S3_ListObjectsRequestParams extends Record {
    constructor(Bucket, Delimiter, Prefix) {
        super();
        this.Bucket = Bucket;
        this.Delimiter = Delimiter;
        this.Prefix = Prefix;
    }
}

export function S3_ListObjectsRequestParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ListObjectsRequestParams", [], S3_ListObjectsRequestParams, () => [["Bucket", string_type], ["Delimiter", string_type], ["Prefix", string_type]]);
}

export function S3_ListObjectsRequestParams_get_create() {
    return (value) => value;
}

export class S3_ListObjectsV2RequestParams extends Record {
    constructor(Bucket, Delimiter, Prefix, StartAfter) {
        super();
        this.Bucket = Bucket;
        this.Delimiter = Delimiter;
        this.Prefix = Prefix;
        this.StartAfter = StartAfter;
    }
}

export function S3_ListObjectsV2RequestParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ListObjectsV2RequestParams", [], S3_ListObjectsV2RequestParams, () => [["Bucket", string_type], ["Delimiter", string_type], ["Prefix", string_type], ["StartAfter", string_type]]);
}

export function S3_ListObjectsV2RequestParams_get_create() {
    return (value) => value;
}

export class S3_DeleteObjectRequestParams extends Record {
    constructor(Bucket, Key) {
        super();
        this.Bucket = Bucket;
        this.Key = Key;
    }
}

export function S3_DeleteObjectRequestParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.DeleteObjectRequestParams", [], S3_DeleteObjectRequestParams, () => [["Bucket", string_type], ["Key", string_type]]);
}

export function S3_DeleteObjectRequestParams_get_create() {
    return (value) => value;
}

export class S3_ObjectIdentifierParams extends Record {
    constructor(Key) {
        super();
        this.Key = Key;
    }
}

export function S3_ObjectIdentifierParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ObjectIdentifierParams", [], S3_ObjectIdentifierParams, () => [["Key", string_type]]);
}

export class S3_ObjectIdentifierListParams extends Record {
    constructor(Objects, Quiet) {
        super();
        this.Objects = Objects;
        this.Quiet = Quiet;
    }
}

export function S3_ObjectIdentifierListParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.ObjectIdentifierListParams", [], S3_ObjectIdentifierListParams, () => [["Objects", array_type(S3_ObjectIdentifierParams_$reflection())], ["Quiet", bool_type]]);
}

export class S3_DeleteObjectsRequestParams extends Record {
    constructor(Bucket, Delete) {
        super();
        this.Bucket = Bucket;
        this.Delete = Delete;
    }
}

export function S3_DeleteObjectsRequestParams_$reflection() {
    return record_type("Fable.Helpers.Aws.S3.DeleteObjectsRequestParams", [], S3_DeleteObjectsRequestParams, () => [["Bucket", string_type], ["Delete", S3_ObjectIdentifierListParams_$reflection()]]);
}

export function S3_DeleteObjectsRequestParams_get_create() {
    return (value) => value;
}

export class S3_PutObjectRequestParams extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ACL", "Body", "Bucket", "Key", "ServerSideEncryption", "StorageClass"];
    }
}

export function S3_PutObjectRequestParams_$reflection() {
    return union_type("Fable.Helpers.Aws.S3.PutObjectRequestParams", [], S3_PutObjectRequestParams, () => [[["Item", string_type]], [["Item", class_type("Fable.Core.U5`5", [class_type("System.Buffer"), array_type(uint8_type), class_type("Browser.Types.Blob", undefined), string_type, class_type("Node.Stream.Readable`1", [uint8_type])])]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function S3_PutObjectRequestParams_get_create() {
    return (arg) => keyValueList(arg, 0);
}

