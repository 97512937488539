import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { lambda_type, unit_type, bool_type, list_type, union_type, record_type, option_type, class_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { S3Context_$reflection } from "../Common/AwsCommon.js";
import { UserData_$reflection, UserRole_$reflection } from "../RAWMap.Models/Security.js";
import { Security_UserInfo$2_$reflection } from "../fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { InstitutionStatsViewModel_$reflection, InstitutionViewModel_$reflection } from "../RAWMap.Models/View/Institution.js";
import { Common_SortInfo$1_$reflection } from "../RAWMap.Models/Common.js";
import { OutboundMsg_$reflection, InboundMsg_$reflection, LocalMsg_$reflection, Model_$reflection as Model_$reflection_1 } from "../ImageManager/ImageManagerTypes.js";
import { ChildAbsentStrategy$1_$reflection, DefaultActions_ChildModel$2_$reflection } from "../Common/Terminate.js";
import { ErrorMessage_$reflection } from "../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { Notification_$reflection } from "../RAWMap.Models/Api.js";
import { Msg$3_$reflection } from "../Common/InboundOutbound.js";
import { ChainedChildMsg$2_$reflection } from "../Common/ElmishDebugger/ChainedChildMsg.js";

export class InstitutionFileArgs extends Record {
    constructor(token, maybeCredentials, maybeS3Context) {
        super();
        this.token = token;
        this.maybeCredentials = maybeCredentials;
        this.maybeS3Context = maybeS3Context;
    }
}

export function InstitutionFileArgs_$reflection() {
    return record_type("Client.Institution.Types.InstitutionFileArgs", [], InstitutionFileArgs, () => [["token", string_type], ["maybeCredentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))], ["maybeS3Context", option_type(S3Context_$reflection())]]);
}

export class UpdateArgs extends Record {
    constructor(fileArgs, token) {
        super();
        this.fileArgs = fileArgs;
        this.token = token;
    }
}

export function UpdateArgs_$reflection() {
    return record_type("Client.Institution.Types.UpdateArgs", [], UpdateArgs, () => [["fileArgs", InstitutionFileArgs_$reflection()], ["token", string_type]]);
}

export class Mode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["List", "Create", "Edit", "Detail", "DownloadCSV"];
    }
}

export function Mode_$reflection() {
    return union_type("Client.Institution.Types.Mode", [], Mode, () => [[], [], [], [], []]);
}

export class Model extends Record {
    constructor(Mode, MaybeCurrentUser, Institutions, SelectedInstitution, EditInstitutionVm, SortInfo, StatsDateStart, StatsDateEnd, isDownloadFieldsValid, ImageManagerComponent) {
        super();
        this.Mode = Mode;
        this.MaybeCurrentUser = MaybeCurrentUser;
        this.Institutions = Institutions;
        this.SelectedInstitution = SelectedInstitution;
        this.EditInstitutionVm = EditInstitutionVm;
        this.SortInfo = SortInfo;
        this.StatsDateStart = StatsDateStart;
        this.StatsDateEnd = StatsDateEnd;
        this.isDownloadFieldsValid = isDownloadFieldsValid;
        this.ImageManagerComponent = ImageManagerComponent;
    }
}

export function Model_$reflection() {
    return record_type("Client.Institution.Types.Model", [], Model, () => [["Mode", Mode_$reflection()], ["MaybeCurrentUser", option_type(Security_UserInfo$2_$reflection(UserRole_$reflection(), UserData_$reflection()))], ["Institutions", list_type(InstitutionViewModel_$reflection())], ["SelectedInstitution", option_type(InstitutionViewModel_$reflection())], ["EditInstitutionVm", InstitutionViewModel_$reflection()], ["SortInfo", Common_SortInfo$1_$reflection(InstitutionViewModel_$reflection())], ["StatsDateStart", option_type(class_type("System.DateTimeOffset"))], ["StatsDateEnd", option_type(class_type("System.DateTimeOffset"))], ["isDownloadFieldsValid", bool_type], ["ImageManagerComponent", DefaultActions_ChildModel$2_$reflection(Model_$reflection_1(), Model_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "GetInstitutions", "GetInstitutionsResult", "GetInstitutionStats", "GetInstitutionStatsResult", "ToCsv", "ViewInstitution", "AddInstitution", "EditInstitution", "SaveInstitution", "DownloadStats", "ValidateStatsFields", "SetStatsDateStart", "SetStatsDateEnd", "CreateInstitutionResult", "EditInstitutionResult", "SetInstitutionVm", "UpdateSortInfo", "Cancel", "Notification", "CreateImageManagerComponent", "TerminateImageManagerComponent", "ImageManagerComponentMsg"];
    }
}

export function Msg_$reflection() {
    return union_type("Client.Institution.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Security_UserInfo$2_$reflection(UserRole_$reflection(), UserData_$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(InstitutionViewModel_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(InstitutionViewModel_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(InstitutionStatsViewModel_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(InstitutionStatsViewModel_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item", list_type(InstitutionStatsViewModel_$reflection())]], [["institutionId", class_type("System.Guid")]], [], [], [], [], [], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [InstitutionViewModel_$reflection(), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", InstitutionViewModel_$reflection()]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item", lambda_type(InstitutionViewModel_$reflection(), InstitutionViewModel_$reflection())]], [["Item", Common_SortInfo$1_$reflection(InstitutionViewModel_$reflection())]], [], [["Item", Notification_$reflection()]], [["Item", InstitutionViewModel_$reflection()]], [], [["Item", ChainedChildMsg$2_$reflection(Msg$3_$reflection(LocalMsg_$reflection(), InboundMsg_$reflection(), OutboundMsg_$reflection()), ChildAbsentStrategy$1_$reflection(Model_$reflection()))]]]);
}

