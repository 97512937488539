import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { int32_type, list_type, record_type, option_type, string_type, class_type, union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { collect, singleton as singleton_1, empty, contains, forAll, tryFind, ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { defaultArg, map } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Csv_CsvRowMulti, DateTimeOffset_yyyyMMdd, Subdivisions_getSubdivisionsForValidation } from "../Common.js";
import { int32ToString, stringHash } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { utcNow } from "../../fable_modules/fable-library-js.4.19.3/DateOffset.js";

export class InstitutionClinicalDesignation extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Research", "Clinical"];
    }
}

export function InstitutionClinicalDesignation_$reflection() {
    return union_type("RAWMap.Models.View.InstitutionClinicalDesignation", [], InstitutionClinicalDesignation, () => [[], []]);
}

export function InstitutionClinicalDesignation_get_all() {
    return ofArray([new InstitutionClinicalDesignation(0, []), new InstitutionClinicalDesignation(1, [])]);
}

export function InstitutionClinicalDesignation_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Clinical" : "Research");
}

export function InstitutionClinicalDesignation_tryParse_Z721C83C5(designation) {
    return tryFind((d) => (InstitutionClinicalDesignation_get_describe()(d) === designation), InstitutionClinicalDesignation_get_all());
}

export function InstitutionClinicalDesignation_get_describeOrDefault() {
    let f1;
    const mapping = InstitutionClinicalDesignation_get_describe();
    f1 = ((option) => map(mapping, option));
    return (arg) => defaultArg(f1(arg), "");
}

export function InstitutionClinicalDesignation_get_designationToString() {
    return [InstitutionClinicalDesignation_get_describeOrDefault(), (_arg) => undefined];
}

export class InstitutionViewModel extends Record {
    constructor(id, name, address, city, country, subdivision, clinicalDesignation) {
        super();
        this.id = id;
        this.name = name;
        this.address = address;
        this.city = city;
        this.country = country;
        this.subdivision = subdivision;
        this.clinicalDesignation = clinicalDesignation;
    }
}

export function InstitutionViewModel_$reflection() {
    return record_type("RAWMap.Models.View.InstitutionViewModel", [], InstitutionViewModel, () => [["id", class_type("System.Guid")], ["name", string_type], ["address", string_type], ["city", string_type], ["country", string_type], ["subdivision", string_type], ["clinicalDesignation", option_type(InstitutionClinicalDesignation_$reflection())]]);
}

export function InstitutionViewModel_get_empty() {
    return new InstitutionViewModel("00000000-0000-0000-0000-000000000000", "", "", "", "", "", undefined);
}

export function InstitutionViewModel_isValid_Z12B60C4C(institution) {
    if (forAll((arg) => !isNullOrWhiteSpace(arg), ofArray([institution.name, institution.address, institution.city, institution.country, institution.subdivision])) && (institution.clinicalDesignation != null)) {
        return contains(institution.subdivision, Subdivisions_getSubdivisionsForValidation(institution.country), {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        });
    }
    else {
        return false;
    }
}

export function InstitutionViewModel_get_id_() {
    return [(m) => m.id, (v) => ((m_1) => (new InstitutionViewModel(v, m_1.name, m_1.address, m_1.city, m_1.country, m_1.subdivision, m_1.clinicalDesignation)))];
}

export function InstitutionViewModel_get_name_() {
    return [(m) => m.name, (v) => ((m_1) => (new InstitutionViewModel(m_1.id, v, m_1.address, m_1.city, m_1.country, m_1.subdivision, m_1.clinicalDesignation)))];
}

export function InstitutionViewModel_get_address_() {
    return [(m) => m.address, (v) => ((m_1) => (new InstitutionViewModel(m_1.id, m_1.name, v, m_1.city, m_1.country, m_1.subdivision, m_1.clinicalDesignation)))];
}

export function InstitutionViewModel_get_city_() {
    return [(m) => m.city, (v) => ((m_1) => (new InstitutionViewModel(m_1.id, m_1.name, m_1.address, v, m_1.country, m_1.subdivision, m_1.clinicalDesignation)))];
}

export function InstitutionViewModel_get_country_() {
    return [(m) => m.country, (v) => ((m_1) => (new InstitutionViewModel(m_1.id, m_1.name, m_1.address, m_1.city, v, m_1.subdivision, m_1.clinicalDesignation)))];
}

export function InstitutionViewModel_get_subdivision_() {
    return [(m) => m.subdivision, (v) => ((m_1) => (new InstitutionViewModel(m_1.id, m_1.name, m_1.address, m_1.city, m_1.country, v, m_1.clinicalDesignation)))];
}

export function InstitutionViewModel_get_clinicalDesignation_() {
    return [(m) => m.clinicalDesignation, (v) => ((m_1) => (new InstitutionViewModel(m_1.id, m_1.name, m_1.address, m_1.city, m_1.country, m_1.subdivision, v)))];
}

export class UserInstitutionsViewModel extends Record {
    constructor(UserId, Institutions) {
        super();
        this.UserId = UserId;
        this.Institutions = Institutions;
    }
}

export function UserInstitutionsViewModel_$reflection() {
    return record_type("RAWMap.Models.View.UserInstitutionsViewModel", [], UserInstitutionsViewModel, () => [["UserId", class_type("System.Guid")], ["Institutions", list_type(InstitutionViewModel_$reflection())]]);
}

export function UserInstitutionsViewModel_get_empty() {
    return new UserInstitutionsViewModel("00000000-0000-0000-0000-000000000000", empty());
}

export function UserInstitutionsViewModelModule_UserId(_arg) {
    return _arg.UserId;
}

export function UserInstitutionsViewModelModule_Institutions(_arg) {
    return _arg.Institutions;
}

export class InstitutionStatsViewModel extends Record {
    constructor(name, patientId, numAnalyses) {
        super();
        this.name = name;
        this.patientId = patientId;
        this.numAnalyses = (numAnalyses | 0);
    }
}

export function InstitutionStatsViewModel_$reflection() {
    return record_type("RAWMap.Models.View.InstitutionStatsViewModel", [], InstitutionStatsViewModel, () => [["name", string_type], ["patientId", option_type(class_type("System.Guid"))], ["numAnalyses", int32_type]]);
}

export function InstitutionStatsViewModel_get_empty() {
    return new InstitutionStatsViewModel("", undefined, 0);
}

export function InstitutionStatsViewModel_makeCsvRows(dStart, dEnd, isvm) {
    return toList(delay(() => append(singleton(new Csv_CsvRowMulti("Export Date", singleton_1(DateTimeOffset_yyyyMMdd(utcNow())))), delay(() => append(singleton(new Csv_CsvRowMulti("Start Date", singleton_1(dStart))), delay(() => append(singleton(new Csv_CsvRowMulti("End Date", singleton_1(dEnd))), delay(() => append(singleton(new Csv_CsvRowMulti("Institution Name", ofArray(["Patient ID", "Number of Completed Analyses"]))), delay(() => collect((vm) => {
        let patternInput;
        const matchValue = vm.patientId;
        patternInput = ((matchValue == null) ? ["N/A", "N/A"] : [matchValue, int32ToString(vm.numAnalyses)]);
        return singleton_1(new Csv_CsvRowMulti(vm.name, ofArray([patternInput[0], patternInput[1]])));
    }, isvm)))))))))));
}

