import { Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.3/List.js";

export class InternalMsg$2 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Local", "Inbound"];
    }
}

export function InternalMsg$2_$reflection(gen0, gen1) {
    return union_type("RAWMap.Client.Common.InboundOutbound.InOut.InternalMsg`2", [gen0, gen1], InternalMsg$2, () => [[["Item", gen0]], [["Item", gen1]]]);
}

export class Msg$3 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Internal", "External"];
    }
}

export function Msg$3_$reflection(gen0, gen1, gen2) {
    return union_type("RAWMap.Client.Common.InboundOutbound.InOut.Msg`3", [gen0, gen1, gen2], Msg$3, () => [[["Item", InternalMsg$2_$reflection(gen0, gen1)]], [["Item", gen2]]]);
}

export function wrapLocalMsg(msg) {
    return new Msg$3(0, [new InternalMsg$2(0, [msg])]);
}

export function wrapInboundMsg(msg) {
    return new Msg$3(0, [new InternalMsg$2(1, [msg])]);
}

export function wrapOutboundMsg(msg) {
    return new Msg$3(1, [msg]);
}

export function update(updateLocal, updateInbound, args, msg, model) {
    const patternInput = (msg.tag === 0) ? updateLocal(args, msg.fields[0], model) : updateInbound(args, msg.fields[0], model);
    return [patternInput[0], Cmd_batch(ofArray([Cmd_map(wrapLocalMsg, patternInput[1]), Cmd_map((Item) => (new Msg$3(1, [Item])), patternInput[2])]))];
}

