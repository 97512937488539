import { wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { Option, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { DOMAttr, HTMLAttr } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { empty, ofArray, singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Option as Option_1, icon as icon_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Button_visibilityControlled } from "../../Common/Controls.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background } from "../../fable_modules/Fulma.3.0.0/Components/Modal.fs.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { content } from "../../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";

export function mkDispatchLocal(dispatch) {
    return (arg) => dispatch(wrapLocalMsg(arg));
}

export function mkToolReactButton(dispatch, extraButtonStyles, icon, toggleMsg, isLoading, isDisabled, desc) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    return button(toList(delay(() => append(singleton(new Option(17, [singleton_1(new HTMLAttr(158, [desc]))])), delay(() => append(singleton(new Option(13, [isLoading])), delay(() => append(singleton(new Option(16, [isDisabled])), delay(() => append(singleton(new Option(17, [ofArray([new DOMAttr(40, [(_arg) => {
        dispatchLocal(toggleMsg);
    }]), new HTMLAttr(159, ["button"])])])), delay(() => extraButtonStyles)))))))))), singleton_1(icon_1(singleton_1(new Option_1(4, [singleton_1(["style", {}])])), singleton_1(Fa_i(singleton_1(icon), [])))));
}

export function mkToolReactButtonWithVisibility(dispatch, extraButtonStyles, icon, toggleMsg, isLoading, visibility, desc) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    return Button_visibilityControlled(undefined, visibility, toList(delay(() => append(singleton(new Option(17, [singleton_1(new HTMLAttr(158, [desc]))])), delay(() => append(singleton(new Option(13, [isLoading])), delay(() => append(singleton(new Option(17, [ofArray([new DOMAttr(40, [(_arg) => {
        dispatchLocal(toggleMsg);
    }]), new HTMLAttr(159, ["button"])])])), delay(() => extraButtonStyles)))))))), singleton_1(icon_1(singleton_1(new Option_1(4, [singleton_1(["style", {}])])), singleton_1(Fa_i(singleton_1(icon), [])))));
}

export function deleteConfirmationDispatched(itemName, itemDescription, onDelete, isSaving, onCancel) {
    let children, props_2;
    return ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton_1(Card_title(empty(), singleton_1(toText(printf("Delete %s"))(itemName))))), Card_body(empty(), singleton_1(content(empty(), ofArray([(children = [toText(printf("Are you sure you want to delete %s:"))(itemName)], react.createElement("div", {}, ...children)), (props_2 = [new HTMLAttr(64, ["long-text-container"]), ["style", {
        marginTop: 10,
    }]], react.createElement("div", keyValueList(props_2, 1), itemDescription))])))), Card_foot(empty(), ofArray([button(ofArray([new Option(0, [new Color_IColor(8, [])]), new Option(16, [isSaving]), new Option(13, [isSaving]), new Option(17, [ofArray([new DOMAttr(40, [onDelete]), new HTMLAttr(159, ["button"])])])]), singleton_1("Delete")), button(ofArray([new Option(0, [new Color_IColor(2, [])]), new Option(16, [isSaving]), new Option(17, [ofArray([new DOMAttr(40, [onCancel]), new HTMLAttr(159, ["button"])])])]), singleton_1("Cancel"))]))]))]);
}

export function deleteConfirmation(itemName, itemDescription, deleteMsg, isSaving, cancelMsg, dispatch) {
    const dispatchLocal = mkDispatchLocal(dispatch);
    return deleteConfirmationDispatched(itemName, itemDescription, (_arg) => {
        dispatchLocal(deleteMsg);
    }, isSaving, (_arg_1) => {
        dispatchLocal(cancelMsg);
    });
}

