import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, bool_type, float64_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Result_isOk, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotEmptyGuid } from "../../fable_modules/Webbler.Models.1.3.1/Validation.fs.js";
import { ErrorMessage } from "../ErrorMessage.js";
import { StudyValidators_validateStringNotNullOrWhiteSpace } from "./Study.js";

export class VisualizationNoteViewModel extends Record {
    constructor(studyId, noteId, content, x, y, isHidden) {
        super();
        this.studyId = studyId;
        this.noteId = noteId;
        this.content = content;
        this.x = x;
        this.y = y;
        this.isHidden = isHidden;
    }
}

export function VisualizationNoteViewModel_$reflection() {
    return record_type("RAWMap.Models.View.VisualizationNote.VisualizationNoteViewModel", [], VisualizationNoteViewModel, () => [["studyId", class_type("System.Guid")], ["noteId", class_type("System.Guid")], ["content", string_type], ["x", float64_type], ["y", float64_type], ["isHidden", bool_type]]);
}

export function VisualizationNoteViewModel_validate_45C878EE(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["NoteId", "not be empty Guid"]), vm.noteId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["StudyId", "not be empty Guid"]), vm.studyId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateStringNotNullOrWhiteSpace("Note", vm.content), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))))));
}

export function VisualizationNoteViewModel_get_label_() {
    return [(m) => m.content, (v) => ((m_1) => (new VisualizationNoteViewModel(m_1.studyId, m_1.noteId, v, m_1.x, m_1.y, m_1.isHidden)))];
}

export function VisualizationNoteViewModel_isValid_45C878EE(vm) {
    return Result_isOk(VisualizationNoteViewModel_validate_45C878EE(vm));
}

export function VisualizationNoteViewModel_getStudyId_45C878EE(vm) {
    return vm.studyId;
}

export function VisualizationNoteViewModel_getPrimaryId_45C878EE(vm) {
    return vm.noteId;
}

export function VisualizationNoteViewModel_get_primaryIdName() {
    return "NoteId";
}

export function VisualizationNoteViewModel_get_description() {
    return "Visualization Note";
}

export function VisualizationNoteViewModel_desc_45C878EE(_arg) {
    return VisualizationNoteViewModel_get_description();
}

