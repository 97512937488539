import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Result_isOk, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotEmptyGuid } from "../../fable_modules/Webbler.Models.1.3.1/Validation.fs.js";
import { ErrorMessage } from "../ErrorMessage.js";
import { StudyValidators_validateStringNotNullOrWhiteSpace } from "./Study.js";

export class VisualizationScreenshotViewModel extends Record {
    constructor(studyId, screenshotId, description, imagePath, imageUrl) {
        super();
        this.studyId = studyId;
        this.screenshotId = screenshotId;
        this.description = description;
        this.imagePath = imagePath;
        this.imageUrl = imageUrl;
    }
}

export function VisualizationScreenshotViewModel_$reflection() {
    return record_type("RAWMap.Models.View.VisualizationScreenshot.VisualizationScreenshotViewModel", [], VisualizationScreenshotViewModel, () => [["studyId", class_type("System.Guid")], ["screenshotId", class_type("System.Guid")], ["description", string_type], ["imagePath", string_type], ["imageUrl", option_type(string_type)]]);
}

export function VisualizationScreenshotViewModel_validate_Z6DE73A12(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["ScreenshotId", "not be empty Guid"]), vm.screenshotId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["StudyId", "not be empty Guid"]), vm.studyId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateStringNotNullOrWhiteSpace("ImagePath", vm.imagePath), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))))));
}

export function VisualizationScreenshotViewModel_isValid_Z6DE73A12(vm) {
    return Result_isOk(VisualizationScreenshotViewModel_validate_Z6DE73A12(vm));
}

export function VisualizationScreenshotViewModel_getStudyId_Z6DE73A12(vm) {
    return vm.studyId;
}

export function VisualizationScreenshotViewModel_getPrimaryId_Z6DE73A12(vm) {
    return vm.screenshotId;
}

export function VisualizationScreenshotViewModel_get_primaryIdName() {
    return "ScreenshotId";
}

export function VisualizationScreenshotViewModel_get_describe() {
    return "Visualization Screenshot";
}

export function VisualizationScreenshotViewModel_desc_Z6DE73A12(_arg) {
    return VisualizationScreenshotViewModel_get_describe();
}

