import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Option, container } from "../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { Urls_Page, Urls_AuthPage, Urls_pageHash, InputElement_ref, Forms_onEnterKeyPress } from "../Common/General.js";
import { Msg } from "./AuthTypes.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { tryHead, ofArray, empty, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Option as Option_4, div } from "../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { label } from "../fable_modules/Fulma.3.0.0/Elements/Form/Label.fs.js";
import { Option as Option_1, div as div_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { input } from "../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_2, IInputType } from "../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Security_RegisterViewModel } from "../fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { Option as Option_3, icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Color_IColor, Size_ISize } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { a, Option as Option_5, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import * as react from "react";

export function view(model, dispatch) {
    let children, value_3;
    const isDisabled = isNullOrWhiteSpace(model.registerVm.email) ? true : isNullOrWhiteSpace(model.registerVm.password);
    return container(singleton(new Option(3, [singleton(new DOMAttr(17, [(e) => {
        Forms_onEnterKeyPress(isDisabled, () => {
            dispatch(new Msg(10, []));
        }, e);
    }]))])), singleton((children = [div(empty(), ofArray([label(empty(), singleton("Email")), div_1(singleton(new Option_1(1, [])), ofArray([input(ofArray([new Option_2(1, [new IInputType(0, [])]), new Option_2(14, [(e_1) => {
        InputElement_ref(model.registerVm.email, e_1);
    }]), new Option_2(13, [(e_2) => {
        dispatch(new Msg(1, [(rvm) => (new Security_RegisterViewModel(e_2.currentTarget.value, rvm.password, rvm.givenName, rvm.familyName, rvm.roles))]));
    }])])), icon(ofArray([new Option_3(0, [new Size_ISize(0, [])]), new Option_3(1, [])]), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-envelope"])), [])))]))])), div(empty(), ofArray([label(empty(), singleton("Password")), div_1(singleton(new Option_1(1, [])), ofArray([input(ofArray([new Option_2(1, [new IInputType(1, [])]), new Option_2(14, [(e_3) => {
        InputElement_ref(model.registerVm.password, e_3);
    }]), new Option_2(13, [(e_4) => {
        dispatch(new Msg(1, [(rvm_1) => (new Security_RegisterViewModel(rvm_1.email, e_4.currentTarget.value, rvm_1.givenName, rvm_1.familyName, rvm_1.roles))]));
    }])])), icon(ofArray([new Option_3(0, [new Size_ISize(0, [])]), new Option_3(1, [])]), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-lock"])), [])))]))])), div(empty(), ofArray([label(empty(), singleton("Role")), div_1(empty(), singleton(input(ofArray([new Option_2(1, [new IInputType(0, [])]), new Option_2(14, [(value_3 = defaultArg(tryHead(model.registerVm.roles), ""), (e_5) => {
        InputElement_ref(value_3, e_5);
    })]), new Option_2(13, [(e_6) => {
        dispatch(new Msg(1, [(rvm_2) => (new Security_RegisterViewModel(rvm_2.email, rvm_2.password, rvm_2.givenName, rvm_2.familyName, (e_6.currentTarget.value === "") ? empty() : singleton(e_6.currentTarget.value)))]));
    }])]))))])), div(singleton(new Option_4(4, [])), ofArray([div_1(empty(), singleton(button(ofArray([new Option_5(16, [isDisabled]), new Option_5(0, [new Color_IColor(4, [])]), new Option_5(18, [(_arg_1) => {
        dispatch(new Msg(10, []));
    }])]), singleton("Submit")))), div_1(empty(), singleton(a(ofArray([new Option_5(0, [new Color_IColor(2, [])]), new Option_5(17, [singleton(new HTMLAttr(94, [Urls_pageHash(new Urls_Page(0, [new Urls_AuthPage(0, [])]))]))])]), singleton("Cancel"))))]))], react.createElement("div", {}, ...children))));
}

