import { InboundMsg as InboundMsg_1, FileManagementUpdateArgs, FileManagementViewModel_abortUpload_27E04A4D, Model_tryTerminate_Z379152CB, FileHandling, Model_getNumberOfAssociatedFiles_Z379152CB } from "../../../FileManagement/FileManagementTypes.js";
import { length, singleton, iterate, ofArray, exists } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { last } from "../../../fable_modules/fable-library-js.4.19.3/Array.js";
import { split } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { toList } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { update as update_1, init as init_1 } from "../../../FileManagement/FileManagementState.js";
import { update as update_2, init as init_2 } from "../../../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropState.js";
import { OutboundMsg, LocalMsg, Model } from "./AnalysisFormFileTypes.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__ReturnFrom_1505, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { update as update_3, wrapInboundMsg } from "../../../Common/InboundOutbound.js";
import { DragDropFileSelectorUpdateArgs, InboundMsg } from "../../../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { S3Context } from "../../../Common/AwsCommon.js";
import { some } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";

export function numberOfAssociatedFiles(model) {
    return Model_getNumberOfAssociatedFiles_Z379152CB(model.fileUpload);
}

export function hasFileWithExtension(extension, model) {
    return exists((tupledArg) => {
        const fileExtension = last(split(tupledArg[1].Name, ["."], undefined, 0));
        if (fileExtension === extension) {
            return true;
        }
        else {
            return ("." + fileExtension) === extension;
        }
    }, toList(model.fileUpload.DownloadableFiles));
}

export function init(fileSpecs, fileOperationContext) {
    const patternInput = init_1(fileSpecs, fileOperationContext, new FileHandling(0, []));
    const patternInput_1 = init_2(fileSpecs);
    return [new Model(patternInput[0], patternInput_1[0]), Cmd_batch(ofArray([Cmd_map((Item) => (new LocalMsg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new LocalMsg(3, [Item_1])), patternInput_1[1])]))];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__ReturnFrom_1505(ResultComputationExpression_result, Model_tryTerminate_Z379152CB(model.fileUpload))));
}

export function forceTerminate(model) {
    iterate((arg) => {
        FileManagementViewModel_abortUpload_27E04A4D(arg[1]);
    }, toList(model.fileUpload.FileManagementsInProgress));
}

export function updateLocal(args, msg, model) {
    let msg_2, bind$0040, msg_8;
    switch (msg.tag) {
        case 1:
            return [model, Cmd_batch(singleton((msg_2 = (new LocalMsg(3, [wrapInboundMsg(new InboundMsg())])), singleton((dispatch) => {
                dispatch(msg_2);
            })))), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 0) {
                const patternInput = update_1(new FileManagementUpdateArgs(args.Token, args.Credentials, (bind$0040 = args.Context, new S3Context(bind$0040.S3Config, bind$0040.S3Client, bind$0040.S3Expiry, args.Context.ActiveBucket))), msg.fields[0].fields[0], model.fileUpload);
                return [new Model(patternInput[0], model.dragDrop), Cmd_map((Item_1) => (new LocalMsg(2, [Item_1])), patternInput[1]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0].tag === 0) {
                return [model, Cmd_none(), singleton((dispatch_1) => {
                    dispatch_1(new OutboundMsg(0, []));
                })];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        case 3:
            if (msg.fields[0].tag === 0) {
                const patternInput_1 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, numberOfAssociatedFiles(model) + length(model.fileUpload.UploadSelection), args.Credentials, args.Context), msg.fields[0].fields[0], model.dragDrop);
                return [new Model(model.fileUpload, patternInput_1[0]), Cmd_map((Item_4) => (new LocalMsg(3, [Item_4])), patternInput_1[1]), Cmd_none()];
            }
            else {
                return [model, Cmd_batch(ofArray([(msg_8 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg_1(1, [msg.fields[0].fields[0].fields[0]]))])), singleton((dispatch_2) => {
                    dispatch_2(msg_8);
                })), singleton((dispatch_3) => {
                    dispatch_3(new LocalMsg(1, []));
                })])), Cmd_none()];
            }
        default: {
            console.error(some(msg.fields[0]));
            return [model, Cmd_none(), Cmd_none()];
        }
    }
}

export function updateInbound(_args, msg, model) {
    let msg_2;
    return [model, (msg_2 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg_1(0, []))])), singleton((dispatch) => {
        dispatch(msg_2);
    })), Cmd_none()];
}

export function update(args, msg, model) {
    return update_3(updateLocal, updateInbound, args, msg, model);
}

