import { head, tail, isEmpty, tryFind, ofArray, find, singleton, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { equals, defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { Msg, Model } from "./StudyTypes.js";
import { Cmd_map, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { toConsole, printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { bind, map, defaultArg, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { PatientInfo as PatientInfo_1, InternalMsg, InboundMsg as InboundMsg_1, MsgCore, LocalMsg, wrapLocalMsg } from "./CoreForm/CoreFormTypes.js";
import { wrapLocalMsg as wrapLocalMsg_1, wrapInboundMsg } from "../Common/InboundOutbound.js";
import { InboundMsg } from "./CoreForm/CoreFormFileManagement/CoreFormFileTypes.js";
import { OfAsyncWith_result } from "../fable_modules/Fable.Elmish.4.2.0/cmd.obsolete.fs.js";
import { Cmd_OfAsyncWith_either, Cmd_OfAsync_start } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Async_map } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { sleep } from "../fable_modules/fable-library-js.4.19.3/Async.js";
import { Navigation_modifyUrl, Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.4.0.3/navigation.fs.js";
import { Toast_errorToast, Urls_Page, Urls_PatientPage, Urls_pageHash } from "../Common/General.js";
import { initWithCreate as initWithCreate_1, initWithDisplay as initWithDisplay_1, updateCore } from "./CoreForm/CoreFormState.js";
import { securedApi } from "../Api.js";
import { Common_StudyStatus } from "../RAWMap.Models/Common.js";
import { empty as empty_1, singleton as singleton_1, append, delay as delay_1, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { initWithCreate, initWithDisplay, updateAnalysis } from "./AnalysisForm/AnalysisFormState.js";
import { tryPick } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { updateVisualization, init as init_1 } from "../Visualization/VisualizationState.js";
import { InboundMsg as InboundMsg_2 } from "../Visualization/VisualizationTypes.js";
import { MsgAnalysis, InternalMsg as InternalMsg_1, InboundMsg as InboundMsg_3, PatientInfo } from "./AnalysisForm/AnalysisFormTypes.js";
import { update as update_1, init as init_2 } from "./CustomReport/CustomReportState.js";
import { CustomReportUpdateArgs, CustomReportInitArgs } from "./CustomReport/CustomReportTypes.js";
import { FileManagementUpdateArgs } from "../FileManagement/FileManagementTypes.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";
import { CustomReportViewModel, CustomReportStatus_isNowOutdated_6FCE9E49, CustomReportViewModel_empty_244AC511 } from "../RAWMap.Models/View/CustomReport.js";
import { ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";

export function init() {
    return [new Model(undefined, empty(), empty(), empty(), "00000000-0000-0000-0000-000000000000", undefined, undefined, undefined, undefined, false, false, undefined, undefined, undefined, undefined, undefined, defaultOf(), undefined), Cmd_none()];
}

export function update(token, msg, model) {
    let msg_3, msg_10, _patientInst, matchValue_2, studyId_1, matchValue_4, msg_33, msg_38, msg_41, msg_43, tupledArg, msg_45, tupledArg_1, msg_50, msg_75, tupledArg_5, msg_81, tupledArg_6;
    let matchResult, ex, studyId, commandAfterRefresh, studyGuid, delay, msg_9, runCheck_1, command_1, svm, msg_22, avm, avm_1, avm_2, msg_35, selectedStudy, studyId_3, analysis_1, analysis_2, _studyId, msg_47, customReportData, selectedStudy_1, msg_49, data, studyId_4, data_1, err, status, userInfo, cognitoCredentials, s3Context_1, pid, studies, e, sid_3, patientInstitution_1, study_12, patientId, toSelect, toSelect_1, toSelect_2, pid_1, patients, institutions, _mode, _pvm, e_1, e_2;
    switch (msg.tag) {
        case 1: {
            if (msg.fields[0].tag === 0) {
                matchResult = 7;
                msg_22 = msg.fields[0].fields[0];
            }
            else {
                switch (msg.fields[0].fields[0].tag) {
                    case 2: {
                        matchResult = 2;
                        commandAfterRefresh = msg.fields[0].fields[0].fields[0][1];
                        studyGuid = msg.fields[0].fields[0].fields[0][0];
                        break;
                    }
                    case 1: {
                        matchResult = 5;
                        svm = msg.fields[0].fields[0].fields[0];
                        break;
                    }
                    case 3: {
                        matchResult = 6;
                        break;
                    }
                    default: {
                        matchResult = 1;
                        studyId = msg.fields[0].fields[0].fields[0];
                    }
                }
            }
            break;
        }
        case 2: {
            matchResult = 3;
            delay = msg.fields[1];
            msg_9 = msg;
            runCheck_1 = msg.fields[0];
            break;
        }
        case 3: {
            matchResult = 4;
            command_1 = msg.fields[0];
            break;
        }
        case 4: {
            if (msg.fields[0].tag === 0) {
                matchResult = 14;
                msg_35 = msg.fields[0].fields[0];
            }
            else {
                switch (msg.fields[0].fields[0].tag) {
                    case 1: {
                        matchResult = 9;
                        avm_1 = msg.fields[0].fields[0].fields[0];
                        break;
                    }
                    case 2: {
                        matchResult = 10;
                        break;
                    }
                    case 3: {
                        matchResult = 13;
                        break;
                    }
                    case 4: {
                        matchResult = 15;
                        selectedStudy = msg.fields[0].fields[0].fields[0];
                        break;
                    }
                    default: {
                        matchResult = 8;
                        avm = msg.fields[0].fields[0].fields[0];
                    }
                }
            }
            break;
        }
        case 5: {
            matchResult = 11;
            avm_2 = msg.fields[0];
            break;
        }
        case 6: {
            if (msg.fields[0].tag === 1) {
                matchResult = 50;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 12;
            }
            break;
        }
        case 7: {
            matchResult = 16;
            studyId_3 = msg.fields[0];
            break;
        }
        case 8: {
            if (msg.fields[0].tag === 1) {
                matchResult = 49;
                e_1 = msg.fields[0].fields[0];
            }
            else if (msg.fields[0].fields[0] == null) {
                matchResult = 18;
            }
            else {
                matchResult = 17;
                analysis_1 = msg.fields[0].fields[0];
            }
            break;
        }
        case 9: {
            matchResult = 19;
            break;
        }
        case 10: {
            matchResult = 20;
            analysis_2 = msg.fields[0];
            break;
        }
        case 11: {
            matchResult = 21;
            _studyId = msg.fields[0];
            break;
        }
        case 12: {
            if (msg.fields[0].tag === 1) {
                switch (msg.fields[0].fields[0].tag) {
                    case 2: {
                        matchResult = 24;
                        customReportData = msg.fields[0].fields[0].fields[1];
                        selectedStudy_1 = msg.fields[0].fields[0].fields[0];
                        break;
                    }
                    case 0: {
                        matchResult = 32;
                        status = msg.fields[0].fields[0].fields[0];
                        break;
                    }
                    default:
                        matchResult = 23;
                }
            }
            else {
                matchResult = 22;
                msg_47 = msg.fields[0].fields[0];
            }
            break;
        }
        case 13: {
            if (msg.fields[0].tag === 1) {
                switch (msg.fields[0].fields[0].tag) {
                    case 2: {
                        matchResult = 27;
                        break;
                    }
                    case 0: {
                        matchResult = 32;
                        status = msg.fields[0].fields[0].fields[0];
                        break;
                    }
                    default: {
                        matchResult = 26;
                        data = msg.fields[0].fields[0].fields[0];
                    }
                }
            }
            else {
                matchResult = 25;
                msg_49 = msg.fields[0].fields[0];
            }
            break;
        }
        case 14: {
            matchResult = 28;
            studyId_4 = msg.fields[0];
            break;
        }
        case 15: {
            if (msg.fields[0].tag === 1) {
                matchResult = 31;
                err = msg.fields[0].fields[0];
            }
            else if (msg.fields[0].fields[0] == null) {
                matchResult = 30;
            }
            else {
                matchResult = 29;
                data_1 = msg.fields[0].fields[0];
            }
            break;
        }
        case 16: {
            matchResult = 33;
            userInfo = msg.fields[0];
            break;
        }
        case 31: {
            matchResult = 34;
            cognitoCredentials = msg.fields[0];
            s3Context_1 = msg.fields[1];
            break;
        }
        case 17: {
            matchResult = 35;
            pid = msg.fields[0];
            break;
        }
        case 18: {
            if (msg.fields[0].tag === 1) {
                matchResult = 37;
                e = msg.fields[0].fields[0];
            }
            else {
                matchResult = 36;
                studies = msg.fields[0].fields[0];
            }
            break;
        }
        case 26: {
            matchResult = 38;
            sid_3 = msg.fields[0];
            break;
        }
        case 27: {
            matchResult = 39;
            patientInstitution_1 = msg.fields[1];
            study_12 = msg.fields[0];
            break;
        }
        case 28: {
            matchResult = 40;
            patientId = msg.fields[0];
            break;
        }
        case 25: {
            matchResult = 41;
            break;
        }
        case 19: {
            matchResult = 42;
            toSelect = msg.fields[0];
            break;
        }
        case 20: {
            matchResult = 43;
            toSelect_1 = msg.fields[0];
            break;
        }
        case 21: {
            matchResult = 44;
            toSelect_2 = msg.fields[0];
            break;
        }
        case 22: {
            matchResult = 45;
            pid_1 = msg.fields[0];
            break;
        }
        case 29: {
            if (msg.fields[0].tag === 1) {
                matchResult = 50;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 46;
                patients = msg.fields[0].fields[0];
            }
            break;
        }
        case 30: {
            if (msg.fields[0].tag === 1) {
                matchResult = 50;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 47;
                institutions = msg.fields[0].fields[0];
            }
            break;
        }
        case 23: {
            matchResult = 48;
            _mode = msg.fields[1];
            _pvm = msg.fields[0];
            break;
        }
        case 24: {
            if (msg.fields[0].tag === 4) {
                matchResult = 51;
            }
            else {
                matchResult = 52;
            }
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, studyId, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        case 2: {
            const model$0027 = new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, true, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            return [model$0027, Cmd_batch(ofArray([(msg_3 = (new Msg(1, [wrapLocalMsg(new LocalMsg(2, [wrapInboundMsg(new InboundMsg(0, []))]))])), singleton((dispatch) => {
                dispatch(msg_3);
            })), singleton((dispatch_1) => {
                dispatch_1(new Msg(17, [model$0027.PatientGuid]));
            }), singleton((dispatch_2) => {
                dispatch_2(new Msg(2, [(model_1) => {
                    if (model_1.isStudiesLoading) {
                        return undefined;
                    }
                    else {
                        return Cmd_map((arg_1) => (new Msg(1, [new MsgCore(0, [arg_1])])), commandAfterRefresh(find((study) => (study.studyId === studyGuid), model_1.Studies)));
                    }
                }, 1000]));
            })]))];
        }
        case 3: {
            const matchValue = runCheck_1(model);
            if (matchValue == null) {
                return [model, OfAsyncWith_result((x) => {
                    Cmd_OfAsync_start(x);
                }, Async_map(() => msg_9, sleep(delay)))];
            }
            else {
                return [model, (msg_10 = (new Msg(3, [matchValue])), singleton((dispatch_3) => {
                    dispatch_3(msg_10);
                }))];
            }
        }
        case 4:
            return [model, command_1];
        case 5:
            if (model.PatientInstitution != null) {
                if ((_patientInst = model.PatientInstitution, equals(model.StudyGuid, svm.studyId))) {
                    const _patientInst_1 = model.PatientInstitution;
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, svm.studyId, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([singleton((dispatch_4) => {
                        dispatch_4(new Msg(17, [model.PatientGuid]));
                    }), singleton((dispatch_5) => {
                        dispatch_5(new Msg(1, [new MsgCore(0, [new InternalMsg(1, [new InboundMsg_1(0, [svm])])])]));
                    })]))];
                }
                else {
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, svm.studyId, undefined, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([singleton((dispatch_6) => {
                        dispatch_6(new Msg(17, [model.PatientGuid]));
                    }), Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(2, [[svm.patientId, svm.studyId]])])))]))];
                }
            }
            else {
                return [model, Cmd_batch(ofArray([Toast_errorToast("No patient institution found"), singleton((dispatch_7) => {
                    dispatch_7(new Msg(22, [model.PatientGuid]));
                })]))];
            }
        case 6:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, undefined, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [model.PatientGuid])]))), singleton((dispatch_8) => {
                dispatch_8(new Msg(22, [model.PatientGuid]));
            })]))];
        case 7: {
            const matchValue_1 = model.CoreFormComponent;
            if (matchValue_1 == null) {
                let matchResult_1;
                if (msg_22.tag === 0) {
                    if (msg_22.fields[0].tag === 2) {
                        if (msg_22.fields[0].fields[0].tag === 1) {
                            if (msg_22.fields[0].fields[0].fields[0].tag === 4) {
                                matchResult_1 = 0;
                            }
                            else {
                                matchResult_1 = 1;
                            }
                        }
                        else {
                            matchResult_1 = 1;
                        }
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0:
                        return [model, Cmd_none()];
                    default: {
                        console.error(some(toText(printf("CoreForm does not exist. Message: %A"))(msg_22)));
                        return [model, Toast_errorToast("CoreForm does not exist")];
                    }
                }
            }
            else {
                const patternInput = updateCore(token, msg_22, matchValue_1);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, patternInput[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((Item_8) => (new Msg(1, [Item_8])), patternInput[1])];
            }
        }
        case 8: {
            const activeStudy = tryFind((study_1) => (study_1.studyId === avm.studyId), model.Studies);
            let matchResult_2;
            if (activeStudy == null) {
                matchResult_2 = 0;
            }
            else if (activeStudy.status.tag === 4) {
                matchResult_2 = 0;
            }
            else {
                matchResult_2 = 1;
            }
            switch (matchResult_2) {
                case 0:
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, undefined, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), singleton((dispatch_9) => {
                        dispatch_9(new Msg(7, [avm.studyId]));
                    })];
                default:
                    return [model, singleton((dispatch_10) => {
                        dispatch_10(new Msg(5, [avm]));
                    })];
            }
        }
        case 9:
            return [model, singleton((dispatch_11) => {
                dispatch_11(new Msg(5, [avm_1]));
            })];
        case 10:
            return [model, Cmd_batch((matchValue_2 = model.StudyGuid, (matchValue_2 == null) ? singleton(Toast_errorToast("Unable to find selected study")) : ((studyId_1 = matchValue_2, singleton(Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).updateStudyStatus, [studyId_1, new Common_StudyStatus(3, [])], (Item_11) => (new Msg(6, [Item_11])), (Item_12) => (new Msg(0, [Item_12]))))))))];
        case 11: {
            let studyCmd_1;
            const matchValue_3 = model.StudyGuid;
            if (matchValue_3 == null) {
                studyCmd_1 = singleton(Toast_errorToast("Unable to find selected study"));
            }
            else {
                const studyId_2 = matchValue_3;
                studyCmd_1 = singleton(Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedApi(token).updateStudyStatus, [studyId_2, new Common_StudyStatus(4, [])], (Item_13) => (new Msg(6, [Item_13])), (Item_14) => (new Msg(0, [Item_14]))));
            }
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, undefined, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(toList(delay_1(() => append(singleton_1(singleton((dispatch_12) => {
                dispatch_12(new Msg(7, [avm_2.studyId]));
            })), delay_1(() => studyCmd_1)))))];
        }
        case 12:
            return [model, singleton((dispatch_13) => {
                dispatch_13(new Msg(17, [model.PatientGuid]));
            })];
        case 13:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, undefined, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), (matchValue_4 = model.SelectedAnalysis, (matchValue_4 != null) ? ((msg_33 = (new Msg(10, [matchValue_4])), singleton((dispatch_14) => {
                dispatch_14(msg_33);
            }))) : Cmd_none())];
        case 14: {
            const matchValue_5 = model.AnalysisFormComponent;
            if (matchValue_5 == null) {
                let matchResult_3;
                if (msg_35.tag === 0) {
                    switch (msg_35.fields[0].tag) {
                        case 3: {
                            if (msg_35.fields[0].fields[0].tag === 0) {
                                if (msg_35.fields[0].fields[0].fields[0].tag === 0) {
                                    if (msg_35.fields[0].fields[0].fields[0].fields[0].tag === 2) {
                                        if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                            if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                                if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                                    matchResult_3 = 0;
                                                }
                                                else {
                                                    matchResult_3 = 1;
                                                }
                                            }
                                            else {
                                                matchResult_3 = 1;
                                            }
                                        }
                                        else {
                                            matchResult_3 = 1;
                                        }
                                    }
                                    else {
                                        matchResult_3 = 1;
                                    }
                                }
                                else {
                                    matchResult_3 = 1;
                                }
                            }
                            else {
                                matchResult_3 = 1;
                            }
                            break;
                        }
                        case 4: {
                            if (msg_35.fields[0].fields[0].tag === 0) {
                                if (msg_35.fields[0].fields[0].fields[0].tag === 0) {
                                    if (msg_35.fields[0].fields[0].fields[0].fields[0].tag === 2) {
                                        if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                            if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                                if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                                    matchResult_3 = 0;
                                                }
                                                else {
                                                    matchResult_3 = 1;
                                                }
                                            }
                                            else {
                                                matchResult_3 = 1;
                                            }
                                        }
                                        else {
                                            matchResult_3 = 1;
                                        }
                                    }
                                    else {
                                        matchResult_3 = 1;
                                    }
                                }
                                else {
                                    matchResult_3 = 1;
                                }
                            }
                            else {
                                matchResult_3 = 1;
                            }
                            break;
                        }
                        default:
                            matchResult_3 = 1;
                    }
                }
                else {
                    matchResult_3 = 1;
                }
                switch (matchResult_3) {
                    case 0:
                        return [model, Cmd_none()];
                    default:
                        return [model, Toast_errorToast("AnalysisForm does not exist")];
                }
            }
            else {
                const patternInput_1 = updateAnalysis(token, msg_35, matchValue_5);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_1[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((Item_15) => (new Msg(4, [Item_15])), patternInput_1[1])];
            }
        }
        case 15: {
            const matchValue_6 = model.S3Context;
            const matchValue_7 = model.AnalysisFormComponent;
            const matchValue_8 = model.PatientInstitution;
            let matchResult_4, analysisModel, context, patientInstitution;
            if (matchValue_6 == null) {
                matchResult_4 = 2;
            }
            else if (matchValue_7 != null) {
                if (matchValue_8 != null) {
                    matchResult_4 = 0;
                    analysisModel = matchValue_7;
                    context = matchValue_6;
                    patientInstitution = matchValue_8;
                }
                else {
                    matchResult_4 = 1;
                }
            }
            else {
                matchResult_4 = 1;
            }
            switch (matchResult_4) {
                case 0: {
                    const maybeUiFileManagementVm = tryPick((_arg, vm) => vm, analysisModel.UiDataFileManager.fileUpload.DownloadableFiles);
                    const matchValue_10 = patientInstitution.clinicalDesignation;
                    if (maybeUiFileManagementVm == null) {
                        return [model, Toast_errorToast("Missing Visualization data files, please contact ViTAA support")];
                    }
                    else if (matchValue_10 == null) {
                        return [model, Toast_errorToast("Missing Institution Designation")];
                    }
                    else {
                        const designation = matchValue_10;
                        const uiFileManagementVm = maybeUiFileManagementVm;
                        const patternInput_2 = init_1(context, model.Studies, model.PatientGuid, selectedStudy, uiFileManagementVm.Name, designation, model.MaybeCurrentUser);
                        return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, patternInput_2[0], model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((arg_5) => (new Msg(12, [wrapLocalMsg_1(arg_5)])), patternInput_2[1]), (msg_38 = (new Msg(12, [wrapInboundMsg(new InboundMsg_2(1, []))])), singleton((dispatch_15) => {
                            dispatch_15(msg_38);
                        }))]))];
                    }
                }
                case 1:
                    return [model, Toast_errorToast("Missing Analysis data, please contact ViTAA support")];
                default:
                    return [model, Toast_errorToast("Missing S3 Context")];
            }
        }
        case 16:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, true, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(token).tryGetAnalysis, studyId_3, (Item_18) => (new Msg(8, [Item_18])), (Item_19) => (new Msg(0, [Item_19])))];
        case 17:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, false, model.AnalysisFormComponent, analysis_1, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), singleton((dispatch_16) => {
                dispatch_16(new Msg(10, [analysis_1]));
            })];
        case 18:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, false, model.AnalysisFormComponent, undefined, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        case 19: {
            const matchValue_12 = model.StudyGuid;
            if (matchValue_12 == null) {
                return [model, Toast_errorToast("No study selected")];
            }
            else {
                return [model, (msg_41 = (new Msg(11, [matchValue_12])), singleton((dispatch_17) => {
                    dispatch_17(msg_41);
                }))];
            }
        }
        case 20: {
            const activeStudy_1 = tryFind((study_2) => defaultArg(map((activeGuid) => (study_2.studyId === activeGuid), model.StudyGuid), false), model.Studies);
            const userRoles = defaultArg(map((user) => user.roles, model.MaybeCurrentUser), empty());
            const matchValue_13 = model.PatientInstitution;
            const matchValue_14 = model.S3Context;
            if (activeStudy_1 != null) {
                if (matchValue_13 == null) {
                    return [model, Toast_errorToast("No patient institution found.")];
                }
                else if (matchValue_14 == null) {
                    const patInst_1 = matchValue_13;
                    const study_4 = activeStudy_1;
                    const patternInput_4 = initWithDisplay(analysis_2, new PatientInfo(model.PatientGuid, patInst_1), study_4, patInst_1, userRoles);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_4[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((Item_26) => (new Msg(4, [Item_26])), patternInput_4[1])];
                }
                else {
                    const context_1 = matchValue_14;
                    const patInst = matchValue_13;
                    const study_3 = activeStudy_1;
                    const patternInput_3 = initWithDisplay(analysis_2, new PatientInfo(model.PatientGuid, patInst), study_3, patInst, userRoles);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_3[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((Item_20) => (new Msg(4, [Item_20])), patternInput_3[1]), (msg_43 = (new Msg(4, [new MsgAnalysis(0, [new InternalMsg_1(1, [(tupledArg = [model.Credentials, context_1], new InboundMsg_3(2, [tupledArg[0], tupledArg[1]]))])])])), singleton((dispatch_18) => {
                        dispatch_18(msg_43);
                    }))]))];
                }
            }
            else {
                return [model, Toast_errorToast("No active study found.")];
            }
        }
        case 21: {
            const activeStudy_2 = tryFind((study_5) => defaultArg(map((activeGuid_1) => (study_5.studyId === activeGuid_1), model.StudyGuid), false), model.Studies);
            const userRoles_1 = defaultArg(map((user_1) => user_1.roles, model.MaybeCurrentUser), empty());
            const matchValue_16 = model.PatientInstitution;
            const matchValue_17 = model.S3Context;
            if (activeStudy_2 != null) {
                if (matchValue_16 == null) {
                    return [model, Toast_errorToast("No patient institution found.")];
                }
                else if (matchValue_17 == null) {
                    const patInst_3 = matchValue_16;
                    const study_7 = activeStudy_2;
                    const patternInput_6 = initWithCreate(new PatientInfo(model.PatientGuid, patInst_3), study_7, patInst_3, userRoles_1);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_6[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((Item_33) => (new Msg(4, [Item_33])), patternInput_6[1])];
                }
                else {
                    const context_2 = matchValue_17;
                    const patInst_2 = matchValue_16;
                    const study_6 = activeStudy_2;
                    const patternInput_5 = initWithCreate(new PatientInfo(model.PatientGuid, patInst_2), study_6, patInst_2, userRoles_1);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_5[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((Item_27) => (new Msg(4, [Item_27])), patternInput_5[1]), (msg_45 = (new Msg(4, [new MsgAnalysis(0, [new InternalMsg_1(1, [(tupledArg_1 = [model.Credentials, context_2], new InboundMsg_3(2, [tupledArg_1[0], tupledArg_1[1]]))])])])), singleton((dispatch_19) => {
                        dispatch_19(msg_45);
                    }))]))];
                }
            }
            else {
                return [model, Toast_errorToast("No active study found.")];
            }
        }
        case 22: {
            const matchValue_19 = model.VisualizationComponent;
            if (matchValue_19 == null) {
                return [model, Toast_errorToast("Visualization does not exist")];
            }
            else {
                const patternInput_7 = updateVisualization(token, msg_47, matchValue_19);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, patternInput_7[0], model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((Item_34) => (new Msg(12, [Item_34])), patternInput_7[1])];
            }
        }
        case 23:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, undefined, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        case 24: {
            let matchResult_5, analysis_3, institution, patient;
            if (model.SelectedPatient != null) {
                if (model.PatientInstitution != null) {
                    if (model.SelectedAnalysis != null) {
                        matchResult_5 = 0;
                        analysis_3 = model.SelectedAnalysis;
                        institution = model.PatientInstitution;
                        patient = model.SelectedPatient;
                    }
                    else {
                        matchResult_5 = 1;
                    }
                }
                else {
                    matchResult_5 = 1;
                }
            }
            else {
                matchResult_5 = 1;
            }
            switch (matchResult_5) {
                case 0: {
                    const patternInput_8 = init_2(new CustomReportInitArgs(selectedStudy_1, patient, institution, analysis_3, customReportData.geometryData, customReportData.growthData, customReportData.maybeCurrentStudyAcquisitionDate, customReportData.maybePreviousStudyAcquisitionDate));
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, patternInput_8[0], model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_7) => (new Msg(13, [wrapLocalMsg_1(arg_7)])), patternInput_8[1])];
                }
                default:
                    return [model, Toast_errorToast("Unable to create custom report")];
            }
        }
        case 25: {
            const matchValue_20 = model.CustomReportComponent;
            const matchValue_21 = model.S3Context;
            const matchValue_22 = model.CustomReportModel;
            if (matchValue_20 == null) {
                return [model, Cmd_none()];
            }
            else if (matchValue_21 == null) {
                return [model, Toast_errorToast("S3 context does not exist")];
            }
            else if (matchValue_22 == null) {
                return [model, Cmd_none()];
            }
            else {
                const customReportComponent = matchValue_20;
                const customReportModel = matchValue_22;
                const s3Context = matchValue_21;
                const patternInput_9 = update_1(new CustomReportUpdateArgs(customReportModel, new FileManagementUpdateArgs(token, model.Credentials, s3Context), defaultArg(map((user_2) => user_2.roles, model.MaybeCurrentUser), empty())), msg_49, customReportComponent);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, patternInput_9[0], model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((Item_36) => (new Msg(13, [Item_36])), patternInput_9[1])];
            }
        }
        case 26:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, data, model.Credentials, model.S3Context), Cmd_none()];
        case 27:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, undefined, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        case 28:
            if (defaultArg(map((user_3) => isAllowed(new Permission(18, []))(user_3.roles), model.MaybeCurrentUser), false)) {
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, CustomReportViewModel_empty_244AC511(studyId_4), model.Credentials, model.S3Context), Cmd_OfAsyncWith_either((x_4) => {
                    Cmd_OfAsync_start(x_4);
                }, securedApi(token).getCustomReport, studyId_4, (Item_37) => (new Msg(15, [Item_37])), (Item_38) => (new Msg(0, [Item_38])))];
            }
            else {
                return [model, Cmd_none()];
            }
        case 29:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, data_1, model.Credentials, model.S3Context), Cmd_none()];
        case 30:
            return [model, Cmd_none()];
        case 31:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(err))];
        case 32:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, map((crm) => (new CustomReportViewModel(crm.studyId, crm.maybeConclusion, crm.maybeLastReportPath, crm.maybeLastReportDate, CustomReportStatus_isNowOutdated_6FCE9E49(crm.maybeLastReportOutdated)(status))), model.CustomReportModel), model.Credentials, model.S3Context), Cmd_none()];
        case 33: {
            const model_2 = new Model(userInfo, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            if (model_2.StudyGuid != null) {
                return [model_2, (msg_50 = (new Msg(26, [model_2.StudyGuid])), singleton((dispatch_20) => {
                    dispatch_20(msg_50);
                }))];
            }
            else {
                return [model_2, Cmd_none()];
            }
        }
        case 34:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, cognitoCredentials, s3Context_1), Cmd_batch(toList(delay_1(() => {
                let msg_52, tupledArg_2;
                return append((model.CoreFormComponent != null) ? singleton_1((msg_52 = (new Msg(1, [new MsgCore(0, [new InternalMsg(1, [(tupledArg_2 = [cognitoCredentials, s3Context_1], new InboundMsg_1(1, [tupledArg_2[0], tupledArg_2[1]]))])])])), singleton((dispatch_21) => {
                    dispatch_21(msg_52);
                }))) : empty_1(), delay_1(() => {
                    let msg_54, tupledArg_3;
                    return append((model.AnalysisFormComponent != null) ? singleton_1((msg_54 = (new Msg(4, [new MsgAnalysis(0, [new InternalMsg_1(1, [(tupledArg_3 = [cognitoCredentials, s3Context_1], new InboundMsg_3(2, [tupledArg_3[0], tupledArg_3[1]]))])])])), singleton((dispatch_22) => {
                        dispatch_22(msg_54);
                    }))) : empty_1(), delay_1(() => {
                        let msg_57, tupledArg_4;
                        return (model.VisualizationComponent != null) ? singleton_1((msg_57 = (new Msg(12, [wrapInboundMsg((tupledArg_4 = [cognitoCredentials, s3Context_1], new InboundMsg_2(0, [tupledArg_4[0], tupledArg_4[1]])))])), singleton((dispatch_23) => {
                            dispatch_23(msg_57);
                        }))) : empty_1();
                    }));
                }));
            })))];
        case 35:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, pid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, true, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, securedApi(token).getPatientStudies, model.PatientGuid, (Item_52) => (new Msg(18, [Item_52])), (Item_53) => (new Msg(0, [Item_53])))];
        case 36: {
            const model_3 = new Model(model.MaybeCurrentUser, studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, false, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            let matchResult_6, sid_1, sid_2, patient_1;
            if (model_3.StudyGuid != null) {
                if (model_3.CoreFormComponent == null) {
                    matchResult_6 = 1;
                    sid_2 = model_3.StudyGuid;
                }
                else {
                    matchResult_6 = 0;
                    sid_1 = model_3.StudyGuid;
                }
            }
            else if (model_3.CoreFormComponent != null) {
                if (model_3.CoreFormComponent.Mode.tag === 1) {
                    matchResult_6 = 2;
                }
                else if (model_3.SelectedPatient != null) {
                    matchResult_6 = 3;
                    patient_1 = model_3.SelectedPatient;
                }
                else {
                    matchResult_6 = 4;
                }
            }
            else if (model_3.SelectedPatient != null) {
                matchResult_6 = 3;
                patient_1 = model_3.SelectedPatient;
            }
            else {
                matchResult_6 = 4;
            }
            switch (matchResult_6) {
                case 0: {
                    const updatedStudy = tryFind((study_8) => (study_8.studyId === sid_1), studies);
                    if (updatedStudy == null) {
                        return [new Model(model_3.MaybeCurrentUser, studies, model_3.Patients, model_3.Institutions, model_3.PatientGuid, model_3.SelectedPatient, model_3.PatientInstitution, model_3.StudyGuid, model_3.CoreFormComponent, model_3.isStudiesLoading, model_3.isAnalysisLoading, model_3.AnalysisFormComponent, model_3.SelectedAnalysis, model_3.VisualizationComponent, model_3.CustomReportComponent, model_3.CustomReportModel, model_3.Credentials, model_3.S3Context), Cmd_none()];
                    }
                    else {
                        const study_9 = updatedStudy;
                        const maybeAnalysisCommand = (model_3.AnalysisFormComponent != null) ? singleton(singleton((dispatch_24) => {
                            dispatch_24(new Msg(4, [new MsgAnalysis(0, [new InternalMsg_1(1, [new InboundMsg_3(1, [study_9])])])]));
                        })) : empty();
                        return [model_3, Cmd_batch(toList(delay_1(() => append(singleton_1(singleton((dispatch_25) => {
                            dispatch_25(new Msg(1, [new MsgCore(0, [new InternalMsg(1, [new InboundMsg_1(0, [study_9])])])]));
                        })), delay_1(() => {
                            let msg_65;
                            return append(singleton_1((msg_65 = (new Msg(1, [wrapLocalMsg(new LocalMsg(2, [wrapInboundMsg(new InboundMsg(1, [study_9.status]))]))])), singleton((dispatch_26) => {
                                dispatch_26(msg_65);
                            }))), delay_1(() => maybeAnalysisCommand));
                        })))))];
                    }
                }
                case 1:
                    return [model_3, singleton((dispatch_27) => {
                        dispatch_27(new Msg(26, [sid_2]));
                    })];
                case 2:
                    return [model_3, Cmd_none()];
                case 3:
                    return [new Model(model_3.MaybeCurrentUser, studies, model_3.Patients, model_3.Institutions, model_3.PatientGuid, model_3.SelectedPatient, model_3.PatientInstitution, model_3.StudyGuid, model_3.CoreFormComponent, model_3.isStudiesLoading, model_3.isAnalysisLoading, model_3.AnalysisFormComponent, model_3.SelectedAnalysis, model_3.VisualizationComponent, model_3.CustomReportComponent, model_3.CustomReportModel, model_3.Credentials, model_3.S3Context), singleton((dispatch_28) => {
                        dispatch_28(new Msg(21, [patient_1.id]));
                    })];
                default:
                    return [model_3, Cmd_none()];
            }
        }
        case 37:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, false, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Toast_errorToast(ErrorMessage_get_describe()(e))];
        case 38: {
            const updatedStudy_1 = tryFind((study_10) => (study_10.studyId === sid_3), model.Studies);
            let matchResult_7, _userInfo, patientInst, study_11;
            if (model.MaybeCurrentUser != null) {
                if (model.PatientInstitution != null) {
                    if (updatedStudy_1 != null) {
                        matchResult_7 = 0;
                        _userInfo = model.MaybeCurrentUser;
                        patientInst = model.PatientInstitution;
                        study_11 = updatedStudy_1;
                    }
                    else {
                        matchResult_7 = 1;
                    }
                }
                else {
                    matchResult_7 = 1;
                }
            }
            else {
                matchResult_7 = 1;
            }
            switch (matchResult_7) {
                case 0:
                    return [model, Cmd_batch(ofArray([singleton((dispatch_29) => {
                        dispatch_29(new Msg(27, [study_11, patientInst]));
                    }), singleton((dispatch_30) => {
                        dispatch_30(new Msg(7, [sid_3]));
                    })]))];
                default:
                    return [model, Cmd_none()];
            }
        }
        case 39: {
            const matchValue_25 = model.S3Context;
            const matchValue_26 = model.MaybeCurrentUser;
            let matchResult_8, context_3, role, role_1;
            if (matchValue_25 == null) {
                if (matchValue_26 != null) {
                    if (!isEmpty(matchValue_26.roles)) {
                        if (isEmpty(tail(matchValue_26.roles))) {
                            matchResult_8 = 1;
                            role_1 = head(matchValue_26.roles);
                        }
                        else {
                            matchResult_8 = 2;
                        }
                    }
                    else {
                        matchResult_8 = 2;
                    }
                }
                else {
                    matchResult_8 = 2;
                }
            }
            else if (matchValue_26 != null) {
                if (!isEmpty(matchValue_26.roles)) {
                    if (isEmpty(tail(matchValue_26.roles))) {
                        matchResult_8 = 0;
                        context_3 = matchValue_25;
                        role = head(matchValue_26.roles);
                    }
                    else {
                        matchResult_8 = 2;
                    }
                }
                else {
                    matchResult_8 = 2;
                }
            }
            else {
                matchResult_8 = 2;
            }
            switch (matchResult_8) {
                case 0: {
                    const patternInput_10 = initWithDisplay_1(model.CoreFormComponent, study_12, patientInstitution_1, role);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, patternInput_10[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((Item_65) => (new Msg(1, [Item_65])), patternInput_10[1]), (msg_75 = (new Msg(1, [new MsgCore(0, [new InternalMsg(1, [(tupledArg_5 = [model.Credentials, context_3], new InboundMsg_1(1, [tupledArg_5[0], tupledArg_5[1]]))])])])), singleton((dispatch_31) => {
                        dispatch_31(msg_75);
                    })), singleton((dispatch_32) => {
                        dispatch_32(new Msg(14, [study_12.studyId]));
                    })]))];
                }
                case 1: {
                    const patternInput_11 = initWithDisplay_1(model.CoreFormComponent, study_12, patientInstitution_1, role_1);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, patternInput_11[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((Item_71) => (new Msg(1, [Item_71])), patternInput_11[1]), singleton((dispatch_33) => {
                        dispatch_33(new Msg(14, [study_12.studyId]));
                    })]))];
                }
                default:
                    return [model, Toast_errorToast("No user role found.")];
            }
        }
        case 40: {
            const matchValue_28 = model.PatientInstitution;
            const matchValue_29 = model.S3Context;
            const matchValue_30 = model.MaybeCurrentUser;
            let matchResult_9, context_4, patInst_4, role_2, patInst_5, role_3;
            if (matchValue_28 == null) {
                if (matchValue_30 != null) {
                    if (!isEmpty(matchValue_30.roles)) {
                        if (isEmpty(tail(matchValue_30.roles))) {
                            matchResult_9 = 2;
                        }
                        else {
                            matchResult_9 = 3;
                        }
                    }
                    else {
                        matchResult_9 = 3;
                    }
                }
                else {
                    matchResult_9 = 3;
                }
            }
            else if (matchValue_29 == null) {
                if (matchValue_30 != null) {
                    if (!isEmpty(matchValue_30.roles)) {
                        if (isEmpty(tail(matchValue_30.roles))) {
                            matchResult_9 = 1;
                            patInst_5 = matchValue_28;
                            role_3 = head(matchValue_30.roles);
                        }
                        else {
                            matchResult_9 = 3;
                        }
                    }
                    else {
                        matchResult_9 = 3;
                    }
                }
                else {
                    matchResult_9 = 3;
                }
            }
            else if (matchValue_30 != null) {
                if (!isEmpty(matchValue_30.roles)) {
                    if (isEmpty(tail(matchValue_30.roles))) {
                        matchResult_9 = 0;
                        context_4 = matchValue_29;
                        patInst_4 = matchValue_28;
                        role_2 = head(matchValue_30.roles);
                    }
                    else {
                        matchResult_9 = 3;
                    }
                }
                else {
                    matchResult_9 = 3;
                }
            }
            else {
                matchResult_9 = 3;
            }
            switch (matchResult_9) {
                case 0: {
                    const patternInput_12 = initWithCreate_1(new PatientInfo_1(patientId, patInst_4), model.Studies, role_2);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, undefined, patternInput_12[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Navigation_modifyUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [patientId])]))), Cmd_map((Item_72) => (new Msg(1, [Item_72])), patternInput_12[1]), (msg_81 = (new Msg(1, [new MsgCore(0, [new InternalMsg(1, [(tupledArg_6 = [model.Credentials, context_4], new InboundMsg_1(1, [tupledArg_6[0], tupledArg_6[1]]))])])])), singleton((dispatch_34) => {
                        dispatch_34(msg_81);
                    }))]))];
                }
                case 1: {
                    const patternInput_13 = initWithCreate_1(new PatientInfo_1(patientId, patInst_5), model.Studies, role_3);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, undefined, patternInput_13[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Navigation_modifyUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [patientId])]))), Cmd_map((Item_78) => (new Msg(1, [Item_78])), patternInput_13[1])]))];
                }
                case 2:
                    return [model, Toast_errorToast("No patient institution found.")];
                default:
                    return [model, Toast_errorToast("No user role found.")];
            }
        }
        case 41:
            return [model, singleton((dispatch_35) => {
                dispatch_35(new Msg(28, [model.PatientGuid]));
            })];
        case 42: {
            const model_4 = new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, toSelect, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            return [model_4, Cmd_batch(ofArray([singleton((dispatch_36) => {
                dispatch_36(new Msg(26, [toSelect]));
            }), singleton((dispatch_37) => {
                dispatch_37(new Msg(21, [model_4.PatientGuid]));
            })]))];
        }
        case 43: {
            let matchResult_10;
            if (model.CoreFormComponent != null) {
                switch (model.CoreFormComponent.Mode.tag) {
                    case 1:
                    case 2: {
                        matchResult_10 = 0;
                        break;
                    }
                    default:
                        if (model.AnalysisFormComponent != null) {
                            switch (model.AnalysisFormComponent.Mode.tag) {
                                case 1:
                                case 2: {
                                    matchResult_10 = 0;
                                    break;
                                }
                                default:
                                    matchResult_10 = 1;
                            }
                        }
                        else {
                            matchResult_10 = 1;
                        }
                }
            }
            else if (model.AnalysisFormComponent != null) {
                switch (model.AnalysisFormComponent.Mode.tag) {
                    case 1:
                    case 2: {
                        matchResult_10 = 0;
                        break;
                    }
                    default:
                        matchResult_10 = 1;
                }
            }
            else {
                matchResult_10 = 1;
            }
            switch (matchResult_10) {
                case 0:
                    return [model, Toast_errorToast("Study modification is in progress. Please complete or cancel to navigate away.")];
                default:
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, toSelect_1.studyId, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(singleton(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(2, [[toSelect_1.patientId, toSelect_1.studyId]])])))))];
            }
        }
        case 44: {
            const studyPatient = tryFind((patient_2) => (patient_2.id === toSelect_2), model.Patients);
            const model_5 = new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, toSelect_2, studyPatient, bind((patient_3) => tryFind((inst) => (inst.id === patient_3.institutionId), model.Institutions), studyPatient), model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            const matchValue_32 = model_5.StudyGuid;
            if (matchValue_32 != null) {
                if (model_5.CoreFormComponent == null) {
                    const studyId_5 = matchValue_32;
                    return [model_5, singleton((dispatch_38) => {
                        dispatch_38(new Msg(26, [studyId_5]));
                    })];
                }
                else {
                    return [model_5, Cmd_none()];
                }
            }
            else {
                return [new Model(model_5.MaybeCurrentUser, model_5.Studies, model_5.Patients, model_5.Institutions, model_5.PatientGuid, model_5.SelectedPatient, model_5.PatientInstitution, model_5.StudyGuid, undefined, model_5.isStudiesLoading, model_5.isAnalysisLoading, model_5.AnalysisFormComponent, model_5.SelectedAnalysis, model_5.VisualizationComponent, model_5.CustomReportComponent, model_5.CustomReportModel, model_5.Credentials, model_5.S3Context), Cmd_none()];
            }
        }
        case 45:
            return [new Model(model.MaybeCurrentUser, empty(), model.Patients, model.Institutions, pid_1, undefined, undefined, undefined, undefined, false, false, undefined, undefined, undefined, undefined, undefined, model.Credentials, model.S3Context), Cmd_batch(ofArray([singleton((dispatch_39) => {
                dispatch_39(new Msg(17, [pid_1]));
            }), singleton((dispatch_40) => {
                dispatch_40(new Msg(21, [pid_1]));
            })]))];
        case 46:
            return [new Model(model.MaybeCurrentUser, model.Studies, patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), singleton((dispatch_41) => {
                dispatch_41(new Msg(21, [model.PatientGuid]));
            })];
        case 47:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), singleton((dispatch_42) => {
                dispatch_42(new Msg(21, [model.PatientGuid]));
            })];
        case 48:
            return [model, Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(0, [])])))];
        case 49:
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, false, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Toast_errorToast(ErrorMessage_get_describe()(e_1))];
        case 50:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_2))];
        case 51: {
            toConsole(printf("Got updated study notification"));
            return [model, singleton((dispatch_43) => {
                dispatch_43(new Msg(17, [model.PatientGuid]));
            })];
        }
        default:
            return [model, Cmd_none()];
    }
}

