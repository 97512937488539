import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, bool_type, option_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotEmptyGuid } from "../../fable_modules/Webbler.Models.1.3.1/Validation.fs.js";
import { ErrorMessage } from "../ErrorMessage.js";

export class CustomReportViewModel extends Record {
    constructor(studyId, maybeConclusion, maybeLastReportPath, maybeLastReportDate, maybeLastReportOutdated) {
        super();
        this.studyId = studyId;
        this.maybeConclusion = maybeConclusion;
        this.maybeLastReportPath = maybeLastReportPath;
        this.maybeLastReportDate = maybeLastReportDate;
        this.maybeLastReportOutdated = maybeLastReportOutdated;
    }
}

export function CustomReportViewModel_$reflection() {
    return record_type("RAWMap.Models.View.CustomReport.CustomReportViewModel", [], CustomReportViewModel, () => [["studyId", class_type("System.Guid")], ["maybeConclusion", option_type(string_type)], ["maybeLastReportPath", option_type(string_type)], ["maybeLastReportDate", option_type(class_type("System.DateTimeOffset"))], ["maybeLastReportOutdated", option_type(bool_type)]]);
}

export function CustomReportViewModel_get_studyId_() {
    return [(m) => m.studyId, (v) => ((m_1) => (new CustomReportViewModel(v, m_1.maybeConclusion, m_1.maybeLastReportPath, m_1.maybeLastReportDate, m_1.maybeLastReportOutdated)))];
}

export function CustomReportViewModel_get_maybeConclusion_() {
    return [(m) => m.maybeConclusion, (v) => ((m_1) => (new CustomReportViewModel(m_1.studyId, v, m_1.maybeLastReportPath, m_1.maybeLastReportDate, m_1.maybeLastReportOutdated)))];
}

export function CustomReportViewModel_empty_244AC511(studyId) {
    return new CustomReportViewModel(studyId, undefined, undefined, undefined, undefined);
}

export function CustomReportViewModel_validate_Z39AF19B2(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["StudyId", "not be empty Guid"]), vm.studyId), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))));
}

export class CustomReportConclusionViewModel extends Record {
    constructor(studyId, maybeConclusion) {
        super();
        this.studyId = studyId;
        this.maybeConclusion = maybeConclusion;
    }
}

export function CustomReportConclusionViewModel_$reflection() {
    return record_type("RAWMap.Models.View.CustomReport.CustomReportConclusionViewModel", [], CustomReportConclusionViewModel, () => [["studyId", class_type("System.Guid")], ["maybeConclusion", option_type(string_type)]]);
}

export function CustomReportConclusionViewModel_validate_Z895A113(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["StudyId", "not be empty Guid"]), vm.studyId), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))));
}

export function CustomReportConclusionViewModel_ofFull_Z39AF19B2(model) {
    return new CustomReportConclusionViewModel(model.studyId, model.maybeConclusion);
}

export class CustomReportMetadataViewModel extends Record {
    constructor(studyId, maybeLastReportPath, maybeLastReportDate, maybeLastReportOutdated) {
        super();
        this.studyId = studyId;
        this.maybeLastReportPath = maybeLastReportPath;
        this.maybeLastReportDate = maybeLastReportDate;
        this.maybeLastReportOutdated = maybeLastReportOutdated;
    }
}

export function CustomReportMetadataViewModel_$reflection() {
    return record_type("RAWMap.Models.View.CustomReport.CustomReportMetadataViewModel", [], CustomReportMetadataViewModel, () => [["studyId", class_type("System.Guid")], ["maybeLastReportPath", option_type(string_type)], ["maybeLastReportDate", option_type(class_type("System.DateTimeOffset"))], ["maybeLastReportOutdated", option_type(bool_type)]]);
}

export function CustomReportMetadataViewModel_validate_Z51117B9D(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["StudyId", "not be empty Guid"]), vm.studyId), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))));
}

export function CustomReportMetadataViewModel_ofFull_Z39AF19B2(model) {
    return new CustomReportMetadataViewModel(model.studyId, model.maybeLastReportPath, model.maybeLastReportDate, model.maybeLastReportOutdated);
}

export class CustomReportStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Outdated", "UpToDate"];
    }
}

export function CustomReportStatus_$reflection() {
    return union_type("RAWMap.Models.View.CustomReport.CustomReportStatus", [], CustomReportStatus, () => [[], []]);
}

export function CustomReportStatus_isNowOutdated_6FCE9E49(wasOutdated) {
    return (_arg) => ((_arg.tag === 1) ? wasOutdated : true);
}

