import { Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { float64_type, record_type, option_type, class_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { AsyncResult_ofSuccess, AsyncResultComputationExpression_AsyncResultBuilder__Zero, AsyncResult_ofError, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7, AsyncResult_ofAsync, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { securedApi } from "../../../../Api.js";
import { ErrorMessage } from "../../../../RAWMap.Models/ErrorMessage.js";
import { AsyncResult_ofPromise } from "../../../../Common/Extensions.js";
import { empty, ofArray, map } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../../../../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create } from "../../../../bindings/Fable.Helpers.Aws.js";
import { value as value_2, some } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { VisualizationScreenshotViewModel_$reflection, VisualizationScreenshotViewModel } from "../../../../RAWMap.Models/View/VisualizationScreenshot.js";
import { awaitPromise } from "../../../../fable_modules/fable-library-js.4.19.3/Async.js";
import { fetch$ } from "../../../../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { Shared_pixelsToMm } from "../../../../Visualization/VisualizationShared.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Adv_makeTitle, Pdf_Spacing_baseSpacingY, Pdf_Spacing_narrowSpacingY, Pdf_Spacing_leftMargin, Pdf_Images_Adv_addImage } from "../ReportPdfHelpers.js";
import { sortBy, item } from "../../../../fable_modules/fable-library-js.4.19.3/Array.js";
import { replace } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { comparePrimitives } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { constants } from "../../../../../../src/RAWMap.Client/Visualization/js/Common.js";

export class Args extends Record {
    constructor(currentStudy, maybePreviousStudy) {
        super();
        this.currentStudy = currentStudy;
        this.maybePreviousStudy = maybePreviousStudy;
    }
}

export function Args_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.ScreenshotSection.Args", [], Args, () => [["currentStudy", class_type("System.Guid")], ["maybePreviousStudy", option_type(class_type("System.Guid"))]]);
}

export function getScreenshots(uniArgs, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, securedApi(uniArgs.token).getVisualizationScreenshotsForStudy(args.currentStudy), (_arg) => {
        let pr;
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((Item) => (new ErrorMessage(1, [Item])), AsyncResult_ofPromise()((pr = map((s) => PromiseBuilder__Run_212F1D4B(promise_1, PromiseBuilder__Delay_62FBFDE1(promise_1, () => {
            let tupledArg;
            return ((tupledArg = ["getObject", some(S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, [uniArgs.s3Context.ActiveBucket]), new S3_PutObjectRequestParams(3, [s.imagePath])])))], uniArgs.s3Context.S3Client.getSignedUrlPromise(tupledArg[0], tupledArg[1]))).then((_arg_1) => (Promise.resolve(new VisualizationScreenshotViewModel(s.studyId, s.screenshotId, s.description, s.imagePath, _arg_1))));
        })), _arg), Promise.all(pr)))), (_arg_2) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg_2));
    }));
}

export const screenshotsPerPage = 2;

export function addImage(uniArgs, args, startY, image, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(PromiseBuilder__Run_212F1D4B(promise_1, PromiseBuilder__Delay_62FBFDE1(promise_1, () => (fetch$(value_2(image.imageUrl), empty()).then((_arg) => (_arg.arrayBuffer()))))))), (_arg_1) => {
        const fileArray = _arg_1;
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.getWidth();
        const pageHeight = pageSize.getHeight();
        const processed = doc.processPNG(fileArray, 0, image.description, "NONE");
        const pixelWidth = processed.width;
        const pixelHeight = processed.height;
        return AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7(AsyncResultComputationExpression_asyncResult, ((pageWidth > pixelWidth) ? true : (pageHeight > pixelHeight)) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofError(new ErrorMessage(6, ["actual screenshot dimensions", "fit on the page"]))) : AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult), AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
            const mmWidth = Shared_pixelsToMm(pixelWidth);
            const mmHeight = Shared_pixelsToMm(pixelHeight);
            return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [startY + mmHeight, Pdf_Images_Adv_addImage(fileArray, (mmWidth < pageWidth) ? ((pageWidth - mmWidth) / 2) : 0, startY, mmWidth, mmHeight, doc)]);
        }));
    }));
}

export class AddToDocArgs extends Record {
    constructor(image, maxPageHeight, maxPageWidth, screenshotHeight, restartY, lineHeight) {
        super();
        this.image = image;
        this.maxPageHeight = maxPageHeight;
        this.maxPageWidth = maxPageWidth;
        this.screenshotHeight = screenshotHeight;
        this.restartY = restartY;
        this.lineHeight = lineHeight;
    }
}

export function AddToDocArgs_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.ScreenshotSection.AddToDocArgs", [], AddToDocArgs, () => [["image", VisualizationScreenshotViewModel_$reflection()], ["maxPageHeight", float64_type], ["maxPageWidth", float64_type], ["screenshotHeight", float64_type], ["restartY", float64_type], ["lineHeight", float64_type]]);
}

export function addSingleScreenshotText(uniArgs, args, docArgs, i, splitText, currentY, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        let currentY_1;
        if ((currentY + docArgs.lineHeight) > docArgs.maxPageHeight) {
            doc.addPage();
            currentY_1 = docArgs.restartY;
        }
        else {
            currentY_1 = currentY;
        }
        const doc_1 = doc.text(item(i, splitText), Pdf_Spacing_leftMargin, currentY_1);
        const i_1 = (i + 1) | 0;
        const currentY_2 = currentY_1 + docArgs.lineHeight;
        return (i_1 < splitText.length) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addSingleScreenshotText(uniArgs, args, docArgs, i_1, splitText, currentY_2, doc_1)) : AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [currentY_2, doc_1]);
    });
}

export function addAllScreenshots(uniArgs, args, docArgs, i, currentY, images, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const docArgs_1 = new AddToDocArgs(item(i, images), docArgs.maxPageHeight, docArgs.maxPageWidth, docArgs.screenshotHeight, docArgs.restartY, docArgs.lineHeight);
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, addImage(uniArgs, args, (((currentY + Pdf_Spacing_narrowSpacingY) + docArgs_1.screenshotHeight) > docArgs_1.maxPageHeight) ? ((void doc.addPage(), docArgs_1.restartY)) : (currentY + Pdf_Spacing_narrowSpacingY), docArgs_1.image, doc), (_arg) => {
            let spacedDescription;
            const doc_1 = _arg[1];
            const currentY_3 = _arg[0] + ((Pdf_Spacing_narrowSpacingY + Pdf_Spacing_baseSpacingY) / 2);
            return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, (docArgs_1.image.description.length > 0) ? ((spacedDescription = replace(replace(docArgs_1.image.description, "\n\n", "\n"), "\n", "\n\n"), addSingleScreenshotText(uniArgs, args, docArgs_1, 0, doc_1.splitTextToSize(spacedDescription, docArgs_1.maxPageWidth), currentY_3, doc_1))) : AsyncResult_ofSuccess([currentY_3, doc_1]), (_arg_1) => {
                const doc_2 = _arg_1[1];
                const i_1 = (i + 1) | 0;
                return (i_1 < images.length) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addAllScreenshots(uniArgs, args, docArgs_1, i_1, _arg_1[0], images, doc_2)) : AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, doc_2);
            });
        });
    });
}

export function addImages(uniArgs, args, images, startY, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        if (images.length > 0) {
            const images_1 = sortBy((image) => image.description, images, {
                Compare: comparePrimitives,
            });
            const doc_2 = Pdf_Table_Adv_makeTitle("Captured Screenshots", Pdf_Spacing_topMargin, doc.addPage());
            const pageSize = doc_2.internal.pageSize;
            const pageHeight = pageSize.getHeight();
            const maxWidth = pageSize.getWidth() - (2 * Pdf_Spacing_leftMargin);
            const maxHeight = pageHeight - Pdf_Spacing_topMargin;
            return AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addAllScreenshots(uniArgs, args, new AddToDocArgs(item(0, images_1), maxHeight, maxWidth, Shared_pixelsToMm(constants.screenshotSize.height), Pdf_Spacing_topMargin, Pdf_Spacing_narrowSpacingY), 0, startY, images_1, doc_2));
        }
        else {
            return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, doc);
        }
    });
}

export function create(doc, uniArgs, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, getScreenshots(uniArgs, args), (_arg) => {
        const previousFont = doc.getFontSize();
        doc.setFontSize(12);
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, addImages(uniArgs, args, _arg, Pdf_Spacing_topMargin + Pdf_Spacing_baseSpacingY, doc), (_arg_1) => {
            const doc_1 = _arg_1;
            doc_1.setFontSize(previousFont);
            return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, doc_1);
        });
    }));
}

