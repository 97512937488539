import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { StudyViewModel_$reflection } from "../../RAWMap.Models/View/Study.js";
import { PatientViewModel_$reflection } from "../../RAWMap.Models/View/Patient.js";
import { InstitutionViewModel_$reflection } from "../../RAWMap.Models/View/Institution.js";
import { AnalysisViewModel_$reflection } from "../../RAWMap.Models/View/Analysis.js";
import { class_type, union_type, record_type, option_type, list_type, tuple_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { CustomReportStatus_$reflection, CustomReportViewModel_$reflection } from "../../RAWMap.Models/View/CustomReport.js";
import { FileManagementUpdateArgs_$reflection } from "../../FileManagement/FileManagementTypes.js";
import { UserRole_$reflection } from "../../RAWMap.Models/Security.js";
import { FSharpChoice$2 } from "../../fable_modules/fable-library-js.4.19.3/Choice.js";
import { OutboundMsg_$reflection as OutboundMsg_$reflection_1, InboundMsg_$reflection as InboundMsg_$reflection_1, LocalMsg_$reflection as LocalMsg_$reflection_1, ModelScreenshot_$reflection } from "../../Visualization/Screenshot/ScreenshotTypes.js";
import { OutboundMsg_$reflection as OutboundMsg_$reflection_2, InboundMsg_$reflection as InboundMsg_$reflection_2, LocalMsg_$reflection as LocalMsg_$reflection_2, AdditionalDataModel_$reflection } from "./AdditionalData/AdditionalDataTypes.js";
import { PromptType_$reflection, OutboundMsg_$reflection as OutboundMsg_$reflection_3, InboundMsg_$reflection as InboundMsg_$reflection_3, LocalMsg_$reflection as LocalMsg_$reflection_3, ModelReportPrompt_$reflection } from "./ReportPrompt/ReportPromptTypes.js";
import { ChildAbsentStrategy$1_$reflection, DefaultActions_ChildModel$2_$reflection } from "../../Common/Terminate.js";
import { ErrorMessage_$reflection } from "../../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { Msg$3_$reflection } from "../../Common/InboundOutbound.js";
import { ChainedChildMsg$2_$reflection } from "../../Common/ElmishDebugger/ChainedChildMsg.js";
import { Csv_CsvRowMulti_$reflection } from "../../RAWMap.Models/Common.js";

export class CustomReportInitArgs extends Record {
    constructor(selectedStudy, selectedPatient, patientInstitution, studyAnalysis, geometryData, growthData, maybeCurrentStudyAcquisitionDate, maybePreviousStudyAcquisitionDate) {
        super();
        this.selectedStudy = selectedStudy;
        this.selectedPatient = selectedPatient;
        this.patientInstitution = patientInstitution;
        this.studyAnalysis = studyAnalysis;
        this.geometryData = geometryData;
        this.growthData = growthData;
        this.maybeCurrentStudyAcquisitionDate = maybeCurrentStudyAcquisitionDate;
        this.maybePreviousStudyAcquisitionDate = maybePreviousStudyAcquisitionDate;
    }
}

export function CustomReportInitArgs_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.Types.CustomReportInitArgs", [], CustomReportInitArgs, () => [["selectedStudy", StudyViewModel_$reflection()], ["selectedPatient", PatientViewModel_$reflection()], ["patientInstitution", InstitutionViewModel_$reflection()], ["studyAnalysis", AnalysisViewModel_$reflection()], ["geometryData", option_type(list_type(tuple_type(string_type, string_type)))], ["growthData", option_type(list_type(tuple_type(string_type, string_type)))], ["maybeCurrentStudyAcquisitionDate", option_type(string_type)], ["maybePreviousStudyAcquisitionDate", option_type(string_type)]]);
}

export class CustomReportUpdateArgs extends Record {
    constructor(customReport, fileArgs, userRoles) {
        super();
        this.customReport = customReport;
        this.fileArgs = fileArgs;
        this.userRoles = userRoles;
    }
}

export function CustomReportUpdateArgs_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.Types.CustomReportUpdateArgs", [], CustomReportUpdateArgs, () => [["customReport", CustomReportViewModel_$reflection()], ["fileArgs", FileManagementUpdateArgs_$reflection()], ["userRoles", list_type(UserRole_$reflection())]]);
}

export class Operation extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["GeneratingReportPdf"];
    }
}

export function Operation_$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.Operation", [], Operation, () => [[]]);
}

export function Operation_viewDisplay_4DAF4246(operation) {
    return "Generating Report";
}

export class Mode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Review", "RunningOperation"];
    }
}

export function Mode_$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.Mode", [], Mode, () => [[], [["Item", Operation_$reflection()]]]);
}

export function ModeModule_$007CDisableButtons$007CEnableButtons$007C(mode) {
    if (mode.tag === 0) {
        return new FSharpChoice$2(1, [undefined]);
    }
    else {
        return new FSharpChoice$2(0, [Operation_viewDisplay_4DAF4246(mode.fields[0])]);
    }
}

export function ModeModule_$007CSpinner$007CNoSpinner$007C(mode) {
    if (mode.tag === 0) {
        return new FSharpChoice$2(1, [undefined]);
    }
    else {
        return new FSharpChoice$2(0, [Operation_viewDisplay_4DAF4246(mode.fields[0])]);
    }
}

export class ModelCustomReport extends Record {
    constructor(SelectedStudy, SelectedPatient, PatientInstitution, StudyAnalysis, MaybePreviousStudy, GeometryData, GrowthData, MaybeCurrentStudyAcquisitionDate, MaybePreviousStudyAcquisitionDate, ScreenshotModel, AdditionalDataModel, Mode, PromptComponent) {
        super();
        this.SelectedStudy = SelectedStudy;
        this.SelectedPatient = SelectedPatient;
        this.PatientInstitution = PatientInstitution;
        this.StudyAnalysis = StudyAnalysis;
        this.MaybePreviousStudy = MaybePreviousStudy;
        this.GeometryData = GeometryData;
        this.GrowthData = GrowthData;
        this.MaybeCurrentStudyAcquisitionDate = MaybeCurrentStudyAcquisitionDate;
        this.MaybePreviousStudyAcquisitionDate = MaybePreviousStudyAcquisitionDate;
        this.ScreenshotModel = ScreenshotModel;
        this.AdditionalDataModel = AdditionalDataModel;
        this.Mode = Mode;
        this.PromptComponent = PromptComponent;
    }
}

export function ModelCustomReport_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.Types.ModelCustomReport", [], ModelCustomReport, () => [["SelectedStudy", StudyViewModel_$reflection()], ["SelectedPatient", PatientViewModel_$reflection()], ["PatientInstitution", InstitutionViewModel_$reflection()], ["StudyAnalysis", AnalysisViewModel_$reflection()], ["MaybePreviousStudy", option_type(StudyViewModel_$reflection())], ["GeometryData", option_type(list_type(tuple_type(string_type, string_type)))], ["GrowthData", option_type(list_type(tuple_type(string_type, string_type)))], ["MaybeCurrentStudyAcquisitionDate", option_type(string_type)], ["MaybePreviousStudyAcquisitionDate", option_type(string_type)], ["ScreenshotModel", ModelScreenshot_$reflection()], ["AdditionalDataModel", AdditionalDataModel_$reflection()], ["Mode", Mode_$reflection()], ["PromptComponent", DefaultActions_ChildModel$2_$reflection(ModelReportPrompt_$reflection(), ModelCustomReport_$reflection())]]);
}

export class LocalMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "TryGetPreviousStudy", "TryGetPreviousStudyResult", "ScreenshotMsg", "AdditionalDataMsg", "ReportPromptMsg", "TerminatePromptComponent", "CreatePromptComponent", "DownloadMeasurementCsv", "GetMeasurementCsvRows"];
    }
}

export function LocalMsg_$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(StudyViewModel_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(StudyViewModel_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item", Msg$3_$reflection(LocalMsg_$reflection_1(), InboundMsg_$reflection_1(), OutboundMsg_$reflection_1())]], [["Item", Msg$3_$reflection(LocalMsg_$reflection_2(), InboundMsg_$reflection_2(), OutboundMsg_$reflection_2())]], [["Item", ChainedChildMsg$2_$reflection(Msg$3_$reflection(LocalMsg_$reflection_3(), InboundMsg_$reflection_3(), OutboundMsg_$reflection_3()), ChildAbsentStrategy$1_$reflection(ModelCustomReport_$reflection()))]], [], [["Item", PromptType_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Csv_CsvRowMulti_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(Csv_CsvRowMulti_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]], []]);
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg_$reflection() {
    return class_type("RAWMap.Client.Study.CustomReport.Types.InboundMsg", undefined, InboundMsg);
}

export class OutboundMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateCustomReportStatus", "UpdateCustomReport", "CancelCustomReport"];
    }
}

export function OutboundMsg_$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.OutboundMsg", [], OutboundMsg, () => [[["Item", CustomReportStatus_$reflection()]], [["Item", CustomReportViewModel_$reflection()]], []]);
}

