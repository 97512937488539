import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Security_PublicAuthApi_$reflection, Security_SecuredAuthApi$1_$reflection, Security_UserInfo$2_$reflection, Route_builder } from "./fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { UserData_$reflection, UserRole_$reflection } from "./RAWMap.Models/Security.js";
import { SecuredAwsApi_$reflection, SecuredApi_$reflection, SecuredUserManagementApi_$reflection } from "./RAWMap.Models/Api.js";

export function securedAuthApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), Security_SecuredAuthApi$1_$reflection(Security_UserInfo$2_$reflection(UserRole_$reflection(), UserData_$reflection())));
}

export function securedUserManagementApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), SecuredUserManagementApi_$reflection());
}

export function securedApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), SecuredApi_$reflection());
}

export function securedAwsApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), SecuredAwsApi_$reflection());
}

export const publicAuthApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Security_PublicAuthApi_$reflection());

