import { Union } from "../../fable-library-js.4.19.3/Types.js";
import { Common_parseOptions, Color_ofColor, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Color_IColor_$reflection } from "../Common.fs.js";
import { bool_type, union_type, string_type, list_type, class_type } from "../../fable-library-js.4.19.3/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.19.3/MapUtil.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Color", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Panel.Option", [], Option, () => [[["Item", Color_IColor_$reflection()]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

export class Tab_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-active", "Props", "CustomClass", "Modifiers"];
    }
}

export function Tab_Option_$reflection() {
    return union_type("Fulma.Panel.Tab.Option", [], Tab_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

export class Block_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-active", "Props", "CustomClass", "Modifiers"];
    }
}

export function Block_Option_$reflection() {
    return union_type("Fulma.Panel.Block.Option", [], Block_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

export function Block_panel(element, options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
        }
    }, "panel-block"), element, children);
}

/**
 * Generate <div class="panel-block"></div>
 */
export function Block_div(options, children) {
    return Block_panel((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

/**
 * Generate <a class="panel-block"></a>
 */
export function Block_a(options, children) {
    return Block_panel((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

/**
 * Generate <label class="panel-block"></label>
 */
export function Block_label(options, children) {
    return Block_panel((props, children_1) => react.createElement("label", keyValueList(props, 1), ...children_1), options, children);
}

/**
 * Generate <p class="panel-block"></p>
 */
export function Block_p(options, children) {
    return Block_panel((props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), options, children);
}

/**
 * Generate <label class="panel-block"></label>
 */
export function checkbox(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
        }
    }, "panel-block"), (props_1, children_1) => react.createElement("label", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <nav class="panel"></nav>
 */
export function panel(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
        }
    }, "panel"), (props_1, children_1) => react.createElement("nav", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <div class="panel-heading"></div>
 */
export function heading(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "panel-heading"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="panel-tabs"></div>
 */
export function tabs(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "panel-tabs"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <a></a>
 */
export function tab(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
        }
    }), (props_1, children_1) => react.createElement("a", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <span class="panel-icon"></span>
 */
export function icon(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "panel-icon"), (props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), children);
}

