import { Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { toFail } from "../../fable_modules/fable-library-js.4.19.3/String.js";

export class MeshViewportLocation extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TopRight", "BottomLeft", "BottomRight"];
    }
}

export function MeshViewportLocation_$reflection() {
    return union_type("Client.Visualization.Common.Types.MeshViewportLocation", [], MeshViewportLocation, () => [[], [], []]);
}

export function MeshViewportLocation_get_all() {
    return ofArray([new MeshViewportLocation(0, []), new MeshViewportLocation(1, []), new MeshViewportLocation(2, [])]);
}

export function MeshViewportLocation_parse_Z721C83C5(s) {
    switch (s) {
        case "TopRight":
            return new MeshViewportLocation(0, []);
        case "BottomLeft":
            return new MeshViewportLocation(1, []);
        case "BottomRight":
            return new MeshViewportLocation(2, []);
        default:
            return toFail(`Unable to parse ${s}`);
    }
}

export function MeshViewportLocation_get_ofOrder() {
    return (_arg) => ((_arg === 0) ? (new MeshViewportLocation(0, [])) : ((_arg === 1) ? (new MeshViewportLocation(1, [])) : (new MeshViewportLocation(2, []))));
}

export class TargetStudy extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Current", "Previous"];
    }
}

export function TargetStudy_$reflection() {
    return union_type("Client.Visualization.Common.Types.TargetStudy", [], TargetStudy, () => [[], []]);
}

export class CardMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Enabled", "ViewOnly", "Disabled"];
    }
}

export function CardMode_$reflection() {
    return union_type("Client.Visualization.Common.Types.CardMode", [], CardMode, () => [[], [], []]);
}

