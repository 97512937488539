import { FSharpRef, toString, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Prop, HTMLAttr, DOMAttr, DOMAttr_$reflection } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { lambda_type, unit_type, option_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { defaultOf, structuralHash, equals, curry2 } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { String_trimString_, String_stringOption_, String_toOption } from "../RAWMap.Models/Common.js";
import { defaultArg, ofNullable, map as map_1, bind, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { toUniversalTime, tryParse, minValue } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { Option as Option_2, label as label_1, div } from "../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { contains, filter, map, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { isEmpty, append as append_1, cons, ofArray, singleton, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { div as div_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { Option, IInputType, input as input_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_1, textarea as textarea_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Textarea.fs.js";
import { fromDateTimeOffset, today, toString as toString_1 } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { InputElement_ref } from "./General.js";
import { SelectPropsMulti$1, SelectOptions_value, SelectOption$1 } from "./ReactSelectBind.js";
import { equalsWith, item as item_2, tryFindIndex } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import * as react from "react";
import react_select from "react-select";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { ReactSelectCreatableProps$1, SelectOption$1 as SelectOption$1_1 } from "./ReactSelectCreatable.js";
import creatable from "react-select/creatable";

export class FormField_OnChangeField extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["OnChangeField"];
    }
}

export function FormField_OnChangeField_$reflection() {
    return union_type("Client.Forms.FormField.OnChangeField", [], FormField_OnChangeField, () => [[["Item", DOMAttr_$reflection()]]]);
}

export function FormField_OnChangeField_get_getDomAttr() {
    return (_arg) => _arg.fields[0];
}

export class FormField_ReactSelectCallback$1 extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ReactSelectCallback"];
    }
}

export function FormField_ReactSelectCallback$1_$reflection(gen0) {
    return union_type("Client.Forms.FormField.ReactSelectCallback`1", [gen0], FormField_ReactSelectCallback$1, () => [[["Item", lambda_type(option_type(gen0), unit_type)]]]);
}

export function FormField_onChangeReactSelect(dispatchChange, optic_, optic__1) {
    const optic = [optic_, curry2(optic__1)];
    return new FormField_ReactSelectCallback$1((newValue) => {
        dispatchChange((svm) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), optic)(newValue)(svm));
    });
}

export function FormField_onChangeStringOptic(dispatchChange, optic_, optic__1) {
    const optic = [optic_, curry2(optic__1)];
    return new FormField_OnChangeField(new DOMAttr(9, [(e) => {
        dispatchChange((svm) => {
            let value_1;
            return ((value_1 = (equals(String_toOption(e.target.value), undefined) ? undefined : some(e.target.value)), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), optic)(value_1)))(svm);
        });
    }]));
}

export function FormField_onChangeStringOpticTrimmed(dispatchChange, optic_, optic__1) {
    const optic = [optic_, curry2(optic__1)];
    return new FormField_OnChangeField(new DOMAttr(9, [(e) => {
        dispatchChange((svm) => {
            let optic_1, l_2, value_2;
            return ((optic_1 = ((l_2 = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), String_stringOption_)(optic), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), String_trimString_)(l_2))), (value_2 = (equals(String_toOption(e.target.value), undefined) ? "" : e.target.value), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), optic_1)(value_2))))(svm);
        });
    }]));
}

export function FormField_onChangeDateOptic(dispatchChange, optic_, optic__1) {
    const optic = [optic_, curry2(optic__1)];
    return new FormField_OnChangeField(new DOMAttr(9, [(e) => {
        dispatchChange((svm) => {
            let value_2, lastValue;
            return ((value_2 = (e.target.validity.badInput ? ((lastValue = Optic_Get_op_HatDot_21762A61(new Optic_Get(), optic)(svm), (e.target.value = lastValue, lastValue))) : (equals(String_toOption(toString(e.target.value)), undefined) ? undefined : some(e.target.valueAsDate))), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), optic)(value_2)))(svm);
        });
    }]));
}

export function FormField_onChangeNumberOptic(dispatchChange, optic_, optic__1) {
    const optic = [optic_, curry2(optic__1)];
    return new FormField_OnChangeField(new DOMAttr(9, [(e) => {
        dispatchChange((svm) => {
            let value_2, lastValue;
            return ((value_2 = (e.target.validity.badInput ? ((lastValue = Optic_Get_op_HatDot_21762A61(new Optic_Get(), optic)(svm), (e.target.value = lastValue, lastValue))) : (equals(String_toOption(toString(e.target.value)), undefined) ? undefined : some(e.target.valueAsNumber))), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), optic)(value_2)))(svm);
        });
    }]));
}

export function FormField_onChangeNumberDispatch(dispatchChange, lastValue) {
    return new FormField_OnChangeField(new DOMAttr(9, [(e) => {
        dispatchChange(e.target.validity.badInput ? ((e.target.value = lastValue, lastValue)) : (equals(String_toOption(toString(e.target.value)), undefined) ? undefined : some(e.target.valueAsNumber)));
    }]));
}

export function FormField_onChangeSet(dispatchChange, overWrite) {
    return new FormField_OnChangeField(new DOMAttr(9, [(e) => {
        dispatchChange((svm) => overWrite(svm, e.target.value));
    }]));
}

export function FormField_parseDate(date) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse(date, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return toUniversalTime(matchValue[1]);
    }
    else {
        return minValue();
    }
}

export function FormField_onEnterKeyPress(disabled, dispatchMsg, e) {
    if (!disabled && ((e.char === "13") ? true : (e.which === 13))) {
        e.preventDefault();
        dispatchMsg();
    }
}

export function FormField_labeledField(label, fieldElement, props) {
    return div(toList(delay(() => props)), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(fieldElement))]));
}

/**
 * Create an Input component with type Input.Text good for short string fields
 */
export function FormField_text(initialValue, extraProps, extraInputProps, _arg, label, labeledFieldProps) {
    return FormField_labeledField(label, input_1(toList(delay(() => append(singleton_1(new Option(1, [new IInputType(0, [])])), delay(() => append(singleton_1(new Option(10, [initialValue])), delay(() => append(singleton_1(new Option(12, [label])), delay(() => append(singleton_1(new Option(15, [cons(_arg.fields[0], extraProps)])), delay(() => extraInputProps))))))))))), labeledFieldProps);
}

/**
 * Create a TextArea component good for block paragraph text fields
 */
export function FormField_textArea(description, initialValue, extraProps, extraTextAreaProps, _arg, label, labeledFieldProps) {
    return FormField_labeledField(label, textarea_1(toList(delay(() => append(singleton_1(new Option_1(13, [description])), delay(() => append(singleton_1(new Option_1(11, [initialValue])), delay(() => append(singleton_1(new Option_1(14, [cons(_arg.fields[0], extraProps)])), delay(() => extraTextAreaProps)))))))), empty()), labeledFieldProps);
}

/**
 * Create an Input component with type Input.Date for entering dates
 */
export function FormField_date(initialValue, extraProps, extraInputProps, _arg, label, labeledFieldProps) {
    const formatDate = (d) => toString_1(d, "yyyy-MM-dd");
    const maxDate = formatDate(today());
    const initDate = (initialValue == null) ? "" : formatDate(fromDateTimeOffset(initialValue, 0));
    return FormField_labeledField(label, div_1(empty(), singleton(input_1(toList(delay(() => append(singleton_1(new Option(1, [new IInputType(3, [])])), delay(() => append(singleton_1(new Option(10, [initDate])), delay(() => append(singleton_1(new Option(12, [label])), delay(() => append(singleton_1(new Option(15, [toList(delay(() => append(singleton_1(_arg.fields[0]), delay(() => append(extraProps, delay(() => append(singleton_1(new HTMLAttr(114, [maxDate])), delay(() => singleton_1(new HTMLAttr(119, ["1900-01-01"]))))))))))])), delay(() => extraInputProps))))))))))))), labeledFieldProps);
}

/**
 * Creates an Input component with type Input.Number for entering numbers
 */
export function FormField_number(value, extraProps, extraInputProps, _arg, label, labeledFieldProps) {
    return FormField_labeledField(label, input_1(toList(delay(() => append(singleton_1(new Option(1, [new IInputType(7, [])])), delay(() => append(singleton_1(new Option(10, [value])), delay(() => append(singleton_1(new Option(14, [(e) => {
        InputElement_ref(value, e);
    }])), delay(() => append(singleton_1(new Option(12, [label])), delay(() => append(singleton_1(new Option(15, [cons(_arg.fields[0], extraProps)])), delay(() => extraInputProps))))))))))))), labeledFieldProps);
}

/**
 * Create an Input component with a slider for selecting numbers
 */
export function FormField_slider(min, max, value, extraProps, extraInputProps, _arg, label, labeledFieldProps) {
    return FormField_labeledField(label, input_1(toList(delay(() => append(singleton_1(new Option(8, [value])), delay(() => append(singleton_1(new Option(15, [append_1(cons(_arg.fields[0], extraProps), ofArray([new HTMLAttr(159, ["range"]), new HTMLAttr(119, [min]), new HTMLAttr(114, [max])]))])), delay(() => extraInputProps))))))), labeledFieldProps);
}

/**
 * Creates a ReactSelect component used to select a single item with a dropdown
 * from a list. This includes an X so the user can also unselect the item and
 * return to None.
 */
export function FormField_reactSelectUnselect(fieldLabel, currentlySelected, availableToSelect, optionLabel, isClearable, changeCallback, fieldProps) {
    const canSelect = Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    defaultSelection = ((matchValue == null) ? empty() : singleton(new SelectPropsMulti$1(2, [[item_2(matchValue, canSelect)]])));
    let reactSelect;
    const props_1 = keyValueList(toList(delay(() => append(defaultSelection, delay(() => append(singleton_1(new SelectPropsMulti$1(1, [canSelect])), delay(() => append(singleton_1(new SelectPropsMulti$1(7, [false])), delay(() => append(singleton_1(new SelectPropsMulti$1(8, [isClearable])), delay(() => singleton_1(new SelectPropsMulti$1(3, [(arg_2) => {
        changeCallback(map_1(SelectOptions_value, ofNullable(arg_2)));
    }])))))))))))), 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, [singleton(new Prop(0, [fieldLabel]))])), delay(() => fieldProps)))), ofArray([label_1(empty(), singleton(fieldLabel)), reactSelect]));
}

/**
 * Creates a ReactSelect component used to select a single item with a dropdown
 * from a list.
 */
export function FormField_reactSelectSingle(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg, fieldLabel, fieldProps) {
    const canSelect = Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    defaultSelection = ((matchValue == null) ? empty() : singleton(new SelectPropsMulti$1(2, [[item_2(matchValue, canSelect)]])));
    let key;
    const arg_1 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg_1);
    let reactSelect;
    const props_1 = keyValueList(toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(1, [canSelect])), delay(() => append(singleton_1(new SelectPropsMulti$1(7, [false])), delay(() => singleton_1(new SelectPropsMulti$1(3, [(value) => {
        _arg.fields[0](some(value.value));
    }])))))))))))), 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, [singleton(new Prop(0, [key]))])), delay(() => fieldProps)))), ofArray([label_1(empty(), singleton(fieldLabel)), reactSelect]));
}

/**
 * Creates a ReactSelect component used to select multiple items with a dropdown
 * from a list.
 */
export function FormField_reactSelectMulti(fieldLabel, currentlySelected, otherProps, availableToSelect, optionLabel, changeCallback, fieldProps) {
    const toOptions = (values) => Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), values));
    const notAlreadySelected = filter((item) => !contains(item, currentlySelected, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), availableToSelect);
    let reactSelect;
    const props_1 = keyValueList(toList(delay(() => append(singleton_1(new SelectPropsMulti$1(0, [toOptions(currentlySelected)])), delay(() => append(singleton_1(new SelectPropsMulti$1(1, [toOptions(notAlreadySelected)])), delay(() => append(singleton_1(new SelectPropsMulti$1(7, [true])), delay(() => append(singleton_1(new SelectPropsMulti$1(3, [(value) => {
        changeCallback(equalsWith(equals, value, defaultOf()) ? (new Array(0)) : value);
    }])), delay(() => otherProps)))))))))), 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(singleton(new Option_2(11, [toList(delay(() => fieldProps))])), ofArray([label_1(empty(), singleton(fieldLabel)), reactSelect]));
}

/**
 * Creates a ReactSelect component that allows a new entry to be created by the user.
 */
export function FormField_reactSelectCreatable(items, extraSelectProps, mkLabel, maybeCurrentlySelectedItem, _arg, fieldLabel, fieldProps) {
    const selectOptions = Array.from(map((item) => (new SelectOption$1_1(mkLabel(item), item)), items));
    const maybeSelectedValue = map_1((index) => (new ReactSelectCreatableProps$1(2, [item_2(index, selectOptions)])), bind((item_1) => tryFindIndex((selectOption) => equals(selectOption.value, item_1), selectOptions), maybeCurrentlySelectedItem));
    let key;
    const arg_1 = (maybeSelectedValue == null) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg_1);
    let reactSelect;
    const props_1 = keyValueList(toList(delay(() => append(singleton_1(defaultArg(maybeSelectedValue, new ReactSelectCreatableProps$1(10, []))), delay(() => append(extraSelectProps, delay(() => append(singleton_1(new ReactSelectCreatableProps$1(1, [selectOptions])), delay(() => append(singleton_1(new ReactSelectCreatableProps$1(5, [false])), delay(() => append(singleton_1(new ReactSelectCreatableProps$1(3, [(selectOption_1) => {
        _arg.fields[0](some(selectOption_1.value));
    }])), delay(() => singleton_1(new ReactSelectCreatableProps$1(8, ["first"])))))))))))))), 1);
    reactSelect = react.createElement(creatable, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, [singleton(new Prop(0, [key]))])), delay(() => fieldProps)))), ofArray([label_1(empty(), singleton(fieldLabel)), reactSelect]));
}

