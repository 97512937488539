import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, array_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { AllMeshData, AdditionalMeasurementData, StudyMeshData, AdditionalMeasurementData_$reflection, AllMeshData_$reflection } from "./MeshesBindings.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { skip, item, equalsWith, map } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { printf, toText, endsWith, substring, isNullOrEmpty, split } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { comparePrimitives, defaultOf } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { reject, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import * as jszip from "jszip";
import { promise } from "../../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { addToDict } from "../../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { exists, length, tryFind, filter, sortBy, map as map_1 } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { map as map_2 } from "../../fable_modules/fable-library-js.4.19.3/Option.js";

export class ExtractedData extends Record {
    constructor(Dicoms, MeshData, AdditionalMeasurements) {
        super();
        this.Dicoms = Dicoms;
        this.MeshData = MeshData;
        this.AdditionalMeasurements = AdditionalMeasurements;
    }
}

export function ExtractedData_$reflection() {
    return record_type("RAWMap.Client.Visualization.Meshes.Extraction.ExtractedData", [], ExtractedData, () => [["Dicoms", array_type(class_type("Browser.Types.File", undefined))], ["MeshData", AllMeshData_$reflection()], ["AdditionalMeasurements", AdditionalMeasurementData_$reflection()]]);
}

export function parseDataFileCsvStringToList(csvString) {
    let array_1;
    return ofArray(map((line_1) => {
        const matchValue = split(line_1, [","], undefined, 0);
        if (!equalsWith((x, y) => (x === y), matchValue, defaultOf()) && (matchValue.length === 2)) {
            const value_2 = item(1, matchValue);
            return [item(0, matchValue), value_2];
        }
        else {
            throw new Error("Invalid CSV format: each row must have exactly two values (key,value)");
        }
    }, skip(1, (array_1 = map((line) => line.trim(), split(csvString, ["\n"], undefined, 0)), array_1.filter((arg) => !isNullOrEmpty(arg))))));
}

export function extractData(zipBuffer) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const zip = new jszip();
        return zip.loadAsync(zipBuffer).then((_arg) => {
            const zipFile = _arg;
            const fileData = new Map([]);
            zipFile.forEach((relPath, file) => {
                const zipObj = zipFile.files[file.name];
                const outputByTypePromise = zipObj.async("arraybuffer");
                addToDict(fileData, file.name, outputByTypePromise);
            });
            const dicomFilePromises = map_1((kvp) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (kvp[1].then((_arg_1) => {
                let fileName;
                return Promise.resolve(new File([_arg_1], ((fileName = kvp[0], substring(fileName, fileName.lastIndexOf("/") + 1)))));
            })))), sortBy((kvp_2) => kvp_2[0], filter((kvp_1) => endsWith(kvp_1[0], ".dcm"), fileData), {
                Compare: comparePrimitives,
            }));
            const csvStringPromise = (filename) => {
                let fullFileName;
                const path2 = filename;
                fullFileName = toText(printf("%s/%s"))("UI_Files")(path2);
                const file_1 = zipFile.files[fullFileName];
                const _arg_2 = (file_1 == null) ? undefined : file_1.async("text");
                if (_arg_2 == null) {
                    return Promise.resolve(undefined);
                }
                else {
                    const pr = _arg_2;
                    return pr.then((Value_1) => Value_1);
                }
            };
            const getMeshData = (dir) => {
                const asArrayBuffer = (data) => data;
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const getMeshPromise = (fileName_1) => {
                        let fullFileName_1;
                        const path1_1 = dir;
                        const path2_1 = fileName_1;
                        fullFileName_1 = ((path1_1 === "") ? path2_1 : toText(printf("%s/%s"))(path1_1)(path2_1));
                        const _arg_3 = tryFind((kvp_3) => (kvp_3[0].indexOf(fullFileName_1) >= 0), fileData);
                        if (_arg_3 != null) {
                            return _arg_3[1];
                        }
                        else {
                            return reject(new Error(toText(printf("Missing %s"))(fullFileName_1)));
                        }
                    };
                    return getMeshPromise("Anatomy.vtp").then((_arg_4) => {
                        let pr_2, pr_1;
                        return ((pr_2 = ((pr_1 = getMeshPromise("Diameter_Growth.vtp"), pr_1.then((Value_2) => Value_2))), pr_2.then(void 0, ((_arg_5) => undefined)))).then((_arg_6) => (getMeshPromise("Lumen.vtp").then((_arg_7) => (getMeshPromise("Lumen_Centerline.vtp").then((_arg_8) => (getMeshPromise("RAW.vtp").then((_arg_9) => (getMeshPromise("Wall.vtp").then((_arg_10) => (getMeshPromise("Wall_Centerline.vtp").then((_arg_11) => (getMeshPromise("Renal_Artery.vtp").then((_arg_12) => {
                            const meshData = new StudyMeshData(asArrayBuffer(_arg_4), map_2(asArrayBuffer, _arg_6), asArrayBuffer(_arg_7), asArrayBuffer(_arg_8), asArrayBuffer(_arg_9), asArrayBuffer(_arg_10), asArrayBuffer(_arg_11), asArrayBuffer(_arg_12));
                            return Promise.resolve(meshData);
                        })))))))))))));
                    });
                }));
            };
            return ((length(dicomFilePromises) === 0) ? reject(new Error("Missing DICOMs")) : (Promise.all(dicomFilePromises))).then((_arg_13) => {
                const asString = (data_1) => {
                    const matchValue = data_1;
                    if (typeof matchValue === "string") {
                        const s = matchValue;
                        return s;
                    }
                    else {
                        return undefined;
                    }
                };
                return csvStringPromise("geometryData.csv").then((_arg_14) => (csvStringPromise("growthData.csv").then((_arg_15) => {
                    const additionalMeasurements = new AdditionalMeasurementData(map_2(parseDataFileCsvStringToList, asString(_arg_14)), map_2(parseDataFileCsvStringToList, asString(_arg_15)));
                    return getMeshData("UI_Files").then((_arg_16) => {
                        let pr_4;
                        return (exists((kvp_5) => (kvp_5[0].indexOf("Previous_Study_Files") === 0), fileData) ? ((pr_4 = getMeshData("Previous_Study_Files"), pr_4.then((Value_3) => Value_3))) : (Promise.resolve(undefined))).then((_arg_17) => {
                            const meshData_1 = new AllMeshData(_arg_16, _arg_17);
                            return Promise.resolve(new ExtractedData(_arg_13, meshData_1, additionalMeasurements));
                        });
                    });
                })));
            });
        });
    }));
}

