import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, bool_type, option_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class AnatomyOrientationProps extends Record {
    constructor(orientationData, showOrientation) {
        super();
        this.orientationData = orientationData;
        this.showOrientation = showOrientation;
    }
}

export function AnatomyOrientationProps_$reflection() {
    return record_type("RAWMap.Client.Visualization.AnatomyOrientation.Bindings.AnatomyOrientationProps", [], AnatomyOrientationProps, () => [["orientationData", option_type(class_type("Fable.Core.JS.ArrayBuffer"))], ["showOrientation", bool_type]]);
}

