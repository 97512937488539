import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { startAsPromise } from "../../fable_modules/fable-library-js.4.19.3/Async.js";
import { securedApi } from "../../Api.js";
import { Result_map, Result_isOk } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create, S3_DeleteObjectRequestParams, S3_DeleteObjectRequestParams_get_create } from "../../bindings/Fable.Helpers.Aws.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { filter, map, ofArray, toArray, length, append, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Mode_modify_Z7A73AF2A, Mode_get_revert, OutboundMsg, Mode, ScreenshotModal, LocalMsg, ModelScreenshot } from "./ScreenshotTypes.js";
import { update as update_1, wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { VisualizationScreenshotViewModel } from "../../RAWMap.Models/View/VisualizationScreenshot.js";
import { Cmd_batch, Cmd_OfPromise_either, Cmd_ofEffect, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { splitImages } from "../../../../src/RAWMap.Client/Visualization/Screenshot/Screenshot.js";
import { constants } from "../../../../src/RAWMap.Client/Visualization/js/Common.js";
import { InteractionMode } from "../Common/CommonBindings.js";
import { some } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Toast_successToast, Toast_errorToast } from "../../Common/General.js";
import { ErrorMessage_get_describe } from "../../RAWMap.Models/ErrorMessage.js";

export function makeUploadDestinationPathGuid(patientId, studyId, screenshotId) {
    return toText(printf("%O/%O/Reports/Screenshots/%O.png"))(patientId)(studyId)(screenshotId);
}

export function deleteScreenshot(args, vm) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (startAsPromise(securedApi(args.token).deleteVisualizationScreenshot([vm.screenshotId, vm.studyId])).then((_arg) => {
        let parms, deleteRequest, pr;
        const deleteScreenshotResult = _arg;
        return (Result_isOk(deleteScreenshotResult) ? ((parms = S3_DeleteObjectRequestParams_get_create()(new S3_DeleteObjectRequestParams(args.s3Context.ActiveBucket, vm.imagePath)), (deleteRequest = args.s3Context.S3Client.deleteObject(parms), ((pr = deleteRequest.promise(), pr.then((value) => {
        }))).then(() => (Promise.resolve(undefined)))))) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(deleteScreenshotResult))));
    }))));
}

function dataUrlToBlob(url) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(url, empty()).then((_arg) => (_arg.blob())))));
}

export function init(mode, selectedPatientId, selectedStudyId) {
    let msg;
    return [new ModelScreenshot(mode, empty(), selectedPatientId, selectedStudyId, false), (msg = wrapLocalMsg(new LocalMsg(5, [mode])), singleton((dispatch) => {
        dispatch(msg);
    }))];
}

export function updateInbound(args, msg, model) {
    let msg_1;
    const d = msg.fields[0];
    const mkSetModeMsg = (tupledArg) => {
        const screenshotId = newGuid();
        return new LocalMsg(5, [tupledArg[1](new VisualizationScreenshotViewModel(model.SelectedStudyId, screenshotId, "", makeUploadDestinationPathGuid(model.SelectedPatientId, model.SelectedStudyId, screenshotId), tupledArg[0]))]);
    };
    const matchValue = model.Mode;
    switch (matchValue.tag) {
        case 0:
            return [model, (msg_1 = mkSetModeMsg([d, (arg) => (new Mode(3, [new ScreenshotModal(0, [arg])]))]), singleton((dispatch) => {
                dispatch(msg_1);
            })), Cmd_none()];
        case 1: {
            const expectedNumberOfCaptures = matchValue.fields[0] | 0;
            const captured = append(matchValue.fields[1], singleton(d));
            return [new ModelScreenshot(new Mode(1, [expectedNumberOfCaptures, captured]), model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, model.IsSaving), (length(captured) !== expectedNumberOfCaptures) ? Cmd_none() : Cmd_ofEffect((dispatch_1) => {
                let expected;
                splitImages((b64Img_1) => {
                    dispatch_1(mkSetModeMsg([b64Img_1, (vm) => (new Mode(3, [new ScreenshotModal(1, [expectedNumberOfCaptures, vm])]))]));
                }, (expected = expectedNumberOfCaptures, (constants.splitScreenshotSize.width * expected) + (constants.splitScreenshotPadding * (expected - 1))), constants.splitScreenshotSize.height, toArray(captured));
            }), Cmd_none()];
        }
        default:
            return [model, Cmd_none(), Cmd_none()];
    }
}

export function updateLocal(args, msg, model) {
    let matchValue, url, matchValue_1, matchValue_2, f1, msg_13, matchValue_3, f1_1;
    switch (msg.tag) {
        case 2:
            return [model, Cmd_none(), singleton((dispatch) => {
                dispatch(new OutboundMsg(1, [new InteractionMode(5, [])]));
            })];
        case 3: {
            const newMode = Mode_get_revert()(model.Mode);
            return [model, singleton((dispatch_2) => {
                dispatch_2(new LocalMsg(5, [newMode]));
            }), (newMode.tag === 0) ? singleton((dispatch_1) => {
                dispatch_1(new OutboundMsg(1, [new InteractionMode(0, [])]));
            }) : ((newMode.tag === 1) ? singleton((dispatch_1) => {
                dispatch_1(new OutboundMsg(1, [new InteractionMode(0, [])]));
            }) : Cmd_none())];
        }
        case 1:
            return [new ModelScreenshot(Mode_modify_Z7A73AF2A(msg.fields[0])(model.Mode), model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, model.IsSaving), Cmd_none(), Cmd_none()];
        case 4:
            return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, true), (matchValue = model.Mode, (matchValue.tag === 3) ? ((matchValue.fields[0].tag === 0) ? ((matchValue.fields[0].fields[0].imageUrl != null) ? ((url = matchValue.fields[0].fields[0].imageUrl, Cmd_OfPromise_either(() => {
                const pr = dataUrlToBlob(url);
                return pr.then((blob) => {
                    let upload;
                    const params = S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, [args.s3Context.ActiveBucket]), new S3_PutObjectRequestParams(3, [matchValue.fields[0].fields[0].imagePath]), new S3_PutObjectRequestParams(1, [blob])]));
                    upload = args.s3Context.S3Client.upload(params);
                    return upload.promise();
                });
            }, undefined, (_arg) => (new LocalMsg(8, [])), (Item) => (new LocalMsg(0, [Item]))))) : Cmd_none()) : ((matchValue.fields[0].tag === 1) ? ((matchValue.fields[0].fields[1].imageUrl != null) ? ((url = matchValue.fields[0].fields[1].imageUrl, Cmd_OfPromise_either(() => {
                const pr = dataUrlToBlob(url);
                return pr.then((blob) => {
                    let upload;
                    const params = S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, [args.s3Context.ActiveBucket]), new S3_PutObjectRequestParams(3, [matchValue.fields[0].fields[1].imagePath]), new S3_PutObjectRequestParams(1, [blob])]));
                    upload = args.s3Context.S3Client.upload(params);
                    return upload.promise();
                });
            }, undefined, (_arg) => (new LocalMsg(8, [])), (Item) => (new LocalMsg(0, [Item]))))) : Cmd_none()) : ((matchValue.fields[0].tag === 2) ? singleton((dispatch_3) => {
                dispatch_3(new LocalMsg(13, [matchValue.fields[0].fields[0]]));
            }) : Cmd_none()))) : Cmd_none()), Cmd_none()];
        case 5:
            return [new ModelScreenshot(msg.fields[0], model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, model.IsSaving), (msg.fields[0].tag === 2) ? singleton((dispatch_4) => {
                dispatch_4(new LocalMsg(9, []));
            }) : ((msg.fields[0].tag === 3) ? ((msg.fields[0].fields[0].tag === 2) ? ((msg.fields[0].fields[0].fields[0].imageUrl == null) ? singleton((dispatch_5) => {
                dispatch_5(new LocalMsg(7, []));
            }) : Cmd_none()) : Cmd_none()) : Cmd_none()), Cmd_none()];
        case 7:
            return [model, (matchValue_1 = model.Mode, (matchValue_1.tag === 3) ? ((matchValue_1.fields[0].tag === 2) ? Cmd_OfPromise_either((tupledArg) => args.s3Context.S3Client.getSignedUrlPromise(tupledArg[0], tupledArg[1]), ["getObject", some(S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, [args.s3Context.ActiveBucket]), new S3_PutObjectRequestParams(3, [matchValue_1.fields[0].fields[0].imagePath])])))], (url_1) => (new LocalMsg(1, [(vm_5) => (new VisualizationScreenshotViewModel(vm_5.studyId, vm_5.screenshotId, vm_5.description, vm_5.imagePath, url_1))])), (Item_1) => (new LocalMsg(0, [Item_1]))) : ((matchValue_1.fields[0].tag === 3) ? Cmd_OfPromise_either((tupledArg) => args.s3Context.S3Client.getSignedUrlPromise(tupledArg[0], tupledArg[1]), ["getObject", some(S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, [args.s3Context.ActiveBucket]), new S3_PutObjectRequestParams(3, [matchValue_1.fields[0].fields[0].imagePath])])))], (url_1) => (new LocalMsg(1, [(vm_5) => (new VisualizationScreenshotViewModel(vm_5.studyId, vm_5.screenshotId, vm_5.description, vm_5.imagePath, url_1))])), (Item_1) => (new LocalMsg(0, [Item_1]))) : Cmd_none())) : Cmd_none()), Cmd_none()];
        case 8:
            return [model, (matchValue_2 = model.Mode, (matchValue_2.tag === 3) ? ((matchValue_2.fields[0].tag === 0) ? singleton((dispatch_6) => {
                dispatch_6(new LocalMsg(11, [matchValue_2.fields[0].fields[0]]));
            }) : ((matchValue_2.fields[0].tag === 1) ? singleton((dispatch_6) => {
                dispatch_6(new LocalMsg(11, [matchValue_2.fields[0].fields[1]]));
            }) : Cmd_none())) : Cmd_none()), Cmd_none()];
        case 11: {
            const vm$0027 = new VisualizationScreenshotViewModel(msg.fields[0].studyId, msg.fields[0].screenshotId, msg.fields[0].description.trim(), msg.fields[0].imagePath, msg.fields[0].imageUrl);
            return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(args.token).createVisualizationScreenshot, vm$0027, (Item_2) => (new LocalMsg(12, [Item_2])), (Item_3) => (new LocalMsg(0, [Item_3]))), Cmd_none()];
        }
        case 12:
            if (msg.fields[0].tag === 1) {
                return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, false), Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0])), Cmd_none()];
            }
            else {
                return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, false), Cmd_batch(ofArray([Toast_successToast("Saved screenshot"), singleton((dispatch_7) => {
                    dispatch_7(new LocalMsg(3, []));
                })])), singleton((dispatch_8) => {
                    dispatch_8(new OutboundMsg(0, [msg.fields[0].fields[0][0]]));
                })];
            }
        case 13: {
            const vm$0027_1 = new VisualizationScreenshotViewModel(msg.fields[0].studyId, msg.fields[0].screenshotId, msg.fields[0].description.trim(), msg.fields[0].imagePath, msg.fields[0].imageUrl);
            return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, true), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(args.token).editVisualizationScreenshot, vm$0027_1, (f1 = Result_map()((r) => [r, vm$0027_1]), (arg_1) => (new LocalMsg(14, [f1(arg_1)]))), (Item_5) => (new LocalMsg(0, [Item_5]))), Cmd_none()];
        }
        case 14:
            if (msg.fields[0].tag === 1) {
                return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, false), Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0])), Cmd_none()];
            }
            else {
                return [new ModelScreenshot(model.Mode, map((s) => {
                    if (s.screenshotId === msg.fields[0].fields[0][1].screenshotId) {
                        return msg.fields[0].fields[0][1];
                    }
                    else {
                        return s;
                    }
                }, model.Screenshots), model.SelectedPatientId, model.SelectedStudyId, false), Cmd_batch(ofArray([Toast_successToast("Saved screenshot"), (msg_13 = (new LocalMsg(5, [Mode_get_revert()(model.Mode)])), singleton((dispatch_9) => {
                    dispatch_9(msg_13);
                }))])), singleton((dispatch_10) => {
                    dispatch_10(new OutboundMsg(0, [msg.fields[0].fields[0][0]]));
                })];
            }
        case 6:
            return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, true), (matchValue_3 = model.Mode, (matchValue_3.tag === 3) ? ((matchValue_3.fields[0].tag === 3) ? Cmd_OfPromise_either((vm_11) => deleteScreenshot(args, vm_11), matchValue_3.fields[0].fields[0], (f1_1 = Result_map()((r_1) => [r_1, matchValue_3.fields[0].fields[0].screenshotId]), (arg_3) => (new LocalMsg(15, [f1_1(arg_3)]))), (Item_7) => (new LocalMsg(0, [Item_7]))) : Cmd_none()) : Cmd_none()), Cmd_none()];
        case 15:
            if (msg.fields[0].tag === 1) {
                return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, false), Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0])), Cmd_none()];
            }
            else {
                return [new ModelScreenshot(model.Mode, filter((s_1) => (s_1.screenshotId !== msg.fields[0].fields[0][1]), model.Screenshots), model.SelectedPatientId, model.SelectedStudyId, false), Cmd_batch(ofArray([Toast_successToast("Deleted screenshot"), singleton((dispatch_11) => {
                    dispatch_11(new LocalMsg(5, [new Mode(2, [])]));
                })])), singleton((dispatch_12) => {
                    dispatch_12(new OutboundMsg(0, [msg.fields[0].fields[0][0]]));
                })];
            }
        case 9:
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(args.token).getVisualizationScreenshotsForStudy, model.SelectedStudyId, (Item_8) => (new LocalMsg(10, [Item_8])), (Item_9) => (new LocalMsg(0, [Item_9]))), Cmd_none()];
        case 10:
            if (msg.fields[0].tag === 1) {
                return [model, Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0])), Cmd_none()];
            }
            else {
                return [new ModelScreenshot(model.Mode, msg.fields[0].fields[0], model.SelectedPatientId, model.SelectedStudyId, model.IsSaving), Cmd_none(), Cmd_none()];
            }
        default: {
            console.error(some(msg.fields[0]));
            return [new ModelScreenshot(model.Mode, model.Screenshots, model.SelectedPatientId, model.SelectedStudyId, false), Toast_errorToast("An error has occured"), Cmd_none()];
        }
    }
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

