import * as toastr$002Emin from "toastr/build/toastr.min.css";
import { Record } from "../fable-library-js.4.19.3/Types.js";
import { record_type, option_type, lambda_type, unit_type, class_type, string_type } from "../fable-library-js.4.19.3/Reflection.js";
import { clear, remove, options as options_1, warning, info, error, success } from "toastr";
import { singleton } from "../fable-library-js.4.19.3/List.js";


export class Toastr_ToastrMsg$1 extends Record {
    constructor(Message, Title, Options, Dispatcher) {
        super();
        this.Message = Message;
        this.Title = Title;
        this.Options = Options;
        this.Dispatcher = Dispatcher;
    }
}

export function Toastr_ToastrMsg$1_$reflection(gen0) {
    return record_type("Elmish.Toastr.Toastr.ToastrMsg`1", [gen0], Toastr_ToastrMsg$1, () => [["Message", string_type], ["Title", string_type], ["Options", class_type("Elmish.Toastr.ToastrOptions")], ["Dispatcher", option_type(lambda_type(gen0, unit_type))]]);
}

export function Toastr_defaultMsg() {
    return new Toastr_ToastrMsg$1("", "", {}, undefined);
}

export const Toastr_successToastWithTitle = success;

export const Toastr_errorToastWithTitle = error;

export const Toastr_infoToastWithTitle = info;

export const Toastr_warningToastWithTitle = warning;

/**
 * Sets the message of toast
 */
export function Toastr_message(msg) {
    const bind$0040 = Toastr_defaultMsg();
    return new Toastr_ToastrMsg$1(msg, bind$0040.Title, bind$0040.Options, bind$0040.Dispatcher);
}

/**
 * Sets the title of the toast
 */
export function Toastr_title(title, msg) {
    return new Toastr_ToastrMsg$1(msg.Message, title, msg.Options, msg.Dispatcher);
}

/**
 * Defines the duration in ms after which the toast starts to disappear
 */
export function Toastr_timeout(timeout, msg) {
    msg.Options.timeOut=timeout;
    return msg;
}

/**
 * Sets the position of the toastr relative to the screen
 */
export function Toastr_position(pos, msg) {
    msg.Options.positionClass=pos;
    return msg;
}

export function Toastr_extendedTimout(t, msg) {
    msg.Options.extendedTimeOut=t;
    return msg;
}

/**
 * Configures a message to be sent to the dispatch loop when the toast is clicked
 */
export function Toastr_onClick(nextMsg, msg) {
    msg.Options.onclick = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

/**
 * Configures a message to be sent to the dispatch loop when the toast is shown on screen
 */
export function Toastr_onShown(nextMsg, msg) {
    msg.Options.onShown = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

export function Toastr_tapToDismiss(msg) {
    msg.Options.tapToDismiss = true;
    return msg;
}

/**
 * Configures a message to be sent to the dispatch loop when the toast has disappeared
 */
export function Toastr_onHidden(nextMsg, msg) {
    msg.Options.onHidden = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

/**
 * Configures the toast to show a close button
 */
export function Toastr_showCloseButton(msg) {
    msg.Options.closeButton = true;
    return msg;
}

/**
 * Shows the progress bar of how long the toast will take before it disappears
 */
export function Toastr_withProgressBar(msg) {
    msg.Options.progressBar = true;
    return msg;
}

/**
 * Configures a message to be sent to the dispatch loop when the close button of toast is clicked
 */
export function Toastr_closeButtonClicked(nextMsg, msg) {
    msg.Options.onCloseClick = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

export function Toastr_hideEasing(e, msg) {
    msg.Options.hideEasing = e;
    return msg;
}

export const Toastr_options = options_1;

/**
 * Overrides global options
 */
export function Toastr_overrideOptions(opts) {
    Toastr_options = (Object.assign({}, Toastr_options, opts));
}

export const Toastr_remove = remove;

export const Toastr_clear = clear;

/**
 * Remove current toasts using animation
 */
export function Toastr_clearAll() {
    return singleton((_arg) => {
        Toastr_clear();
    });
}

/**
 * Remove current toasts using animation
 */
export function Toastr_removeAll() {
    return singleton((_arg) => {
        Toastr_remove();
    });
}

/**
 * Shows a success toast
 */
export function Toastr_success(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_successToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

/**
 * Shows an error taost
 */
export function Toastr_error(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_errorToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

/**
 * Shows an info toast
 */
export function Toastr_info(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_infoToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

/**
 * Shows a warning toast
 */
export function Toastr_warning(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_warningToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

