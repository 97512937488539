import { constants, logDebug, mkFontInfo } from '../js/Common.js';
import { mkStaticLabelManager } from './StaticLabelManager.js';
import { mkWorldLabelManager } from './WorldLabelManager.js';

import { useRef } from 'react';
import { useCustomCompareEffect } from "../js/UseCustomCompareEffect";
import { equals } from "fable-compiler/bin/fable-library/Util.js";

const isDebugging = false;

function log(msg) {
    logDebug("MeshVisualization.Notes", isDebugging, msg);
}

const fontInfo = mkFontInfo(20, 'serif');

function LabelsEqual(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => equals(val, b[index]));
}

function compareDependencies(a, b) {
    let areEqual =
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        (a.length === 0 ||
            a[1] && b[1] &&
            LabelsEqual(a[0], b[0]));

    return areEqual;
}

export function useNotes(identifier, container, parentContext, {
    staticLabels,
    worldLabels,
    onStaticLabelDragEnd,
    wrapLines
}) {
    const context = useRef(null);

    useCustomCompareEffect(() => {

        if (container && parentContext) {

            const { renderer } = parentContext;

            if (!context.current) {

                // Initialize

                const createLabelCanvas = id => {
                    const canvas = document.createElement('canvas');
                    canvas.classList.add('vtkLabel');
                    canvas.setAttribute("id", id);
                    container.appendChild(canvas);
                    return canvas;
                };
                const worldLabelCanvas = createLabelCanvas(identifier + "_" + constants.worldLabelCanvasId);
                const staticLabelCanvas = createLabelCanvas(identifier + "_" + constants.staticLabelCanvasId);
                worldLabelCanvas.style.pointerEvents = "none";
                staticLabelCanvas.style.pointerEvents = "none";

                const staticLabelManager = mkStaticLabelManager(staticLabels,
                    fontInfo,
                    container,
                    staticLabelCanvas,
                    onStaticLabelDragEnd,
                    wrapLines);
                staticLabelManager.configureInteractionEvents(staticLabelManager);

                const worldLabelManager = mkWorldLabelManager(worldLabels, fontInfo, container, worldLabelCanvas);
                renderer.addActor(worldLabelManager.textActor);

                context.current = {
                    identifier,
                    staticLabelManager,
                    staticLabelCanvas,
                    worldLabelManager,
                    worldLabelCanvas
                };
            }

            if (staticLabels) {

                // Apply effect

                log(`In staticLabels effect with context.current = ${context.current}`);
                const staticLabelManager = context.current.staticLabelManager;
                staticLabelManager.updateLabels(staticLabelManager, staticLabels);
            }
        }

        const ctx = context.current;

        return () => {

            // Dispose

            if (ctx) {
                const { worldLabelManager, staticLabelManager } = ctx;
                worldLabelManager.dispose(worldLabelManager);
                staticLabelManager.cleanupInteractionEvents(staticLabelManager);
            }
        };

        // Make sure that it will keep trying to run this until it is initialised. Needed due to only initialising
        // when we have a container and a parent context.
    }, [staticLabels, !!context.current], compareDependencies);

    // Interaction handlers

    const startInteraction = () => {
        if (context.current) {
            const { staticLabelManager } = context.current;
            staticLabelManager.setIsMoving(staticLabelManager, true);
        }
    };
    const stopInteraction = () => {
        if (context.current) {
            const { staticLabelManager } = context.current;
            staticLabelManager.setIsMoving(staticLabelManager, false);
        }
    };
    const redrawForCanvas = (canvas, dims) => {
        if (context.current) {
            const { staticLabelManager, staticLabelCanvas } = context.current;
            if (canvas.id == staticLabelCanvas.id) {
                staticLabelManager.redrawLabelsForCanvas(staticLabels, canvas, dims);
            }

            // Implement redraw for world labels if needed
            //else if (canvasId == worldLabelCanvas.id) {
            //     worldLabelManager.redrawLabelsForCanvas()
            //}
        }
    };

    return [redrawForCanvas, startInteraction, stopInteraction];

}