import { constants, mkFontInfo } from '../js/Common.js';
import { useEffect, useRef } from 'react';

/**
 * Add anatomy scalar bar to visualization
 *
 * @param containerRef
 * @param {boolean} initialized
 * @param {boolean} isVisible
 */
export function useAnatomyScalarBar(containerRef, initialized, isVisible) {
    const context = useRef(null);
    const container = containerRef.current;

    const drawAnatomyScalarBar = (canvas, { width, height }) => {
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);

        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, width, height);

        if (isVisible === true) {
            const anatomyLabel = "Anatomy"
            const lumenLabel = "Lumen"
            const wallLabel = "Wall"
            const analyzedLabel = "(Analyzed)"
            const fontInfo = mkFontInfo(Math.max(height * 0.035, 12), 'serif');
            const fontInfoString = fontInfo.sizeInPixels + " " + fontInfo.font

            const barRightMargin = width * 0.01;
            const barBottomMargin = height * 0.09 + 10;

            const barTop = height * 0.045 + (fontInfo.sizeInPixels*3);
            const barHeight = height - barTop - barBottomMargin;

            const barWidth = Math.max((height * 0.02) + 3, 9);
            const barLeft = width - barWidth - barRightMargin;

            const segmentHeight = barHeight * 0.333;
            const textPadding = width * 0.01

            ctx.fillStyle = 'rgb(255, 0, 0)';
            ctx.fillRect(barLeft, barTop, barWidth, segmentHeight);

            ctx.fillStyle = 'rgb(128, 128, 0)';
            ctx.fillRect(barLeft, barTop + segmentHeight, barWidth, segmentHeight);

            ctx.fillStyle = 'rgb(255, 128, 0)';
            ctx.fillRect(barLeft, barTop + (segmentHeight*2), barWidth, segmentHeight);

            ctx.font = fontInfoString;
            ctx.fillStyle = 'white';
            ctx.fillText(
                anatomyLabel,
                barLeft - ctx.measureText(anatomyLabel).width + (height * 0.01),
                barTop - fontInfo.sizeInPixels + 3);
            ctx.fillText(
                lumenLabel,
                barLeft - ctx.measureText(lumenLabel).width - textPadding,
                segmentHeight*0.5 + barTop);
            ctx.fillText(
                wallLabel,
                barLeft - ctx.measureText(wallLabel).width - textPadding,
                segmentHeight*1.5 + barTop);
            ctx.fillText(
                lumenLabel,
                barLeft - ctx.measureText(lumenLabel).width - textPadding,
                segmentHeight*2.5 + barTop - (fontInfo.sizeInPixels * 0.5));
            ctx.fillText(
                analyzedLabel,
                barLeft - ctx.measureText(analyzedLabel).width - textPadding,
                segmentHeight*2.5 + barTop + (fontInfo.sizeInPixels * 0.5));
        }
    }

    // Effect to create the scalar bar canvas
    useEffect(() => {
        if (container && !context.current) {
            // Initialize
            const { width, height } = container.getBoundingClientRect();

            const createCanvas = id => {
                const canvas = document.createElement('canvas');
                canvas.classList.add('vtkLabel');
                canvas.setAttribute("id", id);
                canvas.setAttribute("width", width);
                canvas.setAttribute("height", height);
                container.appendChild(canvas);
                return canvas;
            };
            const anatomyScalarBarCanvas = createCanvas(constants.anatomyScalarBarCanvasId);
            anatomyScalarBarCanvas.style.pointerEvents = "none";

            const redrawScalarBar = () => {
                if (container && context.current) {
                    const { anatomyScalarBarCanvas } = context.current;
                    const dims = container.getBoundingClientRect();

                    drawAnatomyScalarBar(anatomyScalarBarCanvas, dims);
                }
            }
            // Render on container resizes
            const resizeObserver = new ResizeObserver(redrawScalarBar);
            resizeObserver.observe(container);

            context.current = {
                anatomyScalarBarCanvas,
                resizeObserver
            };
        }
        let ctx = context.current;

        return () => {
            if (ctx) {
                const { resizeObserver } = ctx;
                resizeObserver.unobserve(container);
                context.current = null;
                ctx = null;
            }
        };
    }, [container]);

    // Effect to show/hide the scalar bar
    useEffect(() => {
        if (context.current) {
            const { anatomyScalarBarCanvas } = context.current;
            if (initialized && isVisible === true) {
                anatomyScalarBarCanvas.style.visibility = "visible"
            } else {
                anatomyScalarBarCanvas.style.visibility = "hidden"
            }
        }
    }, [isVisible, initialized, container]);

    const redrawForCanvas = (canvas, dims) => {
        if (context.current) {
            const { anatomyScalarBarCanvas } = context.current;
            if (canvas.id == anatomyScalarBarCanvas.id) {
                drawAnatomyScalarBar(canvas, dims);
            }
        }
    };

    return [ redrawForCanvas ]
}