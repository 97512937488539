import { update as update_7, init as init_1 } from "./Notifications/State.js";
import { update as update_1, init as init_2 } from "./Auth/AuthState.js";
import { update as update_2, init as init_3 } from "./Institution/InstitutionState.js";
import { update as update_3, init as init_4 } from "./User/UserState.js";
import { update as update_5, init as init_5 } from "./Study/StudyState.js";
import { update as update_6, init as init_6 } from "./FileManagement/FileUploadParentExample/FileParentState.js";
import { update as update_4, init as init_7 } from "./Patient/PatientState.js";
import { handleUpdatedUrl } from "./Routing.js";
import { Toast_errorToast, Urls_PatientPage, Urls_pageHash, Urls_Page, Urls_AuthPage } from "./Common/General.js";
import { update as update_8, init as init_8 } from "./SystemInformation/SystemInformationState.js";
import { AppState_canLogout, AppState_canStayLoggedIn, Msg, Model } from "./Types.js";
import { Cmd_OfPromise_either, Cmd_none, Cmd_ofEffect, Cmd_map, Cmd_batch } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { cons, empty, singleton, ofArray, iterate } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { contains } from "./fable_modules/fable-library-js.4.19.3/Array.js";
import { defaultOf, comparePrimitives, structuralHash, equals } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { value as value_1, some } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { UpdateArgs, InstitutionFileArgs, Msg as Msg_1 } from "./Institution/InstitutionTypes.js";
import { Msg as Msg_4, Model as Model_1 } from "./Study/StudyTypes.js";
import { Msg as Msg_3, Model as Model_2 } from "./Patient/PatientTypes.js";
import { Msg as Msg_2 } from "./User/UserTypes.js";
import { Navigation_newUrl } from "./fable_modules/Fable.Elmish.Browser.4.0.3/navigation.fs.js";
import { FileManagementUpdateArgs } from "./FileManagement/FileManagementTypes.js";
import { subscribe } from "./Institution/InstitutionApi.js";
import { subscribe as subscribe_1 } from "./User/UserApi.js";
import { subscribe as subscribe_2 } from "./Auth/AuthApi.js";
import { subscribe as subscribe_3 } from "./Patient/PatientApi.js";
import { subscribe as subscribe_4 } from "./Study/StudyApi.js";
import { subscribe as subscribe_5 } from "./Notifications/Api.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedAwsApi } from "./Api.js";
import { S3Context as S3Context_2, mkS3Client, updateGlobalConfig, mkCredentials } from "./Common/AwsCommon.js";
import { ofList } from "./fable_modules/fable-library-js.4.19.3/Map.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Msg as Msg_6, UpdateArgs as UpdateArgs_1 } from "./SystemInformation/SystemInformationTypes.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library-js.4.19.3/Seq.js";
import { Msg as Msg_5 } from "./Notifications/Types.js";
import { SessionManagement_SessionManagementModel__get_token } from "./Session.js";
import { toUniversalTime, compare, utcNow, op_Addition } from "./fable_modules/fable-library-js.4.19.3/Date.js";
import { fromMinutes } from "./fable_modules/fable-library-js.4.19.3/TimeSpan.js";

export function init(result) {
    const patternInput = init_1();
    const patternInput_1 = init_2();
    const patternInput_2 = init_3();
    const patternInput_3 = init_4();
    const patternInput_4 = init_5();
    const patternInput_5 = init_6();
    const patternInput_6 = init_7();
    const patternInput_8 = handleUpdatedUrl(result, new Model(patternInput[0], new Urls_Page(0, [new Urls_AuthPage(0, [])]), patternInput_1[0], patternInput_2[0], patternInput_3[0], patternInput_4[0], patternInput_5[0], undefined, undefined, undefined, undefined, patternInput_6[0], init_8()[0], false, undefined, false));
    return [patternInput_8[0], Cmd_batch(ofArray([patternInput_8[1], Cmd_map((Item) => (new Msg(9, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(5, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(6, [Item_3])), patternInput_3[1]), Cmd_map((Item_4) => (new Msg(7, [Item_4])), patternInput_4[1]), Cmd_map((Item_5) => (new Msg(8, [Item_5])), patternInput_5[1]), Cmd_map((Item_6) => (new Msg(12, [Item_6])), patternInput_6[1]), Cmd_ofEffect((dispatch) => {
        iterate((ev) => {
            window.addEventListener(ev, (e) => {
                const clickPath = e.composedPath();
                if (!contains(document.getElementById("sidebar-nav"), clickPath, {
                    Equals: equals,
                    GetHashCode: structuralHash,
                })) {
                    dispatch(new Msg(14, [false]));
                }
            });
        }, ofArray(["click", "mousedown", "touchstart", "onfocus", "resize"]));
    })]))];
}

export function authenticatedUpdate(isInactivityDisabled, token, msg, model) {
    let bind$0040, bind$0040_1, rawTopics, matchValue, ui, linkMessageSub;
    let matchResult, ex, msg_2, msg_5, msg_6, is, msg_7, msg_14, msg_15, _msg, editMode, toEdit, msg_16, msg_17, is_1, msg_18, msg_21, page, client, msg_22, msg_24, config, r, creds_1, s3Context_1, active, msg_26;
    switch (msg.tag) {
        case 2: {
            switch (msg.fields[0].tag) {
                case 17: {
                    if (msg.fields[0].fields[0].tag === 2) {
                        matchResult = 1;
                        msg_2 = msg.fields[0];
                    }
                    else {
                        matchResult = 3;
                        msg_6 = msg.fields[0];
                    }
                    break;
                }
                case 6: {
                    matchResult = 2;
                    msg_5 = msg.fields[0];
                    break;
                }
                default: {
                    matchResult = 3;
                    msg_6 = msg.fields[0];
                }
            }
            break;
        }
        case 5: {
            if (msg.fields[0].tag === 3) {
                matchResult = 4;
                is = msg.fields[0].fields[0];
                msg_7 = msg.fields[0];
            }
            else {
                matchResult = 5;
                msg_14 = msg.fields[0];
            }
            break;
        }
        case 6: {
            matchResult = 6;
            msg_15 = msg.fields[0];
            break;
        }
        case 7: {
            if (msg.fields[0].tag === 23) {
                matchResult = 7;
                _msg = msg.fields[0];
                editMode = msg.fields[0].fields[1];
                toEdit = msg.fields[0].fields[0];
            }
            else {
                matchResult = 8;
                msg_16 = msg.fields[0];
            }
            break;
        }
        case 8: {
            if ((model.Credentials != null) && (model.S3Context != null)) {
                matchResult = 9;
                msg_17 = msg.fields[0];
            }
            else {
                matchResult = 10;
            }
            break;
        }
        case 12: {
            if (msg.fields[0].tag === 3) {
                matchResult = 11;
                is_1 = msg.fields[0].fields[0];
                msg_18 = msg.fields[0];
            }
            else {
                matchResult = 12;
                msg_21 = msg.fields[0];
            }
            break;
        }
        case 1: {
            matchResult = 13;
            page = msg.fields[0];
            break;
        }
        case 9: {
            if (msg.fields[0].tag === 3) {
                matchResult = 14;
                client = msg.fields[0].fields[0];
                msg_22 = msg.fields[0];
            }
            else {
                matchResult = 15;
                msg_24 = msg.fields[0];
            }
            break;
        }
        case 10: {
            matchResult = 16;
            break;
        }
        case 11: {
            matchResult = 17;
            config = msg.fields[0];
            break;
        }
        case 3: {
            matchResult = 18;
            r = msg.fields[0];
            break;
        }
        case 4: {
            matchResult = 19;
            creds_1 = msg.fields[0];
            s3Context_1 = msg.fields[1];
            break;
        }
        case 14: {
            matchResult = 20;
            active = msg.fields[0];
            break;
        }
        case 13: {
            matchResult = 21;
            msg_26 = msg.fields[0];
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1: {
            const patternInput = update_1(isInactivityDisabled, msg_2, model.AuthModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, patternInput[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1]), singleton((dispatch) => {
                dispatch(new Msg(5, [new Msg_1(2, [])]));
            })]))];
        }
        case 2: {
            const model_1 = new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, (bind$0040 = model.StudyModel, new Model_1(undefined, bind$0040.Studies, bind$0040.Patients, bind$0040.Institutions, bind$0040.PatientGuid, bind$0040.SelectedPatient, bind$0040.PatientInstitution, bind$0040.StudyGuid, bind$0040.CoreFormComponent, bind$0040.isStudiesLoading, bind$0040.isAnalysisLoading, bind$0040.AnalysisFormComponent, bind$0040.SelectedAnalysis, bind$0040.VisualizationComponent, bind$0040.CustomReportComponent, bind$0040.CustomReportModel, bind$0040.Credentials, bind$0040.S3Context)), model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, (bind$0040_1 = model.PatientsModel, new Model_2(bind$0040_1.Mode, undefined, bind$0040_1.UserInstitutions, bind$0040_1.Patients, bind$0040_1.SelectedPatient, bind$0040_1.CreateEditPatientVm, bind$0040_1.SearchForHospitalPatientIdVm, bind$0040_1.SearchForInternalPatientIdVm, bind$0040_1.SearchByNameDobVm, bind$0040_1.ListDisplayModifications, bind$0040_1.CreateEditFieldsValid, bind$0040_1.SortInfo, bind$0040_1.FilterFieldsValid, bind$0040_1.Filter)), model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug);
            const patternInput_1 = update_1(isInactivityDisabled, msg_5, model_1.AuthModel);
            return [new Model(model_1.NotificationsModel, model_1.CurrentPage, patternInput_1[0], model_1.InstitutionsModel, model_1.UsersModel, model_1.StudyModel, model_1.FileManagementModel, model_1.S3Context, model_1.AwsToken, model_1.AwsConfig, model_1.Credentials, model_1.PatientsModel, model_1.SystemInformationModel, model_1.MenuIsExpanded, model_1.RedirectPage, model_1.IsDebug), Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = update_1(isInactivityDisabled, msg_6, model.AuthModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, patternInput_2[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_2) => (new Msg(2, [Item_2])), patternInput_2[1])];
        }
        case 4: {
            const patternInput_3 = update_2(new UpdateArgs(new InstitutionFileArgs(token, model.Credentials, model.S3Context), token), msg_7, model.InstitutionsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, patternInput_3[0], model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((Item_3) => (new Msg(5, [Item_3])), patternInput_3[1]), singleton((dispatch_1) => {
                dispatch_1(new Msg(6, [new Msg_2(5, [is])]));
            }), singleton((dispatch_2) => {
                dispatch_2(new Msg(12, [new Msg_3(6, [is])]));
            }), singleton((dispatch_3) => {
                dispatch_3(new Msg(7, [new Msg_4(30, [is])]));
            })]))];
        }
        case 5: {
            const patternInput_4 = update_2(new UpdateArgs(new InstitutionFileArgs(token, model.Credentials, model.S3Context), token), msg_14, model.InstitutionsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, patternInput_4[0], model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_4) => (new Msg(5, [Item_4])), patternInput_4[1])];
        }
        case 6: {
            const patternInput_5 = update_3(model.AuthModel.userInfo, token, msg_15, model.UsersModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, patternInput_5[0], model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_5) => (new Msg(6, [Item_5])), patternInput_5[1])];
        }
        case 7: {
            const patternInput_6 = update_4(token, new Msg_3(8, [toEdit, editMode]), model.PatientsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, patternInput_6[0], model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(0, [])]))), Cmd_map((Item_6) => (new Msg(12, [Item_6])), patternInput_6[1])]))];
        }
        case 8: {
            const patternInput_7 = update_5(token, msg_16, model.StudyModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, patternInput_7[0], model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_7) => (new Msg(7, [Item_7])), patternInput_7[1])];
        }
        case 9: {
            const patternInput_8 = update_6(new FileManagementUpdateArgs(token, value_1(model.Credentials), value_1(model.S3Context)), msg_17, model.FileManagementModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, patternInput_8[0], model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_8) => (new Msg(8, [Item_8])), patternInput_8[1])];
        }
        case 10:
            return [model, Toast_errorToast("S3 credentials or context missing.")];
        case 11: {
            const patternInput_9 = update_4(token, msg_18, model.PatientsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, patternInput_9[0], model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((Item_9) => (new Msg(12, [Item_9])), patternInput_9[1]), singleton((dispatch_4) => {
                dispatch_4(new Msg(7, [new Msg_4(29, [is_1])]));
            })]))];
        }
        case 12: {
            const patternInput_10 = update_4(token, msg_21, model.PatientsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, patternInput_10[0], model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_10) => (new Msg(12, [Item_10])), patternInput_10[1])];
        }
        case 13:
            return [model, Cmd_batch(singleton(Navigation_newUrl(Urls_pageHash(page))))];
        case 14: {
            const patternInput_11 = update_7(token, msg_22, model.NotificationsModel);
            const subs_1 = Cmd_ofEffect((rawTopics = ((matchValue = model.AuthModel.userInfo, (matchValue != null) ? ((ui = matchValue, (linkMessageSub = ((apiSubscription, pageMessage) => {
                const tupledArg = apiSubscription(ui.id);
                return [tupledArg[0], (arg_1) => pageMessage(tupledArg[1](arg_1))];
            }), ofArray([linkMessageSub(subscribe, (Item_11) => (new Msg(5, [Item_11]))), linkMessageSub(subscribe_1, (Item_12) => (new Msg(6, [Item_12]))), linkMessageSub(subscribe_2, (Item_13) => (new Msg(2, [Item_13]))), linkMessageSub(subscribe_3, (Item_14) => (new Msg(12, [Item_14]))), linkMessageSub(subscribe_4, (Item_15) => (new Msg(7, [Item_15])))])))) : empty())), (dispatch_5) => {
                subscribe_5(client, rawTopics, dispatch_5);
            }));
            return [new Model(patternInput_11[0], model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((Item_16) => (new Msg(9, [Item_16])), patternInput_11[1]), subs_1]))];
        }
        case 15: {
            const patternInput_12 = update_7(token, msg_24, model.NotificationsModel);
            return [new Model(patternInput_12[0], model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_17) => (new Msg(9, [Item_17])), patternInput_12[1])];
        }
        case 16:
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedAwsApi(token).getS3Configuration, undefined, (Item_18) => (new Msg(11, [Item_18])), (Item_19) => (new Msg(0, [Item_19])))];
        case 17:
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, config, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedAwsApi(token).getIdentity, undefined, (Item_20) => (new Msg(3, [Item_20])), (Item_21) => (new Msg(0, [Item_21])))];
        case 18: {
            const matchValue_1 = model.AwsConfig;
            if (matchValue_1 != null) {
                const config_1 = matchValue_1;
                if (r.tag === 1) {
                    return [model, Cmd_none()];
                }
                else {
                    const result = r.fields[0];
                    const credentials = mkCredentials(config_1.IdentityPoolId, result.IdentityId, config_1.Region, ofList(singleton([config_1.Authority, result.Token]), {
                        Compare: comparePrimitives,
                    }));
                    updateGlobalConfig(config_1.Region, credentials);
                    const s3Context = new S3Context_2(config_1, mkS3Client(config_1.TempBucket), undefined, config_1.PermanentBucket);
                    return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, s3Context, result, model.AwsConfig, credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_OfPromise_either((tupledArg_1) => {
                        const creds = tupledArg_1[0];
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (creds.getPromise().then(() => (Promise.resolve([creds, tupledArg_1[1]]))))));
                    }, [credentials, s3Context], (tupledArg_2) => (new Msg(4, [tupledArg_2[0], tupledArg_2[1]])), (Item_24) => (new Msg(0, [Item_24])))];
                }
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 19:
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, new S3Context_2(s3Context_1.S3Config, s3Context_1.S3Client, creds_1.expireTime, s3Context_1.ActiveBucket), model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(singleton(singleton((dispatch_6) => {
                dispatch_6(new Msg(7, [new Msg_4(31, [creds_1, s3Context_1])]));
            })))];
        case 20:
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, active, model.RedirectPage, model.IsDebug), Cmd_none()];
        default: {
            const patternInput_13 = update_8(new UpdateArgs_1(token), msg_26, model.SystemInformationModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, patternInput_13[0], model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_26) => (new Msg(13, [Item_26])), patternInput_13[1])];
        }
    }
}

export function update(msg, model) {
    const isInactivityDisabled = () => AppState_canStayLoggedIn(model);
    let patternInput_4;
    let matchResult, ex, msg_2, user, msg_16, msg_17, msg_18;
    switch (msg.tag) {
        case 0: {
            matchResult = 0;
            ex = msg.fields[0];
            break;
        }
        case 2: {
            switch (msg.fields[0].tag) {
                case 6: {
                    if (!AppState_canLogout(model)) {
                        matchResult = 1;
                    }
                    else {
                        matchResult = 3;
                        msg_16 = msg.fields[0];
                    }
                    break;
                }
                case 16: {
                    matchResult = 2;
                    msg_2 = msg.fields[0];
                    user = msg.fields[0].fields[0];
                    break;
                }
                default: {
                    matchResult = 3;
                    msg_16 = msg.fields[0];
                }
            }
            break;
        }
        default:
            if (SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement) != null) {
                matchResult = 4;
                msg_17 = msg;
            }
            else {
                matchResult = 5;
                msg_18 = msg;
            }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            patternInput_4 = [model, Cmd_none()];
            break;
        }
        case 1: {
            patternInput_4 = [model, Toast_errorToast("Unable to log out. Please complete or cancel the current operation.")];
            break;
        }
        case 2: {
            const patternInput = update_1(isInactivityDisabled, msg_2, model.AuthModel);
            patternInput_4 = [new Model(model.NotificationsModel, model.CurrentPage, patternInput[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, equals(model.CurrentPage, new Urls_Page(0, [new Urls_AuthPage(0, [])])) ? undefined : model.RedirectPage, model.IsDebug), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1])), delay(() => append(singleton_1(singleton((dispatch) => {
                dispatch(new Msg(6, [new Msg_2(2, [])]));
            })), delay(() => append(singleton_1(singleton((dispatch_1) => {
                dispatch_1(new Msg(9, [new Msg_5(1, [])]));
            })), delay(() => append(singleton_1(singleton((dispatch_2) => {
                dispatch_2(new Msg(5, [new Msg_1(1, [user])]));
            })), delay(() => append(singleton_1(singleton((dispatch_3) => {
                dispatch_3(new Msg(7, [new Msg_4(16, [user])]));
            })), delay(() => append(singleton_1(singleton((dispatch_4) => {
                dispatch_4(new Msg(12, [new Msg_3(1, [user])]));
            })), delay(() => append(singleton_1(singleton((dispatch_5) => {
                dispatch_5(new Msg(13, [new Msg_6(1, [user])]));
            })), delay(() => append(singleton_1(singleton((dispatch_6) => {
                dispatch_6(new Msg(10, []));
            })), delay(() => {
                if (equals(model.CurrentPage, new Urls_Page(0, [new Urls_AuthPage(0, [])]))) {
                    const matchValue = model.RedirectPage;
                    return (matchValue == null) ? singleton_1(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(0, [])])))) : singleton_1(Navigation_newUrl(Urls_pageHash(matchValue)));
                }
                else {
                    return empty_1();
                }
            })))))))))))))))))))];
            break;
        }
        case 3: {
            const patternInput_1 = update_1(isInactivityDisabled, msg_16, model.AuthModel);
            patternInput_4 = [new Model(model.NotificationsModel, model.CurrentPage, patternInput_1[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1])];
            break;
        }
        case 4: {
            const token = value_1(SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement)).rawToken;
            let patternInput_2;
            const expiryThreshold = op_Addition(utcNow(), fromMinutes(10));
            const matchValue_1 = model.S3Context;
            let matchResult_1, expiry_1;
            if (matchValue_1 != null) {
                if (matchValue_1.S3Expiry != null) {
                    if (compare(toUniversalTime(matchValue_1.S3Expiry), expiryThreshold) <= 0) {
                        matchResult_1 = 0;
                        expiry_1 = matchValue_1.S3Expiry;
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0: {
                    patternInput_2 = [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, undefined, undefined, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), singleton(Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedAwsApi(token).getIdentity, undefined, (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(0, [Item_3]))))];
                    break;
                }
                default:
                    patternInput_2 = [model, empty()];
            }
            const patternInput_3 = authenticatedUpdate(isInactivityDisabled, token, msg_17, patternInput_2[0]);
            patternInput_4 = [patternInput_3[0], Cmd_batch(cons(patternInput_3[1], patternInput_2[1]))];
            break;
        }
        default: {
            console.log(some(toText(printf("Ignoring unauthenticated msg: %O"))(msg_18)));
            patternInput_4 = [model, Cmd_none()];
        }
    }
    const moddedModel = patternInput_4[0];
    window.onbeforeunload = (!AppState_canLogout(moddedModel) ? ((e) => {
        e.preventDefault();
    }) : defaultOf());
    return [moddedModel, patternInput_4[1]];
}

