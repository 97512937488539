import { Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, float64_type, bool_type, int32_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { wrapLocalMsg } from "../../../Common/InboundOutbound.js";
import { head as head_2, isEmpty, cons, exists, fold, empty, ofArray, tryFindIndex, length, singleton, collect } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { icon as icon_1, cta, input, Label_label, name } from "../../../fable_modules/Fulma.3.0.0/Elements/Form/File.fs.js";
import { Color_IColor, Common_GenericOption } from "../../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { tryGetOngoingZip } from "./AutoZipState.js";
import { equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import * as react from "react";
import { keyValueList } from "../../../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { Option, progress } from "../../../fable_modules/Fulma.3.0.0/Elements/Progress.fs.js";
import { interpolate, toText } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { toArray } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ComponentVisibility, Button_visibilityControlled } from "../../../Common/Controls.js";
import { DOMAttr, HTMLAttr, CSSProp } from "../../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { Option as Option_1 } from "../../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { labelText, LocalMsg } from "./AutoZipTypes.js";
import { icon } from "../../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { IDropzoneInputProps_makeFileInputProps_193CD1B9, IDropzoneRootProps_makeProps_5BEA2B69, IDropzoneOptions } from "../../../Common/ReactDropzone.js";
import { map } from "../../../fable_modules/fable-library-js.4.19.3/Array.js";
import { FileData_createFromFile } from "../../../Common/FileData.js";
import react_dropzone from "react-dropzone";

export class ZipBatchStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NotStarted", "InProgress"];
    }
}

export function ZipBatchStatus_$reflection() {
    return union_type("RAWMap.Client.FileUploadSelectors.AutoZipFileSelector.View.ZipBatchStatus", [], ZipBatchStatus, () => [[], [["currentChunk", int32_type], ["numChunks", int32_type], ["isActivelyZipping", bool_type], ["batchPercentageComplete", float64_type]]]);
}

export function uploadControl(model, dispatch, _maybeFileTypeRestrictions) {
    const dispatch_1 = (arg) => {
        dispatch(wrapLocalMsg(arg));
    };
    const zipFileList = collect((activeBatch_1) => {
        let children_4;
        return singleton(name(singleton(new Common_GenericOption(1, [singleton(["style", {
            height: "auto",
        }])])), singleton((children_4 = toList(delay(() => {
            const matchValue_3 = tryGetOngoingZip(model);
            if (matchValue_3 != null) {
                let zipBatchStatus_1;
                const activeBatch = activeBatch_1;
                const ongoingZip = matchValue_3;
                const numChunks = length(activeBatch.zipBatch.ZipDirectories) | 0;
                if (numChunks === 0) {
                    zipBatchStatus_1 = (new ZipBatchStatus(0, []));
                }
                else {
                    const maybeZipDirIdx = tryFindIndex((zipDir) => equals(zipDir, ongoingZip.directory), activeBatch.zipBatch.ZipDirectories);
                    const computeCompletedChunksPercentage = (zipDirIdx) => ((zipDirIdx / numChunks) * 100);
                    const matchValue_1 = ongoingZip.state;
                    if (maybeZipDirIdx == null) {
                        zipBatchStatus_1 = (new ZipBatchStatus(0, []));
                    }
                    else if (matchValue_1.tag === 0) {
                        const zipDirIdx_2 = maybeZipDirIdx | 0;
                        zipBatchStatus_1 = (new ZipBatchStatus(1, [zipDirIdx_2 + 1, numChunks, false, computeCompletedChunksPercentage(zipDirIdx_2)]));
                    }
                    else {
                        const zipDirIdx_1 = maybeZipDirIdx | 0;
                        const chunkPercentContribution = ((matchValue_1.fields[0] / 100) / numChunks) * 100;
                        zipBatchStatus_1 = (new ZipBatchStatus(1, [zipDirIdx_1 + 1, numChunks, true, computeCompletedChunksPercentage(zipDirIdx_1) + chunkPercentContribution]));
                    }
                }
                const matchValue_4 = zipBatchStatus_1;
                if (matchValue_4.tag === 1) {
                    const currentChunk = matchValue_4.fields[0] | 0;
                    let zipStatus;
                    const patternInput = matchValue_4.fields[2] ? ["Zipping", currentChunk] : ["Uploading", currentChunk - 1];
                    zipStatus = (`${patternInput[0]} part ${patternInput[1]} of ${matchValue_4.fields[1]}`);
                    return append(singleton_1(activeBatch_1.zipBatch.BatchName), delay(() => {
                        let props;
                        return append(singleton_1((props = [["style", {
                            marginTop: "5px",
                            marginBottom: "5px",
                        }]], react.createElement("div", keyValueList(props, 1), zipStatus))), delay(() => {
                            let props_2, children_2, zipBatchStatus, batchPercentageComplete_1;
                            return singleton_1((props_2 = [["style", {
                                marginTop: "5px",
                                marginBottom: "5px",
                            }]], (children_2 = [(zipBatchStatus = zipBatchStatus_1, (zipBatchStatus.tag === 1) ? ((batchPercentageComplete_1 = zipBatchStatus.fields[3], progress(ofArray([new Option(4, [100]), new Option(3, [~~batchPercentageComplete_1])]), singleton(toText(interpolate("%f%P()%%", [batchPercentageComplete_1])))))) : "Waiting...")], react.createElement("div", keyValueList(props_2, 1), ...children_2))));
                        }));
                    }));
                }
                else {
                    return singleton_1(activeBatch_1.zipBatch.BatchName);
                }
            }
            else {
                return singleton_1(activeBatch_1.zipBatch.BatchName);
            }
        })), react.createElement("div", {}, ...children_4)))));
    }, ofArray(toArray(model.MaybeActiveZipBatch)));
    let zipUploadButtonAndProgress;
    const children_13 = toList(delay(() => append(singleton_1(Button_visibilityControlled(ofArray([new CSSProp(221, ["0"]), new CSSProp(125, ["block"])]), ((length(model.OutstandingZips) > 0) ? true : (model.MaybeCurrentZip != null)) ? (new ComponentVisibility(2, ["Wait for current files to finish"])) : ((model.MaybeActiveZipBatch == null) ? (new ComponentVisibility(2, ["No files selected for zipping"])) : (new ComponentVisibility(0, []))), ofArray([new Option_1(2, []), new Option_1(0, [new Color_IColor(6, [])]), new Option_1(17, [ofArray([["style", {
        display: "block",
    }], new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg) => {
        dispatch_1(new LocalMsg(3, []));
    }])])])]), ofArray([icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-upload"])), []))), react.createElement("span", {}, labelText.zipCta)]))), delay(() => zipFileList))));
    zipUploadButtonAndProgress = react.createElement("div", {}, ...children_13);
    const props_15 = keyValueList(fold((existingProps, newProp) => {
        if (!exists((existingProp) => {
            let matchResult;
            switch (existingProp.tag) {
                case 4: {
                    if (newProp.tag === 4) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
                    break;
                }
                case 3: {
                    if (newProp.tag === 3) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
                    break;
                }
                case 6: {
                    if (newProp.tag === 6) {
                        matchResult = 0;
                    }
                    else {
                        matchResult = 1;
                    }
                    break;
                }
                default:
                    matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return true;
                default:
                    return false;
            }
        }, existingProps)) {
            return cons(newProp, existingProps);
        }
        else {
            return existingProps;
        }
    }, ofArray([new IDropzoneOptions(1, [true]), new IDropzoneOptions(0, [false])]), ofArray([new IDropzoneOptions(6, [(dropzoneState_2) => ((arg_1) => ((arg_2) => ((children_8) => {
        const rootProps = IDropzoneRootProps_makeProps_5BEA2B69(arg_1.getRootProps());
        const props_8 = toList(delay(() => rootProps));
        return react.createElement("div", keyValueList(props_8, 1), ...children_8);
    })(arg_2)))(dropzoneState_2)(ofArray([((dropzoneState) => {
        let props_6;
        const fileInputProps = IDropzoneInputProps_makeFileInputProps_193CD1B9(dropzoneState.getInputProps());
        return Label_label(empty(), ofArray([input(singleton(new Common_GenericOption(1, [toList(delay(() => append(fileInputProps, delay(() => append(singleton_1(["webkitdirectory", ""]), delay(() => singleton_1(["mozdirectory", ""])))))))]))), cta(empty(), ofArray([icon_1(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-cloud-upload-alt"])), []))), (props_6 = [new HTMLAttr(63, ["file-label"]), ["style", {
            whiteSpace: "normal",
            textAlign: "center",
        }]], react.createElement("span", keyValueList(props_6, 1), "Drag and drop or click here to select a directory to upload"))]))]));
    })(dropzoneState_2), zipUploadButtonAndProgress]))]), new IDropzoneOptions(3, [(acceptedFiles, _event) => {
        dispatch_1(new LocalMsg(1, [ofArray(map((fileWithPath) => FileData_createFromFile(fileWithPath.path, fileWithPath), acceptedFiles))]));
    }]), new IDropzoneOptions(4, [(rejections, _event_1) => {
        const matchValue = ofArray(rejections);
        let matchResult_1, head_1;
        if (!isEmpty(matchValue)) {
            if (head_2(matchValue).errors.length > 0) {
                matchResult_1 = 0;
                head_1 = head_2(matchValue);
            }
            else {
                matchResult_1 = 1;
            }
        }
        else {
            matchResult_1 = 1;
        }
        switch (matchResult_1) {
            case 0: {
                const error = head_1.errors[0];
                dispatch_1(new LocalMsg(0, [(error.code === "file-invalid-type") ? "Must be a DICOM directory." : head_1.errors[0].message]));
                break;
            }
            case 1: {
                break;
            }
        }
    }])])), 1);
    return react.createElement(react_dropzone, props_15);
}

