import { update as update_2, tryTerminate as tryTerminate_1, init as init_2, numberOfAssociatedFiles as numberOfAssociatedFiles_1 } from "../../../FileManagement/FileUploadSelectors/AutoZipSelector/AutoZipState.js";
import { InboundMsg as InboundMsg_1, FileManagementUpdateArgs, FileManagementViewModel_abortUpload_27E04A4D, Model_tryTerminate_Z379152CB, FileHandling, Model_getNumberOfAssociatedFiles_Z379152CB } from "../../../FileManagement/FileManagementTypes.js";
import { Common_ActiveBucketState_getBucketState_Z58682287 } from "../../../RAWMap.Models/Common.js";
import { update as update_1, init as init_1 } from "../../../FileManagement/FileManagementState.js";
import { OutboundMsg, LocalMsg, Model } from "./CoreFormFileTypes.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { length, singleton, iterate, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { toList } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { update as update_3, wrapInboundMsg } from "../../../Common/InboundOutbound.js";
import { AutoZipFileSelectorUpdateArgs, InboundMsg } from "../../../FileManagement/FileUploadSelectors/AutoZipSelector/AutoZipTypes.js";
import { S3Context } from "../../../Common/AwsCommon.js";
import { some } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";

export function numberOfAssociatedFiles(model) {
    return numberOfAssociatedFiles_1(Model_getNumberOfAssociatedFiles_Z379152CB(model.fileUpload), model.autoZip);
}

export function getFileHandling(studyStatus) {
    const matchValue = Common_ActiveBucketState_getBucketState_Z58682287(studyStatus);
    if (matchValue.tag === 1) {
        return new FileHandling(0, []);
    }
    else {
        return new FileHandling(1, []);
    }
}

export function init(fileSpecs, fileOperationContext, studyStatus) {
    const patternInput = init_1(fileSpecs, fileOperationContext, getFileHandling(studyStatus));
    const patternInput_1 = init_2(fileSpecs);
    return [new Model(patternInput[0], patternInput_1[0]), Cmd_batch(ofArray([Cmd_map((Item) => (new LocalMsg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new LocalMsg(3, [Item_1])), patternInput_1[1])]))];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Model_tryTerminate_Z379152CB(model.fileUpload), (_arg) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, tryTerminate_1(model.autoZip), (_arg_1) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, () => {
        _arg();
        _arg_1();
    })))));
}

export function forceTerminate(model) {
    iterate((arg) => {
        FileManagementViewModel_abortUpload_27E04A4D(arg[1]);
    }, toList(model.fileUpload.FileManagementsInProgress));
}

export function updateLocal(args, msg, model) {
    let msg_2, bind$0040, msg_8, msg_17;
    switch (msg.tag) {
        case 1:
            return [model, (msg_2 = (new LocalMsg(3, [wrapInboundMsg(new InboundMsg(0, []))])), singleton((dispatch) => {
                dispatch(msg_2);
            })), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 0) {
                const patternInput = update_1(new FileManagementUpdateArgs(args.Token, args.Credentials, (bind$0040 = args.Context, new S3Context(bind$0040.S3Config, bind$0040.S3Client, bind$0040.S3Expiry, args.Context.ActiveBucket))), msg.fields[0].fields[0], model.fileUpload);
                return [new Model(patternInput[0], model.autoZip), Cmd_map((Item_2) => (new LocalMsg(2, [Item_2])), patternInput[1]), Cmd_none()];
            }
            else {
                switch (msg.fields[0].fields[0].tag) {
                    case 0:
                        return [model, Cmd_none(), singleton((dispatch_1) => {
                            dispatch_1(new OutboundMsg(0, []));
                        })];
                    case 2:
                        return [model, Cmd_none(), singleton((dispatch_3) => {
                            dispatch_3(new OutboundMsg(2, []));
                        })];
                    case 3:
                        return [model, (msg_8 = (new LocalMsg(3, [wrapInboundMsg(new InboundMsg(1, []))])), singleton((dispatch_4) => {
                            dispatch_4(msg_8);
                        })), Cmd_none()];
                    case 5:
                        return [model, Cmd_none(), singleton((dispatch_7) => {
                            dispatch_7(new OutboundMsg(5, []));
                        })];
                    default:
                        return [model, Cmd_none(), Cmd_none()];
                }
            }
        case 3:
            if (msg.fields[0].tag === 0) {
                const patternInput_1 = update_2(new AutoZipFileSelectorUpdateArgs(args.Token, Model_getNumberOfAssociatedFiles_Z379152CB(model.fileUpload) + length(model.fileUpload.UploadSelection), args.Credentials, args.Context), msg.fields[0].fields[0], model.autoZip);
                return [new Model(model.fileUpload, patternInput_1[0]), Cmd_map((Item_5) => (new LocalMsg(3, [Item_5])), patternInput_1[1]), Cmd_none()];
            }
            else {
                switch (msg.fields[0].fields[0].tag) {
                    case 0:
                        return [model, Cmd_none(), singleton((dispatch_2) => {
                            dispatch_2(new OutboundMsg(1, []));
                        })];
                    case 3:
                        return [model, singleton((dispatch_5) => {
                            dispatch_5(new LocalMsg(1, []));
                        }), singleton((dispatch_6) => {
                            dispatch_6(new OutboundMsg(3, []));
                        })];
                    case 2:
                        return [model, (msg_17 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg_1(1, [msg.fields[0].fields[0].fields[0]]))])), singleton((dispatch_8) => {
                            dispatch_8(msg_17);
                        })), Cmd_none()];
                    default:
                        return [model, Cmd_none(), Cmd_none()];
                }
            }
        default: {
            console.error(some(msg.fields[0]));
            return [model, Cmd_none(), Cmd_none()];
        }
    }
}

export function updateInbound(_args, msg, model) {
    let msg_5, msg_2;
    if (msg.tag === 1) {
        return [model, (msg_5 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg_1(2, [getFileHandling(msg.fields[0])]))])), singleton((dispatch_1) => {
            dispatch_1(msg_5);
        })), Cmd_none()];
    }
    else {
        return [model, (msg_2 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg_1(0, []))])), singleton((dispatch) => {
            dispatch(msg_2);
        })), Cmd_none()];
    }
}

export function update(args, msg, model) {
    return update_3(updateLocal, updateInbound, args, msg, model);
}

