import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { FileManagementUpdateArgs_$reflection } from "../../../FileManagement/FileManagementTypes.js";
import { CustomReportConclusionViewModel_$reflection, CustomReportStatus_$reflection, CustomReportViewModel_$reflection } from "../../../RAWMap.Models/View/CustomReport.js";
import { lambda_type, union_type, class_type, bool_type, option_type, string_type, record_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ErrorMessage_$reflection } from "../../../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";

export class AdditionalDataUpdateArgs extends Record {
    constructor(fileArgs, customReport) {
        super();
        this.fileArgs = fileArgs;
        this.customReport = customReport;
    }
}

export function AdditionalDataUpdateArgs_$reflection() {
    return record_type("RAWMap.Client.Study.AdditionalData.Types.AdditionalDataUpdateArgs", [], AdditionalDataUpdateArgs, () => [["fileArgs", FileManagementUpdateArgs_$reflection()], ["customReport", CustomReportViewModel_$reflection()]]);
}

export class AdditionalDataModel extends Record {
    constructor(MaybeConclusionBuffer, ShowConclusionModal, IsSaving) {
        super();
        this.MaybeConclusionBuffer = MaybeConclusionBuffer;
        this.ShowConclusionModal = ShowConclusionModal;
        this.IsSaving = IsSaving;
    }
}

export function AdditionalDataModel_$reflection() {
    return record_type("RAWMap.Client.Study.AdditionalData.Types.AdditionalDataModel", [], AdditionalDataModel, () => [["MaybeConclusionBuffer", option_type(string_type)], ["ShowConclusionModal", bool_type], ["IsSaving", bool_type]]);
}

export function AdditionalDataModel_get_maybeConclusionBuffer_() {
    return [(m) => m.MaybeConclusionBuffer, (v) => ((m_1) => (new AdditionalDataModel(v, m_1.ShowConclusionModal, m_1.IsSaving)))];
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg_$reflection() {
    return class_type("RAWMap.Client.Study.AdditionalData.Types.InboundMsg", undefined, InboundMsg);
}

export class OutboundMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateCustomReportStatus", "SaveFinished"];
    }
}

export function OutboundMsg_$reflection() {
    return union_type("RAWMap.Client.Study.AdditionalData.Types.OutboundMsg", [], OutboundMsg, () => [[["Item", CustomReportStatus_$reflection()]], [["Item", CustomReportConclusionViewModel_$reflection()]]]);
}

export class LocalMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "SetConclusion", "EditConclusion", "CancelConclusion", "SaveData", "SavedData"];
    }
}

export function LocalMsg_$reflection() {
    return union_type("RAWMap.Client.Study.AdditionalData.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [["Item", lambda_type(AdditionalDataModel_$reflection(), AdditionalDataModel_$reflection())]], [], [], [], [["Item1", CustomReportConclusionViewModel_$reflection()], ["Item2", union_type("Microsoft.FSharp.Core.FSharpResult`2", [CustomReportStatus_$reflection(), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", CustomReportStatus_$reflection()]], [["ErrorValue", ErrorMessage_$reflection()]]])]]]);
}

