import { DefaultActions_create, DefaultActions_UpdateArgs$3, DefaultActions_runUpdate, ChildMsg_ofMsg, DefaultActions_ChildModel$2, Child$1, ChildAbsentStrategy$1, Child_absentChildCheck } from "../../Common/Terminate.js";
import { FileComponentSpecs, MaximumFiles } from "../Shared.js";
import { update as update_2, init as init_1 } from "../FileUploadSelectors/DragDropFileSelector/DragDropState.js";
import { Msg, Model } from "./FileParentTypes.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { Model_getNumberOfAssociatedFiles_Z379152CB, InboundMsg as InboundMsg_2, FileHandling, FileManagementUpdateArgs, Model_tryTerminate_Z379152CB } from "../FileManagementTypes.js";
import { update as update_3, init as init_3, tryTerminate as tryTerminate_1 } from "../FileUploadSelectors/AutoZipSelector/AutoZipState.js";
import { some } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { AutoZipFileSelectorUpdateArgs, InboundMsg } from "../FileUploadSelectors/AutoZipSelector/AutoZipTypes.js";
import { length, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { DragDropFileSelectorUpdateArgs, InboundMsg as InboundMsg_1 } from "../FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { Toastr_message, Toastr_title, Toastr_position, Toastr_timeout, Toastr_info } from "../../fable_modules/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { init as init_2, update as update_1 } from "../FileManagementState.js";
import { S3Context } from "../../Common/AwsCommon.js";
import { Toast_errorToast } from "../../Common/General.js";
import { Terminate_TerminateError_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { FileOperationContext } from "../../RAWMap.Models/Api.js";

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, [(model) => Child_absentChildCheck(model.fileUpload.child)]);

export const fileSpecs = new FileComponentSpecs(undefined, "UploadTest/", new MaximumFiles(0, []));

export function init() {
    const patternInput = init_1(fileSpecs);
    return [new Model(new DefaultActions_ChildModel$2(new Child$1(1, []), childAbsentStrategy, "file upload component"), patternInput[0], new DefaultActions_ChildModel$2(new Child$1(1, []), childAbsentStrategy, "auto zip component")), Cmd_map((Item) => (new Msg(5, [Item])), patternInput[1])];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => {
        let matchValue;
        return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (matchValue = model.fileUpload.child, (matchValue.tag === 1) ? (new FSharpResult$2(0, [() => {
        }])) : ((matchValue.tag === 2) ? (new FSharpResult$2(0, [() => {
        }])) : Model_tryTerminate_Z379152CB(matchValue.fields[0]))), (_arg) => {
            let matchValue_1;
            return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (matchValue_1 = model.autoZip.child, (matchValue_1.tag === 1) ? (new FSharpResult$2(0, [() => {
            }])) : ((matchValue_1.tag === 2) ? (new FSharpResult$2(0, [() => {
            }])) : tryTerminate_1(matchValue_1.fields[0]))), (_arg_1) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, () => {
                _arg();
                _arg_1();
            }));
        });
    }));
}

export function update(args, msg, model) {
    let msg_3, msg_6, arg_1, bind$0040_1, bind$0040_2, msg_20, msg_26, msg_30;
    let matchResult, ex, model_1, msg_10, fileModel, msg_22, msg_23, selected, selected_1, msg_32;
    switch (msg.tag) {
        case 1: {
            matchResult = 1;
            break;
        }
        case 2: {
            if (msg.fields[0].elmishChainedMsg.tag === 1) {
                if (msg.fields[0].elmishChainedMsg.fields[0].tag === 3) {
                    matchResult = 2;
                }
                else {
                    matchResult = 3;
                    msg_10 = msg.fields[0];
                }
            }
            else {
                matchResult = 3;
                msg_10 = msg.fields[0];
            }
            break;
        }
        case 4: {
            matchResult = 4;
            break;
        }
        case 3: {
            matchResult = 5;
            break;
        }
        case 5: {
            if (msg.fields[0].tag === 1) {
                matchResult = 8;
                selected = msg.fields[0].fields[0].fields[0];
            }
            else if (model.fileUpload.child.tag === 0) {
                matchResult = 6;
                fileModel = model.fileUpload.child.fields[0];
                msg_22 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 7;
                msg_23 = msg.fields[0].fields[0];
            }
            break;
        }
        case 6: {
            if (msg.fields[0].elmishChainedMsg.tag === 1) {
                if (msg.fields[0].elmishChainedMsg.fields[0].tag === 2) {
                    matchResult = 9;
                    selected_1 = msg.fields[0].elmishChainedMsg.fields[0].fields[0];
                }
                else {
                    matchResult = 10;
                    msg_32 = msg.fields[0];
                }
            }
            else {
                matchResult = 10;
                msg_32 = msg.fields[0];
            }
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
            model_1 = model;
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(ex));
            return [model_1, Cmd_none()];
        }
        case 1:
            return [model, Cmd_batch(ofArray([(msg_3 = (new Msg(6, [ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg(0, [])))])), singleton((dispatch) => {
                dispatch(msg_3);
            })), (msg_6 = (new Msg(5, [wrapInboundMsg(new InboundMsg_1())])), singleton((dispatch_1) => {
                dispatch_1(msg_6);
            }))]))];
        case 2:
            return [model, singleton((dispatch_2) => {
                dispatch_2(new Msg(1, []));
            })];
        case 3: {
            const patternInput = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_11, model_2) => {
                let bind$0040;
                if (msg_11.tag === 1) {
                    return [model_2, Toastr_info(Toastr_timeout(2000, Toastr_position("toast-bottom-right", Toastr_title("Info", Toastr_message(toText(printf("Outbound of %O"))(msg_11.fields[0]))))))];
                }
                else {
                    return update_1(new FileManagementUpdateArgs(args.Token, args.Credentials, (bind$0040 = args.Context, new S3Context(bind$0040.S3Config, bind$0040.S3Client, bind$0040.S3Expiry, args.Context.ActiveBucket))), msg_11.fields[0], model_2);
                }
            }, model), model.fileUpload, msg_10);
            return [new Model(patternInput[0], model.dragDrop, model.autoZip), Cmd_map((Item_2) => (new Msg(2, [Item_2])), patternInput[1])];
        }
        case 4: {
            const matchValue_1 = tryTerminate(model);
            if (matchValue_1.tag === 1) {
                return [model, Toast_errorToast((arg_1 = Terminate_TerminateError_get_describe()(matchValue_1.fields[0]), toText(printf("Termination failed: %O"))(arg_1)))];
            }
            else {
                matchValue_1.fields[0]();
                return [new Model((bind$0040_1 = model.fileUpload, new DefaultActions_ChildModel$2(new Child$1(2, []), bind$0040_1.childAbsentStrategy, bind$0040_1.childComponentName)), model.dragDrop, (bind$0040_2 = model.autoZip, new DefaultActions_ChildModel$2(new Child$1(2, []), bind$0040_2.childAbsentStrategy, bind$0040_2.childComponentName))), Cmd_none()];
            }
        }
        case 5: {
            const createdUpload = init_2(fileSpecs, new FileOperationContext(6, []), new FileHandling(0, []));
            const createdAutoZip = init_3(fileSpecs);
            const patternInput_1 = DefaultActions_create(createdUpload[0], createdUpload[1], model.fileUpload);
            const patternInput_2 = DefaultActions_create(createdAutoZip[0], createdAutoZip[1], model.autoZip);
            return [new Model(patternInput_1[0], model.dragDrop, patternInput_2[0]), Cmd_batch(ofArray([Cmd_map((Item_3) => (new Msg(2, [Item_3])), patternInput_1[1]), Cmd_map((Item_4) => (new Msg(6, [Item_4])), patternInput_2[1]), (msg_20 = (new Msg(2, [ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg_2(0, [])))])), singleton((dispatch_3) => {
                dispatch_3(msg_20);
            }))]))];
        }
        case 6: {
            const patternInput_3 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, Model_getNumberOfAssociatedFiles_Z379152CB(fileModel) + length(fileModel.UploadSelection), args.Credentials, args.Context), msg_22, model.dragDrop);
            return [new Model(model.fileUpload, patternInput_3[0], model.autoZip), Cmd_map((Item_6) => (new Msg(5, [Item_6])), patternInput_3[1])];
        }
        case 7: {
            const patternInput_4 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, 0, args.Credentials, args.Context), msg_23, model.dragDrop);
            return [new Model(model.fileUpload, patternInput_4[0], model.autoZip), Cmd_map((Item_7) => (new Msg(5, [Item_7])), patternInput_4[1])];
        }
        case 8:
            return [model, (msg_26 = (new Msg(2, [ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg_2(1, [selected])))])), singleton((dispatch_4) => {
                dispatch_4(msg_26);
            }))];
        case 9:
            return [model, (msg_30 = (new Msg(2, [ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg_2(1, [selected_1])))])), singleton((dispatch_5) => {
                dispatch_5(msg_30);
            }))];
        default: {
            const patternInput_5 = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_33, zipModel) => {
                if (msg_33.tag === 1) {
                    return [zipModel, Toastr_info(Toastr_timeout(2000, Toastr_position("toast-bottom-right", Toastr_title("Info", Toastr_message(toText(printf("Outbound of %O"))(msg_33.fields[0]))))))];
                }
                else if (model.fileUpload.child.tag === 0) {
                    return update_3(new AutoZipFileSelectorUpdateArgs(args.Token, Model_getNumberOfAssociatedFiles_Z379152CB(model.fileUpload.child.fields[0]) + length(model.fileUpload.child.fields[0].UploadSelection), args.Credentials, args.Context), msg_33.fields[0], zipModel);
                }
                else {
                    return update_3(new AutoZipFileSelectorUpdateArgs(args.Token, 0, args.Credentials, args.Context), msg_33.fields[0], zipModel);
                }
            }, model), model.autoZip, msg_32);
            return [new Model(model.fileUpload, model.dragDrop, patternInput_5[0]), Cmd_map((Item_12) => (new Msg(6, [Item_12])), patternInput_5[1])];
        }
    }
}

