import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { string_type, union_type, record_type, float64_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { toFail } from "../../fable_modules/fable-library-js.4.19.3/String.js";

export class Bounds extends Record {
    constructor(width, height) {
        super();
        this.width = width;
        this.height = height;
    }
}

export function Bounds_$reflection() {
    return record_type("Client.Visualization.Common.Bindings.Bounds", [], Bounds, () => [["width", float64_type], ["height", float64_type]]);
}

export class InteractionMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Standard", "StaticMoveNotes", "MeasurementMoveNotes", "Calipers", "CenterlineDistanceMeasurement", "Screenshot", "DiameterRangeMeasurement", "LocalDiameterMeasurement", "CenterlineVolumeMeasurement"];
    }
}

export function InteractionMode_$reflection() {
    return union_type("Client.Visualization.Common.Bindings.InteractionMode", [], InteractionMode, () => [[], [], [], [], [], [], [], [], []]);
}

export class DisplayMap extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Anatomy", "LocalExpansionGrowth", "DiametricGrowth", "Ilt", "ContinuousRaw", "Strain"];
    }
}

export function DisplayMap_$reflection() {
    return union_type("Client.Visualization.Common.Bindings.DisplayMap", [], DisplayMap, () => [[], [], [], [], [], []]);
}

export function DisplayMap_parse_Z721C83C5(s) {
    switch (s) {
        case "Anatomy":
            return new DisplayMap(0, []);
        case "LocalExpansionGrowth":
            return new DisplayMap(1, []);
        case "DiametricGrowth":
            return new DisplayMap(2, []);
        case "Ilt":
            return new DisplayMap(3, []);
        case "ContinuousRaw":
            return new DisplayMap(4, []);
        case "Strain":
            return new DisplayMap(5, []);
        default:
            return toFail(`Unable to parse ${s}`);
    }
}

export function DisplayMap_get_tryGetUnits() {
    return (_arg) => ((_arg.tag === 2) ? "(mm/year)" : ((_arg.tag === 3) ? "mm" : ((_arg.tag === 4) ? undefined : ((_arg.tag === 5) ? undefined : ((_arg.tag === 0) ? undefined : "(mm/year)")))));
}

export class Meshes_PointDataArrayNames extends Record {
    constructor(MaximumDiameters, SecondaryDiameters, CircleEquivalentDiameters, Normals, Volume, Distances, ZDimension, ZPosition) {
        super();
        this.MaximumDiameters = MaximumDiameters;
        this.SecondaryDiameters = SecondaryDiameters;
        this.CircleEquivalentDiameters = CircleEquivalentDiameters;
        this.Normals = Normals;
        this.Volume = Volume;
        this.Distances = Distances;
        this.ZDimension = ZDimension;
        this.ZPosition = ZPosition;
    }
}

export function Meshes_PointDataArrayNames_$reflection() {
    return record_type("Client.Visualization.Common.Bindings.Meshes.PointDataArrayNames", [], Meshes_PointDataArrayNames, () => [["MaximumDiameters", string_type], ["SecondaryDiameters", string_type], ["CircleEquivalentDiameters", string_type], ["Normals", string_type], ["Volume", string_type], ["Distances", string_type], ["ZDimension", string_type], ["ZPosition", string_type]]);
}

export class Meshes_FieldDataArrayNames extends Record {
    constructor(TransitionBranchTrunk, TransitionBranchLeft, TransitionBranchRight, TransitionVolume, DistanceBranchTrunk, DistanceBranchLeft, DistanceBranchRight, DistanceBranchTrunkCusp) {
        super();
        this.TransitionBranchTrunk = TransitionBranchTrunk;
        this.TransitionBranchLeft = TransitionBranchLeft;
        this.TransitionBranchRight = TransitionBranchRight;
        this.TransitionVolume = TransitionVolume;
        this.DistanceBranchTrunk = DistanceBranchTrunk;
        this.DistanceBranchLeft = DistanceBranchLeft;
        this.DistanceBranchRight = DistanceBranchRight;
        this.DistanceBranchTrunkCusp = DistanceBranchTrunkCusp;
    }
}

export function Meshes_FieldDataArrayNames_$reflection() {
    return record_type("Client.Visualization.Common.Bindings.Meshes.FieldDataArrayNames", [], Meshes_FieldDataArrayNames, () => [["TransitionBranchTrunk", string_type], ["TransitionBranchLeft", string_type], ["TransitionBranchRight", string_type], ["TransitionVolume", string_type], ["DistanceBranchTrunk", string_type], ["DistanceBranchLeft", string_type], ["DistanceBranchRight", string_type], ["DistanceBranchTrunkCusp", string_type]]);
}

