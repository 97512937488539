import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, bool_type, union_type, int32_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { AnatomyOrientationProps } from "./AnatomyOrientationBindings.js";

export class LocalMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "GetAnatomyOrientationData", "GotAnatomyOrientationData", "ToggleAnatomyOrientationMarker"];
    }
}

export function LocalMsg_$reflection() {
    return union_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("Fable.Core.JS.ArrayBuffer"), int32_type], FSharpResult$2, () => [[["ResultValue", class_type("Fable.Core.JS.ArrayBuffer")]], [["ErrorValue", int32_type]]])]], [["Item", bool_type]]]);
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg_$reflection() {
    return class_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.InboundMsg", undefined, InboundMsg);
}

export class OutboundMsg {
    constructor() {
    }
}

export function OutboundMsg_$reflection() {
    return class_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.OutboundMsg", undefined, OutboundMsg);
}

export class ModelAnatomyOrientation extends Record {
    constructor(MaybeAnatomyOrientationData, ShowAnatomyOrientation) {
        super();
        this.MaybeAnatomyOrientationData = MaybeAnatomyOrientationData;
        this.ShowAnatomyOrientation = ShowAnatomyOrientation;
    }
}

export function ModelAnatomyOrientation_$reflection() {
    return record_type("RAWMap.Client.Visualization.AnatomyOrientation.Types.ModelAnatomyOrientation", [], ModelAnatomyOrientation, () => [["MaybeAnatomyOrientationData", option_type(class_type("Fable.Core.JS.ArrayBuffer"))], ["ShowAnatomyOrientation", bool_type]]);
}

export function ModelAnatomyOrientation_Props_Z550008EC(model) {
    return new AnatomyOrientationProps(model.MaybeAnatomyOrientationData, model.ShowAnatomyOrientation);
}

