import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, list_type, unit_type, record_type, string_type, lambda_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Cmd_none, Cmd_map } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ChainedChildMsg$2 } from "./ElmishDebugger/ChainedChildMsg.js";
import { Toast_errorToast } from "./General.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";

export class ChildAbsentBehaviour extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Alert", "Ignore"];
    }
}

export function ChildAbsentBehaviour_$reflection() {
    return union_type("RAWMap.Client.Common.Terminate.Terminatable.ChildAbsentBehaviour", [], ChildAbsentBehaviour, () => [[], []]);
}

export class ChildAbsentStrategy$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Dynamic", "Fixed"];
    }
}

export function ChildAbsentStrategy$1_$reflection(gen0) {
    return union_type("RAWMap.Client.Common.Terminate.Terminatable.ChildAbsentStrategy`1", [gen0], ChildAbsentStrategy$1, () => [[["Item", lambda_type(gen0, ChildAbsentBehaviour_$reflection())]], [["Item", ChildAbsentBehaviour_$reflection()]]]);
}

export class Child$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Active", "NeverCreated", "Terminated"];
    }
}

export function Child$1_$reflection(gen0) {
    return union_type("RAWMap.Client.Common.Terminate.Terminatable.Child`1", [gen0], Child$1, () => [[["Item", gen0]], [], []]);
}

export function Child_absentChildCheck(terminatable) {
    switch (terminatable.tag) {
        case 2:
            return new ChildAbsentBehaviour(1, []);
        default:
            return new ChildAbsentBehaviour(0, []);
    }
}

export function ChildMsg_evaluateStrategy(strategy, parentModel) {
    if (strategy.tag === 1) {
        return strategy.fields[0];
    }
    else {
        return strategy.fields[0](parentModel);
    }
}

export function ChildMsg_mapMessage(strategy, childCmd) {
    return Cmd_map((childMsg) => (new ChainedChildMsg$2(childMsg, strategy)), childCmd);
}

export function ChildMsg_mapParentMessage(strategy, parentMessage, childCmd) {
    return Cmd_map(parentMessage, ChildMsg_mapMessage(strategy, childCmd));
}

export function ChildMsg_ofMsg(strategy, msg) {
    return new ChainedChildMsg$2(msg, strategy);
}

export class DefaultActions_ChildModel$2 extends Record {
    constructor(child, childAbsentStrategy, childComponentName) {
        super();
        this.child = child;
        this.childAbsentStrategy = childAbsentStrategy;
        this.childComponentName = childComponentName;
    }
}

export function DefaultActions_ChildModel$2_$reflection(gen0, gen1) {
    return record_type("RAWMap.Client.Common.Terminate.Terminatable.DefaultActions.ChildModel`2", [gen0, gen1], DefaultActions_ChildModel$2, () => [["child", Child$1_$reflection(gen0)], ["childAbsentStrategy", ChildAbsentStrategy$1_$reflection(gen1)], ["childComponentName", string_type]]);
}

export class DefaultActions_UpdateArgs$3 extends Record {
    constructor(runUpdate, parentModel) {
        super();
        this.runUpdate = runUpdate;
        this.parentModel = parentModel;
    }
}

export function DefaultActions_UpdateArgs$3_$reflection(gen0, gen1, gen2) {
    return record_type("RAWMap.Client.Common.Terminate.Terminatable.DefaultActions.UpdateArgs`3", [gen0, gen1, gen2], DefaultActions_UpdateArgs$3, () => [["runUpdate", lambda_type(gen0, lambda_type(gen1, tuple_type(gen1, list_type(lambda_type(lambda_type(gen0, unit_type), unit_type)))))], ["parentModel", gen2]]);
}

export function DefaultActions_runUpdate(args, model, msg) {
    const matchValue = model.child;
    let matchResult, strategy;
    switch (matchValue.tag) {
        case 2: {
            matchResult = 0;
            strategy = msg.heldData;
            break;
        }
        case 0: {
            matchResult = 1;
            break;
        }
        default: {
            matchResult = 0;
            strategy = msg.heldData;
        }
    }
    switch (matchResult) {
        case 0: {
            const _arg = ChildMsg_evaluateStrategy(strategy, args.parentModel);
            if (_arg.tag === 1) {
                return [model, Cmd_none()];
            }
            else {
                return [model, Toast_errorToast(toText(printf("%s does not exist."))(model.childComponentName))];
            }
        }
        default: {
            const patternInput = args.runUpdate(msg.elmishChainedMsg, matchValue.fields[0]);
            return [new DefaultActions_ChildModel$2(new Child$1(0, [patternInput[0]]), model.childAbsentStrategy, model.childComponentName), ChildMsg_mapMessage(model.childAbsentStrategy, patternInput[1])];
        }
    }
}

export function DefaultActions_terminate(terminate, model) {
    const matchValue = model.child;
    switch (matchValue.tag) {
        case 1:
        case 2:
            return [model, Toast_errorToast(toText(printf("%s component does not exist."))(model.childComponentName))];
        default: {
            const termination = terminate(matchValue.fields[0]);
            if (termination.tag === 1) {
                return [model, Cmd_none()];
            }
            else {
                termination.fields[0]();
                return [new DefaultActions_ChildModel$2(new Child$1(2, []), model.childAbsentStrategy, model.childComponentName), Cmd_none()];
            }
        }
    }
}

export function DefaultActions_create(childModel, childCommand, model) {
    const matchValue = model.child;
    switch (matchValue.tag) {
        case 0:
            return [model, Toast_errorToast(toText(printf("%s already exists."))(model.childComponentName))];
        default:
            return [new DefaultActions_ChildModel$2(new Child$1(0, [childModel]), model.childAbsentStrategy, model.childComponentName), ChildMsg_mapMessage(model.childAbsentStrategy, childCommand)];
    }
}

