import { Union } from "../fable-library-js.4.19.3/Types.js";
import { union_type } from "../fable-library-js.4.19.3/Reflection.js";
import { some, bind, value as value_2, map } from "../fable-library-js.4.19.3/Option.js";
import { compare, curry2 } from "../fable-library-js.4.19.3/Util.js";
import { empty, mapIndexed, item, length, cons, tail, head, isEmpty, toArray, ofArray } from "../fable-library-js.4.19.3/List.js";
import { FSharpChoice$2 } from "../fable-library-js.4.19.3/Choice.js";
import { FSharpResult$2 } from "../fable-library-js.4.19.3/Result.js";
import { ofList, toList, ofArray as ofArray_1, toArray as toArray_1, remove, add, containsKey, tryFind } from "../fable-library-js.4.19.3/Map.js";

export class Compose_Lens extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Lens"];
    }
}

export function Compose_Lens_$reflection() {
    return union_type("Aether.Compose.Lens", [], Compose_Lens, () => [[]]);
}

export function Compose_Lens_op_GreaterMinusGreater_31E453EA(_arg1, _arg) {
    return (_arg_1) => {
        const g1 = _arg_1[0];
        return [(a) => _arg[0](g1(a)), (c) => ((a_1) => _arg_1[1](_arg[1](c)(g1(a_1)))(a_1))];
    };
}

export function Compose_Lens_op_GreaterMinusGreater_17732769(_arg3, _arg) {
    return (_arg_1) => {
        const g1 = _arg_1[0];
        return [(a) => _arg[0](g1(a)), (c) => ((a_1) => _arg_1[1](_arg[1](c)(g1(a_1)))(a_1))];
    };
}

export function Compose_Lens_op_GreaterMinusGreater_2536FC39(_arg5, _arg) {
    return (_arg_1) => [(a) => _arg[0](_arg_1[0](a)), (c) => ((a_1) => _arg_1[1](_arg[1](c))(a_1))];
}

export function Compose_Lens_op_GreaterMinusGreater_6B29103A(_arg7, _arg) {
    return (_arg_1) => [(a) => _arg[0](_arg_1[0](a)), (c) => ((a_1) => _arg_1[1](_arg[1](c))(a_1))];
}

export class Compose_Prism extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Prism"];
    }
}

export function Compose_Prism_$reflection() {
    return union_type("Aether.Compose.Prism", [], Compose_Prism, () => [[]]);
}

export function Compose_Prism_op_GreaterQmarkGreater_4C84C7CB(_arg1, _arg) {
    return (_arg_1) => {
        const g1 = _arg_1[0];
        return [(a) => map(_arg[0], g1(a)), (c) => ((a_1) => {
            const _arg_2 = map(_arg[1](c), g1(a_1));
            return (_arg_2 != null) ? _arg_1[1](value_2(_arg_2))(a_1) : a_1;
        })];
    };
}

export function Compose_Prism_op_GreaterQmarkGreater_6A13B348(_arg3, _arg) {
    return (_arg_1) => {
        const g1 = _arg_1[0];
        return [(a) => bind(_arg[0], g1(a)), (c) => ((a_1) => {
            const _arg_2 = map(_arg[1](c), g1(a_1));
            return (_arg_2 != null) ? _arg_1[1](value_2(_arg_2))(a_1) : a_1;
        })];
    };
}

export function Compose_Prism_op_GreaterQmarkGreater_58566818(_arg5, _arg) {
    return (_arg_1) => [(a) => map(_arg[0], _arg_1[0](a)), (c) => ((a_1) => _arg_1[1](_arg[1](c))(a_1))];
}

export function Compose_Prism_op_GreaterQmarkGreater_1649841B(_arg7, _arg) {
    return (_arg_1) => [(a) => bind(_arg[0], _arg_1[0](a)), (c) => ((a_1) => _arg_1[1](_arg[1](c))(a_1))];
}

/**
 * Compose a total lens and a total lens, giving a total lens
 */
export function Compose_totalLensTotalLens(l1_, l1__1, l2_, l2__1) {
    const l1 = [l1_, curry2(l1__1)];
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), [l2_, curry2(l2__1)])(l1);
}

/**
 * Compose a total lens and a partial lens, giving a partial lens
 */
export function Compose_totalLensPartialLens(l1_, l1__1, p1_, p1__1) {
    const l1 = [l1_, curry2(l1__1)];
    return Compose_Lens_op_GreaterMinusGreater_17732769(new Compose_Lens(), [p1_, curry2(p1__1)])(l1);
}

/**
 * Compose a partial lens and a total lens, giving a partial lens
 */
export function Compose_partialLensTotalLens(p1_, p1__1, l1_, l1__1) {
    const p1 = [p1_, curry2(p1__1)];
    return Compose_Prism_op_GreaterQmarkGreater_4C84C7CB(new Compose_Prism(), [l1_, curry2(l1__1)])(p1);
}

/**
 * Compose two partial lenses, giving a partial lens
 */
export function Compose_partialLensPartialLens(p1_, p1__1, p2_, p2__1) {
    const p1 = [p1_, curry2(p1__1)];
    return Compose_Prism_op_GreaterQmarkGreater_6A13B348(new Compose_Prism(), [p2_, curry2(p2__1)])(p1);
}

/**
 * Compose a total lens with a total isomorphism, giving a total lens
 */
export function Compose_totalLensTotalIsomorphism(l1_, l1__1, i1_, i1__1) {
    const l1 = [l1_, curry2(l1__1)];
    return Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(), [i1_, i1__1])(l1);
}

/**
 * Compose a total lens with a partial isomorphism, giving a partial lens
 */
export function Compose_totalLensPartialIsomorphism(l1_, l1__1, p1_, p1__1) {
    const l1 = [l1_, curry2(l1__1)];
    return Compose_Lens_op_GreaterMinusGreater_6B29103A(new Compose_Lens(), [p1_, p1__1])(l1);
}

/**
 * Compose a partial lens with a total isomorphism, giving a partial lens
 */
export function Compose_partialLensTotalIsomorphism(p1_, p1__1, i1_, i1__1) {
    const p1 = [p1_, curry2(p1__1)];
    return Compose_Prism_op_GreaterQmarkGreater_58566818(new Compose_Prism(), [i1_, i1__1])(p1);
}

/**
 * Compose a partial lens with a partial isomorphism, giving a partial lens
 */
export function Compose_partialLensPartialIsomorphism(p1_, p1__1, e1_, e1__1) {
    const p1 = [p1_, curry2(p1__1)];
    return Compose_Prism_op_GreaterQmarkGreater_1649841B(new Compose_Prism(), [e1_, e1__1])(p1);
}

export class Optic_Get extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Get"];
    }
}

export function Optic_Get_$reflection() {
    return union_type("Aether.Optic.Get", [], Optic_Get, () => [[]]);
}

export function Optic_Get_op_HatDot_21762A61(_arg1, _arg) {
    return (a) => _arg[0](a);
}

export function Optic_Get_op_HatDot_7E15EE2(_arg3, _arg) {
    return (a) => _arg[0](a);
}

export class Optic_Set extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Set"];
    }
}

export function Optic_Set_$reflection() {
    return union_type("Aether.Optic.Set", [], Optic_Set, () => [[]]);
}

export function Optic_Set_op_HatEquals_2170E4F5(_arg1, _arg) {
    return (b) => _arg[1](b);
}

export function Optic_Set_op_HatEquals_7E79076(_arg3, _arg) {
    return (b) => _arg[1](b);
}

export class Optic_Map extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Map"];
    }
}

export function Optic_Map_$reflection() {
    return union_type("Aether.Optic.Map", [], Optic_Map, () => [[]]);
}

export function Optic_Map_op_HatPercent_216CF0AB(_arg1, _arg) {
    return (f) => ((a) => _arg[1](f(_arg[0](a)))(a));
}

export function Optic_Map_op_HatPercent_7FB8428(_arg3, _arg) {
    return (f) => ((a) => {
        const _arg_1 = map(f, _arg[0](a));
        return (_arg_1 != null) ? _arg[1](value_2(_arg_1))(a) : a;
    });
}

/**
 * Converts an isomorphism into a lens.
 */
export function Lens_ofIsomorphism(_arg1_, _arg1__1) {
    const _arg = [_arg1_, _arg1__1];
    return [_arg[0], (b) => ((_arg_1) => _arg[1](b))];
}

/**
 * Get a value option using a partial lens
 */
export function Lens_getPartial(p_, p__1) {
    const p = [p_, curry2(p__1)];
    return (target) => Optic_Get_op_HatDot_7E15EE2(new Optic_Get(), p)(target);
}

/**
 * Get a value or a default using a partial lens
 */
export function Lens_getPartialOrElse(p_, p__1, b) {
    const p = [p_, curry2(p__1)];
    return (arg_2) => {
        const _arg = Optic_Get_op_HatDot_7E15EE2(new Optic_Get(), p)(arg_2);
        return (_arg != null) ? value_2(_arg) : b;
    };
}

/**
 * Set a value using a partial lens
 */
export function Lens_setPartial(p_, p__1) {
    const p = [p_, curry2(p__1)];
    return (value) => Optic_Set_op_HatEquals_7E79076(new Optic_Set(), p)(value);
}

/**
 * Modify a value using a partial lens
 */
export function Lens_mapPartial(p_, p__1) {
    const p = [p_, curry2(p__1)];
    return (f) => Optic_Map_op_HatPercent_7FB8428(new Optic_Map(), p)(f);
}

/**
 * Converts an epimorphism into a prism.
 */
export function Prism_ofEpimorphism(_arg1_, _arg1__1) {
    const _arg = [_arg1_, _arg1__1];
    return [_arg[0], (b) => ((_arg_1) => _arg[1](b))];
}

export function Optics_id_() {
    return [(x) => x, (x_1) => ((_arg) => x_1)];
}

/**
 * Isomorphism between a boxed and unboxed type.
 */
export function Optics_box_() {
    return [(value) => value, (value_1) => value_1];
}

/**
 * Lens to the first item of a tuple.
 */
export function Optics_fst_() {
    return [(tuple) => tuple[0], (a) => ((t) => [a, t[1]])];
}

/**
 * Lens to the second item of a tuple.
 */
export function Optics_snd_() {
    return [(tuple) => tuple[1], (b) => ((t) => [t[0], b])];
}

/**
 * Isomorphism to an list.
 */
export function Optics_Array_list_() {
    return [ofArray, toArray];
}

/**
 * Prism to Choice1Of2.
 */
export function Optics_Choice_choice1Of2_() {
    return [(x) => ((x.tag === 0) ? some(x.fields[0]) : undefined), (v_1) => ((x_1) => ((x_1.tag === 0) ? (new FSharpChoice$2(0, [v_1])) : x_1))];
}

/**
 * Prism to Choice2Of2.
 */
export function Optics_Choice_choice2Of2_() {
    return [(x) => ((x.tag === 1) ? some(x.fields[0]) : undefined), (v_1) => ((x_1) => ((x_1.tag === 1) ? (new FSharpChoice$2(1, [v_1])) : x_1))];
}

/**
 * Prism to Ok.
 */
export function Optics_Result_ok_() {
    return [(x) => ((x.tag === 0) ? some(x.fields[0]) : undefined), (v_1) => ((x_1) => ((x_1.tag === 0) ? (new FSharpResult$2(0, [v_1])) : x_1))];
}

/**
 * Prism to Error.
 */
export function Optics_Result_error_() {
    return [(x) => ((x.tag === 1) ? some(x.fields[0]) : undefined), (v_1) => ((x_1) => ((x_1.tag === 1) ? (new FSharpResult$2(1, [v_1])) : x_1))];
}

/**
 * Prism to the head of a list.
 */
export function Optics_List_head_() {
    return [(_arg) => (!isEmpty(_arg) ? some(head(_arg)) : undefined), (v) => ((_arg_1) => (!isEmpty(_arg_1) ? cons(v, tail(_arg_1)) : _arg_1))];
}

/**
 * Prism to an indexed position in a list.
 */
export function Optics_List_pos_(i) {
    return [(_arg) => ((length(_arg) > i) ? some(item(i, _arg)) : undefined), (v) => ((l_2) => mapIndexed((i$0027, x) => ((i === i$0027) ? v : x), l_2))];
}

/**
 * Prism to the tail of a list.
 */
export function Optics_List_tail_() {
    return [(_arg) => (!isEmpty(_arg) ? tail(_arg) : undefined), (t_1) => ((_arg_1) => (isEmpty(_arg_1) ? empty() : cons(head(_arg_1), t_1)))];
}

/**
 * Isomorphism to an array.
 */
export function Optics_List_array_() {
    return [toArray, ofArray];
}

/**
 * Prism to a value associated with a key in a map.
 */
export function Optics_Map_key_(k) {
    return [(table) => tryFind(k, table), (v) => ((x) => (containsKey(k, x) ? add(k, v, x) : x))];
}

/**
 * Lens to a value option associated with a key in a map.
 */
export function Optics_Map_value_(k) {
    return [(table) => tryFind(k, table), (v) => ((x) => ((v != null) ? add(k, value_2(v), x) : remove(k, x)))];
}

/**
 * Weak Isomorphism to an array of key-value pairs.
 */
export function Optics_Map_array_() {
    return [toArray_1, (elements) => ofArray_1(elements, {
        Compare: compare,
    })];
}

/**
 * Weak Isomorphism to a list of key-value pairs.
 */
export function Optics_Map_list_() {
    return [toList, (elements) => ofList(elements, {
        Compare: compare,
    })];
}

/**
 * Prism to the value in an Option.
 */
export function Optics_Option_value_() {
    return [(x) => x, (v) => ((_arg) => ((_arg == null) ? undefined : some(v)))];
}

/**
 * Identity lens returning the original item regardless of modifiction
 */
export function id_() {
    return Optics_id_();
}

/**
 * First item of a tuple giving a total lens
 */
export function fst_() {
    return Optics_fst_();
}

/**
 * Second item of a tuple giving a total lens
 */
export function snd_() {
    return Optics_snd_();
}

/**
 * Head of a list giving a partial lens
 */
export function head_() {
    return Optics_List_head_();
}

/**
 * Position of a list giving a partial lens
 */
export function pos_(i) {
    return Optics_List_pos_(i);
}

/**
 * Tail of a list giving a partial lens
 */
export function tail_() {
    return Optics_List_tail_();
}

/**
 * Key of a map giving a partial lens
 */
export function key_(k) {
    return Optics_Map_key_(k);
}

/**
 * Modify a value using a total lens
 */
export function Operators_op_HatPercentEquals(f, l_, l__1) {
    return Optic_Map_op_HatPercent_216CF0AB(new Optic_Map(), [l_, curry2(l__1)])(f);
}

