import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Permission, isAllowed } from "../../RAWMap.Models/Security.js";
import { view as view_1 } from "./ReportPrompt/ReportPromptView.js";
import { wrapOutboundMsg, wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { ChildMsg_ofMsg } from "../../Common/Terminate.js";
import { childAbsentStrategy } from "./CustomReportState.js";
import { OutboundMsg, ModeModule_$007CDisableButtons$007CEnableButtons$007C, ModeModule_$007CSpinner$007CNoSpinner$007C, LocalMsg } from "./CustomReportTypes.js";
import { screenshotModal, screenshotTable } from "../../Visualization/Screenshot/ScreenshotView.js";
import { Helpers_nothing } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { DOMAttr, HTMLAttr } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { Button_visibilityControlled, ComponentVisibility } from "../../Common/Controls.js";
import { editConclusionModal, additionalDataView } from "./AdditionalData/AdditionalDataView.js";
import { Controls_asCard } from "../../Common/General.js";
import { content as content_1 } from "../../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import * as react from "react";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.3/Date.js";
import { toLocalTime } from "../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Option, div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { PromptType } from "./ReportPrompt/ReportPromptTypes.js";
import { button, Option as Option_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";

export function canUserViewCustomReport(maybeCurrentUser) {
    return defaultArg(map((user) => isAllowed(new Permission(18, []))(user.roles), maybeCurrentUser), false);
}

export function canUserEditCustomReportData(maybeCurrentUser) {
    return defaultArg(map((user) => isAllowed(new Permission(19, []))(user.roles), maybeCurrentUser), false);
}

export function canUserCreateCustomReport(maybeCurrentUser) {
    return defaultArg(map((user) => isAllowed(new Permission(20, []))(user.roles), maybeCurrentUser), false);
}

export function canUserDeleteCustomReport(maybeCurrentUser) {
    return defaultArg(map((user) => isAllowed(new Permission(21, []))(user.roles), maybeCurrentUser), false);
}

export function promptView(promptModel, _model, dispatch) {
    return view_1(promptModel, (arg_2) => {
        dispatch(wrapLocalMsg(new LocalMsg(5, [ChildMsg_ofMsg(childAbsentStrategy, arg_2)])));
    });
}

export function view(customReportModel, model, maybeCurrentUser, dispatch) {
    const dispatchScreenshotLocal = (arg_1) => {
        dispatch(wrapLocalMsg(new LocalMsg(3, [arg_1])));
    };
    const screenshots = screenshotTable(model.ScreenshotModel, dispatchScreenshotLocal);
    const dispatchAdditionalDataLocal = (arg_3) => {
        dispatch(wrapLocalMsg(new LocalMsg(4, [arg_3])));
    };
    let reportPrompt;
    const matchValue = model.PromptComponent.child;
    switch (matchValue.tag) {
        case 2:
        case 1: {
            reportPrompt = Helpers_nothing;
            break;
        }
        default:
            reportPrompt = promptView(matchValue.fields[0], model, dispatch);
    }
    const loadingProps = (ModeModule_$007CSpinner$007CNoSpinner$007C(model.Mode).tag === 1) ? empty() : singleton(new HTMLAttr(64, ["spinner is-loading"]));
    const buttonVisibility = (userHasPermission) => {
        if (userHasPermission) {
            const activePatternResult_1 = ModeModule_$007CDisableButtons$007CEnableButtons$007C(model.Mode);
            if (activePatternResult_1.tag === 1) {
                return new ComponentVisibility(0, []);
            }
            else {
                return new ComponentVisibility(2, [activePatternResult_1.fields[0]]);
            }
        }
        else {
            return new ComponentVisibility(1, []);
        }
    };
    const additionalData = additionalDataView(model.AdditionalDataModel, customReportModel, dispatchAdditionalDataLocal, !canUserEditCustomReportData(maybeCurrentUser));
    return Controls_asCard("Custom Report", ofArray([content_1(empty(), toList(delay(() => {
        let props_2, children_2;
        return append(singleton_1((props_2 = toList(delay(() => loadingProps)), (children_2 = [react.createElement("b", {}, "Screenshots"), screenshots], react.createElement("div", keyValueList(props_2, 1), ...children_2)))), delay(() => {
            let props_4;
            return append(singleton_1((props_4 = toList(delay(() => loadingProps)), react.createElement("div", keyValueList(props_4, 1), additionalData))), delay(() => {
                let children_14, props_12, children_12;
                return append(singleton_1((children_14 = [react.createElement("b", {}, "Previously Generated Report"), (props_12 = [["style", {
                    padding: "0.5em 0.75em",
                }]], (children_12 = toList(delay(() => {
                    let props_8, children_8, arg_4;
                    const matchValue_4 = customReportModel.maybeLastReportPath;
                    const matchValue_5 = customReportModel.maybeLastReportDate;
                    let matchResult_1, gdate, path;
                    if (matchValue_4 != null) {
                        if (matchValue_5 != null) {
                            matchResult_1 = 0;
                            gdate = matchValue_5;
                            path = matchValue_4;
                        }
                        else {
                            matchResult_1 = 1;
                        }
                    }
                    else {
                        matchResult_1 = 1;
                    }
                    switch (matchResult_1) {
                        case 0:
                            return append(singleton_1((props_8 = [["style", {
                                padding: "0 0 0.5em 0",
                            }]], (children_8 = [(arg_4 = toString(toLocalTime(gdate), "yyyy-MM-dd | HH:mm:ss"), toText(printf("Generated on: %s"))(arg_4))], react.createElement("div", keyValueList(props_8, 1), ...children_8)))), delay(() => singleton_1(div(ofArray([new Option(11, [singleton(["style", {
                                marginTop: 20,
                            }])]), new Option(4, [])]), ofArray([div_1(empty(), singleton(Button_visibilityControlled(undefined, buttonVisibility(canUserViewCustomReport(maybeCurrentUser)), ofArray([new Option_1(18, [(_arg) => {
                                dispatch(wrapLocalMsg(new LocalMsg(7, [new PromptType(1, [path])])));
                            }]), new Option_1(0, [new Color_IColor(6, [])]), new Option_1(17, [singleton(new HTMLAttr(159, ["button"]))])]), singleton("Download")))), div_1(empty(), singleton(Button_visibilityControlled(undefined, buttonVisibility(canUserDeleteCustomReport(maybeCurrentUser)), ofArray([new Option_1(18, [(_arg_1) => {
                                dispatch(wrapLocalMsg(new LocalMsg(7, [new PromptType(2, [path])])));
                            }]), new Option_1(0, [new Color_IColor(5, [])]), new Option_1(17, [singleton(new HTMLAttr(159, ["button"]))])]), singleton("Delete"))))])))));
                        default:
                            return singleton_1(react.createElement("div", {}, "No previously generated report available"));
                    }
                })), react.createElement("div", keyValueList(props_12, 1), ...children_12)))], react.createElement("div", {}, ...children_14))), delay(() => append(singleton_1(div(ofArray([new Option(11, [singleton(["style", {
                    marginTop: 20,
                }])]), new Option(6, [])]), ofArray([div_1(empty(), singleton(Button_visibilityControlled(undefined, buttonVisibility(canUserCreateCustomReport(maybeCurrentUser)), ofArray([new Option_1(18, [(_arg_2) => {
                    dispatch(wrapLocalMsg(new LocalMsg(7, [new PromptType(0, [])])));
                }]), new Option_1(0, [new Color_IColor(6, [])]), new Option_1(17, [singleton(new HTMLAttr(159, ["button"]))])]), singleton("Generate New Report")))), div_1(empty(), singleton(button(ofArray([new Option_1(18, [(_arg_3) => {
                    dispatch(wrapLocalMsg(new LocalMsg(9, [])));
                }]), new Option_1(0, [new Color_IColor(6, [])]), new Option_1(17, [singleton(new HTMLAttr(159, ["button"]))])]), singleton("Download Measurement CSV")))), div_1(empty(), singleton(Button_visibilityControlled(undefined, buttonVisibility(true), ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(17, [ofArray([new DOMAttr(40, [(_arg_4) => {
                    dispatch(wrapOutboundMsg(new OutboundMsg(2, [])));
                }]), new HTMLAttr(159, ["button"])])])]), singleton("Cancel"))))]))), delay(() => append(singleton_1(screenshotModal(model.ScreenshotModel, dispatchScreenshotLocal, !canUserEditCustomReportData(maybeCurrentUser))), delay(() => (canUserEditCustomReportData(maybeCurrentUser) ? singleton_1(editConclusionModal(model.AdditionalDataModel, customReportModel, dispatchAdditionalDataLocal)) : empty_1())))))));
            }));
        }));
    }))), reportPrompt]));
}

