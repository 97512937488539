import { find, remove, add, tryFind, toSeq, ofList, ofSeq, toList, empty } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { compare, comparePrimitives } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { FileManagementViewModel_abortUpload_27E04A4D, Model_getNumberOfNovelSelections_Z379152CB, Model_getNumberOfAssociatedFiles_Z379152CB, OutboundMsg, FileManagementViewModel, LocalMsg, Model, StringDisplays_get_defaults } from "./FileManagementTypes.js";
import { toArray, length, map as map_2, ofArray, exists, singleton, empty as empty_1 } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { update as update_1, wrapLocalMsg } from "../Common/InboundOutbound.js";
import { value as value_7, bind, map, defaultArg, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { FileManagementProgress } from "./Shared.js";
import { tryLast, last } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { split } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { S3_DeleteObjectsRequestParams, S3_ObjectIdentifierListParams, S3_DeleteObjectsRequestParams_get_create, S3_ObjectIdentifierParams, S3_ListObjectsV2RequestParams, S3_ListObjectsV2RequestParams_get_create, S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create } from "../bindings/Fable.Helpers.Aws.js";
import { Cmd_OfFunc_either, Cmd_ofEffect, Cmd_batch, Cmd_OfPromise_either, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Toast_errorToast } from "../Common/General.js";
import { empty as empty_2, choose, concat, map as map_1, toList as toList_1 } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { TelemetryAction, S3ObjectViewModel } from "../RAWMap.Models/Api.js";
import { validateFiles } from "./Validation.js";
import { String_pluralize } from "../Common/Extensions.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { getSignedUrlCmd } from "../Common/AwsCommon.js";

export function init(specs, operationContext, fileHandling) {
    let FailedUploads, FileManagementsInProgress, DownloadableFiles, SignedUrls, msg_1;
    return [(FailedUploads = empty({
        Compare: comparePrimitives,
    }), (FileManagementsInProgress = empty({
        Compare: comparePrimitives,
    }), (DownloadableFiles = empty({
        Compare: comparePrimitives,
    }), (SignedUrls = empty({
        Compare: comparePrimitives,
    }), new Model(specs, StringDisplays_get_defaults(), "", fileHandling, empty_1(), FailedUploads, FileManagementsInProgress, empty_1(), DownloadableFiles, undefined, SignedUrls, operationContext))))), (msg_1 = wrapLocalMsg(new LocalMsg(3, [])), singleton((dispatch) => {
        dispatch(msg_1);
    }))];
}

export function mkManagedUpload(fileName, s3Client, req, dispatch) {
    const managedUpload = s3Client.upload(req);
    managedUpload.on('httpUploadProgress',((progress) => {
        const matchValue = progress.total == null;
        if (matchValue) {
            console.log(some("Total size undefined, calculating..."));
        }
        else {
            dispatch(new LocalMsg(14, [fileName, new FileManagementProgress(0, [(progress.loaded * 100) / progress.total])]));
        }
    }));
    dispatch(new LocalMsg(9, [managedUpload, fileName]));
}

export function hasFileWithExtension(extension, model) {
    return exists((tupledArg) => {
        const fileExtension = last(split(tupledArg[1].Name, ["."], undefined, 0));
        if (fileExtension === extension) {
            return true;
        }
        else {
            return ("." + fileExtension) === extension;
        }
    }, toList(model.DownloadableFiles));
}

function mkPutObjectRequest(model, bucket, fileData) {
    return S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, [bucket]), new S3_PutObjectRequestParams(3, [model.Specs.uploadDestinationPath + fileData.file.name]), new S3_PutObjectRequestParams(1, [fileData.file])]));
}

export function updateLocal(args, msg, model) {
    let FileManagementsInProgress, req_1;
    switch (msg.tag) {
        case 1: {
            console.error(some(`Error: ${msg.fields[0]}`));
            return [model, Cmd_none(), Cmd_none()];
        }
        case 2:
            return [model, Toast_errorToast(msg.fields[0]), Cmd_none()];
        case 3: {
            const listParams = S3_ListObjectsV2RequestParams_get_create()(new S3_ListObjectsV2RequestParams(args.Context.ActiveBucket, "/", model.Specs.uploadDestinationPath, model.Specs.uploadDestinationPath));
            const listRequest = args.Context.S3Client.listObjectsV2(listParams);
            return [model, Cmd_OfPromise_either(() => listRequest.promise(), undefined, (Item) => (new LocalMsg(4, [Item])), (Item_1) => (new LocalMsg(1, [Item_1]))), Cmd_none()];
        }
        case 4: {
            const output = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, defaultArg(map((arg) => toList_1(map_1((file) => (new S3ObjectViewModel(defaultArg(bind((k) => tryLast(split(k, ["/"], undefined, 0)), file.Key), "Unknown File"), 0, value_7(file.LastModified), value_7(file.Key))), arg)), output.Contents), empty_1()), defaultArg(map((arg_1) => ofSeq(map_1((file_1) => {
                const fileName_1 = defaultArg(map((x) => x, file_1.Key), "");
                return [fileName_1, new FileManagementViewModel(undefined, fileName_1, undefined, new FileManagementProgress(0, [0]))];
            }, arg_1), {
                Compare: comparePrimitives,
            }), output.Contents), empty({
                Compare: comparePrimitives,
            })), model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), singleton((dispatch) => {
                dispatch(new OutboundMsg(0, []));
            })];
        }
        case 5:
            return validateFiles(msg.fields[0], msg.fields[1], model, model.Specs, (model_1) => (Model_getNumberOfAssociatedFiles_Z379152CB(model_1) + Model_getNumberOfNovelSelections_Z379152CB(model_1)));
        case 6: {
            const fileDataList_1 = msg.fields[0];
            return [model, singleton((dispatch_3) => {
                dispatch_3(new LocalMsg(5, [fileDataList_1, (model_2) => [new Model(model_2.Specs, model_2.DisplayContent, model_2.Message, model_2.FileHandling, fileDataList_1, model_2.FailedUploads, model_2.FileManagementsInProgress, model_2.PersistedFiles, model_2.DownloadableFiles, model_2.MaybeS3VmsToDelete, model_2.SignedUrls, model_2.OperationContext), msg.fields[1] ? singleton((dispatch_1) => {
                    dispatch_1(new LocalMsg(8, [fileDataList_1]));
                }) : Cmd_none(), singleton((dispatch_2) => {
                    dispatch_2(new OutboundMsg(1, []));
                })]]));
            }), Cmd_none()];
        }
        case 7: {
            const fileDataList_2 = msg.fields[0];
            return [model, singleton((dispatch_5) => {
                dispatch_5(new LocalMsg(5, [fileDataList_2, (model_3) => [model_3, singleton((dispatch_4) => {
                    dispatch_4(new LocalMsg(8, [fileDataList_2]));
                }), Cmd_none()]]));
            }), Cmd_none()];
        }
        case 8: {
            const fileDataList_3 = msg.fields[0];
            const fileMap = ofList(map_2((fileData) => {
                const name = model.Specs.uploadDestinationPath + fileData.file.name;
                return [name, new FileManagementViewModel(fileData, name, undefined, new FileManagementProgress(0, [0]))];
            }, fileDataList_3), {
                Compare: comparePrimitives,
            });
            const uploadCmds = Cmd_batch(map_2((fileData_1) => {
                let fileName_2, req;
                return Cmd_ofEffect((fileName_2 = (model.Specs.uploadDestinationPath + fileData_1.file.name), (req = mkPutObjectRequest(model, args.Context.ActiveBucket, fileData_1), (dispatch_6) => {
                    mkManagedUpload(fileName_2, args.Context.S3Client, req, dispatch_6);
                })));
            }, fileDataList_3));
            const numFiles = length(fileDataList_3) | 0;
            return [(FileManagementsInProgress = ofSeq(concat([toSeq(model.FileManagementsInProgress), toSeq(fileMap)]), {
                Compare: compare,
            }), new Model(model.Specs, model.DisplayContent, `Uploading ${numFiles} file${String_pluralize(numFiles)}...`, model.FileHandling, model.UploadSelection, model.FailedUploads, FileManagementsInProgress, model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext)), Cmd_batch(ofArray([uploadCmds, singleton((dispatch_7) => {
                dispatch_7(new LocalMsg(6, [empty_1(), false]));
            })])), Cmd_none()];
        }
        case 9: {
            const upload = msg.fields[0];
            const fileName_3 = msg.fields[1];
            const matchValue = tryFind(fileName_3, model.FileManagementsInProgress);
            if (matchValue != null) {
                const file_2 = matchValue;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, add(fileName_3, new FileManagementViewModel(file_2.MaybeFileData, file_2.Name, upload, file_2.Progress), model.FileManagementsInProgress), model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_OfPromise_either(() => upload.promise(), undefined, (sendData) => (new LocalMsg(12, [fileName_3, sendData])), (e_1) => (new LocalMsg(13, [fileName_3, e_1]))), singleton((dispatch_8) => {
                    dispatch_8(new OutboundMsg(2, []));
                })];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 10: {
            const matchValue_1 = tryFind(msg.fields[0], model.FileManagementsInProgress);
            if (matchValue_1 != null) {
                const file_3 = matchValue_1;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, remove(file_3.Name, model.FileManagementsInProgress), model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_OfFunc_either((fileManagementVm) => {
                    FileManagementViewModel_abortUpload_27E04A4D(fileManagementVm);
                }, file_3, () => (new LocalMsg(11, [undefined])), (Item_3) => (new LocalMsg(1, [Item_3]))), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 11:
            return [model, Cmd_none(), Cmd_none()];
        case 12: {
            const fileName_5 = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, `${fileName_5} uploaded`, model.FileHandling, model.UploadSelection, model.FailedUploads, remove(fileName_5, model.FileManagementsInProgress), model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_batch(ofArray([singleton((dispatch_9) => {
                dispatch_9(new LocalMsg(3, []));
            }), singleton((dispatch_10) => {
                dispatch_10(new LocalMsg(6, [empty_1(), false]));
            }), Cmd_OfAsyncWith_perform((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, securedApi(args.Token).telemetry, new TelemetryAction(0, [model.OperationContext, singleton(fileName_5)]), (_arg) => (new LocalMsg(0, [])))])), singleton((dispatch_11) => {
                dispatch_11(new OutboundMsg(3, []));
            })];
        }
        case 13: {
            const fileName_6 = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, `Failed to upload ${fileName_6}`, model.FileHandling, model.UploadSelection, add(fileName_6, find(fileName_6, model.FileManagementsInProgress), model.FailedUploads), remove(fileName_6, model.FileManagementsInProgress), model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), singleton((dispatch_12) => {
                dispatch_12(new OutboundMsg(4, []));
            })];
        }
        case 14: {
            const fileName_7 = msg.fields[0];
            const matchValue_2 = tryFind(fileName_7, model.FileManagementsInProgress);
            if (matchValue_2 != null) {
                const file_4 = matchValue_2;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, add(fileName_7, new FileManagementViewModel(file_4.MaybeFileData, file_4.Name, file_4.MaybeManagedUpload, msg.fields[1]), model.FileManagementsInProgress), model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 15: {
            const fileName_8 = msg.fields[1];
            return [new Model(model.Specs, model.DisplayContent, `${fileName_8} uploaded`, model.FileHandling, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), getSignedUrlCmd(args.Context, msg.fields[0], fileName_8, (tupledArg) => (new LocalMsg(16, [tupledArg[0], tupledArg[1]])), (Item_4) => (new LocalMsg(1, [Item_4]))), Cmd_none()];
        }
        case 16: {
            const fileName_10 = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, remove(fileName_10, model.FileManagementsInProgress), model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, add(fileName_10, msg.fields[1], model.SignedUrls), model.OperationContext), Cmd_batch(ofArray([singleton((dispatch_13) => {
                dispatch_13(new LocalMsg(3, []));
            }), Cmd_OfAsyncWith_perform((x_6) => {
                Cmd_OfAsync_start(x_6);
            }, securedApi(args.Token).telemetry, new TelemetryAction(1, [model.OperationContext, singleton(fileName_10)]), (_arg_1) => (new LocalMsg(0, [])))])), Cmd_none()];
        }
        case 19: {
            const keys = toArray(map_2((s3Vm) => (new S3_ObjectIdentifierParams(s3Vm.FilePath)), msg.fields[0]));
            const deleteObjectsRequest = S3_DeleteObjectsRequestParams_get_create()(new S3_DeleteObjectsRequestParams(args.Context.ActiveBucket, new S3_ObjectIdentifierListParams(keys, false)));
            const deleteRequest = args.Context.S3Client.deleteObjects(deleteObjectsRequest);
            return [model, Cmd_OfPromise_either(() => deleteRequest.promise(), undefined, (output_1) => (new LocalMsg(20, [toList_1(defaultArg(map((source_3) => choose((deleted) => deleted.Key, source_3), output_1.Deleted), empty_2())), output_1])), (Item_5) => (new LocalMsg(1, [Item_5]))), Cmd_none()];
        }
        case 20:
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PersistedFiles, model.DownloadableFiles, undefined, model.SignedUrls, model.OperationContext), Cmd_batch(ofArray([singleton((dispatch_14) => {
                dispatch_14(new LocalMsg(3, []));
            }), Cmd_OfAsyncWith_perform((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, securedApi(args.Token).telemetry, new TelemetryAction(2, [model.OperationContext, msg.fields[0]]), (_arg_2) => (new LocalMsg(0, [])))])), singleton((dispatch_15) => {
                dispatch_15(new OutboundMsg(5, []));
            })];
        case 21: {
            const fileName_11 = msg.fields[0];
            const failedUploadInfo = find(fileName_11, model.FailedUploads);
            const newUploadInfo = new FileManagementViewModel(failedUploadInfo.MaybeFileData, failedUploadInfo.Name, undefined, new FileManagementProgress(0, [0]));
            const matchValue_3 = failedUploadInfo.MaybeFileData;
            if (matchValue_3 != null) {
                const fileData_2 = matchValue_3;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, remove(fileName_11, model.FailedUploads), add(fileName_11, newUploadInfo, model.FileManagementsInProgress), model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_ofEffect((req_1 = mkPutObjectRequest(model, args.Context.ActiveBucket, fileData_2), (dispatch_16) => {
                    mkManagedUpload(fileName_11, args.Context.S3Client, req_1, dispatch_16);
                })), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 22:
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, remove(msg.fields[0], model.FailedUploads), model.FileManagementsInProgress, model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
        case 17:
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PersistedFiles, model.DownloadableFiles, msg.fields[0], model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
        case 18:
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.FileHandling, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PersistedFiles, model.DownloadableFiles, undefined, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
        default:
            return [model, Cmd_none(), Cmd_none()];
    }
}

export function updateInbound(_args, msg, model) {
    switch (msg.tag) {
        case 1: {
            const uploadSelection = msg.fields[0];
            return [model, singleton((dispatch_1) => {
                dispatch_1(new LocalMsg(6, [uploadSelection.files, uploadSelection.triggerUpload]));
            }), Cmd_none()];
        }
        case 2:
            return [new Model(model.Specs, model.DisplayContent, model.Message, msg.fields[0], model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PersistedFiles, model.DownloadableFiles, model.MaybeS3VmsToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
        default:
            return [model, singleton((dispatch) => {
                dispatch(new LocalMsg(3, []));
            }), Cmd_none()];
    }
}

export function update(args, msg, model) {
    return update_1(updateLocal, updateInbound, args, msg, model);
}

