import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { VisualizationScreenshotViewModel_$reflection } from "../../RAWMap.Models/View/VisualizationScreenshot.js";
import { bool_type, tuple_type, lambda_type, class_type, record_type, list_type, string_type, union_type, int32_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { S3Context_$reflection } from "../../Common/AwsCommon.js";
import { CustomReportStatus_$reflection } from "../../RAWMap.Models/View/CustomReport.js";
import { InteractionMode_$reflection } from "../Common/CommonBindings.js";
import { ErrorMessage_$reflection } from "../../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { ScreenshotProps } from "./ScreenshotBindings.js";

export class ScreenshotModal extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CreateScreenshot", "CreateSplitScreenshot", "EditScreenshot", "DeleteScreenshot"];
    }
}

export function ScreenshotModal_$reflection() {
    return union_type("RAWMap.Client.Visualization.Screenshot.Types.ScreenshotModal", [], ScreenshotModal, () => [[["Item", VisualizationScreenshotViewModel_$reflection()]], [["expectedCaptures", int32_type], ["Item2", VisualizationScreenshotViewModel_$reflection()]], [["Item", VisualizationScreenshotViewModel_$reflection()]], [["Item", VisualizationScreenshotViewModel_$reflection()]]]);
}

export function ScreenshotModal_modify_Z7A73AF2A(f) {
    return (_arg) => {
        switch (_arg.tag) {
            case 1: {
                const tupledArg = [_arg.fields[0], f(_arg.fields[1])];
                return new ScreenshotModal(1, [tupledArg[0], tupledArg[1]]);
            }
            case 2:
                return new ScreenshotModal(2, [f(_arg.fields[0])]);
            case 3:
                return new ScreenshotModal(3, [f(_arg.fields[0])]);
            default:
                return new ScreenshotModal(0, [f(_arg.fields[0])]);
        }
    };
}

export class Mode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Create", "CreateSplit", "Edit", "Modal"];
    }
}

export function Mode_$reflection() {
    return union_type("RAWMap.Client.Visualization.Screenshot.Types.Mode", [], Mode, () => [[], [["expectedCaptures", int32_type], ["b64Images", list_type(string_type)]], [], [["Item", ScreenshotModal_$reflection()]]]);
}

export function Mode_modify_Z7A73AF2A(f) {
    return (_arg) => ((_arg.tag === 3) ? (new Mode(3, [ScreenshotModal_modify_Z7A73AF2A(f)(_arg.fields[0])])) : _arg);
}

/**
 * Reverts back to the appropriate state based on the current state
 */
export function Mode_get_revert() {
    return (_arg) => ((_arg.tag === 3) ? ((_arg.fields[0].tag === 1) ? (new Mode(1, [_arg.fields[0].fields[0], empty()])) : ((_arg.fields[0].tag === 2) ? (new Mode(2, [])) : ((_arg.fields[0].tag === 3) ? (new Mode(3, [new ScreenshotModal(2, [_arg.fields[0].fields[0]])])) : (new Mode(0, []))))) : _arg);
}

export class ScreenshotUpdateArgs extends Record {
    constructor(token, s3Context) {
        super();
        this.token = token;
        this.s3Context = s3Context;
    }
}

export function ScreenshotUpdateArgs_$reflection() {
    return record_type("RAWMap.Client.Visualization.Screenshot.Types.ScreenshotUpdateArgs", [], ScreenshotUpdateArgs, () => [["token", string_type], ["s3Context", S3Context_$reflection()]]);
}

export class InboundMsg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["GotImageData"];
    }
}

export function InboundMsg_$reflection() {
    return union_type("RAWMap.Client.Visualization.Screenshot.Types.InboundMsg", [], InboundMsg, () => [[["Item", string_type]]]);
}

export class OutboundMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateCustomReportStatus", "SetInteractionModeOutbound"];
    }
}

export function OutboundMsg_$reflection() {
    return union_type("RAWMap.Client.Visualization.Screenshot.Types.OutboundMsg", [], OutboundMsg, () => [[["Item", CustomReportStatus_$reflection()]], [["Item", InteractionMode_$reflection()]]]);
}

export class LocalMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "SetVisualizationScreenshotVm", "InitiateScreenshot", "CancelScreenshot", "SaveScreenshot", "SetMode", "ConfirmDeleteVisualizationScreenshot", "GetScreenshotSignedUrl", "ScreenshotUploadCompleted", "GetScreenshots", "GetScreenshotsResult", "CreateVisualizationScreenshot", "CreateVisualizationScreenshotResult", "EditVisualizationScreenshot", "EditVisualizationScreenshotResult", "DeleteVisualizationScreenshotResult"];
    }
}

export function LocalMsg_$reflection() {
    return union_type("RAWMap.Client.Visualization.Screenshot.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [["Item", lambda_type(VisualizationScreenshotViewModel_$reflection(), VisualizationScreenshotViewModel_$reflection())]], [], [], [], [["Item", Mode_$reflection()]], [], [], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(VisualizationScreenshotViewModel_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(VisualizationScreenshotViewModel_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item", VisualizationScreenshotViewModel_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(CustomReportStatus_$reflection(), VisualizationScreenshotViewModel_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", tuple_type(CustomReportStatus_$reflection(), VisualizationScreenshotViewModel_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item", VisualizationScreenshotViewModel_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(CustomReportStatus_$reflection(), VisualizationScreenshotViewModel_$reflection()), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", tuple_type(CustomReportStatus_$reflection(), VisualizationScreenshotViewModel_$reflection())]], [["ErrorValue", ErrorMessage_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(CustomReportStatus_$reflection(), class_type("System.Guid")), ErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", tuple_type(CustomReportStatus_$reflection(), class_type("System.Guid"))]], [["ErrorValue", ErrorMessage_$reflection()]]])]]]);
}

export class ModelScreenshot extends Record {
    constructor(Mode, Screenshots, SelectedPatientId, SelectedStudyId, IsSaving) {
        super();
        this.Mode = Mode;
        this.Screenshots = Screenshots;
        this.SelectedPatientId = SelectedPatientId;
        this.SelectedStudyId = SelectedStudyId;
        this.IsSaving = IsSaving;
    }
}

export function ModelScreenshot_$reflection() {
    return record_type("RAWMap.Client.Visualization.Screenshot.Types.ModelScreenshot", [], ModelScreenshot, () => [["Mode", Mode_$reflection()], ["Screenshots", list_type(VisualizationScreenshotViewModel_$reflection())], ["SelectedPatientId", class_type("System.Guid")], ["SelectedStudyId", class_type("System.Guid")], ["IsSaving", bool_type]]);
}

export function ModelScreenshot_Props_Z625F521(dispatch) {
    return new ScreenshotProps((arg_1) => {
        dispatch(wrapInboundMsg(new InboundMsg(arg_1)));
    });
}

