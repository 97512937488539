import { Msg, Model, mode as mode_3 } from "./UserTypes.js";
import { tryFind as tryFind_1, ofArray, singleton, sort, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Permission, isUserAllowed, UserViewModelModule_empty } from "../RAWMap.Models/Security.js";
import { Common_SortInfo$1, Common_SortDirection } from "../RAWMap.Models/Common.js";
import { compare } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { value as value_1, map, defaultArg, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_attempt } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedUserManagementApi, publicAuthApi } from "../Api.js";
import { Toast_errorToast, Toast_successToast } from "../Common/General.js";
import { tryFind } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { Security_UserViewModel$2, Common_EmailAddress_create_Z721C83C5, Security_UserInfo$2_toViewModel_Z3F1C2E28, Security_AuthErrorMessage_get_describe, Security_UserInfo$2_get_id_ } from "../fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";

export function init() {
    return [new Model(new mode_3(0, []), empty(), empty(), undefined, UserViewModelModule_empty, new Common_SortInfo$1("", new Common_SortDirection(1, []), (list) => sort(list, {
        Compare: compare,
    }))), Cmd_none()];
}

export function update(userInfo, token, msg, model) {
    let option_1, f1_1, f1, optic;
    const mkListViewModel = () => (new Model(new mode_3(0, []), model.users, model.institutions, model.selectedUser, UserViewModelModule_empty, model.sortInfo));
    let matchResult, ex, email, users, e, institutions, e_1, userId_1, setter, user, toDelete, sortInfo, e_2, e_3;
    switch (msg.tag) {
        case 2: {
            matchResult = 1;
            break;
        }
        case 3: {
            matchResult = 2;
            email = msg.fields[0];
            break;
        }
        case 1: {
            if (isUserAllowed(new Permission(4, []), userInfo)) {
                matchResult = 3;
            }
            else {
                matchResult = 4;
            }
            break;
        }
        case 4: {
            if (msg.fields[0].tag === 1) {
                matchResult = 6;
                e = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
                users = msg.fields[0].fields[0];
            }
            break;
        }
        case 5: {
            if (msg.fields[0].tag === 1) {
                matchResult = 8;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 7;
                institutions = msg.fields[0].fields[0];
            }
            break;
        }
        case 6: {
            matchResult = 9;
            userId_1 = msg.fields[0];
            break;
        }
        case 8: {
            if (model.selectedUser == null) {
                matchResult = 10;
            }
            else {
                matchResult = 11;
            }
            break;
        }
        case 17: {
            matchResult = 12;
            setter = msg.fields[0];
            break;
        }
        case 7: {
            matchResult = 13;
            break;
        }
        case 9: {
            matchResult = 14;
            user = msg.fields[0];
            break;
        }
        case 13: {
            if (msg.fields[0].tag === 1) {
                matchResult = 22;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 15;
            }
            break;
        }
        case 14: {
            if (msg.fields[0].tag === 1) {
                matchResult = 22;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 16;
            }
            break;
        }
        case 10: {
            matchResult = 17;
            break;
        }
        case 11: {
            matchResult = 18;
            toDelete = msg.fields[0];
            break;
        }
        case 12: {
            if (msg.fields[0].tag === 1) {
                matchResult = 22;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 19;
            }
            break;
        }
        case 15: {
            if (msg.fields[0].tag === 1) {
                matchResult = 23;
                e_3 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 20;
            }
            break;
        }
        case 16: {
            matchResult = 21;
            sortInfo = msg.fields[0];
            break;
        }
        case 18: {
            matchResult = 24;
            break;
        }
        case 19: {
            if (msg.fields[0].tag === 1) {
                matchResult = 25;
            }
            else {
                matchResult = 26;
            }
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1:
            return [model, singleton((dispatch) => {
                dispatch(new Msg(1, []));
            })];
        case 2:
            return [model, Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x) => {
                Cmd_OfAsync_start(x);
            }, publicAuthApi.requestPasswordResetTokenEmail, email, (Item) => (new Msg(0, [Item]))), Toast_successToast(toText(printf("A password reset email has been sent to %s"))(email))]))];
        case 3:
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedUserManagementApi(token).getUsers, undefined, (Item_1) => (new Msg(4, [Item_1])), (Item_2) => (new Msg(0, [Item_2])))];
        case 4:
            return [model, Cmd_none()];
        case 5: {
            let cmd;
            const matchValue_1 = model.selectedUser;
            let matchResult_1;
            if (model.mode.tag === 3) {
                if (matchValue_1 != null) {
                    matchResult_1 = 0;
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0: {
                    const selectedUser = matchValue_1;
                    cmd = defaultArg((option_1 = tryFind((u) => (u.id === selectedUser.id), users), map((f1_1 = ((f1 = ((optic = Security_UserInfo$2_get_id_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(), optic)(target))), (arg_5) => (new Msg(6, [f1(arg_5)])))), (arg_6) => {
                        const msg_2 = f1_1(arg_6);
                        return singleton((dispatch_1) => {
                            dispatch_1(msg_2);
                        });
                    }), option_1)), Cmd_none());
                    break;
                }
                default:
                    cmd = Cmd_none();
            }
            return [new Model(model.mode, ofArray(users), model.institutions, model.selectedUser, model.editUserVm, model.sortInfo), cmd];
        }
        case 6:
            return [model, Toast_errorToast(Security_AuthErrorMessage_get_describe()(e))];
        case 7:
            return [new Model(model.mode, model.users, institutions, model.selectedUser, model.editUserVm, model.sortInfo), Cmd_none()];
        case 8:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_1))];
        case 9:
            return [new Model(new mode_3(3, []), model.users, model.institutions, map(Security_UserInfo$2_toViewModel_Z3F1C2E28, tryFind_1((u_1) => (u_1.id === userId_1), model.users)), model.editUserVm, model.sortInfo), Cmd_none()];
        case 10:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(new ErrorMessage(0, [])))];
        case 11:
            return [new Model(new mode_3(2, []), model.users, model.institutions, model.selectedUser, value_1(model.selectedUser), model.sortInfo), Cmd_none()];
        case 12:
            return [new Model(model.mode, model.users, model.institutions, model.selectedUser, setter(model.editUserVm), model.sortInfo), Cmd_none()];
        case 13:
            return [new Model(new mode_3(1, []), model.users, model.institutions, undefined, UserViewModelModule_empty, model.sortInfo), Cmd_none()];
        case 14: {
            const matchValue_3 = Common_EmailAddress_create_Z721C83C5(user.email);
            if (matchValue_3.tag === 0) {
                const updatedUser = new Security_UserViewModel$2(user.id, user.email, user.name, user.roles, user.userData);
                return [new Model(new mode_3(0, []), model.users, model.institutions, model.selectedUser, model.editUserVm, model.sortInfo), (user.id !== "00000000-0000-0000-0000-000000000000") ? Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedUserManagementApi(token).editUser, updatedUser, (Item_3) => (new Msg(14, [Item_3])), (Item_4) => (new Msg(0, [Item_4]))) : Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, securedUserManagementApi(token).createUser, updatedUser, (Item_5) => (new Msg(13, [Item_5])), (Item_6) => (new Msg(0, [Item_6])))];
            }
            else {
                return [model, Toast_errorToast(toText(printf("%s is not a valid email address"))(user.email))];
            }
        }
        case 15:
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast("Created user"), singleton((dispatch_2) => {
                dispatch_2(new Msg(1, []));
            })]))];
        case 16:
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast("User updated"), singleton((dispatch_3) => {
                dispatch_3(new Msg(1, []));
            })]))];
        case 17:
            return [new Model(new mode_3(4, []), model.users, model.institutions, model.selectedUser, model.editUserVm, model.sortInfo), Cmd_none()];
        case 18:
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedUserManagementApi(token).deleteUser, toDelete, (Item_7) => (new Msg(12, [Item_7])), (Item_8) => (new Msg(0, [Item_8])))];
        case 19:
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast("Deleted user"), singleton((dispatch_4) => {
                dispatch_4(new Msg(1, []));
            })]))];
        case 20:
            return [mkListViewModel(), Cmd_none()];
        case 21:
            return [new Model(model.mode, model.users, model.institutions, model.selectedUser, model.editUserVm, sortInfo), Cmd_none()];
        case 22:
            return [model, Toast_errorToast(Security_AuthErrorMessage_get_describe()(e_2))];
        case 23:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_3))];
        case 24:
            return [mkListViewModel(), Cmd_none()];
        case 25:
            return [model, singleton((dispatch_5) => {
                dispatch_5(new Msg(1, []));
            })];
        default:
            return [model, Cmd_none()];
    }
}

