import { FSharpException, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { string_type, class_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Security_AuthErrorMessage_get_describe, Security_AuthErrorMessage_$reflection } from "../fable_modules/Webbler.Models.1.3.1/Api.fs.js";

export class DatabaseConversionError extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["InvalidStudyStatus"];
    }
}

export function DatabaseConversionError_$reflection() {
    return union_type("RAWMap.Models.Api.DatabaseConversionError", [], DatabaseConversionError, () => [[]]);
}

export function DatabaseConversionError_get_describe() {
    return (_arg) => "Invalid Study Status";
}

export class DatabaseTransactionMissingException extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
    "System.Exception.get_Message"() {
        return "Database operation aborted, should be in a transaction";
    }
}

export function DatabaseTransactionMissingException_$reflection() {
    return class_type("RAWMap.Models.Api.DatabaseTransactionMissingException", undefined, DatabaseTransactionMissingException, class_type("System.Exception"));
}

export class Terminate_TerminateError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Generic", "Custom", "UncancellableOperation"];
    }
}

export function Terminate_TerminateError_$reflection() {
    return union_type("RAWMap.Models.Api.Terminate.TerminateError", [], Terminate_TerminateError, () => [[], [["Item", string_type]], [["operation", string_type]]]);
}

export function Terminate_TerminateError_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? _arg.fields[0] : ((_arg.tag === 2) ? toText(printf("Unable to cancel %s operation"))(_arg.fields[0]) : "Generic termination error"));
}

export class ErrorMessage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GenericError", "ExnError", "AuthError", "ParseError", "DatabaseConversionError", "TerminateError", "InvalidValue", "MandatoryNotSet", "ProviderFieldNotSet", "DependentFieldNotSet", "NotFound", "InvalidInstitutionId", "InvalidFileType", "InvalidDesignation", "InvalidInstitutionNumber", "InvalidPatientId", "InvalidVitaaId", "InvalidHospitalId", "InvalidDateOfBirth", "NotPartOfUserInstitutions", "InvalidPatientVM", "InvalidStudyId", "InvalidSex", "InvalidWeight", "InvalidHeight", "InvalidStudyStatus", "DuplicateStudyNumber", "DuplicateHospitalPatientIdHash", "InvalidStringFilter", "InvalidNumericalFilter", "InvalidDateFilter", "InvalidGuidFilter", "InvalidFilterType", "InvalidCenterline", "InvalidBranch", "DownloadError"];
    }
}

export function ErrorMessage_$reflection() {
    return union_type("RAWMap.Models.Api.ErrorMessage", [], ErrorMessage, () => [[], [["Item", class_type("System.Exception")]], [["Item", Security_AuthErrorMessage_$reflection()]], [["Item", string_type]], [["Item", DatabaseConversionError_$reflection()]], [["Item", Terminate_TerminateError_$reflection()]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", string_type]]]);
}

export function ErrorMessage_get_describe() {
    return (_arg) => {
        switch (_arg.tag) {
            case 1:
                return toText(printf("An exception has occurred %O"))(_arg.fields[0]);
            case 3:
                return toText(printf("Failed to parse input %s"))(_arg.fields[0]);
            case 4: {
                const arg_2 = DatabaseConversionError_get_describe()(_arg.fields[0]);
                return toText(printf("A data conversion error has occurred: %s"))(arg_2);
            }
            case 5:
                return Terminate_TerminateError_get_describe()(_arg.fields[0]);
            case 6:
                return toText(printf("%s must %s"))(_arg.fields[0])(_arg.fields[1]);
            case 7:
                return toText(printf("%s is a required value."))(_arg.fields[0]);
            case 8:
                return toText(printf("%s had a value while the provider %s did not."))(_arg.fields[0])(_arg.fields[1]);
            case 9:
                return toText(printf("%s had a value while the dependent %s did not."))(_arg.fields[0])(_arg.fields[1]);
            case 10:
                return toText(printf("Unable to find %s"))(_arg.fields[0]);
            case 11:
                return "Invalid Institution ID";
            case 12:
                return "Unsupported file type. Only zip and pdf files are supported.";
            case 2:
                return Security_AuthErrorMessage_get_describe()(_arg.fields[0]);
            case 13:
                return "Invalid institution designation";
            case 14:
                return "Invalid institution was provided";
            case 15:
                return "Invalid Patient ID";
            case 16:
                return "Invalid ViTAA ID generated";
            case 17:
                return "Institution ID must not be empty";
            case 18:
                return "Date of birth is invalid";
            case 19:
                return "Attempting to create a Patient outside User institution";
            case 20:
                return "Supplied Patient ViewModel is invalid";
            case 21:
                return "Invalid Study ID";
            case 22:
                return "Sex must not be empty";
            case 23:
                return "Weight must be a positive number";
            case 24:
                return "Height must be a positive number";
            case 25:
                return "Invalid Study Status";
            case 26:
                return "Duplicate study number";
            case 27:
                return "Institution patient ID must not match any existing institution patient IDs.";
            case 28:
                return "String filter must not be null or empty";
            case 29:
                return "Cannot parse filter to a number";
            case 30:
                return "Cannot parse filter to a date";
            case 31:
                return "Internal ID filter must be a combination of digits 0-9, letters a-f, or dashes (-)";
            case 32:
                return "Invalid filter type";
            case 33:
                return "Invalid Centerline";
            case 34:
                return "Invalid Branch";
            case 35:
                return toText(printf("Error when downloading: %s"))(_arg.fields[0]);
            default:
                return "An error has occurred";
        }
    };
}

