import { ChildMsg_ofMsg, DefaultActions_create, DefaultActions_terminate, DefaultActions_UpdateArgs$3, DefaultActions_runUpdate, DefaultActions_ChildModel$2, Child$1, ChildAbsentStrategy$1, Child_absentChildCheck } from "../Common/Terminate.js";
import { Msg, Model, Mode as Mode_3 } from "./InstitutionTypes.js";
import { ofArray, tryFind, singleton, sort, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { InstitutionStatsViewModel_makeCsvRows, InstitutionViewModel_get_empty } from "../RAWMap.Models/View/Institution.js";
import { DateTimeOffset_maybeParse, Csv_makeCsvMultiLink, DateTimeOffset_yyyyMMdd, Common_SortInfo$1, Common_SortDirection } from "../RAWMap.Models/Common.js";
import { compare } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_map, Cmd_batch, Cmd_OfFunc_attempt, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { value as value_4, defaultArg, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { Toast_successToast, Toast_errorToast } from "../Common/General.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";
import { utcNow, minValue } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { compare as compare_1 } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { FileManagementUpdateArgs } from "../FileManagement/FileManagementTypes.js";
import { init as init_1, tryTerminate, update as update_1 } from "../ImageManager/ImageManagerState.js";
import { S3Context } from "../Common/AwsCommon.js";
import { FileComponentSpecs, MaximumFiles, FileType } from "../FileManagement/Shared.js";
import { FileOperationContext } from "../RAWMap.Models/Api.js";
import { wrapInboundMsg } from "../Common/InboundOutbound.js";
import { InboundMsg } from "../ImageManager/ImageManagerTypes.js";

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, [(model) => Child_absentChildCheck(model.ImageManagerComponent.child)]);

export function init() {
    return [new Model(new Mode_3(0, []), undefined, empty(), undefined, InstitutionViewModel_get_empty(), new Common_SortInfo$1("", new Common_SortDirection(1, []), (list) => sort(list, {
        Compare: compare,
    })), undefined, undefined, false, new DefaultActions_ChildModel$2(new Child$1(1, []), childAbsentStrategy, "institution logo component")), Cmd_none()];
}

export function update(args, msg, model) {
    let matchValue_1, msg_2, msg_7, matchValue_4, matchValue_5, dEnd_3, dStart_3, bind$0040_1;
    const mkListViewModel = () => (new Model(new Mode_3(0, []), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, InstitutionViewModel_get_empty(), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent));
    let matchResult, ex, userInfo, institutions, e, stats, e_1, isvm, institutionId, setter, date, date_1, institution, sortInfo, e_2, msg_16, institution_1;
    switch (msg.tag) {
        case 1: {
            matchResult = 1;
            userInfo = msg.fields[0];
            break;
        }
        case 2: {
            matchResult = 2;
            break;
        }
        case 3: {
            if (msg.fields[0].tag === 1) {
                matchResult = 4;
                e = msg.fields[0].fields[0];
            }
            else {
                matchResult = 3;
                institutions = msg.fields[0].fields[0];
            }
            break;
        }
        case 4: {
            matchResult = 5;
            break;
        }
        case 5: {
            if (msg.fields[0].tag === 1) {
                matchResult = 7;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 6;
                stats = msg.fields[0].fields[0];
            }
            break;
        }
        case 6: {
            matchResult = 8;
            isvm = msg.fields[0];
            break;
        }
        case 7: {
            matchResult = 9;
            institutionId = msg.fields[0];
            break;
        }
        case 9: {
            if (model.SelectedInstitution == null) {
                matchResult = 10;
            }
            else {
                matchResult = 11;
            }
            break;
        }
        case 17: {
            matchResult = 12;
            setter = msg.fields[0];
            break;
        }
        case 8: {
            matchResult = 13;
            break;
        }
        case 10: {
            if (model.SelectedInstitution == null) {
                matchResult = 14;
            }
            else {
                matchResult = 15;
            }
            break;
        }
        case 11: {
            matchResult = 16;
            break;
        }
        case 13: {
            matchResult = 17;
            date = msg.fields[0];
            break;
        }
        case 14: {
            matchResult = 18;
            date_1 = msg.fields[0];
            break;
        }
        case 12: {
            matchResult = 19;
            break;
        }
        case 15: {
            if (msg.fields[0].tag === 1) {
                matchResult = 23;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 20;
                institution = msg.fields[0].fields[0];
            }
            break;
        }
        case 16: {
            if (msg.fields[0].tag === 1) {
                matchResult = 23;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 21;
            }
            break;
        }
        case 18: {
            matchResult = 22;
            sortInfo = msg.fields[0];
            break;
        }
        case 19: {
            matchResult = 24;
            break;
        }
        case 20: {
            if (msg.fields[0].tag === 0) {
                matchResult = 25;
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 23: {
            matchResult = 27;
            msg_16 = msg.fields[0];
            break;
        }
        case 22: {
            matchResult = 28;
            break;
        }
        case 21: {
            matchResult = 29;
            institution_1 = msg.fields[0];
            break;
        }
        default: {
            matchResult = 0;
            ex = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1:
            return [new Model(model.Mode, userInfo, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), singleton((dispatch) => {
                dispatch(new Msg(2, []));
            })];
        case 2:
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(args.token).getInstitutions, undefined, (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(0, [Item_1])))];
        case 3:
            return [new Model(model.Mode, model.MaybeCurrentUser, institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), (matchValue_1 = model.SelectedInstitution, (model.Mode.tag === 3) ? ((matchValue_1 != null) ? ((msg_2 = (new Msg(7, [matchValue_1.id])), singleton((dispatch_1) => {
                dispatch_1(msg_2);
            }))) : Cmd_none()) : Cmd_none())];
        case 4:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e))];
        case 5: {
            const dStart = defaultArg(model.StatsDateStart, minValue());
            const dEnd = defaultArg(model.StatsDateEnd, utcNow());
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(args.token).getInstitutionStatistics, [dStart, dEnd], (Item_2) => (new Msg(5, [Item_2])), (Item_3) => (new Msg(0, [Item_3])))];
        }
        case 6:
            return [model, singleton((dispatch_2) => {
                dispatch_2(new Msg(6, [stats]));
            })];
        case 7:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_1))];
        case 8: {
            const dStart_1 = DateTimeOffset_yyyyMMdd(defaultArg(model.StatsDateStart, minValue()));
            const dEnd_1 = DateTimeOffset_yyyyMMdd(defaultArg(model.StatsDateEnd, utcNow()));
            const fileName = toText(printf("institution_stats_%O-%O.csv"))(dStart_1)(dEnd_1);
            return [model, Cmd_OfFunc_attempt((vm) => {
                const link = Csv_makeCsvMultiLink(fileName, InstitutionStatsViewModel_makeCsvRows(dStart_1, dEnd_1, vm));
                link.click();
            }, isvm, (Item_4) => (new Msg(0, [Item_4])))];
        }
        case 9:
            return [new Model(new Mode_3(3, []), model.MaybeCurrentUser, model.Institutions, tryFind((a) => (a.id === institutionId), model.Institutions), model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        case 10:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(new ErrorMessage(0, [])))];
        case 11:
            return [new Model(new Mode_3(2, []), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, value_4(model.SelectedInstitution), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        case 12:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, setter(model.EditInstitutionVm), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        case 13:
            return [new Model(new Mode_3(1, []), model.MaybeCurrentUser, model.Institutions, undefined, InstitutionViewModel_get_empty(), model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        case 14:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.EditInstitutionVm, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), singleton((dispatch_3) => {
                dispatch_3(new Msg(10, []));
            })];
        case 15: {
            const matchValue_3 = model.SelectedInstitution;
            if (matchValue_3 == null) {
                return [model, (msg_7 = (new Msg(0, [new Error("No institution selected for editing")])), singleton((dispatch_4) => {
                    dispatch_4(msg_7);
                }))];
            }
            else {
                return [new Model(new Mode_3(0, []), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), (matchValue_3.id === "00000000-0000-0000-0000-000000000000") ? Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedApi(args.token).createInstitution, model.EditInstitutionVm, (Item_5) => (new Msg(15, [Item_5])), (Item_6) => (new Msg(0, [Item_6]))) : Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, securedApi(args.token).editInstitution, model.EditInstitutionVm, (Item_7) => (new Msg(16, [Item_7])), (Item_8) => (new Msg(0, [Item_8])))];
            }
        }
        case 16:
            return [new Model(new Mode_3(4, []), model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        case 17:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, DateTimeOffset_maybeParse(date + "T00:00"), model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), singleton((dispatch_5) => {
                dispatch_5(new Msg(12, []));
            })];
        case 18:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, DateTimeOffset_maybeParse(date_1 + "T00:00"), model.isDownloadFieldsValid, model.ImageManagerComponent), singleton((dispatch_6) => {
                dispatch_6(new Msg(12, []));
            })];
        case 19:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, (matchValue_4 = model.StatsDateStart, (matchValue_5 = model.StatsDateEnd, (matchValue_4 != null) && ((matchValue_5 != null) && ((dEnd_3 = matchValue_5, (dStart_3 = matchValue_4, (compare_1(dEnd_3, utcNow()) <= 0) && (compare_1(dStart_3, dEnd_3) < 0))))))), model.ImageManagerComponent), Cmd_none()];
        case 20:
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast(toText(printf("Created institution %s"))(institution.name)), singleton((dispatch_7) => {
                dispatch_7(new Msg(2, []));
            })]))];
        case 21:
            return [mkListViewModel(), Cmd_batch(ofArray([Toast_successToast(toText(printf("Institution updated"))), singleton((dispatch_8) => {
                dispatch_8(new Msg(2, []));
            })]))];
        case 22:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, sortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, model.ImageManagerComponent), Cmd_none()];
        case 23:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_2))];
        case 24:
            return [mkListViewModel(), Cmd_none()];
        case 25:
            return [model, singleton((dispatch_9) => {
                dispatch_9(new Msg(2, []));
            })];
        case 26:
            return [model, Cmd_none()];
        case 27: {
            const patternInput = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_17, model_1) => {
                let bind$0040;
                const matchValue_7 = args.fileArgs.maybeCredentials;
                const matchValue_8 = args.fileArgs.maybeS3Context;
                let matchResult_1, context, credentials;
                if (matchValue_7 != null) {
                    if (matchValue_8 != null) {
                        matchResult_1 = 0;
                        context = matchValue_8;
                        credentials = matchValue_7;
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0: {
                        const args_1 = new FileManagementUpdateArgs(args.fileArgs.token, credentials, context);
                        if (msg_17.tag === 1) {
                            return [model_1, Cmd_none()];
                        }
                        else {
                            return update_1(new FileManagementUpdateArgs(args_1.Token, args_1.Credentials, (bind$0040 = args_1.Context, new S3Context(bind$0040.S3Config, bind$0040.S3Client, bind$0040.S3Expiry, args_1.Context.ActiveBucket))), msg_17.fields[0], model_1);
                        }
                    }
                    default:
                        return [model_1, Toast_errorToast("Missing Credentials or S3 Context")];
                }
            }, model), model.ImageManagerComponent, msg_16);
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, patternInput[0]), Cmd_map((Item_10) => (new Msg(23, [Item_10])), patternInput[1])];
        }
        case 28: {
            const patternInput_1 = DefaultActions_terminate(tryTerminate, model.ImageManagerComponent);
            return [new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, patternInput_1[0]), Cmd_map((Item_11) => (new Msg(23, [Item_11])), patternInput_1[1])];
        }
        default: {
            const fileSpecs = new FileComponentSpecs(ofArray([new FileType(2, []), new FileType(3, [])]), toText(printf("%O/Logo/"))(institution_1.id), new MaximumFiles(1, [1]));
            const createImageManagerComponent = (model_3) => {
                let msg_20;
                const createdImageManager = init_1(fileSpecs, new FileOperationContext(4, []));
                const patternInput_2 = DefaultActions_create(createdImageManager[0], createdImageManager[1], model_3.ImageManagerComponent);
                return [new Model(model_3.Mode, model_3.MaybeCurrentUser, model_3.Institutions, model_3.SelectedInstitution, model_3.EditInstitutionVm, model_3.SortInfo, model_3.StatsDateStart, model_3.StatsDateEnd, model_3.isDownloadFieldsValid, patternInput_2[0]), Cmd_batch(ofArray([Cmd_map((Item_12) => (new Msg(23, [Item_12])), patternInput_2[1]), (msg_20 = (new Msg(23, [ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg()))])), singleton((dispatch_10) => {
                    dispatch_10(msg_20);
                }))]))];
            };
            const matchValue_10 = model.ImageManagerComponent.child;
            switch (matchValue_10.tag) {
                case 1:
                case 2:
                    return createImageManagerComponent(model);
                default: {
                    const termination = tryTerminate(matchValue_10.fields[0]);
                    if (termination.tag === 1) {
                        return [model, Toast_errorToast(toText(printf("%s was unable to switch institutions."))(model.ImageManagerComponent.childComponentName))];
                    }
                    else {
                        termination.fields[0]();
                        return createImageManagerComponent(new Model(model.Mode, model.MaybeCurrentUser, model.Institutions, model.SelectedInstitution, model.EditInstitutionVm, model.SortInfo, model.StatsDateStart, model.StatsDateEnd, model.isDownloadFieldsValid, (bind$0040_1 = model.ImageManagerComponent, new DefaultActions_ChildModel$2(new Child$1(2, []), bind$0040_1.childAbsentStrategy, bind$0040_1.childComponentName))));
                    }
                }
            }
        }
    }
}

