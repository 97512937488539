import { ProgramModule_mkProgram, ProgramModule_run } from "./fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Program_withReactBatched } from "./fable_modules/Fable.Elmish.React.4.0.0/react.fs.js";
import { ProgramModule_toNavigable } from "./fable_modules/Fable.Elmish.Browser.4.0.3/navigation.fs.js";
import { parseHash } from "./fable_modules/Fable.Elmish.Browser.4.0.3/parser.fs.js";
import { handleUpdatedUrl, pageParser } from "./Routing.js";
import { update, init } from "./State.js";
import { root } from "./View.js";

ProgramModule_run(Program_withReactBatched("elmish-app", ProgramModule_toNavigable((location) => parseHash(pageParser, location), handleUpdatedUrl, ProgramModule_mkProgram(init, update, root))));

