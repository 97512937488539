import { Result_isOk, Result_bindOption, Result_map, ResultComputationExpression_ResultBuilder__Combine_71B5E353, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { StudyValidators_validateStringNotNullOrWhiteSpace, StudyValidators_validateFloatIsPositive, StudyValidators_validateNotEqual, StudyValidators_validateLessThanOrEqualTo, StudyValidators_validateFloatIsZeroOrPositive, StudyValidators_validateIntIsZeroOrPositive } from "./Study.js";
import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { array_type, string_type, class_type, union_type, option_type, float64_type, record_type, int32_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Common_floatToMl, Visualization_LabelPositionPercentage_$reflection, Visualization_Centerline_$reflection, Visualization_UnifiedDistanceBranch_$reflection, Visualization_UnifiedTransitionBranch_$reflection } from "../Common.js";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { FSharpChoice$4 } from "../../fable_modules/fable-library-js.4.19.3/Choice.js";
import { validateNotEmptyGuid } from "../../fable_modules/Webbler.Models.1.3.1/Validation.fs.js";
import { ErrorMessage } from "../ErrorMessage.js";
import { tryFind, item } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.3/Util.js";

export function CenterlineMeasurement_validateIndexes(firstIndex, secondIndex) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateIntIsZeroOrPositive("firstIndex", firstIndex), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateIntIsZeroOrPositive("secondIndex", secondIndex), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, undefined)))));
}

export class UnifiedBranchRangeSelectionViewModel$1 extends Record {
    constructor(branch, firstIndex, secondIndex) {
        super();
        this.branch = branch;
        this.firstIndex = (firstIndex | 0);
        this.secondIndex = (secondIndex | 0);
    }
}

export function UnifiedBranchRangeSelectionViewModel$1_$reflection(gen0) {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.UnifiedBranchRangeSelectionViewModel`1", [gen0], UnifiedBranchRangeSelectionViewModel$1, () => [["branch", gen0], ["firstIndex", int32_type], ["secondIndex", int32_type]]);
}

export class UnifiedTransitionBranchPointSelectionViewModel extends Record {
    constructor(branch, index) {
        super();
        this.branch = branch;
        this.index = (index | 0);
    }
}

export function UnifiedTransitionBranchPointSelectionViewModel_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.UnifiedTransitionBranchPointSelectionViewModel", [], UnifiedTransitionBranchPointSelectionViewModel, () => [["branch", Visualization_UnifiedTransitionBranch_$reflection()], ["index", int32_type]]);
}

export function UnifiedTransitionBranchPointSelectionViewModel_validate_Z50EDD576(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateIntIsZeroOrPositive("index", vm.index), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))));
}

export class LocalDiameterViewModel extends Record {
    constructor(min, max, circleEquivalent) {
        super();
        this.min = min;
        this.max = max;
        this.circleEquivalent = circleEquivalent;
    }
}

export function LocalDiameterViewModel_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.LocalDiameterViewModel", [], LocalDiameterViewModel, () => [["min", float64_type], ["max", float64_type], ["circleEquivalent", float64_type]]);
}

export function LocalDiameterViewModel_validate_Z5ED1D555(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsZeroOrPositive("min", vm.min), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsZeroOrPositive("max", vm.max), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsZeroOrPositive("circleEquivalent", vm.circleEquivalent), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateLessThanOrEqualTo("min", "max", vm.min, vm.max), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm)))))));
}

export class DiameterRangeViewModel extends Record {
    constructor(min, max) {
        super();
        this.min = min;
        this.max = max;
    }
}

export function DiameterRangeViewModel_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.DiameterRangeViewModel", [], DiameterRangeViewModel, () => [["min", float64_type], ["max", float64_type]]);
}

export function DiameterRangeViewModel_validate_Z3621E5A7(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsZeroOrPositive("min", vm.min), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsZeroOrPositive("max", vm.max), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateLessThanOrEqualTo("min", "max", vm.min, vm.max), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm))))));
}

export class CenterlineMeasurementValueViewModel extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CenterlineDistance", "DiameterRange", "LocalDiameter", "CenterlineVolume"];
    }
}

export function CenterlineMeasurementValueViewModel_$reflection() {
    return union_type("RAWMap.Models.View.CenterlineMeasurement.CenterlineMeasurementValueViewModel", [], CenterlineMeasurementValueViewModel, () => [[["Item1", UnifiedBranchRangeSelectionViewModel$1_$reflection(Visualization_UnifiedDistanceBranch_$reflection())], ["distance", float64_type]], [["Item1", UnifiedBranchRangeSelectionViewModel$1_$reflection(Visualization_UnifiedTransitionBranch_$reflection())], ["Item2", DiameterRangeViewModel_$reflection()]], [["Item1", UnifiedTransitionBranchPointSelectionViewModel_$reflection()], ["Item2", LocalDiameterViewModel_$reflection()]], [["Item1", UnifiedBranchRangeSelectionViewModel$1_$reflection(Visualization_UnifiedTransitionBranch_$reflection())], ["Item2", option_type(UnifiedTransitionBranchPointSelectionViewModel_$reflection())], ["volume", float64_type]]]);
}

export function CenterlineMeasurementValueViewModel_validate_297A6D85(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => {
        let vm_2, unifiedRange_2, unifiedRange;
        const validateUniqueIndexes = (firstIndex, secondIndex) => StudyValidators_validateNotEqual("firstIndex", "secondIndex", firstIndex, secondIndex);
        return ResultComputationExpression_ResultBuilder__Combine_71B5E353(ResultComputationExpression_result, (vm.tag === 1) ? ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value_1) => {
        })((vm_2 = vm.fields[0], ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, CenterlineMeasurement_validateIndexes(vm_2.firstIndex, vm_2.secondIndex), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm_2)))))), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value_2) => {
        })(DiameterRangeViewModel_validate_Z3621E5A7(vm.fields[1])), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, undefined))) : ((vm.tag === 2) ? ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value_3) => {
        })(UnifiedTransitionBranchPointSelectionViewModel_validate_Z50EDD576(vm.fields[0])), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value_4) => {
        })(LocalDiameterViewModel_validate_Z5ED1D555(vm.fields[1])), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, undefined))) : ((vm.tag === 3) ? ((unifiedRange_2 = vm.fields[0], ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateUniqueIndexes(unifiedRange_2.firstIndex, unifiedRange_2.secondIndex), () => {
            let vm_3;
            return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value_5) => {
            })((vm_3 = unifiedRange_2, ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, CenterlineMeasurement_validateIndexes(vm_3.firstIndex, vm_3.secondIndex), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm_3)))))), () => {
                let f2;
                return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, defaultArg(map((f2 = Result_map()((value_6) => {
                }), (arg) => f2(UnifiedTransitionBranchPointSelectionViewModel_validate_Z50EDD576(arg))), vm.fields[1]), new FSharpResult$2(0, [undefined])), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsPositive("volume", vm.fields[2]), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, undefined)));
            });
        }))) : ((unifiedRange = vm.fields[0], ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateFloatIsPositive("distance", vm.fields[1]), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateUniqueIndexes(unifiedRange.firstIndex, unifiedRange.secondIndex), () => {
            let vm_1;
            return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value) => {
            })((vm_1 = unifiedRange, ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, CenterlineMeasurement_validateIndexes(vm_1.firstIndex, vm_1.secondIndex), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm_1)))))), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, undefined));
        })))))), ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm)));
    }));
}

export function CenterlineMeasurementValueViewModel_viewComponents_297A6D85(vm) {
    let arg_2, arg_3, arg_6, arg_7, arg_8;
    switch (vm.tag) {
        case 1: {
            const diameterModel = vm.fields[1];
            return ["Diameter Range", (arg_2 = toText(printf("%0.1f %s"))(diameterModel.min)("mm"), (arg_3 = toText(printf("%0.1f %s"))(diameterModel.max)("mm"), toText(printf("\n  Min: %s\n  Max: %s"))(arg_2)(arg_3)))];
        }
        case 2: {
            const model = vm.fields[1];
            return ["Local Diameter", (arg_6 = toText(printf("%0.1f %s"))(model.min)("mm"), (arg_7 = toText(printf("%0.1f %s"))(model.max)("mm"), (arg_8 = toText(printf("%0.1f %s"))(model.circleEquivalent)("mm"), toText(printf("\n  Min: %s\n  Max: %s\n  Circle-Equivalent: %s"))(arg_6)(arg_7)(arg_8))))];
        }
        case 3:
            return ["Volume", toText(printf("%0.1f %s"))(vm.fields[2])("mL")];
        default:
            return ["Centerline Distance", toText(printf("%0.1f %s"))(vm.fields[1])("mm")];
    }
}

export function CenterlineMeasurementValueViewModel_tableDisplay_297A6D85(vm) {
    let arg, arg_1, arg_4, arg_5, arg_6;
    switch (vm.tag) {
        case 1: {
            const diameterModel = vm.fields[1];
            return ["Diameter Range", (arg = toText(printf("%0.1f %s"))(diameterModel.min)("mm"), (arg_1 = toText(printf("%0.1f %s"))(diameterModel.max)("mm"), toText(printf("Min: %s\nMax: %s"))(arg)(arg_1)))];
        }
        case 2: {
            const model = vm.fields[1];
            return ["Local Diameter", (arg_4 = toText(printf("%0.1f %s"))(model.min)("mm"), (arg_5 = toText(printf("%0.1f %s"))(model.max)("mm"), (arg_6 = toText(printf("%0.1f %s"))(model.circleEquivalent)("mm"), toText(printf("Min: %s\nMax: %s\nCircle-Equivalent: %s"))(arg_4)(arg_5)(arg_6))))];
        }
        default:
            return CenterlineMeasurementValueViewModel_viewComponents_297A6D85(vm);
    }
}

export function CenterlineMeasurementValueViewModel_comparePrevious(currentVm, previousVm) {
    let measurement_1, arg_3, arg_4, arg_7, measurement_6, arg_8, measurement_8, arg_11, arg_12, arg_13, arg_17, measurement_16, arg_18, measurement_18, arg_19, measurement_20, measurement_23;
    let matchResult, _currentUnifiedRange, _previousUnifiedRange, currentDistance, previousDistance, _currentUnifiedRange_1, _previousUnifiedRange_1, currentDiameterModel, previousDiameterModel, _currentUnifiedPoint, _previousUnifiedPoint, currentModel, previousModel, _currentUnifiedRange_2, _maybeCurrentUnifiedPoint, _maybePreviousUnifiedPoint, _previousUnifiedRange_2, currentVolume, previousVolume;
    switch (currentVm.tag) {
        case 1: {
            if (previousVm.tag === 1) {
                matchResult = 1;
                _currentUnifiedRange_1 = currentVm.fields[0];
                _previousUnifiedRange_1 = previousVm.fields[0];
                currentDiameterModel = currentVm.fields[1];
                previousDiameterModel = previousVm.fields[1];
            }
            else {
                matchResult = 4;
            }
            break;
        }
        case 2: {
            if (previousVm.tag === 2) {
                matchResult = 2;
                _currentUnifiedPoint = currentVm.fields[0];
                _previousUnifiedPoint = previousVm.fields[0];
                currentModel = currentVm.fields[1];
                previousModel = previousVm.fields[1];
            }
            else {
                matchResult = 4;
            }
            break;
        }
        case 3: {
            if (previousVm.tag === 3) {
                matchResult = 3;
                _currentUnifiedRange_2 = currentVm.fields[0];
                _maybeCurrentUnifiedPoint = currentVm.fields[1];
                _maybePreviousUnifiedPoint = previousVm.fields[1];
                _previousUnifiedRange_2 = previousVm.fields[0];
                currentVolume = currentVm.fields[2];
                previousVolume = previousVm.fields[2];
            }
            else {
                matchResult = 5;
            }
            break;
        }
        default:
            if (previousVm.tag === 0) {
                matchResult = 0;
                _currentUnifiedRange = currentVm.fields[0];
                _previousUnifiedRange = previousVm.fields[0];
                currentDistance = currentVm.fields[1];
                previousDistance = previousVm.fields[1];
            }
            else {
                matchResult = 4;
            }
    }
    switch (matchResult) {
        case 0:
            return [toText(printf("%0.1f %s"))(previousDistance)("mm"), (measurement_1 = (currentDistance - previousDistance), toText(printf("%0.1f %s"))(measurement_1)("mm"))];
        case 1:
            return [(arg_3 = toText(printf("%0.1f %s"))(previousDiameterModel.min)("mm"), (arg_4 = toText(printf("%0.1f %s"))(previousDiameterModel.max)("mm"), toText(printf("Min: %s\nMax: %s"))(arg_3)(arg_4))), (arg_7 = ((measurement_6 = (currentDiameterModel.min - previousDiameterModel.min), toText(printf("%0.1f %s"))(measurement_6)("mm"))), (arg_8 = ((measurement_8 = (currentDiameterModel.max - previousDiameterModel.max), toText(printf("%0.1f %s"))(measurement_8)("mm"))), toText(printf("Min: %s\nMax: %s"))(arg_7)(arg_8)))];
        case 2:
            return [(arg_11 = toText(printf("%0.1f %s"))(previousModel.min)("mm"), (arg_12 = toText(printf("%0.1f %s"))(previousModel.max)("mm"), (arg_13 = toText(printf("%0.1f %s"))(previousModel.circleEquivalent)("mm"), toText(printf("Min: %s\nMax: %s\nCircle-Equivalent: %s"))(arg_11)(arg_12)(arg_13)))), (arg_17 = ((measurement_16 = (currentModel.min - previousModel.min), toText(printf("%0.1f %s"))(measurement_16)("mm"))), (arg_18 = ((measurement_18 = (currentModel.max - previousModel.max), toText(printf("%0.1f %s"))(measurement_18)("mm"))), (arg_19 = ((measurement_20 = (currentModel.circleEquivalent - previousModel.circleEquivalent), toText(printf("%0.1f %s"))(measurement_20)("mm"))), toText(printf("Min: %s\nMax: %s\nCircle-Equivalent: %s"))(arg_17)(arg_18)(arg_19))))];
        case 3:
            return [toText(printf("%0.1f %s"))(previousVolume)("mL"), (measurement_23 = (currentVolume - previousVolume), toText(printf("%0.1f %s"))(measurement_23)("mL"))];
        case 4:
            return undefined;
        default:
            return undefined;
    }
}

export function CenterlineMeasurementValueViewModelModule_$007CDistanceRangeBranch$007CTransitionRangeBranch$007CPointBranch$007CVolumeBranch$007C(valueVm) {
    switch (valueVm.tag) {
        case 1:
            return new FSharpChoice$4(1, [valueVm.fields[0]]);
        case 2:
            return new FSharpChoice$4(2, [valueVm.fields[0]]);
        case 3:
            return new FSharpChoice$4(3, [[valueVm.fields[0], valueVm.fields[1]]]);
        default:
            return new FSharpChoice$4(0, [valueVm.fields[0]]);
    }
}

export class CenterlineMeasurementValueTypes extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CenterlineDistanceValue", "DiameterRangeValue", "LocalDiameterValue", "CenterlineVolumeValue"];
    }
}

export function CenterlineMeasurementValueTypes_$reflection() {
    return union_type("RAWMap.Models.View.CenterlineMeasurement.CenterlineMeasurementValueTypes", [], CenterlineMeasurementValueTypes, () => [[], [], [], []]);
}

export function CenterlineMeasurementValueTypes_get_desc() {
    return (_arg) => ((_arg.tag === 1) ? "Diameter Range" : ((_arg.tag === 2) ? "Local Diameter" : ((_arg.tag === 3) ? "Volume" : "Centerline Distance")));
}

export class CenterlineMeasurementViewModel extends Record {
    constructor(measurementId, studyId, name, currentStudyValue, maybePreviousStudyValue, centerline, labelPosition) {
        super();
        this.measurementId = measurementId;
        this.studyId = studyId;
        this.name = name;
        this.currentStudyValue = currentStudyValue;
        this.maybePreviousStudyValue = maybePreviousStudyValue;
        this.centerline = centerline;
        this.labelPosition = labelPosition;
    }
}

export function CenterlineMeasurementViewModel_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.CenterlineMeasurementViewModel", [], CenterlineMeasurementViewModel, () => [["measurementId", class_type("System.Guid")], ["studyId", class_type("System.Guid")], ["name", string_type], ["currentStudyValue", CenterlineMeasurementValueViewModel_$reflection()], ["maybePreviousStudyValue", option_type(CenterlineMeasurementValueViewModel_$reflection())], ["centerline", Visualization_Centerline_$reflection()], ["labelPosition", Visualization_LabelPositionPercentage_$reflection()]]);
}

export function CenterlineMeasurementViewModel_get_name_() {
    return [(vm) => vm.name, (v) => ((vm_1) => (new CenterlineMeasurementViewModel(vm_1.measurementId, vm_1.studyId, v, vm_1.currentStudyValue, vm_1.maybePreviousStudyValue, vm_1.centerline, vm_1.labelPosition)))];
}

export function CenterlineMeasurementViewModel_validate_5181C9AE(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["measurementId", "not be empty Guid"]), vm.measurementId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotEmptyGuid(new ErrorMessage(6, ["studyId", "not be empty Guid"]), vm.studyId), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, StudyValidators_validateStringNotNullOrWhiteSpace("name", vm.name), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value) => {
    })(CenterlineMeasurementValueViewModel_validate_297A6D85(vm.currentStudyValue)), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_map()((value_1) => {
    })(Result_bindOption(CenterlineMeasurementValueViewModel_validate_297A6D85, vm.maybePreviousStudyValue)), () => {
        let matchValue, matchValue_1;
        return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (matchValue = vm.currentStudyValue, (matchValue_1 = vm.maybePreviousStudyValue, (matchValue.tag === 1) ? ((matchValue_1 == null) ? (new FSharpResult$2(0, [undefined])) : ((matchValue_1.tag === 1) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [new ErrorMessage(6, ["measurementType", "current study and previous study measurement types must be the same"])])))) : ((matchValue.tag === 2) ? ((matchValue_1 == null) ? (new FSharpResult$2(0, [undefined])) : ((matchValue_1.tag === 2) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [new ErrorMessage(6, ["measurementType", "current study and previous study measurement types must be the same"])])))) : ((matchValue.tag === 3) ? ((matchValue_1 == null) ? (new FSharpResult$2(0, [undefined])) : ((matchValue_1.tag === 3) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [new ErrorMessage(6, ["measurementType", "current study and previous study measurement types must be the same"])])))) : ((matchValue_1 == null) ? (new FSharpResult$2(0, [undefined])) : ((matchValue_1.tag === 0) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [new ErrorMessage(6, ["measurementType", "current study and previous study measurement types must be the same"])])))))))), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, vm));
    })))))));
}

export function CenterlineMeasurementViewModel_getStudyId_5181C9AE(vm) {
    return vm.studyId;
}

export function CenterlineMeasurementViewModel_getPrimaryId_5181C9AE(vm) {
    return vm.measurementId;
}

export function CenterlineMeasurementViewModel_get_primaryIdName() {
    return "MeasurementId";
}

export function CenterlineMeasurementViewModel_get_descValueType() {
    return CenterlineMeasurementValueTypes_get_desc();
}

export function CenterlineMeasurementViewModel_desc_5181C9AE(vm) {
    const matchValue = vm.currentStudyValue;
    switch (matchValue.tag) {
        case 1:
            return CenterlineMeasurementViewModel_get_descValueType()(new CenterlineMeasurementValueTypes(1, []));
        case 2:
            return CenterlineMeasurementViewModel_get_descValueType()(new CenterlineMeasurementValueTypes(2, []));
        case 3:
            return CenterlineMeasurementViewModel_get_descValueType()(new CenterlineMeasurementValueTypes(3, []));
        default:
            return CenterlineMeasurementViewModel_get_descValueType()(new CenterlineMeasurementValueTypes(0, []));
    }
}

export function CenterlineMeasurementViewModel_isValid_5181C9AE(vm) {
    return Result_isOk(CenterlineMeasurementViewModel_validate_5181C9AE(vm));
}

export function CenterlineMeasurementViewModelModule_$007CValid$007C_$007C(vm) {
    const matchValue = CenterlineMeasurementViewModel_validate_5181C9AE(vm);
    if (matchValue.tag === 0) {
        return matchValue.fields[0];
    }
    else {
        return undefined;
    }
}

export function CenterlineMeasurementViewModelModule_$007CInvalid$007C_$007C(vm) {
    const matchValue = CenterlineMeasurementViewModel_validate_5181C9AE(vm);
    if (matchValue.tag === 1) {
        return matchValue.fields[0];
    }
    else {
        return undefined;
    }
}

export class Bindings_PlaneIdentifier extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["First", "Second", "Third"];
    }
}

export function Bindings_PlaneIdentifier_$reflection() {
    return union_type("RAWMap.Models.View.CenterlineMeasurement.Bindings.PlaneIdentifier", [], Bindings_PlaneIdentifier, () => [[], [], []]);
}

export function Bindings_PlaneIdentifier_Id_Z3EBB1C36(planeIdentifier) {
    switch (planeIdentifier.tag) {
        case 1:
            return "plane-second";
        case 2:
            return "plane-third";
        default:
            return "plane-first";
    }
}

export function Bindings_PlaneIdentifier_label_Z3EBB1C36(planeIdentifier) {
    switch (planeIdentifier.tag) {
        case 1:
            return "Plane 2";
        case 2:
            return "Plane 3";
        default:
            return "Plane 1";
    }
}

export class Bindings_IndexBounds extends Record {
    constructor(firstIndex, lastIndex) {
        super();
        this.firstIndex = (firstIndex | 0);
        this.lastIndex = (lastIndex | 0);
    }
}

export function Bindings_IndexBounds_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.Bindings.IndexBounds", [], Bindings_IndexBounds, () => [["firstIndex", int32_type], ["lastIndex", int32_type]]);
}

export class Bindings_BranchIndexBounds extends Record {
    constructor(transitionTrunk, transitionLeftBranch, transitionRightBranch, distanceTrunk, distanceLeftBranch, distanceRightBranch, cuspTrunk) {
        super();
        this.transitionTrunk = transitionTrunk;
        this.transitionLeftBranch = transitionLeftBranch;
        this.transitionRightBranch = transitionRightBranch;
        this.distanceTrunk = distanceTrunk;
        this.distanceLeftBranch = distanceLeftBranch;
        this.distanceRightBranch = distanceRightBranch;
        this.cuspTrunk = cuspTrunk;
    }
}

export function Bindings_BranchIndexBounds_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.Bindings.BranchIndexBounds", [], Bindings_BranchIndexBounds, () => [["transitionTrunk", Bindings_IndexBounds_$reflection()], ["transitionLeftBranch", Bindings_IndexBounds_$reflection()], ["transitionRightBranch", Bindings_IndexBounds_$reflection()], ["distanceTrunk", Bindings_IndexBounds_$reflection()], ["distanceLeftBranch", Bindings_IndexBounds_$reflection()], ["distanceRightBranch", Bindings_IndexBounds_$reflection()], ["cuspTrunk", Bindings_IndexBounds_$reflection()]]);
}

export class Bindings_UnifiedBranchIndexBounds extends Record {
    constructor(lumenBounds, wallBounds) {
        super();
        this.lumenBounds = lumenBounds;
        this.wallBounds = wallBounds;
    }
}

export function Bindings_UnifiedBranchIndexBounds_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.Bindings.UnifiedBranchIndexBounds", [], Bindings_UnifiedBranchIndexBounds, () => [["lumenBounds", Bindings_BranchIndexBounds_$reflection()], ["wallBounds", Bindings_BranchIndexBounds_$reflection()]]);
}

export class Bindings_CenterlinePointMeasurement extends Record {
    constructor(index, location, normal, maxDiameter, secondaryDiameter, circleEquivalentDiameter, volume, distance, transitionVolume, trunkVolume, numberOfPoints, callbackIdentifier, planeIdentifier) {
        super();
        this.index = (index | 0);
        this.location = location;
        this.normal = normal;
        this.maxDiameter = maxDiameter;
        this.secondaryDiameter = secondaryDiameter;
        this.circleEquivalentDiameter = circleEquivalentDiameter;
        this.volume = volume;
        this.distance = distance;
        this.transitionVolume = transitionVolume;
        this.trunkVolume = trunkVolume;
        this.numberOfPoints = (numberOfPoints | 0);
        this.callbackIdentifier = callbackIdentifier;
        this.planeIdentifier = planeIdentifier;
    }
}

export function Bindings_CenterlinePointMeasurement_$reflection() {
    return record_type("RAWMap.Models.View.CenterlineMeasurement.Bindings.CenterlinePointMeasurement", [], Bindings_CenterlinePointMeasurement, () => [["index", int32_type], ["location", array_type(float64_type)], ["normal", array_type(float64_type)], ["maxDiameter", float64_type], ["secondaryDiameter", float64_type], ["circleEquivalentDiameter", float64_type], ["volume", float64_type], ["distance", float64_type], ["transitionVolume", float64_type], ["trunkVolume", float64_type], ["numberOfPoints", int32_type], ["callbackIdentifier", class_type("System.Guid")], ["planeIdentifier", Bindings_PlaneIdentifier_$reflection()]]);
}

export function CenterlineVolume_calculateVolume(rangeSelection, bounds, measurements) {
    const lastIndexOfTrunk = bounds.transitionTrunk.lastIndex | 0;
    const transitionAreaVolume = ((rangeSelection.firstIndex > lastIndexOfTrunk) ? true : (rangeSelection.secondIndex > lastIndexOfTrunk)) ? item(0, measurements).transitionVolume : 0;
    const getVolumeForPlane = (planeIdentifier) => defaultArg(map((m_1) => m_1.volume, tryFind((m) => equals(m.planeIdentifier, planeIdentifier), measurements)), 0);
    const trunkVolume = item(0, measurements).trunkVolume;
    const leftBranchFirstVolume = getVolumeForPlane(new Bindings_PlaneIdentifier(0, []));
    const leftBranchSecondVolume = getVolumeForPlane(new Bindings_PlaneIdentifier(1, []));
    const rightBranchVolume = getVolumeForPlane(new Bindings_PlaneIdentifier(2, []));
    return Common_floatToMl((((rangeSelection.firstIndex <= lastIndexOfTrunk) && (rangeSelection.secondIndex <= lastIndexOfTrunk)) ? Math.abs(leftBranchSecondVolume - leftBranchFirstVolume) : ((rangeSelection.firstIndex <= lastIndexOfTrunk) ? (((trunkVolume - leftBranchFirstVolume) + leftBranchSecondVolume) + transitionAreaVolume) : (((trunkVolume - leftBranchSecondVolume) + leftBranchFirstVolume) + transitionAreaVolume))) + rightBranchVolume);
}

