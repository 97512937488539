import { tryHead, filter, isEmpty, ofArray, singleton, empty, map } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Option, file as file_3, name as name_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/File.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { ComponentVisibility, Button_visibilityControlled } from "../Common/Controls.js";
import { DOMAttr, HTMLAttr, CSSProp } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { button, Option as Option_1 } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Modifier_IModifier, Text_span, Common_GenericOption, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { FileManagement, FileDownload, LocalMsg } from "./FileManagementTypes.js";
import { icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import * as react from "react";
import { Option as Option_2, progress } from "../fable_modules/Fulma.3.0.0/Elements/Progress.fs.js";
import { split, printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { panel, Block_a } from "../fable_modules/Fulma.3.0.0/Components/Panel.fs.js";
import { Item_Option, right, item, left, Level_Option, level } from "../fable_modules/Fulma.3.0.0/Layouts/Level.fs.js";
import { toString } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_3, modal } from "../fable_modules/Fulma.3.0.0/Components/Modal.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { Option as Option_4, div } from "../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { match, create } from "../fable_modules/fable-library-js.4.19.3/RegExp.js";
import { bind, map as map_1, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__get_IsEmpty, map as map_2, toList as toList_1, FSharpMap__get_Count } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { defaultOf, equals } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { last } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { div as div_1, p } from "../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { wrapLocalMsg } from "../Common/InboundOutbound.js";
import { h5 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { FileComponentVisibility_downloadOnly_Z510FEC72 } from "./Shared.js";

export function uploadControl(mkSelectorView, fileComponentVisibility, uploadSelection, maybeFileTypeRestrictions, dispatch) {
    let uploadSelectorElement_1, filesFormatted, children_2;
    const children_4 = [(uploadSelectorElement_1 = mkSelectorView(maybeFileTypeRestrictions), (filesFormatted = map((fileData) => name_1(empty(), singleton(fileData.file.name)), uploadSelection), file_3(ofArray([new Option(9, []), new Option(10, [])]), singleton((children_2 = toList(delay(() => append(singleton_1(uploadSelectorElement_1), delay(() => {
        let matchValue;
        return append(singleton_1(Button_visibilityControlled(singleton(new CSSProp(221, ["0"])), (matchValue = fileComponentVisibility.uploadButtonVisibility, (matchValue.tag === 0) ? (isEmpty(uploadSelection) ? (new ComponentVisibility(2, ["No files selected for upload"])) : (new ComponentVisibility(0, []))) : matchValue), ofArray([new Option_1(2, []), new Option_1(0, [new Color_IColor(6, [])]), new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg) => {
            dispatch(new LocalMsg(7, [uploadSelection]));
        }])])])]), ofArray([icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-upload"])), []))), react.createElement("span", {}, "Upload")]))), delay(() => filesFormatted));
    })))), react.createElement("div", {
        className: "file-layout",
    }, ...children_2))))))];
    return react.createElement("div", {
        className: "block",
    }, ...children_4);
}

export function progressPanelItem(name, percentComplete) {
    const progressBar = (percentComplete === 0) ? "Waiting..." : progress(ofArray([new Option_2(4, [100]), new Option_2(3, [~~percentComplete])]), singleton(toText(printf("%f%%"))(percentComplete)));
    return Block_a(empty(), singleton(level(singleton(new Level_Option(0, [singleton(["style", {
        flexGrow: 1,
    }])])), ofArray([left(empty(), singleton(item(empty(), singleton(toString(name))))), right(singleton(new Common_GenericOption(1, [singleton(["style", {
        flexGrow: 0.75,
    }])])), singleton(item(singleton(new Item_Option(0, [singleton(["style", {
        flexGrow: 1,
    }])])), singleton(progressBar))))]))));
}

export function deleteConfirmationModal(model, s3Vms, dispatchLocal) {
    return modal(singleton(new Option_3(1, [true])), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton("Delete")))), Card_body(empty(), singleton(model.DisplayContent.DeleteWarning)), Card_foot(empty(), ofArray([button(singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg) => {
        dispatchLocal(new LocalMsg(18, []));
    }])])])), singleton("Cancel")), button(ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_1) => {
        dispatchLocal(new LocalMsg(19, [s3Vms]));
    }])])])]), singleton("Delete"))]))]))]));
}

export function listOfFiles(model, fileUploads, fileDownloads_1, failedFiles, persistedFiles, componentVisibility, dispatch) {
    let files_1, failedFiles_1, persistedFiles_1, fileList, children_6, zipBatchRegex, persistedZipBatchFiles, persistedFileList;
    const fileNameDisplay = (fileName) => {
        const props = [new HTMLAttr(65, [""]), ["style", {
            marginLeft: "1.0rem",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
        }]];
        const children = [toString(fileName)];
        return react.createElement("div", keyValueList(props, 1), ...children);
    };
    const fileListItemContainer = (buttons, fileNameElement) => {
        let children_2;
        return Block_a(empty(), ofArray([(children_2 = [div(ofArray([new Option_4(5, []), new Option_4(11, [singleton(["style", {
            marginLeft: "1.5rem",
        }])])]), buttons)], react.createElement("div", {}, ...children_2)), fileNameElement]));
    };
    const deleteButton = (files, visibility) => Button_visibilityControlled(undefined, visibility, ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg) => {
        dispatch(new LocalMsg(17, [files]));
    }])])])]), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-trash"])), [])))));
    let deleteModal;
    const matchValue_2 = model.MaybeS3VmsToDelete;
    deleteModal = ((matchValue_2 != null) ? deleteConfirmationModal(model, matchValue_2, dispatch) : Helpers_nothing);
    const children_8 = [(files_1 = fileUploads, (failedFiles_1 = failedFiles, (persistedFiles_1 = persistedFiles, (fileList = (isEmpty(files_1) ? (isEmpty(failedFiles_1) ? (isEmpty(fileDownloads_1) ? (isEmpty(persistedFiles_1) ? singleton((children_6 = [react.createElement("div", {}, "No files have been uploaded yet")], react.createElement("div", {}, ...children_6))) : ((zipBatchRegex = create(`^(.+)${"_\\d+_of_\\d+.zip$"}`, 8), (persistedZipBatchFiles = filter((s3Vm) => (match(zipBatchRegex, s3Vm.FileName) != null), persistedFiles_1), (persistedFileList = ((model.FileHandling.tag === 1) ? singleton(defaultArg(map_1((fileName_4) => fileListItemContainer(singleton(deleteButton(persistedZipBatchFiles, (FSharpMap__get_Count(model.FileManagementsInProgress) > 0) ? (new ComponentVisibility(2, [undefined])) : componentVisibility.deleteButtonVisibility)), fileNameDisplay(fileName_4)), map_1((baseFileName) => (`${baseFileName} (multi-part zip)`), bind((s3Vm_1) => {
        const groups = match(zipBatchRegex, s3Vm_1.FileName);
        if (!equals(groups, defaultOf()) && (groups.length >= 2)) {
            return groups[1];
        }
        else {
            return undefined;
        }
    }, tryHead(persistedFiles_1)))), Helpers_nothing)) : map((file) => fileListItemContainer(ofArray([Button_visibilityControlled(undefined, componentVisibility.downloadButtonVisibility, singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_1) => {
        let tupledArg;
        dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, [tupledArg[0], tupledArg[1]])));
    }])])])), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-download"])), []))))), deleteButton(singleton(file), componentVisibility.deleteButtonVisibility)]), fileNameDisplay(file.FileName)), persistedFiles_1)), toList(delay(() => append(map((file_2) => {
        let _arg_4;
        return progressPanelItem(last(split(file_2.Name, ["/"], undefined, 0)), (_arg_4 = file_2.Progress, (_arg_4.tag === 1) ? 100 : _arg_4.fields[0]));
    }, files_1), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, [singleton(["style", {
        flexGrow: 1,
    }])])), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], undefined, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(1, [new Color_IColor(8, [])]))])), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_2) => {
        dispatch(new LocalMsg(21, [file_1]));
    }])])])]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_3) => {
        dispatch(new LocalMsg(22, [file_1]));
    }])])])), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => persistedFileList))))))))))) : ((zipBatchRegex = create(`^(.+)${"_\\d+_of_\\d+.zip$"}`, 8), (persistedZipBatchFiles = filter((s3Vm) => (match(zipBatchRegex, s3Vm.FileName) != null), persistedFiles_1), (persistedFileList = ((model.FileHandling.tag === 1) ? singleton(defaultArg(map_1((fileName_4) => fileListItemContainer(singleton(deleteButton(persistedZipBatchFiles, (FSharpMap__get_Count(model.FileManagementsInProgress) > 0) ? (new ComponentVisibility(2, [undefined])) : componentVisibility.deleteButtonVisibility)), fileNameDisplay(fileName_4)), map_1((baseFileName) => (`${baseFileName} (multi-part zip)`), bind((s3Vm_1) => {
        const groups = match(zipBatchRegex, s3Vm_1.FileName);
        if (!equals(groups, defaultOf()) && (groups.length >= 2)) {
            return groups[1];
        }
        else {
            return undefined;
        }
    }, tryHead(persistedFiles_1)))), Helpers_nothing)) : map((file) => fileListItemContainer(ofArray([Button_visibilityControlled(undefined, componentVisibility.downloadButtonVisibility, singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_1) => {
        let tupledArg;
        dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, [tupledArg[0], tupledArg[1]])));
    }])])])), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-download"])), []))))), deleteButton(singleton(file), componentVisibility.deleteButtonVisibility)]), fileNameDisplay(file.FileName)), persistedFiles_1)), toList(delay(() => append(map((file_2) => {
        let _arg_4;
        return progressPanelItem(last(split(file_2.Name, ["/"], undefined, 0)), (_arg_4 = file_2.Progress, (_arg_4.tag === 1) ? 100 : _arg_4.fields[0]));
    }, files_1), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, [singleton(["style", {
        flexGrow: 1,
    }])])), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], undefined, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(1, [new Color_IColor(8, [])]))])), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_2) => {
        dispatch(new LocalMsg(21, [file_1]));
    }])])])]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_3) => {
        dispatch(new LocalMsg(22, [file_1]));
    }])])])), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => persistedFileList))))))))))) : ((zipBatchRegex = create(`^(.+)${"_\\d+_of_\\d+.zip$"}`, 8), (persistedZipBatchFiles = filter((s3Vm) => (match(zipBatchRegex, s3Vm.FileName) != null), persistedFiles_1), (persistedFileList = ((model.FileHandling.tag === 1) ? singleton(defaultArg(map_1((fileName_4) => fileListItemContainer(singleton(deleteButton(persistedZipBatchFiles, (FSharpMap__get_Count(model.FileManagementsInProgress) > 0) ? (new ComponentVisibility(2, [undefined])) : componentVisibility.deleteButtonVisibility)), fileNameDisplay(fileName_4)), map_1((baseFileName) => (`${baseFileName} (multi-part zip)`), bind((s3Vm_1) => {
        const groups = match(zipBatchRegex, s3Vm_1.FileName);
        if (!equals(groups, defaultOf()) && (groups.length >= 2)) {
            return groups[1];
        }
        else {
            return undefined;
        }
    }, tryHead(persistedFiles_1)))), Helpers_nothing)) : map((file) => fileListItemContainer(ofArray([Button_visibilityControlled(undefined, componentVisibility.downloadButtonVisibility, singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_1) => {
        let tupledArg;
        dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, [tupledArg[0], tupledArg[1]])));
    }])])])), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-download"])), []))))), deleteButton(singleton(file), componentVisibility.deleteButtonVisibility)]), fileNameDisplay(file.FileName)), persistedFiles_1)), toList(delay(() => append(map((file_2) => {
        let _arg_4;
        return progressPanelItem(last(split(file_2.Name, ["/"], undefined, 0)), (_arg_4 = file_2.Progress, (_arg_4.tag === 1) ? 100 : _arg_4.fields[0]));
    }, files_1), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, [singleton(["style", {
        flexGrow: 1,
    }])])), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], undefined, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(1, [new Color_IColor(8, [])]))])), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_2) => {
        dispatch(new LocalMsg(21, [file_1]));
    }])])])]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_3) => {
        dispatch(new LocalMsg(22, [file_1]));
    }])])])), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => persistedFileList))))))))))) : ((zipBatchRegex = create(`^(.+)${"_\\d+_of_\\d+.zip$"}`, 8), (persistedZipBatchFiles = filter((s3Vm) => (match(zipBatchRegex, s3Vm.FileName) != null), persistedFiles_1), (persistedFileList = ((model.FileHandling.tag === 1) ? singleton(defaultArg(map_1((fileName_4) => fileListItemContainer(singleton(deleteButton(persistedZipBatchFiles, (FSharpMap__get_Count(model.FileManagementsInProgress) > 0) ? (new ComponentVisibility(2, [undefined])) : componentVisibility.deleteButtonVisibility)), fileNameDisplay(fileName_4)), map_1((baseFileName) => (`${baseFileName} (multi-part zip)`), bind((s3Vm_1) => {
        const groups = match(zipBatchRegex, s3Vm_1.FileName);
        if (!equals(groups, defaultOf()) && (groups.length >= 2)) {
            return groups[1];
        }
        else {
            return undefined;
        }
    }, tryHead(persistedFiles_1)))), Helpers_nothing)) : map((file) => fileListItemContainer(ofArray([Button_visibilityControlled(undefined, componentVisibility.downloadButtonVisibility, singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_1) => {
        let tupledArg;
        dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, [tupledArg[0], tupledArg[1]])));
    }])])])), singleton(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-download"])), []))))), deleteButton(singleton(file), componentVisibility.deleteButtonVisibility)]), fileNameDisplay(file.FileName)), persistedFiles_1)), toList(delay(() => append(map((file_2) => {
        let _arg_4;
        return progressPanelItem(last(split(file_2.Name, ["/"], undefined, 0)), (_arg_4 = file_2.Progress, (_arg_4.tag === 1) ? 100 : _arg_4.fields[0]));
    }, files_1), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, [singleton(["style", {
        flexGrow: 1,
    }])])), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], undefined, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(1, [new Color_IColor(8, [])]))])), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_2) => {
        dispatch(new LocalMsg(21, [file_1]));
    }])])])]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, [ofArray([new HTMLAttr(159, ["button"]), new DOMAttr(40, [(_arg_3) => {
        dispatch(new LocalMsg(22, [file_1]));
    }])])])), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => persistedFileList))))))))))), panel(empty(), toList(delay(() => fileList))))))), deleteModal];
    return react.createElement("div", {}, ...children_8);
}

export function fileDownloads(files) {
    return map((tuple) => tuple[1], toList_1(map_2((_arg, x) => (new FileDownload(x.Name)), files)));
}

export function downloadAndUpload(model, downloadableFiles, dispatch, fileComponentVisibility) {
    let files_1;
    return listOfFiles(model, map((tuple) => tuple[1], toList_1(map_2((_arg, x) => (new FileManagement(x.Name, x.Progress)), model.FileManagementsInProgress))), downloadableFiles, (files_1 = model.FailedUploads, FSharpMap__get_IsEmpty(files_1) ? empty() : map((tuple_1) => tuple_1[0], toList_1(files_1))), model.PersistedFiles, fileComponentVisibility, dispatch);
}

export function view(mkUploadSelector, fileComponentVisibility, model, dispatch, maybeMkExtraElements) {
    const downloadableFiles = fileDownloads(model.DownloadableFiles);
    const dispatchLocal = (arg) => {
        dispatch(wrapLocalMsg(arg));
    };
    const extraElements = (maybeMkExtraElements == null) ? empty() : maybeMkExtraElements(model);
    const mainDisplay = (fileComponentVisibility.uploadComponentVisibility.tag === 1) ? listOfFiles(model, empty(), downloadableFiles, empty(), model.PersistedFiles, fileComponentVisibility, dispatchLocal) : div_1(empty(), toList(delay(() => append(extraElements, delay(() => append(singleton_1(uploadControl(mkUploadSelector, fileComponentVisibility, model.UploadSelection, model.Specs.maybeFileTypeRestrictions, dispatchLocal)), delay(() => append(singleton_1(h5(empty())(singleton("Files"))), delay(() => singleton_1(downloadAndUpload(model, downloadableFiles, dispatchLocal, fileComponentVisibility)))))))))));
    return react.createElement("div", {}, mainDisplay);
}

/**
 * For when you want to have just the list of files.
 */
export function viewDownloadOnly(model, dispatch) {
    return listOfFiles(model, empty(), fileDownloads(model.DownloadableFiles), empty(), model.PersistedFiles, FileComponentVisibility_downloadOnly_Z510FEC72(new ComponentVisibility(0, [])), (arg) => {
        dispatch(wrapLocalMsg(arg));
    });
}

/**
 * For when you want to place the upload piece and the file list separately.
 */
export function splitViewDownloadAndUpload(mkUploadSelectors, fileComponentVisibility, model, dispatch) {
    const downloadableFiles = fileDownloads(model.DownloadableFiles);
    const dispatchLocal = (arg) => {
        dispatch(wrapLocalMsg(arg));
    };
    return [uploadControl(mkUploadSelectors, fileComponentVisibility, model.UploadSelection, model.Specs.maybeFileTypeRestrictions, dispatchLocal), downloadAndUpload(model, downloadableFiles, dispatchLocal, fileComponentVisibility)];
}

