// Need to import the appropriate rendering profiles because of a breaking change in v18.0.0 (https://github.com/Kitware/vtk-js/releases/tag/v18.0.0)
import 'vtk.js/Sources/Rendering/Profiles/Volume';

import vtkGenericRenderWindow from 'vtk.js/Sources/Rendering/Misc/GenericRenderWindow';
//import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
// Force the loading of HttpDataAccessHelper to support gzip decompression
import 'vtk.js/Sources/IO/Core/DataAccessHelper/HttpDataAccessHelper';

import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import ImageConstants from 'vtk.js/Sources/Rendering/Core/ImageMapper/Constants';

//// DEBUG
//import vtkConeSource from 'vtk.js/Sources/Filters/Sources/ConeSource';
//import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';
//import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import ITKHelper from 'vtk.js/Sources/Common/DataModel/ITKHelper';
import readImageDICOMFileSeries from 'itk/readImageDICOMFileSeries';
import vtkITKImageReader from 'vtk.js/Sources/IO/Misc/ITKImageReader';
import readImageArrayBuffer from 'itk/readImageArrayBuffer';
import React, {useEffect, useRef} from 'react';
import {Vector3} from "./Vector3";
import { vec3 } from 'gl-matrix';

import { logDebug, ignore } from './Common';
import customImageInteractor from "./CustomImageInteractor";
import vtkImageSlice from "vtk.js/Sources/Rendering/Core/ImageSlice";
import vtkMath from "vtk.js/Sources/Common/Core/Math";

import {useScreenshot} from '../Screenshot/Screenshot';
import {useInteraction} from './Interaction';

const { convertItkToVtkImage } = ITKHelper;

vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer);

const isDebugging = false;

function log(msg) {
    logDebug("DicomVisualization", isDebugging, msg);
}

const DicomVisualization = ({ dicomFiles, onSliceChange, onTouchStart, onTouchEnd, interactionModeProps, screenshotProps, isSliceScroll, slicePlaneIndex }) => {

    const containerRef = useRef(null);
    const context = useRef(null);
    const { SlicingMode } = ImageConstants;

    const render = () => {
        if (context.current) {
            log("Rendering");

            // OPTIMIZE:
            // This is a quick fix for the resolution issue when switching between single/quad view.
            // A better fix would be to depend on a visibility prop.
            context.current.genericRenderWindow.resize();
        }
    };

    render();

    useEffect(() => {
        if (!context.current && dicomFiles && dicomFiles.length > 0) {

            log("Reading dicom files");

            // We use the wrapper here to abstract out manual RenderWindow/Renderer/OpenGLRenderWindow setup
            const genericRenderWindow = vtkGenericRenderWindow.newInstance({
                background: [0.0, 0.0, 0.0]
            });
            //var genericRenderWindow = vtkOpenGLRenderWindow.newInstance();
            genericRenderWindow.setContainer(containerRef.current);
            genericRenderWindow.resize();

            const renderer = genericRenderWindow.getRenderer();
            const renderWindow = genericRenderWindow.getRenderWindow();

            // renderer camera to parallel projection
            renderer.getActiveCamera().setParallelProjection(true);

            // --- Set up interactor style for image slicing
            const istyle = customImageInteractor.newInstance();

            // Interaction styles don't provide the ability to distinguish between a touch and left mouse press,
            // so we have to add that functionality ourselves
            const combinedTouchStart = (e) => { onTouchStart(e); istyle.handleTouchStart(e) };
            const combinedTouchEnd = (e) => { onTouchEnd(e); istyle.handleTouchEnd(e) };

            containerRef.current.addEventListener('touchstart', combinedTouchStart);
            containerRef.current.addEventListener('touchend', combinedTouchEnd);
            containerRef.current.addEventListener('touchmove', istyle.handleTouchMove);

            istyle.setContainer(containerRef.current);
            istyle.setRenderWindow(renderWindow);
            istyle.setMouseWheelIsSliceScroll(isSliceScroll);

            istyle.setInteractionMode('IMAGE_SLICING');
            renderWindow.getInteractor().setInteractorStyle(istyle);

            var interactor = renderWindow.getInteractor();
            interactor.bindEvents(containerRef.current);

            // --- Set up the slicing actor ---

            const actor = vtkImageSlice.newInstance();
            const mapper = vtkImageMapper.newInstance();

            mapper.setSliceAtFocalPoint(true);
            mapper.setSlicingMode(SlicingMode.Z);

            // tell the actor which mapper to use
            actor.setMapper(mapper);

            // --- set up default window/level ---

            actor.getProperty().setColorWindow(255);
            actor.getProperty().setColorLevel(127);

            readImageDICOMFileSeries(dicomFiles)
                .then(function ({ image, webWorkerPool }) {
                    webWorkerPool.terminateWorkers();
                    //global.dicomImages = image;
                    return image;
                })
                .then(itkImage => {
                    if (!mapper.isDeleted()) {
                        const imageData = convertItkToVtkImage(itkImage);
                        //global.imageData = imageData;
                        mapper.setInputData(imageData);
                        let baseBounds = mapper.getBounds();
                        let bounds = Vector3.ofBounds(baseBounds);
                        let center = Vector3.midBounds(bounds.min, bounds.max);
                        let minIndex = vec3.create();
                        let maxIndex = vec3.create();
                        imageData.worldToIndex(bounds.min.toVtk(), minIndex);
                        imageData.worldToIndex(bounds.max.toVtk(), maxIndex);
                        let maxSliceIndex = maxIndex[2];

                        let midSlice =
                            mapper.getSliceAtPosition(center.toVtk());

                        mapper.onModified(moddedMapper => {
                            if (onSliceChange) {
                                let sliceLocation = moddedMapper.getSlice();

                                let bounds = moddedMapper.getBoundsForSlice();

                                onSliceChange({ index: sliceLocation, maxIndex: maxSliceIndex, bounds });
                            }
                        });

                        mapper.setSlice(midSlice);

                        // --- Add volume actor to scene ---
                        renderer.addActor(actor);

                        // DEBUG - Add cone for reference
                        // const coneSource = vtkConeSource.newInstance({ height: 100.0, center: [ 5.801562499999989, -0.3984375, -124.25 ], radius: 100, direction: [0,0,1] });
                        // const coneMapper = vtkMapper.newInstance();
                        // coneMapper.setInputConnection(coneSource.getOutputPort());
                        // const coneActor = vtkActor.newInstance();
                        // coneActor.setMapper(coneMapper);
                        // renderer.addActor(coneActor);
                        // global.coneSource = coneSource;
                        // global.coneActor = coneActor;

                        // --- Reset camera and render the scene ---
                        renderer.resetCamera();

                        let camera = renderer.getActiveCamera();
                        camera.setViewUp(vtkMath.multiplyScalar(Vector3.unitY(), -1));
                        renderer.setActiveCamera(camera);

                        renderWindow.render();
                        console.debug("Finished rendering DICOM");
                    } else {
                        console.debug("Aborted rendering DICOM, image mapper was disposed");
                    }
                });

            context.current = {
                genericRenderWindow,
                renderWindow,
                renderer,
                actor,
                mapper,
                istyle,
                combinedTouchStart,
                combinedTouchEnd
            };
        }

        // --- Expose globals so we can play with values in the dev console ---

        //global.dicomGenericRenderWindow = genericRenderWindow;
        //global.dicomRenderWindow = renderWindow;
        //global.dicomRenderer = renderer;
        //global.dicomActor = actor;
        //global.dicomMapper = mapper;

        const container = containerRef.current;
        let ctx = context.current;

        return () => {
            if (ctx) {
                log("Destroying component");
                const { genericRenderWindow, actor, mapper, istyle, combinedTouchStart, combinedTouchEnd } = ctx;
                actor.delete();
                mapper.delete();
                genericRenderWindow.delete();
                context.current = null;
                ctx = null;

                container.removeEventListener('touchstart', combinedTouchStart);
                container.removeEventListener('touchend', combinedTouchEnd);
                container.removeEventListener('touchmove', istyle.handleTouchMove);
            }
        };
    }, [dicomFiles]);

    // Screenshot side effect
    const [captureScreenshot, ...screenshotInteraction] = useScreenshot(containerRef.current,
        context.current,
        null,
        screenshotProps);

    useEffect(() => {
        if (context.current) {
            context.current.istyle.setMouseWheelIsSliceScroll(isSliceScroll);
        }
    }, [isSliceScroll]);

    useEffect(() => {
        if (context.current && slicePlaneIndex) {
            let slice = context.current.mapper.getSlice();
            let diff = slice - slicePlaneIndex;
            if(diff !== 0){
                context.current.istyle.recalculateDistance(context.current.renderer, diff);
                context.current.mapper.setSlice(slicePlaneIndex);
                context.current.renderWindow.render();
            }
        }
    }, [slicePlaneIndex]);

    // Interaction side effect
    useInteraction(
        null,
        interactionModeProps,
        null,
        null,
        screenshotInteraction,
        captureScreenshot,
        null,
        null,
        null,
        null);

    return <div className="layout-view-view-container" ref={containerRef}/>;
};

export default DicomVisualization;