import { printf, toText } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { FileComponentSpecs, MaximumFiles, FileType } from "../../FileManagement/Shared.js";
import { empty, ofArray, head, singleton } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { forceTerminate, hasFileWithExtension, update, init } from "./AnalysisFormFileManagement/AnalysisFormFileState.js";
import { TelemetryAction, FileOperationContext } from "../../RAWMap.Models/Api.js";
import { MsgAnalysis, ModelAnalysis_get_identifiedPatientInfo_, OutboundMsg, LocalMsg, wrapLocalMsg, ModelAnalysis, ModeAnalysis } from "./AnalysisFormTypes.js";
import { AnalysisViewModel_isValid_Z24980752, AnalysisViewModel, AnalysisViewModel_get_empty } from "../../RAWMap.Models/View/Analysis.js";
import { Common_GeneratedReportType, Common_ReportType } from "../../RAWMap.Models/Common.js";
import { PatientCreationViewModel, PatientCreationViewModel_isValid_7D62DD9A, HospitalPatientIdViewModel_get_institutionId_, PatientCreationViewModel_get_hospitalPatientIdVm_, PatientCreationViewModel_get_id_, PatientCreationViewModel_get_nameDobVm_, PatientCreationViewModel_get_empty } from "../../RAWMap.Models/View/Patient.js";
import { Cmd_OfPromise_either, Cmd_map, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { toList } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_attempt } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedApi } from "../../Api.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { getSignedUrlPromise } from "../../Common/AwsCommon.js";
import { defaultArg, some, map, toArray, value as value_9 } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { singleton as singleton_1, append, delay, toList as toList_1 } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { InboundMsg } from "./AnalysisFormFileManagement/AnalysisFormFileTypes.js";
import { Toast_successToast, Toast_errorToast } from "../../Common/General.js";
import { FileManagementUpdateArgs } from "../../FileManagement/FileManagementTypes.js";
import { ErrorMessage_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { generateAnalysisReport } from "../../Common/PdfCommon.js";
import { makeAndPrependTitlePage } from "../CustomReport/ReportPdf/Sections/MakeReportSections.js";
import { TitlePageArgsModule_TitlePageHelperArgs, TitlePageArgsModule_mkTitlePageArgs } from "../CustomReport/ReportPdf/Sections/TitlePageSection.js";
import { Shared_UniversalArgs } from "../CustomReport/ReportPdf/Sections/SharedSection.js";

export function makeUploadDestinationPathGuid(patientInfo, studyGuid) {
    return toText(printf("%O/%O/AnalysisData/"))(patientInfo.patientInfoId)(studyGuid);
}

export function makeIntermediateUploadDestinationPath(patientInfo, studyGuid) {
    return toText(printf("%O/%O/IntermediateAnalysisData/"))(patientInfo.patientInfoId)(studyGuid);
}

export function makeUiDataFileDestinationPath(patientInfo, studyGuid) {
    return toText(printf("%O/%O/UiDataFile/"))(patientInfo.patientInfoId)(studyGuid);
}

export function studyDataFileSpecs(patientInfo, studyGuid) {
    return new FileComponentSpecs(singleton(new FileType(1, [])), makeUploadDestinationPathGuid(patientInfo, studyGuid), new MaximumFiles(1, [1]));
}

export function zipFileSpecs(patientInfo, studyGuid) {
    return new FileComponentSpecs(singleton(new FileType(0, [])), makeIntermediateUploadDestinationPath(patientInfo, studyGuid), new MaximumFiles(1, [1]));
}

export function uiDataFileSpecs(patientInfo, studyGuid) {
    return new FileComponentSpecs(singleton(new FileType(0, [])), makeUiDataFileDestinationPath(patientInfo, studyGuid), new MaximumFiles(1, [1]));
}

export function initWithDisplay(selectedStudyAnalysis, patientInfo, studyInfo, _patientInstitution, userRoles) {
    let msg_1;
    const patternInput = init(zipFileSpecs(patientInfo, selectedStudyAnalysis.studyId), new FileOperationContext(1, []));
    const patternInput_1 = init(uiDataFileSpecs(patientInfo, selectedStudyAnalysis.studyId), new FileOperationContext(2, [selectedStudyAnalysis.studyId]));
    const patternInput_2 = init(studyDataFileSpecs(patientInfo, selectedStudyAnalysis.studyId), new FileOperationContext(0, [selectedStudyAnalysis.studyId]));
    return [new ModelAnalysis(new ModeAnalysis(4, []), AnalysisViewModel_get_empty(), userRoles, selectedStudyAnalysis, studyInfo, patternInput_2[0], patternInput[0], patternInput_1[0], undefined, patientInfo, false, new Common_ReportType(1, []), PatientCreationViewModel_get_empty(), false), Cmd_batch(singleton((msg_1 = wrapLocalMsg(new LocalMsg(9, [])), singleton((dispatch) => {
        dispatch(msg_1);
    }))))];
}

export function initWithCreate(patientInfo, studyInfo, _patientInstitution, userRoles) {
    let msg_1;
    let analysisVm;
    const bind$0040 = AnalysisViewModel_get_empty();
    analysisVm = (new AnalysisViewModel(studyInfo.studyId, bind$0040.analysisInfo, bind$0040.associatedAnalyst, bind$0040.analysisCreationDate));
    return [new ModelAnalysis(new ModeAnalysis(1, []), analysisVm, userRoles, analysisVm, studyInfo, init(studyDataFileSpecs(patientInfo, analysisVm.studyId), new FileOperationContext(0, [analysisVm.studyId]))[0], init(zipFileSpecs(patientInfo, analysisVm.studyId), new FileOperationContext(1, []))[0], init(uiDataFileSpecs(patientInfo, analysisVm.studyId), new FileOperationContext(2, [analysisVm.studyId]))[0], undefined, patientInfo, false, new Common_ReportType(1, []), PatientCreationViewModel_get_empty(), false), Cmd_batch(singleton((msg_1 = wrapLocalMsg(new LocalMsg(9, [])), singleton((dispatch) => {
        dispatch(msg_1);
    }))))];
}

export function getReportURLPromise(token, model) {
    const fileName = head(toList(model.StudyDataFileManager.fileUpload.DownloadableFiles))[1].Name;
    const trackDownload = Cmd_OfAsyncWith_attempt((x) => {
        Cmd_OfAsync_start(x);
    }, securedApi(token).telemetry, new TelemetryAction(1, [new FileOperationContext(0, [model.SelectedStudy.studyId]), singleton(fileName)]), (Item) => (new LocalMsg(1, [Item])));
    return [PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (getSignedUrlPromise(value_9(model.FileUpdateArgs)[1], fileName).then((_arg) => (Promise.resolve(_arg)))))), trackDownload];
}

export function updateAnalysisInbound(_token, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, msg.fields[0], model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_none(), Cmd_none()];
        case 2:
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, [msg.fields[0], msg.fields[1]], model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_batch(toList_1(delay(() => append(ofArray(toArray(map((intermediateModel) => {
                const msg_1 = new LocalMsg(5, [intermediateModel, wrapInboundMsg(new InboundMsg())]);
                return singleton((dispatch) => {
                    dispatch(msg_1);
                });
            }, model.IntermediateZipFileManager))), delay(() => {
                let msg_5;
                return append(singleton_1((msg_5 = (new LocalMsg(7, [model.UiDataFileManager, wrapInboundMsg(new InboundMsg())])), singleton((dispatch_1) => {
                    dispatch_1(msg_5);
                }))), delay(() => {
                    let msg_8;
                    return singleton_1((msg_8 = (new LocalMsg(3, [wrapInboundMsg(new InboundMsg())])), singleton((dispatch_2) => {
                        dispatch_2(msg_8);
                    })));
                }));
            }))))), Cmd_none()];
        default:
            if (model.Mode.tag === 4) {
                return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, msg.fields[0], model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_none(), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
    }
}

export function updateAnalysisLocal(token, msg, model) {
    let msg_16, value_1, l, l_8, l_6, l_4, value_3, l_2, bind$0040;
    let matchResult, ex, ex_1, msg_1, intermediateFileModel, msg_4, msg_6, msg_8, uiDataFileModel, msg_10, setter, study, e, t, setter_1, setter_2, pdf, e_1, isValid_1, e_2;
    switch (msg.tag) {
        case 1: {
            matchResult = 1;
            ex = msg.fields[0];
            break;
        }
        case 2: {
            matchResult = 2;
            ex_1 = msg.fields[0];
            break;
        }
        case 3: {
            if (msg.fields[0].tag === 1) {
                if (msg.fields[0].fields[0].tag === 5) {
                    matchResult = 8;
                }
                else {
                    matchResult = 9;
                }
            }
            else {
                matchResult = 3;
                msg_1 = msg.fields[0].fields[0];
            }
            break;
        }
        case 5: {
            matchResult = 4;
            intermediateFileModel = msg.fields[0];
            msg_4 = msg.fields[1];
            break;
        }
        case 4: {
            if (msg.fields[0].tag === 1) {
                if (msg.fields[0].fields[0].tag === 5) {
                    matchResult = 8;
                }
                else {
                    matchResult = 9;
                }
            }
            else {
                matchResult = 5;
                msg_6 = msg.fields[0].fields[0];
            }
            break;
        }
        case 7: {
            matchResult = 6;
            msg_8 = msg.fields[1];
            uiDataFileModel = msg.fields[0];
            break;
        }
        case 6: {
            if (msg.fields[0].tag === 1) {
                if (msg.fields[0].fields[0].tag === 5) {
                    matchResult = 8;
                }
                else {
                    matchResult = 9;
                }
            }
            else {
                matchResult = 7;
                msg_10 = msg.fields[0].fields[0];
            }
            break;
        }
        case 8: {
            matchResult = 10;
            setter = msg.fields[0];
            break;
        }
        case 9: {
            matchResult = 11;
            break;
        }
        case 10: {
            matchResult = 12;
            break;
        }
        case 11: {
            matchResult = 13;
            break;
        }
        case 12: {
            if (msg.fields[0].tag === 1) {
                matchResult = 17;
                e = msg.fields[0].fields[0];
            }
            else {
                matchResult = 14;
                study = msg.fields[0].fields[0];
            }
            break;
        }
        case 13: {
            if (msg.fields[0].tag === 1) {
                matchResult = 17;
                e = msg.fields[0].fields[0];
            }
            else {
                matchResult = 15;
            }
            break;
        }
        case 23: {
            matchResult = 16;
            break;
        }
        case 21: {
            matchResult = 18;
            t = msg.fields[0];
            break;
        }
        case 14: {
            matchResult = 19;
            setter_1 = msg.fields[0];
            break;
        }
        case 15: {
            matchResult = 20;
            setter_2 = msg.fields[0];
            break;
        }
        case 16: {
            matchResult = 21;
            break;
        }
        case 17: {
            matchResult = 22;
            break;
        }
        case 18: {
            matchResult = 23;
            break;
        }
        case 19: {
            matchResult = 24;
            pdf = msg.fields[0];
            break;
        }
        case 20: {
            if (msg.fields[0].tag === 1) {
                matchResult = 26;
                e_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 25;
            }
            break;
        }
        case 22: {
            if (msg.fields[0].tag === 1) {
                matchResult = 28;
                e_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 27;
                isValid_1 = msg.fields[0].fields[0];
            }
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return [model, Cmd_none(), Cmd_none()];
        case 1: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none(), Cmd_none()];
        }
        case 2: {
            console.error(some(toText(printf("Error: %O"))(ex_1)));
            return [model, Toast_errorToast("Failed to generate analysis report. Please try again."), Cmd_none()];
        }
        case 3: {
            const patternInput = update(new FileManagementUpdateArgs(token, value_9(model.FileUpdateArgs)[0], value_9(model.FileUpdateArgs)[1]), msg_1, model.StudyDataFileManager);
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, patternInput[0], model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_batch(toList_1(delay(() => append(singleton_1(Cmd_map((msg_2) => (new LocalMsg(3, [msg_2])), patternInput[1])), delay(() => ((msg_1.tag === 0) ? ((msg_1.fields[0].tag === 2) ? ((msg_1.fields[0].fields[0].tag === 0) ? ((msg_1.fields[0].fields[0].fields[0].tag === 0) ? ((msg_1.fields[0].fields[0].fields[0].fields[0].tag === 20) ? singleton(singleton((dispatch) => {
                dispatch(new LocalMsg(9, []));
            })) : ((msg_1.fields[0].fields[0].fields[0].fields[0].tag === 4) ? singleton(singleton((dispatch) => {
                dispatch(new LocalMsg(9, []));
            })) : ((msg_1.fields[0].fields[0].fields[0].fields[0].tag === 12) ? singleton(singleton((dispatch) => {
                dispatch(new LocalMsg(9, []));
            })) : empty()))) : empty()) : empty()) : empty()) : empty())))))), Cmd_none()];
        }
        case 4:
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, intermediateFileModel, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), singleton((dispatch_1) => {
                dispatch_1(new LocalMsg(4, [msg_4]));
            }), Cmd_none()];
        case 5: {
            const matchValue = model.IntermediateZipFileManager;
            if (matchValue == null) {
                return [model, Toast_errorToast("Intermediate zip file component does not exist."), Cmd_none()];
            }
            else {
                const intermediateFileModel_1 = matchValue;
                const patternInput_1 = update(new FileManagementUpdateArgs(token, value_9(model.FileUpdateArgs)[0], value_9(model.FileUpdateArgs)[1]), msg_6, intermediateFileModel_1);
                return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, patternInput_1[0], model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_batch(toList_1(delay(() => append(singleton_1(Cmd_map((Item_1) => (new LocalMsg(4, [Item_1])), patternInput_1[1])), delay(() => ((msg_6.tag === 0) ? ((msg_6.fields[0].tag === 2) ? ((msg_6.fields[0].fields[0].tag === 0) ? ((msg_6.fields[0].fields[0].fields[0].tag === 0) ? ((msg_6.fields[0].fields[0].fields[0].fields[0].tag === 20) ? singleton(singleton((dispatch_2) => {
                    dispatch_2(new LocalMsg(9, []));
                })) : ((msg_6.fields[0].fields[0].fields[0].fields[0].tag === 4) ? singleton(singleton((dispatch_2) => {
                    dispatch_2(new LocalMsg(9, []));
                })) : ((msg_6.fields[0].fields[0].fields[0].fields[0].tag === 12) ? singleton(singleton((dispatch_2) => {
                    dispatch_2(new LocalMsg(9, []));
                })) : empty()))) : empty()) : empty()) : empty()) : empty())))))), Cmd_none()];
            }
        }
        case 6:
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, uiDataFileModel, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), singleton((dispatch_3) => {
                dispatch_3(new LocalMsg(6, [msg_8]));
            }), Cmd_none()];
        case 7: {
            const patternInput_2 = update(new FileManagementUpdateArgs(token, value_9(model.FileUpdateArgs)[0], value_9(model.FileUpdateArgs)[1]), msg_10, model.UiDataFileManager);
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, patternInput_2[0], model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_batch(toList_1(delay(() => append(singleton_1(Cmd_map((Item_2) => (new LocalMsg(6, [Item_2])), patternInput_2[1])), delay(() => ((msg_10.tag === 0) ? ((msg_10.fields[0].tag === 2) ? ((msg_10.fields[0].fields[0].tag === 0) ? ((msg_10.fields[0].fields[0].fields[0].tag === 0) ? ((msg_10.fields[0].fields[0].fields[0].fields[0].tag === 20) ? singleton(singleton((dispatch_4) => {
                dispatch_4(new LocalMsg(9, []));
            })) : ((msg_10.fields[0].fields[0].fields[0].fields[0].tag === 4) ? singleton(singleton((dispatch_4) => {
                dispatch_4(new LocalMsg(9, []));
            })) : ((msg_10.fields[0].fields[0].fields[0].fields[0].tag === 12) ? singleton(singleton((dispatch_4) => {
                dispatch_4(new LocalMsg(9, []));
            })) : empty()))) : empty()) : empty()) : empty()) : empty())))))), Cmd_none()];
        }
        case 8:
            return [model, Cmd_none(), singleton((dispatch_5) => {
                dispatch_5(new OutboundMsg(2, []));
            })];
        case 9:
            return [model, Cmd_none(), Cmd_none()];
        case 10:
            return [new ModelAnalysis(model.Mode, setter(model.EditAnalysisVm), model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), singleton((dispatch_6) => {
                dispatch_6(new LocalMsg(9, []));
            }), Cmd_none()];
        case 11:
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, ((AnalysisViewModel_isValid_Z24980752(model.EditAnalysisVm) && hasFileWithExtension("pdf", model.StudyDataFileManager)) && defaultArg(map((zipFileModel) => hasFileWithExtension("zip", zipFileModel), model.IntermediateZipFileManager), false)) && hasFileWithExtension("zip", model.UiDataFileManager), model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_none(), Cmd_none()];
        case 12:
            return [new ModelAnalysis(new ModeAnalysis(2, []), model.SelectedStudyAnalysis, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), singleton((dispatch_7) => {
                dispatch_7(new LocalMsg(9, []));
            }), Cmd_none()];
        case 13: {
            const matchValue_1 = model.Mode;
            switch (matchValue_1.tag) {
                case 2:
                    return [new ModelAnalysis(new ModeAnalysis(3, []), model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedApi(token).editAnalysis, model.EditAnalysisVm, (Item_3) => (new LocalMsg(13, [Item_3])), (Item_4) => (new LocalMsg(1, [Item_4]))), Cmd_none()];
                case 1:
                    return [new ModelAnalysis(new ModeAnalysis(3, []), model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, model.ReportInfoIsValid), Cmd_OfAsyncWith_either((x_1) => {
                        Cmd_OfAsync_start(x_1);
                    }, securedApi(token).createAnalysis, model.EditAnalysisVm, (Item_5) => (new LocalMsg(12, [Item_5])), (Item_6) => (new LocalMsg(1, [Item_6]))), Cmd_none()];
                default:
                    return [model, (msg_16 = (new LocalMsg(1, [new Error("Unable to save study")])), singleton((dispatch_8) => {
                        dispatch_8(msg_16);
                    })), Cmd_none()];
            }
        }
        case 14:
            return [model, Toast_successToast(toText(printf("Created analysis %O"))(study.studyId)), singleton((dispatch_9) => {
                dispatch_9(new OutboundMsg(1, [model.SelectedStudyAnalysis]));
            })];
        case 15:
            return [model, Toast_successToast(toText(printf("Analysis updated"))), singleton((dispatch_10) => {
                dispatch_10(new OutboundMsg(0, [model.SelectedStudyAnalysis]));
            })];
        case 16: {
            const cancelUploads = () => {
                forceTerminate(model.StudyDataFileManager);
                defaultArg(map((zipManager) => {
                    forceTerminate(zipManager);
                }, model.IntermediateZipFileManager), undefined);
                forceTerminate(model.UiDataFileManager);
                return [model, Cmd_none()];
            };
            const matchValue_2 = model.Mode;
            switch (matchValue_2.tag) {
                case 1:
                    return [cancelUploads()[0], Cmd_none(), singleton((dispatch_11) => {
                        dispatch_11(new OutboundMsg(3, []));
                    })];
                case 2: {
                    const patternInput_4 = cancelUploads();
                    const model_2 = patternInput_4[0];
                    return [new ModelAnalysis(new ModeAnalysis(4, []), AnalysisViewModel_get_empty(), model_2.UserRoles, model_2.SelectedStudyAnalysis, model_2.SelectedStudy, model_2.StudyDataFileManager, model_2.IntermediateZipFileManager, model_2.UiDataFileManager, model_2.FileUpdateArgs, model_2.ActivePatientInfo, model_2.IsValid, model_2.ReportGenerationType, model_2.IdentifiedPatientInfo, model_2.ReportInfoIsValid), patternInput_4[1], Cmd_none()];
                }
                case 5:
                    return [new ModelAnalysis(new ModeAnalysis(4, []), model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, PatientCreationViewModel_get_empty(), false), Cmd_none(), Cmd_none()];
                default:
                    return [model, Cmd_none(), singleton((dispatch_12) => {
                        dispatch_12(new OutboundMsg(3, []));
                    })];
            }
        }
        case 17:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e)), Cmd_none()];
        case 18:
            if (t == null) {
                return [model, singleton((dispatch_14) => {
                    dispatch_14(new LocalMsg(16, []));
                }), Cmd_none()];
            }
            else {
                return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, t, model.IdentifiedPatientInfo, model.ReportInfoIsValid), singleton((dispatch_13) => {
                    dispatch_13(new LocalMsg(16, []));
                }), Cmd_none()];
            }
        case 19:
            return [((value_1 = setter_1(model.IdentifiedPatientInfo.nameDobVm), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l = ModelAnalysis_get_identifiedPatientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), PatientCreationViewModel_get_nameDobVm_())(l)))(value_1)))(model), singleton((dispatch_15) => {
                dispatch_15(new LocalMsg(16, []));
            }), Cmd_none()];
        case 20:
            return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l_8 = ModelAnalysis_get_identifiedPatientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), PatientCreationViewModel_get_id_())(l_8)))(model.ActivePatientInfo.patientInfoId)(Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l_6 = ((l_4 = ModelAnalysis_get_identifiedPatientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), PatientCreationViewModel_get_hospitalPatientIdVm_())(l_4))), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), HospitalPatientIdViewModel_get_institutionId_())(l_6)))(model.ActivePatientInfo.patientInstitution.id)(((value_3 = setter_2(model.IdentifiedPatientInfo.hospitalPatientIdVm), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l_2 = ModelAnalysis_get_identifiedPatientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), PatientCreationViewModel_get_hospitalPatientIdVm_())(l_2)))(value_3)))(model))), singleton((dispatch_16) => {
                dispatch_16(new LocalMsg(16, []));
            }), Cmd_none()];
        case 21:
            return [new ModelAnalysis(model.Mode, model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, model.IdentifiedPatientInfo, (model.ReportGenerationType.tag === 1) ? true : PatientCreationViewModel_isValid_7D62DD9A(model.IdentifiedPatientInfo)), Cmd_none(), Cmd_none()];
        case 22:
            return [new ModelAnalysis(new ModeAnalysis(5, []), model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, new Common_ReportType(1, []), model.IdentifiedPatientInfo, model.ReportInfoIsValid), singleton((dispatch_17) => {
                dispatch_17(new LocalMsg(16, []));
            }), Cmd_none()];
        case 23:
            if (model.ReportGenerationType.tag === 0) {
                return [model, Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedApi(token).verifyPatientInfo, model.IdentifiedPatientInfo, (Item_10) => (new LocalMsg(22, [Item_10])), (Item_11) => (new LocalMsg(2, [Item_11]))), Cmd_none()];
            }
            else {
                const patternInput_5 = getReportURLPromise(token, model);
                return [model, Cmd_batch(ofArray([Cmd_OfPromise_either(generateAnalysisReport, patternInput_5[0], (Item_8) => (new LocalMsg(19, [Item_8])), (Item_9) => (new LocalMsg(1, [Item_9]))), patternInput_5[1]])), Cmd_none()];
            }
        case 24: {
            const matchValue_5 = model.FileUpdateArgs;
            if (matchValue_5 == null) {
                return [model, Toast_errorToast("Missing S3 Context"), Cmd_none()];
            }
            else {
                const s3Context = matchValue_5[1];
                return [model, Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, (tupledArg) => makeAndPrependTitlePage(tupledArg[0], tupledArg[1], tupledArg[2]), ["Analysis_Report.pdf", pdf, TitlePageArgsModule_mkTitlePageArgs(new TitlePageArgsModule_TitlePageHelperArgs(new Shared_UniversalArgs(token, s3Context), model.SelectedStudy, new FileManagementUpdateArgs(token, matchValue_5[0], s3Context), model.ReportGenerationType, (bind$0040 = model.IdentifiedPatientInfo, new PatientCreationViewModel(model.ActivePatientInfo.patientInfoId, bind$0040.dateCreated, bind$0040.hospitalPatientIdVm, bind$0040.nameDobVm)), new Common_GeneratedReportType(0, [])))], (Item_12) => (new LocalMsg(20, [Item_12])), (Item_13) => (new LocalMsg(1, [Item_13]))), Cmd_none()];
            }
        }
        case 25:
            return [new ModelAnalysis(new ModeAnalysis(4, []), model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, PatientCreationViewModel_get_empty(), false), Cmd_none(), Cmd_none()];
        case 26:
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_1)), Cmd_none()];
        case 27:
            if (isValid_1) {
                const patternInput_6 = getReportURLPromise(token, model);
                return [model, Cmd_batch(ofArray([Cmd_OfPromise_either(generateAnalysisReport, patternInput_6[0], (Item_14) => (new LocalMsg(19, [Item_14])), (Item_15) => (new LocalMsg(2, [Item_15]))), Toast_successToast("Patient information validated successfully"), patternInput_6[1]])), Cmd_none()];
            }
            else {
                return [model, Toast_errorToast("The provided patient information does not match those in the system"), Cmd_none()];
            }
        default:
            return [new ModelAnalysis(new ModeAnalysis(4, []), model.EditAnalysisVm, model.UserRoles, model.SelectedStudyAnalysis, model.SelectedStudy, model.StudyDataFileManager, model.IntermediateZipFileManager, model.UiDataFileManager, model.FileUpdateArgs, model.ActivePatientInfo, model.IsValid, model.ReportGenerationType, PatientCreationViewModel_get_empty(), false), Toast_errorToast((e_2.tag === 2) ? ((e_2.fields[0].tag === 8) ? "User does not have sufficient permissions to generate the requested report" : "An unexpected error occured, please contact ViTAA Medical for further assistance") : ((e_2.tag === 20) ? "Failed to validate the patient information" : "An unexpected error occured, please contact ViTAA Medical for further assistance")), Cmd_none()];
    }
}

export function updateAnalysis(token, msg, model) {
    const patternInput = (msg.tag === 0) ? updateAnalysisLocal(token, msg.fields[0], model) : updateAnalysisInbound(token, msg.fields[0], model);
    return [patternInput[0], Cmd_batch(ofArray([Cmd_map(wrapLocalMsg, patternInput[1]), Cmd_map((Item) => (new MsgAnalysis(1, [Item])), patternInput[2])]))];
}

