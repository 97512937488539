import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Bounds_$reflection, InteractionMode_$reflection, DisplayMap_$reflection } from "./Common/CommonBindings.js";
import { lambda_type, unit_type, bool_type, option_type, class_type, union_type, record_type, array_type, float64_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ScreenshotProps_$reflection } from "./Screenshot/ScreenshotBindings.js";
import { SliceValues_$reflection, SlicePlaneProps_$reflection } from "./SlicePlane/SlicePlaneBindings.js";
import { NotesProps_$reflection } from "./Notes/NotesBindings.js";
import { CameraProps_$reflection } from "./Camera/CameraBindings.js";
import { CenterlineMeasurementProps_$reflection } from "./CenterlineMeasurement/CenterlineMeasurementBindings.js";
import { CalipersProps_$reflection } from "./Calipers/CalipersBindings.js";
import { AnatomyOrientationProps_$reflection } from "./AnatomyOrientation/AnatomyOrientationBindings.js";
import { PickedMapValueProps_$reflection } from "./PickedMapValue/PickedMapValueBindings.js";
import { MeshViewportLocation_$reflection } from "./Common/CommonTypes.js";

export class Viewport extends Record {
    constructor(map, planeAdjustment) {
        super();
        this.map = map;
        this.planeAdjustment = planeAdjustment;
    }
}

export function Viewport_$reflection() {
    return record_type("Client.Visualization.Bindings.Viewport", [], Viewport, () => [["map", DisplayMap_$reflection()], ["planeAdjustment", array_type(float64_type)]]);
}

export class InteractionView extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SingleView", "CompareView", "StandardView", "HiddenView"];
    }
}

export function InteractionView_$reflection() {
    return union_type("Client.Visualization.Bindings.InteractionView", [], InteractionView, () => [[], [], [], []]);
}

export class InteractionModeProps extends Record {
    constructor(interactionMode, interactionView) {
        super();
        this.interactionMode = interactionMode;
        this.interactionView = interactionView;
    }
}

export function InteractionModeProps_$reflection() {
    return record_type("Client.Visualization.Bindings.InteractionModeProps", [], InteractionModeProps, () => [["interactionMode", InteractionMode_$reflection()], ["interactionView", InteractionView_$reflection()]]);
}

export class VisualizationProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ScreenshotProps", "InteractionModeProps"];
    }
}

export function VisualizationProps_$reflection() {
    return union_type("Client.Visualization.Bindings.VisualizationProps", [], VisualizationProps, () => [[["Item", ScreenshotProps_$reflection()]], [["Item", InteractionModeProps_$reflection()]]]);
}

export class MeshVisualizationProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CurrentStudyMeshes", "PreviousStudyMeshes", "SlicePlaneProps", "SelectedMap", "ShowCenterline", "OverlayPreviousStudyMaps", "OnViewportResized", "StaticNotesProps", "MeasurementNotesProps", "CameraProps", "CenterlineMeasurementProps", "CalipersProps", "AnatomyOrientationProps", "ShowPreviousStudyMapsOnly", "PickedMapValueProps", "MeshViewportLocation", "IsSliceScroll", "OnMeshSliceChange"];
    }
}

export function MeshVisualizationProps_$reflection() {
    return union_type("Client.Visualization.Bindings.MeshVisualizationProps", [], MeshVisualizationProps, () => [[["Item", option_type(class_type("Client.Visualization.Common.Bindings.Meshes.IMeshes"))]], [["Item", option_type(class_type("Client.Visualization.Common.Bindings.Meshes.IMeshes"))]], [["Item", SlicePlaneProps_$reflection()]], [["Item", DisplayMap_$reflection()]], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(Bounds_$reflection(), unit_type)]], [["Item", NotesProps_$reflection()]], [["Item", NotesProps_$reflection()]], [["Item", CameraProps_$reflection()]], [["Item", CenterlineMeasurementProps_$reflection()]], [["Item", CalipersProps_$reflection()]], [["Item", AnatomyOrientationProps_$reflection()]], [["Item", bool_type]], [["Item", PickedMapValueProps_$reflection()]], [["Item", MeshViewportLocation_$reflection()]], [["Item", bool_type]], [["Item", lambda_type(float64_type, unit_type)]]]);
}

export class DicomVisualizationProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DicomFiles", "OnSliceChange", "OnTouchStart", "OnTouchEnd", "IsSliceScroll", "SlicePlaneIndex"];
    }
}

export function DicomVisualizationProps_$reflection() {
    return union_type("Client.Visualization.Bindings.DicomVisualizationProps", [], DicomVisualizationProps, () => [[["Item", array_type(class_type("Browser.Types.File", undefined))]], [["Item", lambda_type(SliceValues_$reflection(), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.TouchEvent", undefined), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.TouchEvent", undefined), unit_type)]], [["Item", bool_type]], [["Item", option_type(float64_type)]]]);
}

