import { Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { Shared_UniversalArgs_$reflection } from "./SharedSection.js";
import { create, Args_$reflection } from "./StudyInfoSection.js";
import { create as create_1, Args_$reflection as Args_$reflection_1 } from "./MeasurementSection.js";
import { create as create_2, Args_$reflection as Args_$reflection_2 } from "./AdditionalDataSection.js";
import { create as create_3, Args_$reflection as Args_$reflection_3 } from "./ScreenshotSection.js";
import { create as create_4, Args_$reflection as Args_$reflection_4 } from "./ConclusionSection.js";
import { record_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { TitlePageSection_create } from "./TitlePageSection.js";
import { Pdf_Doc_create } from "../ReportPdfHelpers.js";
import { ErrorMessage } from "../../../../RAWMap.Models/ErrorMessage.js";
import { AsyncResult_ofPromise } from "../../../../Common/Extensions.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../../../../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { PDFDocument } from "pdf-lib";
import { toArray } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { rangeDouble } from "../../../../fable_modules/fable-library-js.4.19.3/Range.js";
import { ByteArrayExtensions_SaveFileAs_5EF83E14 } from "../../../../fable_modules/Fable.Remoting.Client.7.32.0/Extensions.fs.js";

export class FullReportArgs extends Record {
    constructor(universalArgs, studyArgs, measurementArgs, geometryDataArgs, growthDataArgs, screenshotArgs, conclusionArgs) {
        super();
        this.universalArgs = universalArgs;
        this.studyArgs = studyArgs;
        this.measurementArgs = measurementArgs;
        this.geometryDataArgs = geometryDataArgs;
        this.growthDataArgs = growthDataArgs;
        this.screenshotArgs = screenshotArgs;
        this.conclusionArgs = conclusionArgs;
    }
}

export function FullReportArgs_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.ReportSections.FullReportArgs", [], FullReportArgs, () => [["universalArgs", Shared_UniversalArgs_$reflection()], ["studyArgs", Args_$reflection()], ["measurementArgs", Args_$reflection_1()], ["geometryDataArgs", Args_$reflection_2()], ["growthDataArgs", Args_$reflection_2()], ["screenshotArgs", Args_$reflection_3()], ["conclusionArgs", Args_$reflection_4()]]);
}

export function mkReportTitlePage(args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_create(Pdf_Doc_create(), args.universalArgs, args.titlePageArgs), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg)));
}

export function mkReportSections(args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create(Pdf_Doc_create(), args.studyArgs), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_1(_arg, args.universalArgs, args.measurementArgs), (_arg_1) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_2("ViTAA Automated Measurements", _arg_1, args.geometryDataArgs), (_arg_2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_2("ViTAA Automated Comparison Measurements", _arg_2, args.growthDataArgs), (_arg_3) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_3(_arg_3, args.universalArgs, args.screenshotArgs), (_arg_4) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_4(_arg_4, args.conclusionArgs), (_arg_5) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg_5))))))));
}

export function makeAndPrependTitlePage(fileName, pdf, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, mkReportTitlePage(args), (_arg_3) => AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((Item) => (new ErrorMessage(1, [Item])), AsyncResult_ofPromise()(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PDFDocument.load(_arg_3.output('arraybuffer')).then((_arg) => {
        let arg_1, copyOfStruct;
        const titlePage_1 = _arg;
        return ((arg_1 = toArray(rangeDouble(0, 1, ((copyOfStruct = pdf, copyOfStruct.getPageCount())) - 1)), titlePage_1.copyPages(pdf, arg_1))).then((_arg_1) => {
            let fullDoc;
            _arg_1.forEach((arg_2) => {
                titlePage_1.addPage(arg_2);
            });
            fullDoc = titlePage_1;
            return fullDoc.save().then((_arg_2) => {
                ByteArrayExtensions_SaveFileAs_5EF83E14(_arg_2, fileName);
                return Promise.resolve();
            });
        });
    })))))))));
}

