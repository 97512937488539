import { length, tryHead } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Toast_errorToast } from "../Common/General.js";
import { String_ofListWithOxfordComma, String_pluralize } from "../fable_modules/Webbler.Models.1.3.1/Extensions.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { FileComponentSpecs__isFileExtensionAllowed_Z721C83C5, FileType_asExtensions_3D487FC4 } from "./Shared.js";

export function validateFiles(fileDataList, onSuccessfulValidation, model, specs, getNumberOfFiles) {
    let fileData, allowedFileTypes, fileData_1, allowedFileTypes_1;
    const matchValue = tryHead(fileDataList);
    let matchResult, maxNum_4, allowedFileTypes_2, fileData_2, maxNum_5;
    if (specs.maxFiles.tag === 1) {
        if (length(fileDataList) > specs.maxFiles.fields[0]) {
            matchResult = 0;
            maxNum_4 = specs.maxFiles.fields[0];
        }
        else if (matchValue != null) {
            if (specs.maybeFileTypeRestrictions != null) {
                if ((fileData = matchValue, (allowedFileTypes = specs.maybeFileTypeRestrictions, !FileComponentSpecs__isFileExtensionAllowed_Z721C83C5(specs, fileData.file.name)))) {
                    matchResult = 1;
                    allowedFileTypes_2 = specs.maybeFileTypeRestrictions;
                    fileData_2 = matchValue;
                }
                else if (getNumberOfFiles(model) > specs.maxFiles.fields[0]) {
                    matchResult = 2;
                    maxNum_5 = specs.maxFiles.fields[0];
                }
                else {
                    matchResult = 3;
                }
            }
            else if (getNumberOfFiles(model) > specs.maxFiles.fields[0]) {
                matchResult = 2;
                maxNum_5 = specs.maxFiles.fields[0];
            }
            else {
                matchResult = 3;
            }
        }
        else if (getNumberOfFiles(model) > specs.maxFiles.fields[0]) {
            matchResult = 2;
            maxNum_5 = specs.maxFiles.fields[0];
        }
        else {
            matchResult = 3;
        }
    }
    else if (matchValue != null) {
        if (specs.maybeFileTypeRestrictions != null) {
            if ((fileData_1 = matchValue, (allowedFileTypes_1 = specs.maybeFileTypeRestrictions, !FileComponentSpecs__isFileExtensionAllowed_Z721C83C5(specs, fileData_1.file.name)))) {
                matchResult = 1;
                allowedFileTypes_2 = specs.maybeFileTypeRestrictions;
                fileData_2 = matchValue;
            }
            else {
                matchResult = 3;
            }
        }
        else {
            matchResult = 3;
        }
    }
    else {
        matchResult = 3;
    }
    switch (matchResult) {
        case 0:
            return [model, Toast_errorToast(`Only ${maxNum_4} item${String_pluralize(maxNum_4)} can be uploaded at a time.`), Cmd_none()];
        case 1:
            return [model, Toast_errorToast(`Unsupported file type. Please select a ${String_ofListWithOxfordComma(FileType_asExtensions_3D487FC4(allowedFileTypes_2))} file.`), Cmd_none()];
        case 2:
            return [model, Toast_errorToast(`Can only have ${maxNum_5} file${String_pluralize(maxNum_5)} uploaded. Please delete unneeded files.`), Cmd_none()];
        default:
            return onSuccessfulValidation(model);
    }
}

