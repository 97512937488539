import { some, value as value_3, defaultArg, map, ofNullable, bind } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { printf, toText, isNullOrEmpty } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { Union, Record } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { bool_type, option_type, union_type, class_type, int32_type, record_type, string_type, float64_type } from "./fable_modules/fable-library-js.4.19.3/Reflection.js";
import jwt_decode from "jwt-decode";
import { Security_AuthErrorMessage_$reflection } from "./fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.19.3/Result.js";
import { utcNow, op_Subtraction, create } from "./fable_modules/fable-library-js.4.19.3/Date.js";
import { totalSeconds } from "./fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { ofArray, iterate, singleton } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { max } from "./fable_modules/fable-library-js.4.19.3/Double.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { publicAuthApi } from "./Api.js";

export function SessionStorage_storeApiToken(apiToken) {
    sessionStorage["api_token"]=apiToken;
}

export function SessionStorage_tryGetApiToken() {
    return bind((t) => {
        if (isNullOrEmpty(t)) {
            return undefined;
        }
        else {
            return t;
        }
    }, ofNullable(sessionStorage["api_token"]));
}

export function SessionStorage_clear() {
    sessionStorage.clear();
}

export class Jwt_DecodedJwt extends Record {
    constructor(exp, iat, email) {
        super();
        this.exp = exp;
        this.iat = iat;
        this.email = email;
    }
}

export function Jwt_DecodedJwt_$reflection() {
    return record_type("Client.Jwt.DecodedJwt", [], Jwt_DecodedJwt, () => [["exp", float64_type], ["iat", float64_type], ["email", string_type]]);
}

export function Jwt_decode(token) {
    return jwt_decode(token);
}

export class AccessToken extends Record {
    constructor(rawToken, decoded) {
        super();
        this.rawToken = rawToken;
        this.decoded = decoded;
    }
}

export function AccessToken_$reflection() {
    return record_type("Client.AccessToken", [], AccessToken, () => [["rawToken", string_type], ["decoded", Jwt_DecodedJwt_$reflection()]]);
}

export function AccessToken_create_Z721C83C5(t) {
    return new AccessToken(t, Jwt_decode(t));
}

export function AccessToken_rawToken__Z58A6E8F3(t) {
    return t.rawToken;
}

export class InactivityConfig extends Record {
    constructor(inactivityWarningAfter, inactivityLogoutAfter) {
        super();
        this.inactivityWarningAfter = (inactivityWarningAfter | 0);
        this.inactivityLogoutAfter = (inactivityLogoutAfter | 0);
    }
}

export function InactivityConfig_$reflection() {
    return record_type("Client.InactivityConfig", [], InactivityConfig, () => [["inactivityWarningAfter", int32_type], ["inactivityLogoutAfter", int32_type]]);
}

export function InactivityConfig_create_Z5C7ECD40(warnAfter, logoutAfter) {
    return new InactivityConfig(warnAfter, logoutAfter);
}

export class SessionManagementMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SessionError", "StoreToken", "ClearToken", "Tick", "RefreshAccessToken", "GotRefreshTokenResponse", "ResetInactivityTime", "InactivityElapsedWarning", "InactivityElapsedNoTurningBack"];
    }
}

export function SessionManagementMsg_$reflection() {
    return union_type("Client.SessionManagementMsg", [], SessionManagementMsg, () => [[["Item", class_type("System.Exception")]], [["Item", string_type]], [], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, Security_AuthErrorMessage_$reflection()], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", Security_AuthErrorMessage_$reflection()]]])]], [], [], []]);
}

export class SessionManagement_SessionManagementModel extends Record {
    constructor(token$0027, lastActiveAt, inactiveWarning, inactivityConfig) {
        super();
        this["token\'"] = token$0027;
        this.lastActiveAt = lastActiveAt;
        this.inactiveWarning = inactiveWarning;
        this.inactivityConfig = inactivityConfig;
    }
}

export function SessionManagement_SessionManagementModel_$reflection() {
    return record_type("Client.SessionManagement.SessionManagementModel", [], SessionManagement_SessionManagementModel, () => [["token\'", option_type(AccessToken_$reflection())], ["lastActiveAt", class_type("System.DateTime")], ["inactiveWarning", bool_type], ["inactivityConfig", option_type(InactivityConfig_$reflection())]]);
}

export function SessionManagement_SessionManagementModel_token__39A60A33(t) {
    return map(AccessToken_rawToken__Z58A6E8F3, t["token\'"]);
}

export function SessionManagement_SessionManagementModel__get_token(x) {
    return x["token\'"];
}

const SessionManagement_epoch = create(1970, 1, 1, 0, 0, 0, 0, 1);

export const SessionManagement_tryGetAccessToken = () => map(AccessToken_create_Z721C83C5, SessionStorage_tryGetApiToken());

/**
 * Creates and stores the access token.
 */
export function SessionManagement_create(token) {
    SessionStorage_storeApiToken(token);
    return AccessToken_create_Z721C83C5(token);
}

const SessionManagement_expiryThreshold = 0.2;

function SessionManagement_getUnixTime(dt) {
    return totalSeconds(op_Subtraction(dt, SessionManagement_epoch));
}

function SessionManagement_checkExpiryThreshold(token) {
    if ((token.decoded.exp - SessionManagement_getUnixTime(utcNow())) < ((token.decoded.exp - token.decoded.iat) * SessionManagement_expiryThreshold)) {
        return singleton((dispatch) => {
            dispatch(new SessionManagementMsg(4, []));
        });
    }
    else {
        return Cmd_none();
    }
}

function SessionManagement_checkInactivity(lastActiveAt, isCurrentlyWarning, cfg) {
    const inactivityDuration = SessionManagement_getUnixTime(utcNow()) - SessionManagement_getUnixTime(lastActiveAt);
    let matchResult;
    if (isCurrentlyWarning) {
        if (inactivityDuration > (cfg.inactivityWarningAfter + cfg.inactivityLogoutAfter)) {
            matchResult = 0;
        }
        else {
            matchResult = 2;
        }
    }
    else if (inactivityDuration > cfg.inactivityWarningAfter) {
        matchResult = 1;
    }
    else {
        matchResult = 2;
    }
    switch (matchResult) {
        case 0:
            return singleton((dispatch) => {
                dispatch(new SessionManagementMsg(8, []));
            });
        case 1:
            return singleton((dispatch_1) => {
                dispatch_1(new SessionManagementMsg(7, []));
            });
        default:
            return Cmd_none();
    }
}

/**
 * Initializes the token storage state with the specified refresh interval (1 second is a typical value).
 * 
 * The values in inactivityConfig should be relative. For example, if the user should be warned after 60s and then logged out after another 30s, then
 * warnAfter = 60 and logoutAfter = 30 should be provided.
 */
export function SessionManagement_init(refreshIntervalOverride, inactivityConfig) {
    const maybeToken = SessionManagement_tryGetAccessToken();
    const refreshInterval = defaultArg(refreshIntervalOverride, (inactivityConfig == null) ? defaultArg(map((t) => (~~(~~(t.decoded.exp - t.decoded.iat) / 10) * 1), maybeToken), 1) : max(1, ~~(inactivityConfig.inactivityWarningAfter / 10))) | 0;
    return [new SessionManagement_SessionManagementModel(maybeToken, utcNow(), false, inactivityConfig), Cmd_batch(ofArray([Cmd_ofEffect((dispatch) => {
        window.setInterval((_arg) => {
            dispatch(new SessionManagementMsg(3, []));
        }, refreshInterval * 1000);
    }), Cmd_ofEffect((dispatch_1) => {
        if (inactivityConfig != null) {
            iterate((ev) => {
                window.addEventListener(ev, (_arg_1) => {
                    dispatch_1(new SessionManagementMsg(6, []));
                });
            }, ofArray(["click", "keypress", "mousedown", "touchstart"]));
        }
    })]))];
}

export function SessionManagement_update(isInactivityDisabled, msg, model) {
    switch (msg.tag) {
        case 3:
            if (SessionManagement_SessionManagementModel__get_token(model) != null) {
                const refreshCmd = SessionManagement_checkExpiryThreshold(value_3(SessionManagement_SessionManagementModel__get_token(model)));
                const model_1 = isInactivityDisabled() ? (new SessionManagement_SessionManagementModel(model["token\'"], utcNow(), model.inactiveWarning, model.inactivityConfig)) : model;
                return [model_1, Cmd_batch(ofArray([refreshCmd, defaultArg(map((cfg) => SessionManagement_checkInactivity(model_1.lastActiveAt, model_1.inactiveWarning, cfg), model_1.inactivityConfig), Cmd_none())]))];
            }
            else {
                return [model, Cmd_none()];
            }
        case 1:
            return [new SessionManagement_SessionManagementModel(SessionManagement_create(msg.fields[0]), model.lastActiveAt, model.inactiveWarning, model.inactivityConfig), Cmd_none()];
        case 2: {
            SessionStorage_clear();
            return [new SessionManagement_SessionManagementModel(undefined, model.lastActiveAt, model.inactiveWarning, model.inactivityConfig), Cmd_none()];
        }
        case 7:
            return [new SessionManagement_SessionManagementModel(model["token\'"], model.lastActiveAt, true, model.inactivityConfig), Cmd_none()];
        case 8:
            return [new SessionManagement_SessionManagementModel(model["token\'"], model.lastActiveAt, false, model.inactivityConfig), Cmd_none()];
        case 4: {
            const matchValue = SessionManagement_SessionManagementModel__get_token(model);
            if (matchValue != null) {
                return [model, Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, publicAuthApi.refreshToken, matchValue.decoded.email, (Item) => (new SessionManagementMsg(5, [Item])), (Item_1) => (new SessionManagementMsg(0, [Item_1])))];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 5: {
            const r = msg.fields[0];
            if (r.tag === 1) {
                return [model, Cmd_none()];
            }
            else {
                return [model, singleton((dispatch) => {
                    dispatch(new SessionManagementMsg(1, [r.fields[0]]));
                })];
            }
        }
        case 6:
            return [new SessionManagement_SessionManagementModel(model["token\'"], utcNow(), false, model.inactivityConfig), Cmd_none()];
        default: {
            console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
            return [model, Cmd_none()];
        }
    }
}

