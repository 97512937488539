import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, class_type, union_type, bool_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { CameraProps } from "./CameraBindings.js";

export class LocalMsg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ToggleSyncViewports"];
    }
}

export function LocalMsg_$reflection() {
    return union_type("RAWMap.Client.Visualization.Camera.Types.LocalMsg", [], LocalMsg, () => [[["Item", bool_type]]]);
}

export class InboundMsg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["GotActiveCamera"];
    }
}

export function InboundMsg_$reflection() {
    return union_type("RAWMap.Client.Visualization.Camera.Types.InboundMsg", [], InboundMsg, () => [[["Item", class_type("RAWMap.Client.Visualization.Camera.Bindings.ICamera")]]]);
}

export class OutboundMsg {
    constructor() {
    }
}

export function OutboundMsg_$reflection() {
    return class_type("RAWMap.Client.Visualization.Camera.Types.OutboundMsg", undefined, OutboundMsg);
}

export class ModelCamera extends Record {
    constructor(ActiveCamera, SyncViewports) {
        super();
        this.ActiveCamera = ActiveCamera;
        this.SyncViewports = SyncViewports;
    }
}

export function ModelCamera_$reflection() {
    return record_type("RAWMap.Client.Visualization.Camera.Types.ModelCamera", [], ModelCamera, () => [["ActiveCamera", option_type(class_type("RAWMap.Client.Visualization.Camera.Bindings.ICamera"))], ["SyncViewports", bool_type]]);
}

export function ModelCamera_Props(camAdjustment, dispatch, mc) {
    return new CameraProps(mc.ActiveCamera, camAdjustment, (arg_1) => {
        dispatch(wrapInboundMsg(new InboundMsg(arg_1)));
    });
}

