import * as jspdf from "jspdf";
import * as jspdf_autotable from "jspdf-autotable";
import { ofArray, tryFind, cons, map, toArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, string_type, class_type, union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { substring } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { map as map_1 } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Shared_pixelsToMm } from "../../../Visualization/VisualizationShared.js";

export const Pdf_JsPdf = jspdf;

export const Pdf_Autotable = jspdf_autotable;

export const Pdf_Spacing_leftMargin = 14;

export const Pdf_Spacing_topMargin = 20;

export const Pdf_Spacing_baseSpacingY = 10;

export const Pdf_Spacing_narrowSpacingY = 5;

export function Pdf_Doc_create() {
    return new Pdf_JsPdf.jsPDF({
        format: "letter",
    });
}

export function Pdf_Image_addImage(width, height, yOffset, img, doc) {
    return doc.addImage(img, Pdf_Spacing_leftMargin, Pdf_Spacing_topMargin + yOffset, width, height);
}

export const Pdf_Table_Style = {
    minCellWidth: 35,
};

export function Pdf_Table_ofCsvRows(headers, rows) {
    return [[toArray(headers)], toArray(map((row) => toArray(cons(row.displayName, row.values)), rows))];
}

export function Pdf_Table_Adv_make(tableParameters, doc) {
    const jsPDF = Pdf_JsPdf.jsPDF;
    Pdf_Autotable.applyPlugin(jsPDF);
    return doc.autoTable(tableParameters);
}

export function Pdf_Table_Adv_makeTitle(content, startY, doc) {
    const previousFont = doc.getFontSize();
    doc.setFontSize(20);
    doc.text(content, Pdf_Spacing_leftMargin, startY);
    return doc.setFontSize(previousFont);
}

export function Pdf_Table_Basic_make(title, head, body, startY, doc) {
    const jsPDF = Pdf_JsPdf.jsPDF;
    Pdf_Autotable.applyPlugin(jsPDF);
    return Pdf_Table_Adv_make({
        body: body,
        head: head,
        startY: startY + Pdf_Spacing_narrowSpacingY,
        styles: Pdf_Table_Style,
    }, Pdf_Table_Adv_makeTitle(title, startY, doc));
}

export function Pdf_Images_Adv_addImage(image, startX, startY, width, height, doc) {
    return doc.addImage({
        compression: "NONE",
        height: height,
        imageData: new Uint8Array(image),
        width: width,
        x: startX,
        y: startY,
    });
}

export function Pdf_Images_Basic_addImageAutoScale(image, name, startX, startY, centerImageInY, centerImageInX, width, height, doc) {
    const processed = doc.processPNG(image, 0, name, "NONE");
    const maxWidth = width;
    const maxHeight = height;
    const givenRatio = maxWidth / maxHeight;
    const imageRatio = processed.width / processed.height;
    const patternInput = (givenRatio < imageRatio) ? [maxWidth, maxWidth * (1 / imageRatio)] : [maxHeight * imageRatio, maxHeight];
    const width_1 = patternInput[0];
    const height_1 = patternInput[1];
    return Pdf_Images_Adv_addImage(image, (centerImageInX && (width_1 < maxWidth)) ? (startX + ((maxWidth - width_1) / 2)) : startX, (centerImageInY && (height_1 < maxHeight)) ? (startY + ((maxHeight - height_1) / 2)) : startY, width_1, height_1, doc);
}

export function Pdf_Images_Basic_tryParse(toString, all, v) {
    return tryFind((x) => equals(toString(x), v), all);
}

export class Pdf_Images_Basic_ImageType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["JPG", "PNG"];
    }
}

export function Pdf_Images_Basic_ImageType_$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.ReportPdf.Pdf.Images.Basic.ImageType", [], Pdf_Images_Basic_ImageType, () => [[], []]);
}

export function Pdf_Images_Basic_ImageType_get_all() {
    return ofArray([new Pdf_Images_Basic_ImageType(0, []), new Pdf_Images_Basic_ImageType(1, [])]);
}

export function Pdf_Images_Basic_ImageType_ofString_Z721C83C5(value) {
    const value_1 = value.toLocaleLowerCase();
    switch (value_1) {
        case "png":
        case "pjp":
            return new Pdf_Images_Basic_ImageType(1, []);
        case "jpg":
        case "pjpeg":
        case "jpeg":
        case "jpe":
        case "jif":
        case "jfif":
            return new Pdf_Images_Basic_ImageType(0, []);
        default:
            return undefined;
    }
}

export class Pdf_Images_Basic_JsPdfImage extends Record {
    constructor(imageData, imageName, imageKey, imageType) {
        super();
        this.imageData = imageData;
        this.imageName = imageName;
        this.imageKey = imageKey;
        this.imageType = imageType;
    }
}

export function Pdf_Images_Basic_JsPdfImage_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Pdf.Images.Basic.JsPdfImage", [], Pdf_Images_Basic_JsPdfImage, () => [["imageData", class_type("Fable.Core.JS.ArrayBuffer")], ["imageName", string_type], ["imageKey", string_type], ["imageType", Pdf_Images_Basic_ImageType_$reflection()]]);
}

export function Pdf_Images_Basic_JsPdfImage_processImage(image, doc) {
    if (image.imageType.tag === 0) {
        return doc.processJPEG(image.imageData, 0, image.imageName, "NONE");
    }
    else {
        return doc.processPNG(image.imageData, 0, image.imageName, "NONE");
    }
}

export function Pdf_Images_Basic_JsPdfImage_create(imageData, imageKey) {
    const fileName = substring(imageKey, imageKey.lastIndexOf("/") + 1);
    return map_1((imageType) => (new Pdf_Images_Basic_JsPdfImage(imageData, fileName, imageKey, imageType)), Pdf_Images_Basic_ImageType_ofString_Z721C83C5(substring(imageKey, imageKey.lastIndexOf(".") + 1)));
}

export function Pdf_Images_Basic_addImageAutoScaleMaxOfImageSize(image, startX, startY, width, height, doc) {
    const processed = Pdf_Images_Basic_JsPdfImage_processImage(image, doc);
    const maxWidth = Math.min(width, Shared_pixelsToMm(processed.width));
    const maxHeight = Math.min(height, Shared_pixelsToMm(processed.height));
    const givenRatio = maxWidth / maxHeight;
    const imageRatio = processed.width / processed.height;
    const patternInput = (givenRatio < imageRatio) ? [maxWidth, maxWidth * (1 / imageRatio)] : [maxHeight * imageRatio, maxHeight];
    const height_1 = patternInput[1];
    return Pdf_Images_Adv_addImage(image.imageData, startX, (height_1 < maxHeight) ? (startY + ((maxHeight - height_1) / 2)) : startY, patternInput[0], height_1, doc);
}

