import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { OutboundMsg_$reflection, InboundMsg_$reflection, LocalMsg_$reflection, Model_$reflection as Model_$reflection_1 } from "../FileManagementTypes.js";
import { ChildAbsentStrategy$1_$reflection, DefaultActions_ChildModel$2_$reflection } from "../../Common/Terminate.js";
import { OutboundMsg_$reflection as OutboundMsg_$reflection_1, InboundMsg_$reflection as InboundMsg_$reflection_1, LocalMsg_$reflection as LocalMsg_$reflection_1, Model_$reflection as Model_$reflection_2 } from "../FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { OutboundMsg_$reflection as OutboundMsg_$reflection_2, InboundMsg_$reflection as InboundMsg_$reflection_2, LocalMsg_$reflection as LocalMsg_$reflection_2, Model_$reflection as Model_$reflection_3 } from "../FileUploadSelectors/AutoZipSelector/AutoZipTypes.js";
import { union_type, class_type, record_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Msg$3_$reflection } from "../../Common/InboundOutbound.js";
import { ChainedChildMsg$2_$reflection } from "../../Common/ElmishDebugger/ChainedChildMsg.js";

export class Model extends Record {
    constructor(fileUpload, dragDrop, autoZip) {
        super();
        this.fileUpload = fileUpload;
        this.dragDrop = dragDrop;
        this.autoZip = autoZip;
    }
}

export function Model_$reflection() {
    return record_type("RAWMap.Client.FileManagementParent.Types.Model", [], Model, () => [["fileUpload", DefaultActions_ChildModel$2_$reflection(Model_$reflection_1(), Model_$reflection())], ["dragDrop", Model_$reflection_2()], ["autoZip", DefaultActions_ChildModel$2_$reflection(Model_$reflection_3(), Model_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "ClearChildrenLists", "FileManagementMsg", "CreateFileManagement", "TerminateFileManagement", "DragDropMsg", "AutoZipMsg"];
    }
}

export function Msg_$reflection() {
    return union_type("RAWMap.Client.FileManagementParent.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", ChainedChildMsg$2_$reflection(Msg$3_$reflection(LocalMsg_$reflection(), InboundMsg_$reflection(), OutboundMsg_$reflection()), ChildAbsentStrategy$1_$reflection(Model_$reflection()))]], [], [], [["Item", Msg$3_$reflection(LocalMsg_$reflection_1(), InboundMsg_$reflection_1(), OutboundMsg_$reflection_1())]], [["Item", ChainedChildMsg$2_$reflection(Msg$3_$reflection(LocalMsg_$reflection_2(), InboundMsg_$reflection_2(), OutboundMsg_$reflection_2()), ChildAbsentStrategy$1_$reflection(Model_$reflection()))]]]);
}

