import { Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, string_type, class_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { exists } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { last } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { split } from "../fable_modules/fable-library-js.4.19.3/String.js";

export class FileData extends Record {
    constructor(file, extension, maybePath) {
        super();
        this.file = file;
        this.extension = extension;
        this.maybePath = maybePath;
    }
}

export function FileData_$reflection() {
    return record_type("RAWMap.Client.Common.FileData.FileData", [], FileData, () => [["file", class_type("Browser.Types.File", undefined)], ["extension", string_type], ["maybePath", option_type(string_type)]]);
}

export function FileData_createFromFile(maybePath, file) {
    let matchValue, str;
    return new FileData(file, (matchValue = ((str = file.name, exists((y) => ("." === y), str.split("")))), matchValue ? last(split(file.name, ["."], undefined, 0)) : ""), maybePath);
}

