import { Option as Option_1, div } from "../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { cons, ofArray, singleton, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { label as label_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Label.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { InputElement_ref } from "../Common/General.js";
import { IInputType, Option } from "../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { container } from "../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { h3 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { input as input_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Msg } from "./AuthTypes.js";
import { Option as Option_2, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import * as react from "react";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Security_ResetPasswordViewModel } from "../fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.19.3/String.js";

export function mkField(input, label, value, onChange) {
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(input(ofArray([new Option(14, [(e) => {
        InputElement_ref(value, e);
    }]), new Option(13, [(e_1) => {
        onChange(e_1.currentTarget.value);
    }])]))))]));
}

export function viewRequestResetEmail(email, dispatch) {
    let children;
    return container(empty(), singleton((children = [h3(empty())(singleton("Reset Password")), mkField((options) => input_1(cons(new Option(1, [new IInputType(0, [])]), options)), "Email", email, (arg) => {
        dispatch(new Msg(14, [arg]));
    }), div(singleton(new Option_1(4, [])), singleton(div_1(empty(), singleton(button(ofArray([new Option_2(0, [new Color_IColor(4, [])]), new Option_2(18, [(_arg) => {
        dispatch(new Msg(15, []));
    }])]), singleton("Submit"))))))], react.createElement("div", {}, ...children))));
}

export function view(model, dispatch) {
    let children;
    return container(empty(), singleton((children = toList(delay(() => append(singleton_1(h3(empty())(singleton("Reset Password"))), delay(() => append(!model.hideResetEmail ? singleton_1(mkField((options) => input_1(cons(new Option(1, [new IInputType(0, [])]), options)), "Email", model.resetPasswordVm.email, (v) => {
        dispatch(new Msg(3, [(rvm) => (new Security_ResetPasswordViewModel(v, rvm.resetToken, rvm.newPassword, rvm.confirmPassword))]));
    })) : empty_1(), delay(() => append(singleton_1(mkField((options_2) => input_1(cons(new Option(1, [new IInputType(1, [])]), options_2)), "Password", model.resetPasswordVm.newPassword, (v_1) => {
        dispatch(new Msg(3, [(rvm_1) => (new Security_ResetPasswordViewModel(rvm_1.email, rvm_1.resetToken, v_1, rvm_1.confirmPassword))]));
    })), delay(() => append(singleton_1(mkField((options_4) => input_1(cons(new Option(1, [new IInputType(1, [])]), options_4)), "Confirm Password", model.resetPasswordVm.confirmPassword, (v_2) => {
        dispatch(new Msg(3, [(rvm_2) => (new Security_ResetPasswordViewModel(rvm_2.email, rvm_2.resetToken, rvm_2.newPassword, v_2))]));
    })), delay(() => singleton_1(div(singleton(new Option_1(4, [])), singleton(div_1(empty(), singleton(button(ofArray([new Option_2(0, [new Color_IColor(4, [])]), new Option_2(18, [(_arg) => {
        dispatch(new Msg(12, []));
    }]), new Option_2(16, [isNullOrWhiteSpace(model.resetPasswordVm.newPassword) ? true : (model.resetPasswordVm.confirmPassword !== model.resetPasswordVm.newPassword)])]), singleton("Submit"))))))))))))))))), react.createElement("div", {}, ...children))));
}

