import { SystemInformationViewModel_get_empty } from "../RAWMap.Models/View/SystemInformation.js";
import { Msg, Model, Mode } from "./SystemInformationTypes.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { Toast_successToast, Toast_errorToast } from "../Common/General.js";
import { ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";
import { some } from "../fable_modules/fable-library-js.4.19.3/Option.js";

export function init() {
    return [new Model(undefined, undefined, SystemInformationViewModel_get_empty(), new Mode(0, [])), Cmd_none()];
}

export function update(args, msg, model) {
    let matchValue;
    switch (msg.tag) {
        case 1:
            return [new Model(msg.fields[0], model.maybeCurrentSystemInformation, model.editSystemInformationVm, model.mode), singleton((dispatch) => {
                dispatch(new Msg(7, []));
            })];
        case 2:
            return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, (matchValue = model.maybeCurrentSystemInformation, (matchValue == null) ? SystemInformationViewModel_get_empty() : matchValue), new Mode(1, [])), Cmd_none()];
        case 3:
            return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, model.editSystemInformationVm, new Mode(0, [])), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(args.token).updateSystemInformation, model.editSystemInformationVm, (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(0, [Item_1])))];
        case 4:
            return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, SystemInformationViewModel_get_empty(), new Mode(0, [])), Cmd_none()];
        case 5:
            return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, msg.fields[0](model.editSystemInformationVm), model.mode), Cmd_none()];
        case 6:
            if (msg.fields[0].tag === 1) {
                return [model, Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
            }
            else {
                return [model, Cmd_batch(ofArray([Toast_successToast("System Information updated"), singleton((dispatch_1) => {
                    dispatch_1(new Msg(7, []));
                })]))];
            }
        case 7:
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(args.token).getSystemInformation, undefined, (Item_2) => (new Msg(8, [Item_2])), (Item_3) => (new Msg(0, [Item_3])))];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [model, Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
            }
            else {
                return [new Model(model.MaybeCurrentUser, msg.fields[0].fields[0], model.editSystemInformationVm, model.mode), Cmd_none()];
            }
        default: {
            console.error(some(`Error: ${msg.fields[0]}`));
            return [model, Cmd_none()];
        }
    }
}

