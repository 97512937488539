import { FSharpRef, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, string_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { S3Context_$reflection } from "../../../../Common/AwsCommon.js";
import { day, month, year, create, now, date as date_1, tryParse, minValue } from "../../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { isMatch } from "../../../../fable_modules/fable-library-js.4.19.3/RegExp.js";
import { compare } from "../../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { ofArray, forAll } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { map2 } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";

export class Shared_UniversalArgs extends Record {
    constructor(token, s3Context) {
        super();
        this.token = token;
        this.s3Context = s3Context;
    }
}

export function Shared_UniversalArgs_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.Shared.UniversalArgs", [], Shared_UniversalArgs, () => [["token", string_type], ["s3Context", S3Context_$reflection()]]);
}

export function TimeDelta_tryParseDateTime(input) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse(input, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return undefined;
    }
}

export function TimeDelta_parseYYYYMMDD(input) {
    if (isMatch(/[\d]{4}-[\d]{2}-[\d]{2}/gu, input)) {
        return TimeDelta_tryParseDateTime(input);
    }
    else if (input.length === 8) {
        return TimeDelta_tryParseDateTime(`${input.slice(0, 3 + 1)}-${input.slice(4, 5 + 1)}-${input.slice(6, 7 + 1)}`);
    }
    else {
        return undefined;
    }
}

export function TimeDelta_validateAcquisitionDates(current, previous) {
    let date1, date2, d1, d2;
    const isDateBetweenTodayAnd1980 = (date) => {
        if (date == null) {
            return false;
        }
        else {
            const d = date;
            const today = date_1(now());
            const jan1_1980 = create(1980, 1, 1, 0, 0, 0, 0);
            if (compare(date_1(d), date_1(jan1_1980)) >= 0) {
                return compare(date_1(d), today) <= 0;
            }
            else {
                return false;
            }
        }
    };
    return forAll((x) => x, ofArray([isDateBetweenTodayAnd1980(current), isDateBetweenTodayAnd1980(previous), (date1 = current, (date2 = previous, (date1 != null) && ((date2 != null) && ((d1 = date1, (d2 = date2, compare(d1, d2) > 0))))))]));
}

export function TimeDelta_calculateTimeDelta(currentStudyAcquisitionDate, previousStudyAcquisitionDate) {
    const currentDate = TimeDelta_parseYYYYMMDD(currentStudyAcquisitionDate);
    const previousDate = TimeDelta_parseYYYYMMDD(previousStudyAcquisitionDate);
    if (TimeDelta_validateAcquisitionDates(currentDate, previousDate)) {
        return map2((current, previous) => {
            const totalMonths = ((((year(current) - year(previous)) * 12) + (month(current) - month(previous))) - (((day(current) - day(previous)) < 0) ? 1 : 0)) | 0;
            return [~~(totalMonths / 12), totalMonths % 12];
        }, currentDate, previousDate);
    }
    else {
        return undefined;
    }
}

