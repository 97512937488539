import { InboundMsg as InboundMsg_1, FileManagementUpdateArgs, FileManagementViewModel_abortUpload_27E04A4D, Model_tryTerminate_Z379152CB, FileHandling, Model_getNumberOfAssociatedFiles_Z379152CB } from "../FileManagement/FileManagementTypes.js";
import { update as update_1, init as init_1 } from "../FileManagement/FileManagementState.js";
import { update as update_2, init as init_2 } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropState.js";
import { ImageInfo, OutboundMsg, ImageInfo_getFileName_Z54611DEE, LocalMsg, Model } from "./ImageManagerTypes.js";
import { Cmd_none, Cmd_batch, Cmd_map } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { update as update_3, wrapInboundMsg, wrapLocalMsg } from "../Common/InboundOutbound.js";
import { length, head, tail, isEmpty, singleton, iterate, ofArray } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__ReturnFrom_1505, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { toList } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { DragDropFileSelectorUpdateArgs, InboundMsg } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { getSignedUrlCmd, S3Context } from "../Common/AwsCommon.js";
import { equals } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { some, map } from "../fable_modules/fable-library-js.4.19.3/Option.js";

export function getNumberOfAssociatedFiles(model) {
    return Model_getNumberOfAssociatedFiles_Z379152CB(model.fileManagementModel);
}

export function init(fileSpecs, fileContext) {
    const patternInput = init_1(fileSpecs, fileContext, new FileHandling(0, []));
    const patternInput_1 = init_2(fileSpecs);
    return [new Model(patternInput[0], patternInput_1[0], undefined), Cmd_map(wrapLocalMsg, Cmd_batch(ofArray([Cmd_map((Item) => (new LocalMsg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new LocalMsg(3, [Item_1])), patternInput_1[1])])))];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__ReturnFrom_1505(ResultComputationExpression_result, Model_tryTerminate_Z379152CB(model.fileManagementModel))));
}

export function forceTerminate(model) {
    iterate((arg) => {
        FileManagementViewModel_abortUpload_27E04A4D(arg[1]);
    }, toList(model.fileManagementModel.FileManagementsInProgress));
}

export function updateLocal(args, msg, model) {
    let msg_2, bind$0040, msg_9;
    switch (msg.tag) {
        case 1:
            return [model, Cmd_batch(singleton((msg_2 = (new LocalMsg(3, [wrapInboundMsg(new InboundMsg())])), singleton((dispatch) => {
                dispatch(msg_2);
            })))), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 0) {
                const patternInput_1 = update_1(new FileManagementUpdateArgs(args.Token, args.Credentials, (bind$0040 = args.Context, new S3Context(bind$0040.S3Config, bind$0040.S3Client, bind$0040.S3Expiry, args.Context.ActiveBucket))), msg.fields[0].fields[0], model.fileManagementModel);
                return [new Model(patternInput_1[0], model.dragDropModel, model.maybeActiveImage), Cmd_map((Item_2) => (new LocalMsg(2, [Item_2])), patternInput_1[1]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0].tag === 0) {
                let patternInput;
                const matchValue_1 = model.fileManagementModel.PersistedFiles;
                let matchResult, file;
                if (!isEmpty(matchValue_1)) {
                    if (isEmpty(tail(matchValue_1))) {
                        matchResult = 0;
                        file = head(matchValue_1);
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0: {
                        patternInput = [equals(file.FileName, map(ImageInfo_getFileName_Z54611DEE, model.maybeActiveImage)) ? model : (new Model(model.fileManagementModel, model.dragDropModel, undefined)), singleton((dispatch_1) => {
                            dispatch_1(new LocalMsg(4, [file]));
                        })];
                        break;
                    }
                    default:
                        patternInput = [new Model(model.fileManagementModel, model.dragDropModel, undefined), Cmd_none()];
                }
                return [patternInput[0], patternInput[1], singleton((dispatch_2) => {
                    dispatch_2(new OutboundMsg(0, []));
                })];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        case 3:
            if (msg.fields[0].tag === 0) {
                const patternInput_2 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, getNumberOfAssociatedFiles(model) + length(model.fileManagementModel.UploadSelection), args.Credentials, args.Context), msg.fields[0].fields[0], model.dragDropModel);
                return [new Model(model.fileManagementModel, patternInput_2[0], model.maybeActiveImage), Cmd_map((Item_5) => (new LocalMsg(3, [Item_5])), patternInput_2[1]), Cmd_none()];
            }
            else {
                return [model, Cmd_batch(ofArray([(msg_9 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg_1(1, [msg.fields[0].fields[0].fields[0]]))])), singleton((dispatch_3) => {
                    dispatch_3(msg_9);
                })), singleton((dispatch_4) => {
                    dispatch_4(new LocalMsg(1, []));
                })])), Cmd_none()];
            }
        case 4:
            return [model, getSignedUrlCmd(args.Context, 0, msg.fields[0].FilePath, (tupledArg) => (new LocalMsg(5, [tupledArg[0], tupledArg[1]])), (Item_8) => (new LocalMsg(0, [Item_8]))), Cmd_none()];
        case 5:
            return [new Model(model.fileManagementModel, model.dragDropModel, new ImageInfo(msg.fields[0], msg.fields[1])), Cmd_none(), Cmd_none()];
        default: {
            console.error(some(msg.fields[0]));
            return [model, Cmd_none(), Cmd_none()];
        }
    }
}

export function updateInbound(_args, msg, model) {
    let msg_2;
    return [model, (msg_2 = (new LocalMsg(2, [wrapInboundMsg(new InboundMsg_1(0, []))])), singleton((dispatch) => {
        dispatch(msg_2);
    })), Cmd_none()];
}

export function update(args, msg, model) {
    return update_3(updateLocal, updateInbound, args, msg, model);
}

