import { Helpers_nothing } from "../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { Option, image as image_1 } from "../fable_modules/Fulma.3.0.0/Elements/Image.fs.js";
import { HTMLAttr, DOMAttr, Prop } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { wrapLocalMsg } from "../Common/InboundOutbound.js";
import { Option as Option_1, columns } from "../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_2, ISize, column } from "../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { Screen } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { view as view_1 } from "../FileManagement/FileManagementView.js";
import { uploadControl } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropView.js";
import { LocalMsg } from "./ImageManagerTypes.js";

export function makeImage(model, _dispatch) {
    let props;
    const matchValue = model.maybeActiveImage;
    if (matchValue == null) {
        return Helpers_nothing;
    }
    else {
        const fileInfo = matchValue;
        return image_1(singleton(new Option(25, [ofArray([new Prop(0, [fileInfo.fileName]), new DOMAttr(13, [(e) => {
            const image = e.target;
            const width = image.naturalWidth;
            const height = image.naturalHeight;
            image.style.maxWidth = toText(printf("%fpx"))(width);
            image.style.maxHeight = toText(printf("%fpx"))(height);
        }])])])), singleton((props = [new HTMLAttr(149, [fileInfo.url]), ["style", {
            maxWidth: "0px",
            maxHeight: "0px",
        }]], react.createElement("img", keyValueList(props, 1)))));
    }
}

export function view(displayMode, model, dispatch) {
    const dispatchLocal = (arg) => {
        dispatch(wrapLocalMsg(arg));
    };
    const children = [columns(ofArray([new Option_1(2, []), new Option_1(1, []), new Option_1(0, [])]), ofArray([column(singleton(new Option_2(0, [new Screen(0, []), new ISize(17, [])])), singleton(view_1((maybeFileTypeRestrictions) => uploadControl((arg_1) => {
        dispatchLocal(new LocalMsg(3, [arg_1]));
    }, maybeFileTypeRestrictions), displayMode, model.fileManagementModel, (arg_2) => {
        dispatchLocal(new LocalMsg(2, [arg_2]));
    }, undefined))), column(singleton(new Option_2(3, [singleton(["style", {
        minWidth: "200px",
    }])])), singleton(makeImage(model, dispatch)))]))];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

