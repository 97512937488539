import { EditMode, ListDisplayModification, ModificationSource, Msg, Model, Mode as Mode_8 } from "./PatientTypes.js";
import { tryFind, contains, filter as filter_6, exists, ofArray, singleton, sort, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { PartialInternalPatientIdViewModel_isValid_293DEEA, HospitalPatientIdViewModel_isValid_Z3AB5EA36, NameDobViewModel_isValid_7D3B7E8E, PatientCreationViewModel_isValid_7D62DD9A, PatientCreationViewModel, HospitalPatientIdViewModel_get_institutionId_, PatientCreationViewModel_get_hospitalPatientIdVm_, NameDobViewModel_get_empty, PartialInternalPatientIdViewModel_get_empty, HospitalPatientIdViewModel_get_empty, PatientCreationViewModel_get_empty } from "../RAWMap.Models/View/Patient.js";
import { PatientFilters_Filter, PatientFilters_FilterComparison, PatientFilters_Field, PatientFilters_FilterType_get_fieldToFilterType, PatientFilters_FilterType, PatientFilters_Filter_isValid_72A93D4F, PatientFilters_Filter_get_empty, Common_SortInfo$1, Common_SortDirection } from "../RAWMap.Models/Common.js";
import { stringHash, equals, compare } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { defaultArg, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.4.0.3/navigation.fs.js";
import { Toast_successToast, Toast_errorToast, Urls_Page, Urls_PatientPage, Urls_pageHash } from "../Common/General.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { toString } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { minValue, toUniversalTime } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";

export function init() {
    return [new Model(new Mode_8(0, []), undefined, empty(), empty(), undefined, PatientCreationViewModel_get_empty(), HospitalPatientIdViewModel_get_empty(), PartialInternalPatientIdViewModel_get_empty(), NameDobViewModel_get_empty(), empty(), false, new Common_SortInfo$1("", new Common_SortDirection(1, []), (list) => sort(list, {
        Compare: compare,
    })), false, PatientFilters_Filter_get_empty()), Cmd_none()];
}

export function setCreateEditVm(model, createEditVm) {
    return new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, createEditVm, createEditVm.hospitalPatientIdVm, model.SearchForInternalPatientIdVm, createEditVm.nameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter);
}

export function update(token, msg, model) {
    let l, bind$0040, SearchForHospitalPatientIdVm, bind$0040_1, SearchByNameDobVm, bind$0040_2, matchValue, bind$0040_4, bind$0040_5, bind$0040_6, arg_14, arg_15, matchValue_4, patient_11;
    let matchResult, ex, userInfo, institutions, err, patients, err_1, filter, ids, filter_1, institutionDesignation, patient_2, mode, patient_3, setter, setter_1, setter_2, setter_3, filterField, filter_4, filter_5, comp, pId, err_2, ids_1, err_3, ids_2, pvm, err_4, err_5, sortInfo;
    switch (msg.tag) {
        case 0: {
            matchResult = 0;
            ex = msg.fields[0];
            break;
        }
        case 1: {
            matchResult = 1;
            userInfo = msg.fields[0];
            break;
        }
        case 6: {
            if (msg.fields[0].tag === 1) {
                matchResult = 3;
                err = msg.fields[0].fields[0];
            }
            else {
                matchResult = 2;
                institutions = msg.fields[0].fields[0];
            }
            break;
        }
        case 16: {
            switch (msg.fields[0].tag) {
                case 3: {
                    matchResult = 4;
                    break;
                }
                case 4: {
                    matchResult = 8;
                    break;
                }
                default:
                    matchResult = 48;
            }
            break;
        }
        case 2: {
            matchResult = 4;
            break;
        }
        case 3: {
            if (msg.fields[0].tag === 0) {
                matchResult = 5;
                patients = msg.fields[0].fields[0];
            }
            else {
                matchResult = 6;
                err_1 = msg.fields[0];
            }
            break;
        }
        case 4: {
            matchResult = 7;
            filter = msg.fields[0];
            break;
        }
        case 5: {
            if (msg.fields[0].tag === 0) {
                matchResult = 9;
                ids = msg.fields[0].fields[0];
            }
            else {
                matchResult = 48;
            }
            break;
        }
        case 19: {
            matchResult = 10;
            filter_1 = msg.fields[0];
            break;
        }
        case 18: {
            matchResult = 11;
            institutionDesignation = msg.fields[0];
            break;
        }
        case 7: {
            matchResult = 12;
            patient_2 = msg.fields[0];
            break;
        }
        case 8: {
            matchResult = 13;
            mode = msg.fields[1];
            patient_3 = msg.fields[0];
            break;
        }
        case 9: {
            matchResult = 14;
            setter = msg.fields[0];
            break;
        }
        case 10: {
            matchResult = 15;
            setter_1 = msg.fields[0];
            break;
        }
        case 11: {
            matchResult = 16;
            setter_2 = msg.fields[0];
            break;
        }
        case 12: {
            matchResult = 17;
            setter_3 = msg.fields[0];
            break;
        }
        case 34: {
            matchResult = 18;
            break;
        }
        case 36: {
            matchResult = 19;
            break;
        }
        case 17: {
            matchResult = 20;
            break;
        }
        case 23: {
            matchResult = 21;
            break;
        }
        case 24: {
            matchResult = 22;
            break;
        }
        case 26: {
            matchResult = 23;
            break;
        }
        case 37: {
            matchResult = 24;
            filterField = msg.fields[0];
            break;
        }
        case 38: {
            matchResult = 25;
            filter_4 = msg.fields[0];
            break;
        }
        case 39: {
            matchResult = 26;
            filter_5 = msg.fields[0];
            break;
        }
        case 40: {
            matchResult = 27;
            comp = msg.fields[0];
            break;
        }
        case 25: {
            matchResult = 28;
            break;
        }
        case 27: {
            matchResult = 29;
            break;
        }
        case 28: {
            if (msg.fields[0].tag === 1) {
                if (msg.fields[0].fields[0].tag === 10) {
                    matchResult = 31;
                }
                else {
                    matchResult = 32;
                    err_2 = msg.fields[0];
                }
            }
            else {
                matchResult = 30;
                pId = msg.fields[0].fields[0];
            }
            break;
        }
        case 29: {
            matchResult = 33;
            break;
        }
        case 30: {
            if (msg.fields[0].tag === 1) {
                if (msg.fields[0].fields[0].tag === 10) {
                    matchResult = 35;
                }
                else {
                    matchResult = 36;
                    err_3 = msg.fields[0];
                }
            }
            else {
                matchResult = 34;
                ids_1 = msg.fields[0].fields[0];
            }
            break;
        }
        case 31: {
            matchResult = 37;
            break;
        }
        case 32: {
            if (msg.fields[0].tag === 0) {
                matchResult = 38;
                ids_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 48;
            }
            break;
        }
        case 13: {
            matchResult = 39;
            break;
        }
        case 21: {
            if (msg.fields[0].tag === 1) {
                matchResult = 42;
                err_4 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 40;
                pvm = msg.fields[0].fields[0];
            }
            break;
        }
        case 22: {
            if (msg.fields[0].tag === 1) {
                matchResult = 43;
                err_5 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 41;
            }
            break;
        }
        case 33: {
            matchResult = 44;
            break;
        }
        case 35: {
            matchResult = 45;
            sortInfo = msg.fields[0];
            break;
        }
        case 15: {
            matchResult = 46;
            break;
        }
        case 14: {
            matchResult = 47;
            break;
        }
        default:
            matchResult = 48;
    }
    switch (matchResult) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1:
            return [new Model(model.Mode, userInfo, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_batch(ofArray([singleton((dispatch) => {
                dispatch(new Msg(2, []));
            }), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(token).getInstitutions, undefined, (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(0, [Item_1])))]))];
        case 2:
            return [new Model(model.Mode, model.MaybeCurrentUser, institutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 3:
            return [model, Cmd_none()];
        case 4:
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).getPatients, undefined, (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(0, [Item_3])))];
        case 5:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 6:
            return [model, Cmd_none()];
        case 7:
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(token).getPatientsWithFilter, filter, (Item_4) => (new Msg(5, [Item_4])), (Item_5) => (new Msg(0, [Item_5])))];
        case 8:
            return [model, exists((modification) => equals(modification.Source, new ModificationSource(1, [])), model.ListDisplayModifications) ? singleton((dispatch_1) => {
                dispatch_1(new Msg(4, [model.Filter]));
            }) : Cmd_none()];
        case 9:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_6((modification_1) => !equals(modification_1.Source, new ModificationSource(1, [])), model.ListDisplayModifications), delay(() => singleton_1(new ListDisplayModification(new ModificationSource(1, []), 1, (list_2) => filter_6((patient) => contains(patient.id, ids, {
                Equals: (x_3, y) => (x_3 === y),
                GetHashCode: stringHash,
            }), list_2))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 10:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), singleton((dispatch_2) => {
                dispatch_2(new Msg(4, [filter_1]));
            })];
        case 11:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_6((modification_2) => !equals(modification_2.Source, new ModificationSource(2, [])), model.ListDisplayModifications), delay(() => singleton_1(new ListDisplayModification(new ModificationSource(2, []), 2, (list_5) => filter_6((patient_1) => exists((institution) => {
                if (institution.id === patient_1.institutionId) {
                    return equals(institution.clinicalDesignation, institutionDesignation);
                }
                else {
                    return false;
                }
            }, model.UserInstitutions), list_5))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 12:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, patient_2, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [patient_2.id])])))];
        case 13: {
            const model_1 = setCreateEditVm(model, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(), (l = PatientCreationViewModel_get_hospitalPatientIdVm_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(), HospitalPatientIdViewModel_get_institutionId_())(l)))(patient_3.institutionId)((bind$0040 = PatientCreationViewModel_get_empty(), new PatientCreationViewModel(patient_3.id, bind$0040.dateCreated, bind$0040.hospitalPatientIdVm, bind$0040.nameDobVm))));
            return [new Model(new Mode_8(6, [mode]), model_1.MaybeCurrentUser, model_1.UserInstitutions, model_1.Patients, model_1.SelectedPatient, model_1.CreateEditPatientVm, model_1.SearchForHospitalPatientIdVm, model_1.SearchForInternalPatientIdVm, model_1.SearchByNameDobVm, model_1.ListDisplayModifications, model_1.CreateEditFieldsValid, model_1.SortInfo, model_1.FilterFieldsValid, model_1.Filter), singleton((dispatch_3) => {
                dispatch_3(new Msg(34, []));
            })];
        }
        case 14:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, setter(model.CreateEditPatientVm), model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), singleton((dispatch_4) => {
                dispatch_4(new Msg(34, []));
            })];
        case 15:
            return [(SearchForHospitalPatientIdVm = setter_1(model.SearchForHospitalPatientIdVm), new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, (bind$0040_1 = model.CreateEditPatientVm, new PatientCreationViewModel(bind$0040_1.id, bind$0040_1.dateCreated, setter_1(model.CreateEditPatientVm.hospitalPatientIdVm), bind$0040_1.nameDobVm)), SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter)), singleton((dispatch_5) => {
                dispatch_5(new Msg(34, []));
            })];
        case 16:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, setter_2(model.SearchForInternalPatientIdVm), model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), singleton((dispatch_6) => {
                dispatch_6(new Msg(34, []));
            })];
        case 17:
            return [(SearchByNameDobVm = setter_3(model.SearchByNameDobVm), new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, (bind$0040_2 = model.CreateEditPatientVm, new PatientCreationViewModel(bind$0040_2.id, bind$0040_2.dateCreated, bind$0040_2.hospitalPatientIdVm, setter_3(model.CreateEditPatientVm.nameDobVm))), model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter)), singleton((dispatch_7) => {
                dispatch_7(new Msg(34, []));
            })];
        case 18:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, (matchValue = model.Mode, (matchValue.tag === 1) ? PatientCreationViewModel_isValid_7D62DD9A(model.CreateEditPatientVm) : ((matchValue.tag === 6) ? ((matchValue.fields[0].tag === 1) ? NameDobViewModel_isValid_7D3B7E8E(model.SearchByNameDobVm) : HospitalPatientIdViewModel_isValid_Z3AB5EA36(model.SearchForHospitalPatientIdVm)) : ((matchValue.tag === 2) ? HospitalPatientIdViewModel_isValid_Z3AB5EA36(model.SearchForHospitalPatientIdVm) : ((matchValue.tag === 3) ? PartialInternalPatientIdViewModel_isValid_293DEEA(model.SearchForInternalPatientIdVm) : ((matchValue.tag === 4) && NameDobViewModel_isValid_7D3B7E8E(model.SearchByNameDobVm)))))), model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 19:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, PatientFilters_Filter_isValid_72A93D4F(model.Filter), model.Filter), Cmd_none()];
        case 20:
            return [setCreateEditVm(new Model(new Mode_8(1, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), PatientCreationViewModel_get_empty()), singleton((dispatch_8) => {
                dispatch_8(new Msg(34, []));
            })];
        case 21:
            return [new Model(new Mode_8(2, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, HospitalPatientIdViewModel_get_empty(), model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), singleton((dispatch_9) => {
                dispatch_9(new Msg(34, []));
            })];
        case 22:
            return [new Model(new Mode_8(3, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, PartialInternalPatientIdViewModel_get_empty(), model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), singleton((dispatch_10) => {
                dispatch_10(new Msg(34, []));
            })];
        case 23:
            return [new Model(new Mode_8(5, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), singleton((dispatch_11) => {
                dispatch_11(new Msg(36, []));
            })];
        case 24: {
            let filterType;
            if (filterField == null) {
                filterType = (new PatientFilters_FilterType(2, []));
            }
            else {
                const field = filterField;
                filterType = PatientFilters_FilterType_get_fieldToFilterType()(field);
            }
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, new PatientFilters_Filter("", model.Filter.dateFilter, defaultArg(filterField, new PatientFilters_Field(0, [])), filterType, new PatientFilters_FilterComparison(0, []))), singleton((dispatch_12) => {
                dispatch_12(new Msg(36, []));
            })];
        }
        case 25:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, (bind$0040_4 = model.Filter, new PatientFilters_Filter(filter_4, bind$0040_4.dateFilter, bind$0040_4.filterField, bind$0040_4.filterType, bind$0040_4.comparison))), singleton((dispatch_13) => {
                dispatch_13(new Msg(36, []));
            })];
        case 26:
            if (filter_5 == null) {
                return [model, Cmd_none()];
            }
            else {
                const dto = filter_5;
                return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, (bind$0040_5 = model.Filter, new PatientFilters_Filter(toString(dto, "yyyy-MM-dd") + "T00:00", toUniversalTime(dto), bind$0040_5.filterField, bind$0040_5.filterType, bind$0040_5.comparison))), singleton((dispatch_14) => {
                    dispatch_14(new Msg(36, []));
                })];
            }
        case 27:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, (bind$0040_6 = model.Filter, new PatientFilters_Filter(bind$0040_6.filter, bind$0040_6.dateFilter, bind$0040_6.filterField, bind$0040_6.filterType, defaultArg(comp, new PatientFilters_FilterComparison(0, []))))), singleton((dispatch_15) => {
                dispatch_15(new Msg(36, []));
            })];
        case 28:
            return [new Model(new Mode_8(4, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, NameDobViewModel_get_empty(), model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), singleton((dispatch_16) => {
                dispatch_16(new Msg(34, []));
            })];
        case 29:
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(token).getPatientWithHospitalPatientId, model.SearchForHospitalPatientIdVm, (Item_6) => (new Msg(28, [Item_6])), (Item_7) => (new Msg(0, [Item_7])))];
        case 30:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, tryFind((patient_5) => (patient_5.id === pId), model.Patients), model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [pId])])))];
        case 31:
            return [model, Toast_errorToast("Could not find a patient matching the search criteria")];
        case 32:
            return [model, Toast_errorToast(toText(printf("Failed to find patient. (%O)"))(err_2))];
        case 33:
            return [model, Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, securedApi(token).getPatientsWithPartialInternalPatientId, model.SearchForInternalPatientIdVm, (Item_8) => (new Msg(30, [Item_8])), (Item_9) => (new Msg(0, [Item_9])))];
        case 34:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_6((modification_3) => !equals(modification_3.Source, new ModificationSource(0, [])), model.ListDisplayModifications), delay(() => singleton_1(new ListDisplayModification(new ModificationSource(0, []), 0, (list_8) => filter_6((patient_6) => contains(patient_6.id, ids_1, {
                Equals: (x_6, y_1) => (x_6 === y_1),
                GetHashCode: stringHash,
            }), list_8))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 35:
            return [model, Toast_errorToast("Could not find a patient matching the search criteria")];
        case 36:
            return [model, Toast_errorToast(toText(printf("Failed to find patient. (%O)"))(err_3))];
        case 37:
            return [model, Cmd_OfAsyncWith_either((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, securedApi(token).getPatientsWithNameDob, model.SearchByNameDobVm, (Item_10) => (new Msg(32, [Item_10])), (Item_11) => (new Msg(0, [Item_11])))];
        case 38:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, toList(delay(() => append(filter_6((modification_4) => !equals(modification_4.Source, new ModificationSource(0, [])), model.ListDisplayModifications), delay(() => singleton_1(new ListDisplayModification(new ModificationSource(0, []), 0, (list_10) => filter_6((patient_7) => contains(patient_7.id, ids_2, {
                Equals: (x_8, y_2) => (x_8 === y_2),
                GetHashCode: stringHash,
            }), list_10))))))), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 39: {
            let p;
            const bind$0040_7 = model.CreateEditPatientVm;
            p = (new PatientCreationViewModel(bind$0040_7.id, minValue(), bind$0040_7.hospitalPatientIdVm, bind$0040_7.nameDobVm));
            let matchResult_1;
            if (model.MaybeCurrentUser != null) {
                if (equals(model.Mode, new Mode_8(1, []))) {
                    matchResult_1 = 0;
                }
                else if (equals(model.Mode, new Mode_8(6, [new EditMode(1, [])]))) {
                    matchResult_1 = 1;
                }
                else if (equals(model.Mode, new Mode_8(6, [new EditMode(0, [])]))) {
                    matchResult_1 = 2;
                }
                else {
                    matchResult_1 = 3;
                }
            }
            else {
                matchResult_1 = 3;
            }
            switch (matchResult_1) {
                case 0:
                    return [model, Cmd_OfAsyncWith_either((x_9) => {
                        Cmd_OfAsync_start(x_9);
                    }, securedApi(token).createPatient, p, (Item_12) => (new Msg(21, [Item_12])), (Item_13) => (new Msg(0, [Item_13])))];
                case 1:
                    return [model, Cmd_OfAsyncWith_either((x_10) => {
                        Cmd_OfAsync_start(x_10);
                    }, securedApi(token).addPatientNameDobHash, [p.nameDobVm, p.id], (Item_14) => (new Msg(22, [Item_14])), (Item_15) => (new Msg(0, [Item_15])))];
                case 2:
                    return [model, Cmd_OfAsyncWith_either((x_11) => {
                        Cmd_OfAsync_start(x_11);
                    }, securedApi(token).changePatientHospitalPatientId, [p.hospitalPatientIdVm, p.id], (Item_16) => (new Msg(22, [Item_16])), (Item_17) => (new Msg(0, [Item_17])))];
                default:
                    return [model, Cmd_none()];
            }
        }
        case 40:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, pvm, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_batch(toList(delay(() => append(singleton_1(Toast_successToast("Successfully created patient")), delay(() => append(singleton_1(singleton((dispatch_17) => {
                dispatch_17(new Msg(2, []));
            })), delay(() => {
                let matchValue_2;
                return (matchValue_2 = pvm.id, (matchValue_2 === "00000000-0000-0000-0000-000000000000") ? empty() : singleton(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [matchValue_2])])))));
            })))))))];
        case 41:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_batch(toList(delay(() => append(singleton_1(Toast_successToast("Successfully modified patient")), delay(() => append(singleton_1(singleton((dispatch_18) => {
                dispatch_18(new Msg(2, []));
            })), delay(() => {
                let matchValue_3, patient_9;
                return (matchValue_3 = model.SelectedPatient, (matchValue_3 != null) ? (!(matchValue_3.id === "00000000-0000-0000-0000-000000000000") ? ((patient_9 = matchValue_3, singleton(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [patient_9.id])])))))) : empty()) : empty());
            })))))))];
        case 42:
            return [model, Toast_errorToast((arg_14 = ErrorMessage_get_describe()(err_4), toText(printf("Failed to create patient. Error: %s"))(arg_14)))];
        case 43:
            return [model, Toast_errorToast((arg_15 = ErrorMessage_get_describe()(err_5), toText(printf("Failed to edit patient. Error: %s"))(arg_15)))];
        case 44:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, empty(), model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 45:
            return [new Model(model.Mode, model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, sortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 46:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, PatientCreationViewModel_get_empty(), model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), Cmd_none()];
        case 47:
            return [new Model(new Mode_8(0, []), model.MaybeCurrentUser, model.UserInstitutions, model.Patients, model.SelectedPatient, model.CreateEditPatientVm, model.SearchForHospitalPatientIdVm, model.SearchForInternalPatientIdVm, model.SearchByNameDobVm, model.ListDisplayModifications, model.CreateEditFieldsValid, model.SortInfo, model.FilterFieldsValid, model.Filter), (matchValue_4 = model.SelectedPatient, (matchValue_4 != null) ? (!(matchValue_4.id === "00000000-0000-0000-0000-000000000000") ? ((patient_11 = matchValue_4, Navigation_newUrl(Urls_pageHash(new Urls_Page(6, [new Urls_PatientPage(1, [patient_11.id])]))))) : Cmd_none()) : Cmd_none())];
        default:
            return [model, Cmd_none()];
    }
}

