import { Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { StudyViewModelModule_FieldNames_get_names, StudyViewModelModule_makeCsvRows, StudyViewModel_$reflection } from "../../../../RAWMap.Models/View/Study.js";
import { record_type, option_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { bind, toArray, map } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { empty, singleton as singleton_1, contains, filter, tryPick, ofArray, map as map_1 } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { singleton, append, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Csv_CsvRowMulti } from "../../../../RAWMap.Models/Common.js";
import { stringHash } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Basic_make, Pdf_Table_ofCsvRows } from "../ReportPdfHelpers.js";

export class Args extends Record {
    constructor(currentStudy, maybeLastStudy) {
        super();
        this.currentStudy = currentStudy;
        this.maybeLastStudy = maybeLastStudy;
    }
}

export function Args_$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.StudyInfoSection.Args", [], Args, () => [["currentStudy", StudyViewModel_$reflection()], ["maybeLastStudy", option_type(StudyViewModel_$reflection())]]);
}

function makeMultiRows(args) {
    const currentRows = StudyViewModelModule_makeCsvRows(undefined, undefined, undefined, args.currentStudy);
    const maybeLastRows = map((svm) => StudyViewModelModule_makeCsvRows(undefined, undefined, undefined, svm), args.maybeLastStudy);
    return map_1((row) => {
        const maybeLastMatch = ofArray(toArray(bind((lastRows) => tryPick((lastRow) => {
            if (lastRow.displayName === row.displayName) {
                return lastRow.value;
            }
            else {
                return undefined;
            }
        }, lastRows), maybeLastRows)));
        return new Csv_CsvRowMulti(row.displayName, toList(delay(() => append(singleton(row.value), delay(() => maybeLastMatch)))));
    }, currentRows);
}

function trimUnwantedFields(multiFields) {
    const names = StudyViewModelModule_FieldNames_get_names();
    return filter((multi) => !contains(multi.displayName, ofArray([names.StudyIdName, names.PatientIdName, names.CreatedByName, names.StudyStatusName]), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), multiFields);
}

export function create(doc, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const multiRows = trimUnwantedFields(makeMultiRows(args));
        const patternInput = Pdf_Table_ofCsvRows(toList(delay(() => append(singleton("Field Name"), delay(() => append(singleton("Current Study"), delay(() => ((args.maybeLastStudy != null) ? singleton_1("Previous Study") : empty()))))))), multiRows);
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, Pdf_Table_Basic_make("Study Fields", patternInput[0], patternInput[1], Pdf_Spacing_topMargin, doc));
    });
}

