import { Record, FSharpRef, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, string_type, union_type, tuple_type, option_type, class_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { some, ofNullable, bind, defaultArg, value as value_2, map } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { join, split, printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Permission, UserRole_$reflection, Permission_$reflection } from "../RAWMap.Models/Security.js";
import { isEmpty, forAll, sortDescending, unzip, map as map_2, ofArray, fold, empty, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Common_SortInfo$1_getUpdatedSortInfo, String_fromOption } from "../RAWMap.Models/Common.js";
import { structuralHash, equals, compare, curry2, uncurry2, defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { tryParse } from "../fable_modules/fable-library-js.4.19.3/Int32.js";
import { Toastr_info, Toastr_success, Toastr_message, Toastr_title, Toastr_position, Toastr_timeout, Toastr_hideEasing, Toastr_showCloseButton, Toastr_error } from "../fable_modules/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";
import { ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";
import { equalsWith, item as item_1, tryFindIndex, map as map_1 } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import * as react from "react";
import { content as content_1 } from "../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { box$0027 } from "../fable_modules/Fulma.3.0.0/Elements/Box.fs.js";
import { Screen, Common_GenericOption } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Option, container } from "../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { Prop, DOMAttr_$reflection, DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { TableOption, table } from "../fable_modules/Fulma.3.0.0/Elements/Table.fs.js";
import { contains, filter, empty as empty_1, map as map_3, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { Option as Option_1, columns as columns_1 } from "../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_2, ISize, column } from "../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { content as content_2, Header_title, header as header_1, card } from "../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { Item_Option, Item_button, menu, trigger, dropdown } from "../fable_modules/Fulma.3.0.0/Components/Dropdown.fs.js";
import { button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { icon as icon_1 } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_3, modal } from "../fable_modules/Fulma.3.0.0/Components/Modal.fs.js";
import { fromDateTimeOffset, today, toString, specifyKind, tryParse as tryParse_1, minValue } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { minValue as minValue_1, fromDate, toUniversalTime } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { Option as Option_6, label as label_1, div } from "../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { Option as Option_4, IInputType, input as input_1 } from "../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_5, textarea } from "../fable_modules/Fulma.3.0.0/Elements/Form/Textarea.fs.js";
import { SelectPropsMulti$1, SelectOptions_value, SelectOption$1 } from "./ReactSelectBind.js";
import react_select from "react-select";

export class Urls_PatientPage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PatientList", "PatientStudies", "PatientStudySelected"];
    }
}

export function Urls_PatientPage_$reflection() {
    return union_type("Client.Common.Urls.PatientPage", [], Urls_PatientPage, () => [[], [["maybePatientId", option_type(class_type("System.Guid"))]], [["Item", tuple_type(class_type("System.Guid"), option_type(class_type("System.Guid")))]]]);
}

export function Urls_PatientPage_get_toUrlOption() {
    return (_arg) => ((_arg.tag === 2) ? map((str) => toText(printf("%s/%s"))(_arg.fields[0][0])(str), map((value_1) => value_1, _arg.fields[0][1])) : ((_arg.tag === 0) ? undefined : map((value) => value, _arg.fields[0])));
}

export function Urls_PatientPage_get_tryParse() {
    return (_arg) => ((_arg === "patients") ? (new Urls_PatientPage(0, [])) : undefined);
}

export class Urls_AuthPage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Login", "ResetPassword", "RequestPasswordReset", "Register"];
    }
}

export function Urls_AuthPage_$reflection() {
    return union_type("Client.Common.Urls.AuthPage", [], Urls_AuthPage, () => [[], [["token", option_type(string_type)]], [], []]);
}

export function Urls_AuthPage_get_tryParse() {
    return (_arg) => ((_arg === "login") ? (new Urls_AuthPage(0, [])) : ((_arg === "resetPassword") ? (new Urls_AuthPage(1, [undefined])) : ((_arg === "requestPasswordReset") ? (new Urls_AuthPage(2, [])) : ((_arg === "register") ? (new Urls_AuthPage(3, [])) : undefined))));
}

export class Urls_PageAccess extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Permission", "Role", "DebugOnly"];
    }
}

export function Urls_PageAccess_$reflection() {
    return union_type("Client.Common.Urls.PageAccess", [], Urls_PageAccess, () => [[["Item", Permission_$reflection()]], [["Item", UserRole_$reflection()]], []]);
}

export class Urls_Page extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Auth", "Home", "Institutions", "Users", "Study", "FileManagement", "Patients", "SystemInformation"];
    }
}

export function Urls_Page_$reflection() {
    return union_type("Client.Common.Urls.Page", [], Urls_Page, () => [[["Item", Urls_AuthPage_$reflection()]], [], [], [], [], [], [["Item", Urls_PatientPage_$reflection()]], []]);
}

export function Urls_Page_get_getAccess() {
    return (_arg) => ((_arg.tag === 1) ? singleton(new Urls_PageAccess(2, [])) : ((_arg.tag === 2) ? singleton(new Urls_PageAccess(0, [new Permission(6, [])])) : ((_arg.tag === 3) ? singleton(new Urls_PageAccess(0, [new Permission(4, [])])) : ((_arg.tag === 4) ? singleton(new Urls_PageAccess(0, [new Permission(11, [])])) : ((_arg.tag === 5) ? singleton(new Urls_PageAccess(2, [])) : ((_arg.tag === 6) ? ((_arg.fields[0].tag === 1) ? empty() : ((_arg.fields[0].tag === 2) ? empty() : singleton(new Urls_PageAccess(0, [new Permission(14, [])])))) : ((_arg.tag === 7) ? empty() : empty())))))));
}

export const Urls_hashPrefix = (() => {
    const clo = toText(printf("#%s"));
    return clo;
})();

export const Urls_ofAuth = (() => {
    const clo_1 = toText(printf("%s/%s"))("auth");
    return clo_1;
})();

export function Urls_ofPatient(extraUrl) {
    let clo;
    let arg_2;
    const _arg_1 = String_fromOption(map((clo = toText(printf("/%s")), clo), extraUrl));
    arg_2 = ((_arg_1 === defaultOf()) ? "" : _arg_1);
    return toText(printf("%s%s"))("patients")(arg_2);
}

export function Urls_pageHash(_arg) {
    switch (_arg.tag) {
        case 0:
            switch (_arg.fields[0].tag) {
                case 1:
                    if (_arg.fields[0].fields[0] != null) {
                        const token = _arg.fields[0].fields[0];
                        return Urls_hashPrefix(Urls_ofAuth(toText(printf("%s?token=%s"))("resetPassword")(token)));
                    }
                    else {
                        return Urls_hashPrefix(Urls_ofAuth("resetPassword"));
                    }
                case 2:
                    return Urls_hashPrefix(Urls_ofAuth("requestPasswordReset"));
                case 3:
                    return Urls_hashPrefix(Urls_ofAuth("register"));
                default:
                    return Urls_hashPrefix(Urls_ofAuth("login"));
            }
        case 2:
            return Urls_hashPrefix("institutions");
        case 3:
            return Urls_hashPrefix("users");
        case 4:
            return Urls_hashPrefix("study");
        case 5:
            return Urls_hashPrefix("fileManagement");
        case 6:
            return Urls_hashPrefix(Urls_ofPatient(Urls_PatientPage_get_toUrlOption()(_arg.fields[0])));
        case 7:
            return Urls_hashPrefix("systemInformation");
        default:
            return Urls_hashPrefix("dashboard");
    }
}

export function Urls_combine(xs) {
    let clo;
    return fold(uncurry2((clo = toText(printf("%s/%s")), (arg) => {
        const clo_1 = clo(arg);
        return clo_1;
    })), "", xs);
}

export function Urls_$007CInt$007C_$007C(input) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(input, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return undefined;
    }
}

export function Toast_errorToast(text) {
    return Toastr_error(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_timeout(3000, Toastr_position("toast-top-full-width", Toastr_title("Error", Toastr_message(text)))))));
}

export function Toast_successToast(text) {
    return Toastr_success(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_timeout(3000, Toastr_position("toast-top-full-width", Toastr_title("Success", Toastr_message(text)))))));
}

export function Toast_infoToast(text) {
    return Toastr_info(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_timeout(3000, Toastr_position("toast-top-full-width", Toastr_title("Success", Toastr_message(text)))))));
}

export function Toast_ofErrorMessage(e) {
    return Toast_errorToast(ErrorMessage_get_describe()(e));
}

export function TextDisplay_splitParagraphs(text) {
    return ofArray(map_1((arg_1) => react.createElement("p", {}, arg_1), split(text, ["\n"], undefined, 1 | 1)));
}

export function TextDisplay_nullShowBlank(_arg) {
    if (_arg != null) {
        const thing = value_2(_arg);
        return toText(printf("%O"))(thing);
    }
    else {
        return "";
    }
}

export function TextDisplay_scrollParagraphDetail(label, paragraphText) {
    let children;
    return content_1(empty(), ofArray([(children = [toText(printf("%s:"))(label)], react.createElement("b", {}, ...children)), box$0027(singleton(new Common_GenericOption(1, [singleton(["style", {
        maxHeight: "300px",
        overflowY: "scroll",
    }])])), TextDisplay_splitParagraphs(TextDisplay_nullShowBlank(paragraphText)))]));
}

export function Controls_pluralize(n) {
    if (n === 1) {
        return "";
    }
    else {
        return "s";
    }
}

export function Controls_containerWithContent(id, content) {
    const children = [container(ofArray([new Option(0, []), new Option(3, [singleton(new HTMLAttr(99, [id]))])]), singleton(content))];
    return react.createElement("div", {}, ...children);
}

export function Controls_itemListSortable(columns, viewItem, items, extraItem, sortInfo, onClick) {
    let children_8, children_6, children_10;
    const children_12 = [table(ofArray([new TableOption(1, []), new TableOption(2, []), new TableOption(4, [])]), ofArray([(children_8 = [(children_6 = toList(delay(() => map_2((tupledArg_1) => {
        const value_1 = tupledArg_1[0];
        const icon = (sortInfo.lastClicked === value_1) ? Fa_i(ofArray([(sortInfo.sortDirection.tag === 1) ? (new Fa_IconOption(11, ["fas fa-chevron-down"])) : (new Fa_IconOption(11, ["fas fa-chevron-up"])), new Fa_IconOption(14, [singleton(["style", {
            paddingLeft: "10px",
        }])])]), []) : Helpers_nothing;
        const props_4 = [new HTMLAttr(64, ["disable-select"]), new DOMAttr(40, [curry2(onClick)(Common_SortInfo$1_getUpdatedSortInfo(value_1, tupledArg_1[1], sortInfo))]), ["style", {
            cursor: "pointer",
        }]];
        return react.createElement("td", keyValueList(props_4, 1), value_1, icon);
    }, columns))), react.createElement("tr", {}, ...children_6))], react.createElement("thead", {}, ...children_8)), (children_10 = map_2((item) => {
        const props_2 = toList(delay(() => append(singleton_1(new DOMAttr(40, [curry2(viewItem)(item)])), delay(() => append(singleton_1(["style", {
            cursor: "pointer",
        }]), delay(() => extraItem(item)))))));
        const children_2 = toList(delay(() => map_2((tupledArg) => {
            const children = [tupledArg[1](item)];
            return react.createElement("td", {}, ...children);
        }, columns)));
        return react.createElement("tr", keyValueList(props_2, 1), ...children_2);
    }, sortInfo.sortBy(items)), react.createElement("tbody", {}, ...children_10))]))];
    return react.createElement("div", {}, ...children_12);
}

export function Controls_itemList(columns, viewItem, items, extraItem) {
    let children_8, children_6, children_10;
    const columnLists = unzip(columns);
    const children_12 = [table(ofArray([new TableOption(1, []), new TableOption(2, []), new TableOption(4, [])]), ofArray([(children_8 = [(children_6 = toList(delay(() => map_2((value_1) => react.createElement("th", {}, value_1), columnLists[0]))), react.createElement("tr", {}, ...children_6))], react.createElement("thead", {}, ...children_8)), (children_10 = map_2((item) => {
        const props_2 = toList(delay(() => append(singleton_1(new DOMAttr(40, [curry2(viewItem)(item)])), delay(() => append(singleton_1(["style", {
            cursor: "pointer",
        }]), delay(() => extraItem(item)))))));
        const children_2 = toList(delay(() => map_2((value) => {
            const children = [value(item)];
            return react.createElement("td", {}, ...children);
        }, columnLists[1])));
        return react.createElement("tr", keyValueList(props_2, 1), ...children_2);
    }, sortDescending(items, {
        Compare: compare,
    })), react.createElement("tbody", {}, ...children_10))]))];
    return react.createElement("div", {}, ...children_12);
}

export function Controls_listEditView(mainCol, editCol) {
    let f2, options;
    const children_3 = [columns_1(ofArray([new Option_1(2, []), new Option_1(0, [])]), ofArray([column(ofArray([new Option_2(0, [new Screen(0, []), (editCol == null) ? (new ISize(18, [])) : (new ISize(17, []))]), new Option_2(3, [singleton(["style", {
        flexGrow: 1,
        flexShrink: 1,
    }])])]), singleton(mainCol)), defaultArg(map((f2 = ((options = ofArray([new Option_2(0, [new Screen(0, []), new ISize(17, [])]), new Option_2(3, [singleton(["style", {
        flexGrow: 1,
    }])])]), (children) => column(options, children))), (arg) => f2(singleton(arg))), editCol), react.createElement("div", {}))]))];
    return react.createElement("div", {}, ...children_3);
}

export function Controls_flexColumns(content) {
    return columns_1(ofArray([new Option_1(4, []), new Option_1(2, []), new Option_1(0, [])]), map_2((item) => column(ofArray([new Option_2(0, [new Screen(0, []), new ISize(17, [])]), new Option_2(3, [singleton(["style", {
        flexGrow: 1,
        flexShrink: 1,
    }])])]), item), content));
}

export function Controls_asCard(title, content) {
    return card(empty(), ofArray([header_1(empty(), singleton(Header_title(empty(), singleton(title)))), content_2(empty(), content)]));
}

export function Controls_commaSeparatedStrings(stringFunct, items) {
    return join(", ", map_3(stringFunct, items));
}

export function Controls_itemWithBoldLabel(label, item) {
    const children_2 = [react.createElement("b", {}, label), item];
    return react.createElement("p", {}, ...children_2);
}

export function Controls_multiVerticalTileColumnFlex(columnProps, leftFlexBasis, leftInnerFlexBasis, leftColumns, leftColumnProps, rightColumns, rightColumnProps) {
    return columns_1(singleton(new Option_1(9, [toList(delay(() => columnProps))])), ofArray([column(singleton(new Option_2(3, [singleton(["style", {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: leftFlexBasis,
    }])])), singleton(columns_1(ofArray([new Option_1(4, []), new Option_1(2, []), new Option_1(0, []), new Option_1(9, [leftColumnProps])]), toList(delay(() => {
        let options;
        return map_2((options = singleton(new Option_2(3, [singleton(["style", {
            flexGrow: 1,
            flexShrink: 0,
            flexBasis: leftInnerFlexBasis,
            overflowX: "visible",
            width: "100%",
        }])])), (children) => column(options, children)), leftColumns);
    }))))), column(singleton(new Option_2(3, [rightColumnProps])), toList(delay(() => map_2((children_1) => react.createElement("div", {
        className: "block",
    }, ...children_1), rightColumns))))]));
}

export class Controls_dropdownButton extends Record {
    constructor(OnClick, Label) {
        super();
        this.OnClick = OnClick;
        this.Label = Label;
    }
}

export function Controls_dropdownButton_$reflection() {
    return record_type("Client.Common.Controls.dropdownButton", [], Controls_dropdownButton, () => [["OnClick", DOMAttr_$reflection()], ["Label", string_type]]);
}

export function Controls_buttonDropdown(dropdownLabel, buttonOptions, options, buttons) {
    const enabled = forAll((o) => {
        let matchResult;
        if (o.tag === 16) {
            if (o.fields[0]) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return false;
            default:
                return true;
        }
    }, buttonOptions);
    return dropdown(options, ofArray([trigger(empty(), singleton(button(buttonOptions, singleton(dropdownLabel)))), menu(empty(), map_2((_arg) => Item_button(singleton(new Item_Option(1, [singleton(_arg.OnClick)])), singleton(_arg.Label)), enabled ? buttons : empty()))]));
}

export function Controls_buttonDropdownIcon(icon) {
    const dropdownLabel = icon_1(empty(), singleton(Fa_i(singleton(icon), [])));
    return (buttonOptions) => ((options) => ((buttons) => Controls_buttonDropdown(dropdownLabel, buttonOptions, options, buttons)));
}

export function Controls_buttonDropdownText(text) {
    return (buttonOptions) => ((options) => ((buttons) => Controls_buttonDropdown(text, buttonOptions, options, buttons)));
}

export function Layout_modalCardLayout(title, headerButtons, body, foot) {
    return modal(singleton(new Option_3(1, [true])), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton(title)))), Card_body(empty(), body), Card_foot(empty(), foot)]))]));
}

export function FileList_fileList(files, i, last) {
    return delay(() => append(singleton_1(files[i]), delay(() => {
        if (i >= i) {
            return empty_1();
        }
        else {
            return FileList_fileList(files, i + 1, last);
        }
    })));
}

export function FileList_toSeq(files) {
    if (files.length === 0) {
        return empty_1();
    }
    else {
        return FileList_fileList(files, 0, files.length - 1);
    }
}

export function BrowserFile_name(file) {
    return file.name;
}

export function InputElement_ref(value, e) {
    if (!(e == null) && !equals(e.value, value)) {
        e.value = value;
    }
}

export function Forms_parseDateAsUtc(date) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse_1(date, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return toUniversalTime(fromDate(specifyKind(matchValue[1], 1)));
    }
    else {
        return minValue_1();
    }
}

export function Forms_onEnterKeyPress(disabled, dispatchMsg, e) {
    if (!disabled && ((e.char === "13") ? true : (e.which === 13))) {
        e.preventDefault();
        dispatchMsg();
    }
}

export function Forms_formFieldText(label, initialValue, inputProps, readOnly) {
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(input_1(ofArray([new Option_4(1, [new IInputType(0, [])]), new Option_4(10, [initialValue]), new Option_4(12, [label]), new Option_4(15, [inputProps]), new Option_4(4, [readOnly])]))))]));
}

export function Forms_formLargeFieldText(label, description, initialValue, inputProps, isReadOnly) {
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(textarea(ofArray([new Option_5(13, [description]), new Option_5(11, [initialValue]), new Option_5(14, [inputProps]), new Option_5(6, [isReadOnly])]), empty())))]));
}

export function Forms_formFieldNumeric(label, initialValue, inputProps, readOnly) {
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(input_1(ofArray([new Option_4(1, [new IInputType(7, [])]), new Option_4(10, [initialValue]), new Option_4(12, [label]), new Option_4(15, [inputProps]), new Option_4(5, [readOnly])]))))]));
}

export function Forms_formFieldDate(label, initialValue, inputProps) {
    const formatDate = (d) => toString(d, "yyyy-MM-dd");
    const maxDate = formatDate(today());
    const initDate = (initialValue == null) ? "" : formatDate(fromDateTimeOffset(initialValue, 0));
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(input_1(ofArray([new Option_4(1, [new IInputType(3, [])]), new Option_4(10, [initDate]), new Option_4(12, [label]), new Option_4(15, [toList(delay(() => append(singleton_1(new HTMLAttr(114, [maxDate])), delay(() => append(singleton_1(new HTMLAttr(119, ["1900-01-01"])), delay(() => inputProps))))))])]))))]));
}

export function Forms_formFieldNumber(label, initialValue, inputProps) {
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(input_1(ofArray([new Option_4(1, [new IInputType(7, [])]), new Option_4(10, [initialValue]), new Option_4(12, [label]), new Option_4(15, [inputProps])]))))]));
}

export function Forms_formFieldTextArea(label, initialValue, inputProps) {
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div_1(empty(), singleton(textarea(ofArray([new Option_5(11, [initialValue]), new Option_5(13, [label]), new Option_5(14, [inputProps])]), empty())))]));
}

export function Forms_formFieldReactSelectUnselect(fieldLabel, currentlySelected, availableToSelect, optionLabel, isClearable, changeCallback) {
    const canSelect = Array.from(map_3((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    defaultSelection = ((matchValue == null) ? empty() : singleton(new SelectPropsMulti$1(2, [[item_1(matchValue, canSelect)]])));
    let reactSelect;
    const props_1 = keyValueList(toList(delay(() => append(defaultSelection, delay(() => append(singleton_1(new SelectPropsMulti$1(1, [canSelect])), delay(() => append(singleton_1(new SelectPropsMulti$1(7, [false])), delay(() => append(singleton_1(new SelectPropsMulti$1(8, [isClearable])), delay(() => singleton_1(new SelectPropsMulti$1(3, [(arg_2) => {
        changeCallback(map(SelectOptions_value, ofNullable(arg_2)));
    }])))))))))))), 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(singleton(new Option_6(11, [singleton(new Prop(0, [fieldLabel]))])), ofArray([label_1(empty(), singleton(fieldLabel)), reactSelect]));
}

export function Forms_formFieldReactSelect(additionalProps, fieldLabel, currentlySelected, availableToSelect, optionLabel, changeCallback) {
    const canSelect = Array.from(map_3((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    defaultSelection = ((matchValue == null) ? empty() : singleton(new SelectPropsMulti$1(2, [[item_1(matchValue, canSelect)]])));
    let key;
    const arg_1 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg_1);
    let reactSelect;
    const props_1 = keyValueList(toList(delay(() => append(defaultSelection, delay(() => append(additionalProps, delay(() => append(singleton_1(new SelectPropsMulti$1(1, [canSelect])), delay(() => append(singleton_1(new SelectPropsMulti$1(7, [false])), delay(() => singleton_1(new SelectPropsMulti$1(3, [(value) => {
        changeCallback(some(value.value));
    }])))))))))))), 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(singleton(new Option_6(11, [singleton(new Prop(0, [key]))])), ofArray([label_1(empty(), singleton(fieldLabel)), reactSelect]));
}

export function Forms_formFieldReactSelectMulti(fieldLabel, currentlySelected, otherProps, availableToSelect, optionLabel, changeCallback) {
    const toOptions = (values) => Array.from(map_3((selection) => (new SelectOption$1(optionLabel(selection), selection)), values));
    const notAlreadySelected = filter((item) => !contains(item, currentlySelected, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), availableToSelect);
    let reactSelect;
    const props_1 = keyValueList(toList(delay(() => append(singleton_1(new SelectPropsMulti$1(0, [toOptions(currentlySelected)])), delay(() => append(singleton_1(new SelectPropsMulti$1(1, [toOptions(notAlreadySelected)])), delay(() => append(singleton_1(new SelectPropsMulti$1(7, [true])), delay(() => append(singleton_1(new SelectPropsMulti$1(3, [(value) => {
        changeCallback(equalsWith(equals, value, defaultOf()) ? (new Array(0)) : value);
    }])), delay(() => otherProps)))))))))), 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(empty(), ofArray([label_1(empty(), singleton(fieldLabel)), reactSelect]));
}

