import { Toast_errorToast, Urls_pageHash, Urls_Page_get_getAccess, Urls_PatientPage, Urls_Page, Urls_AuthPage, Urls_PatientPage_get_tryParse, Urls_AuthPage_get_tryParse, Urls_PatientPage_get_toUrlOption, Urls_ofPatient } from "./Common/General.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { oneOf, stringParam, s, map, custom } from "./fable_modules/Fable.Elmish.UrlParser.1.0.2/parser.fs.js";
import { tryParse } from "./fable_modules/fable-library-js.4.19.3/Guid.js";
import { FSharpRef } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.19.3/Result.js";
import { Result_ofOption } from "./fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { some, bind } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { String_toOption } from "./RAWMap.Models/Common.js";
import { singleton, contains, fold, ofArray, collect } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { safeHash, equals } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { isAllowed } from "./RAWMap.Models/Security.js";
import { Navigation_newUrl, Navigation_modifyUrl } from "./fable_modules/Fable.Elmish.Browser.4.0.3/navigation.fs.js";
import { AppState_canNavigate, Msg as Msg_1, Model } from "./Types.js";
import { Cmd_none, Cmd_batch } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Security_ResetPasswordViewModel, Security_ResetPasswordViewModel_get_empty } from "./fable_modules/Webbler.Models.1.3.1/Api.fs.js";
import { Model as Model_1, ResetPasswordModel } from "./Auth/AuthTypes.js";
import { Msg } from "./Study/StudyTypes.js";
import { SessionManagement_SessionManagementModel__get_token } from "./Session.js";

export function parseUrls(page) {
    let token;
    switch (page.tag) {
        case 1:
            return "dashboard";
        case 2:
            return "institutions";
        case 3:
            return "users";
        case 4:
            return "study";
        case 5:
            return "fileManagement";
        case 6:
            return Urls_ofPatient(Urls_PatientPage_get_toUrlOption()(page.fields[0]));
        case 7:
            return "systemInformation";
        default: {
            let arg_3;
            const _arg = page.fields[0];
            arg_3 = ((_arg.tag === 1) ? ((_arg.fields[0] == null) ? "resetPassword" : ((token = _arg.fields[0], toText(printf("%s?token=%s"))("resetPassword")(token)))) : ((_arg.tag === 2) ? "requestPasswordReset" : ((_arg.tag === 3) ? "register" : "login")));
            return toText(printf("%s/%s"))("auth")(arg_3);
        }
    }
}

function guid(chunks) {
    return custom("guid", (arg_1) => {
        let _arg;
        let outArg = "00000000-0000-0000-0000-000000000000";
        _arg = [tryParse(arg_1, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        return _arg[0] ? (new FSharpResult$2(0, [_arg[1]])) : (new FSharpResult$2(1, [undefined]));
    })(chunks);
}

function guidOption(chunks) {
    return custom("guid", (arg_3) => Result_ofOption(undefined, bind((arg_1) => {
        let _arg;
        let outArg = "00000000-0000-0000-0000-000000000000";
        _arg = [tryParse(arg_1, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        if (_arg[0]) {
            return some(_arg[1]);
        }
        else {
            return undefined;
        }
    }, String_toOption(arg_3))))(chunks);
}

function auth(chunks) {
    let f1;
    return custom("auth", (f1 = Urls_AuthPage_get_tryParse(), (arg) => Result_ofOption(undefined, f1(arg))))(chunks);
}

function patient(chunks) {
    let f1;
    return custom("auth", (f1 = Urls_PatientPage_get_tryParse(), (arg) => Result_ofOption(undefined, f1(arg))))(chunks);
}

function curry(f, a, b) {
    return f([a, b]);
}

export const pageParser = (() => {
    let parser, parseBefore, parseAfter, queryParser, parseBefore_2, parseBefore_4, parseBefore_8, parseBefore_6;
    const parsers = ofArray([map((arg) => (new Urls_Page(0, [new Urls_AuthPage(1, [arg])])), (parser = ((parseBefore = s("auth"), (parseAfter = s("resetPassword"), (state) => collect(parseAfter, parseBefore(state))))), (queryParser = stringParam("token"), (state_2) => collect(queryParser, parser(state_2))))), map((Item_1) => (new Urls_Page(0, [Item_1])), (parseBefore_2 = s("auth"), (state_4) => collect(auth, parseBefore_2(state_4)))), map(new Urls_Page(1, []), s("dashboard")), map(new Urls_Page(2, []), s("institutions")), map(new Urls_Page(3, []), s("users")), map(new Urls_Page(4, []), s("study")), map(new Urls_Page(5, []), s("fileManagement")), map((arg_1) => (new Urls_Page(6, [new Urls_PatientPage(1, [arg_1])])), (parseBefore_4 = s("patients"), (state_6) => collect(guidOption, parseBefore_4(state_6)))), map((a) => ((b) => curry((arg_2) => (new Urls_Page(6, [new Urls_PatientPage(2, [arg_2])])), a, b)), (parseBefore_8 = ((parseBefore_6 = s("patients"), (state_8) => collect(guid, parseBefore_6(state_8)))), (state_10) => collect(guidOption, parseBefore_8(state_10)))), map((Item_5) => (new Urls_Page(6, [Item_5])), patient), map(new Urls_Page(7, []), s("systemInformation"))]);
    return (state_12) => oneOf(parsers, state_12);
})();

export function PageAccess_hasAccess(maybeUser, isDebug, page) {
    return fold((previous, access) => {
        let userRoles, userRoles_1;
        return previous && ((access.tag === 1) ? ((maybeUser == null) ? false : ((userRoles = maybeUser.roles, contains(access.fields[0], userRoles, {
            Equals: equals,
            GetHashCode: safeHash,
        })))) : ((access.tag === 0) ? ((maybeUser == null) ? false : ((userRoles_1 = maybeUser.roles, isAllowed(access.fields[0])(userRoles_1)))) : isDebug));
    }, true, Urls_Page_get_getAccess()(page));
}

export function canViewPage(nextPage, model) {
    if (nextPage != null) {
        return PageAccess_hasAccess(model.AuthModel.userInfo, model.IsDebug, nextPage);
    }
    else {
        return false;
    }
}

export function handleUpdatedUrl(nextPage, model) {
    let bind$0040_1, bind$0040, page, page_1, page_2, page_3, page_4;
    console.log(some(toText(printf("Parsed page %O"))(nextPage)));
    const nextPage_1 = canViewPage(nextPage, model) ? nextPage : undefined;
    let matchResult, page_5, token, page_6, pid, pid_1, sid, page_7;
    if (nextPage_1 != null) {
        switch (nextPage_1.tag) {
            case 0: {
                switch (nextPage_1.fields[0].tag) {
                    case 0: {
                        if ((page = nextPage_1, equals(model.AuthModel.userInfo, undefined))) {
                            matchResult = 1;
                            page_5 = nextPage_1;
                        }
                        else if (!AppState_canNavigate(model)) {
                            matchResult = 2;
                        }
                        else if ((page_1 = nextPage_1, ((!equals(page_1, new Urls_Page(0, [new Urls_AuthPage(0, [])])) && !equals(page_1, new Urls_Page(0, [new Urls_AuthPage(3, [])]))) && !equals(page_1, new Urls_Page(0, [new Urls_AuthPage(2, [])]))) && (SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement) == null))) {
                            matchResult = 4;
                            page_6 = nextPage_1;
                        }
                        else {
                            matchResult = 7;
                            page_7 = nextPage_1;
                        }
                        break;
                    }
                    case 1: {
                        if (!AppState_canNavigate(model)) {
                            matchResult = 2;
                        }
                        else {
                            matchResult = 3;
                            token = nextPage_1.fields[0].fields[0];
                        }
                        break;
                    }
                    default:
                        if (!AppState_canNavigate(model)) {
                            matchResult = 2;
                        }
                        else if ((page_2 = nextPage_1, ((!equals(page_2, new Urls_Page(0, [new Urls_AuthPage(0, [])])) && !equals(page_2, new Urls_Page(0, [new Urls_AuthPage(3, [])]))) && !equals(page_2, new Urls_Page(0, [new Urls_AuthPage(2, [])]))) && (SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement) == null))) {
                            matchResult = 4;
                            page_6 = nextPage_1;
                        }
                        else {
                            matchResult = 7;
                            page_7 = nextPage_1;
                        }
                }
                break;
            }
            case 6: {
                if (!AppState_canNavigate(model)) {
                    matchResult = 2;
                }
                else if ((page_3 = nextPage_1, ((!equals(page_3, new Urls_Page(0, [new Urls_AuthPage(0, [])])) && !equals(page_3, new Urls_Page(0, [new Urls_AuthPage(3, [])]))) && !equals(page_3, new Urls_Page(0, [new Urls_AuthPage(2, [])]))) && (SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement) == null))) {
                    matchResult = 4;
                    page_6 = nextPage_1;
                }
                else {
                    switch (nextPage_1.fields[0].tag) {
                        case 1: {
                            if (nextPage_1.fields[0].fields[0] != null) {
                                matchResult = 5;
                                pid = nextPage_1.fields[0].fields[0];
                            }
                            else {
                                matchResult = 7;
                                page_7 = nextPage_1;
                            }
                            break;
                        }
                        case 2: {
                            if (nextPage_1.fields[0].fields[0][1] != null) {
                                matchResult = 6;
                                pid_1 = nextPage_1.fields[0].fields[0][0];
                                sid = nextPage_1.fields[0].fields[0][1];
                            }
                            else {
                                matchResult = 5;
                                pid = nextPage_1.fields[0].fields[0][0];
                            }
                            break;
                        }
                        default: {
                            matchResult = 7;
                            page_7 = nextPage_1;
                        }
                    }
                }
                break;
            }
            default:
                if (!AppState_canNavigate(model)) {
                    matchResult = 2;
                }
                else if ((page_4 = nextPage_1, ((!equals(page_4, new Urls_Page(0, [new Urls_AuthPage(0, [])])) && !equals(page_4, new Urls_Page(0, [new Urls_AuthPage(3, [])]))) && !equals(page_4, new Urls_Page(0, [new Urls_AuthPage(2, [])]))) && (SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement) == null))) {
                    matchResult = 4;
                    page_6 = nextPage_1;
                }
                else {
                    matchResult = 7;
                    page_7 = nextPage_1;
                }
        }
    }
    else {
        matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return [model, Navigation_modifyUrl(Urls_pageHash(model.CurrentPage))];
        case 1:
            return [new Model(model.NotificationsModel, page_5, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(singleton(Navigation_newUrl(Urls_pageHash(page_5))))];
        case 2: {
            const pageHash = Urls_pageHash(model.CurrentPage);
            const goalLocation = (window.location.origin + "/") + pageHash;
            if (window.location.href === goalLocation) {
                return [model, Cmd_none()];
            }
            else {
                window.location.href = goalLocation;
                return [model, Cmd_batch(ofArray([Navigation_modifyUrl(pageHash), Toast_errorToast("Study modification is in progress. Please complete or cancel to navigate away.")]))];
            }
        }
        case 3:
            if (token == null) {
                return [new Model(model.NotificationsModel, new Urls_Page(0, [new Urls_AuthPage(1, [token])]), model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_none()];
            }
            else {
                const resetToken = token;
                return [new Model(model.NotificationsModel, new Urls_Page(0, [new Urls_AuthPage(1, [token])]), (bind$0040_1 = model.AuthModel, new Model_1(bind$0040_1.loginVm, bind$0040_1.registerVm, new ResetPasswordModel((bind$0040 = Security_ResetPasswordViewModel_get_empty(), new Security_ResetPasswordViewModel(bind$0040.email, resetToken, bind$0040.newPassword, bind$0040.confirmPassword)), "", false), bind$0040_1.userInfo, bind$0040_1.sessionManagement, bind$0040_1.inactivity)), model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_none()];
            }
        case 4:
            return [new Model(model.NotificationsModel, new Urls_Page(0, [new Urls_AuthPage(0, [])]), model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, nextPage_1, model.IsDebug), Navigation_modifyUrl(Urls_pageHash(new Urls_Page(0, [new Urls_AuthPage(0, [])])))];
        case 5:
            return [new Model(model.NotificationsModel, new Urls_Page(6, [new Urls_PatientPage(1, [pid])]), model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), singleton((dispatch) => {
                dispatch(new Msg_1(7, [new Msg(22, [pid])]));
            })];
        case 6:
            return [new Model(model.NotificationsModel, new Urls_Page(6, [new Urls_PatientPage(2, [[pid_1, sid]])]), model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([singleton((dispatch_1) => {
                dispatch_1(new Msg_1(7, [new Msg(22, [pid_1])]));
            }), singleton((dispatch_2) => {
                dispatch_2(new Msg_1(7, [new Msg(19, [sid])]));
            })]))];
        default:
            return [new Model(model.NotificationsModel, page_7, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(singleton(Navigation_newUrl(Urls_pageHash(page_7))))];
    }
}

