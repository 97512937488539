import { wrapLocalMsg } from "../../../Common/InboundOutbound.js";
import { view as view_1 } from "../../../FileManagement/FileManagementView.js";
import { uploadControl } from "../../../FileManagement/FileUploadSelectors/AutoZipSelector/AutoZipView.js";
import { LocalMsg } from "./CoreFormFileTypes.js";
import { filter, isEmpty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { ComponentVisibility } from "../../../Common/Controls.js";
import { FileComponentVisibility } from "../../../FileManagement/Shared.js";
import { endsWith } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Model } from "../../../FileManagement/FileManagementTypes.js";
import * as react from "react";

export function view(displayMode, model, dispatch) {
    let matchValue, bind$0040;
    const dispatchLocal = (arg) => {
        dispatch(wrapLocalMsg(arg));
    };
    const children = [view_1((maybeFileTypeRestrictions) => uploadControl(model.autoZip, (arg_1) => {
        dispatchLocal(new LocalMsg(3, [arg_1]));
    }, maybeFileTypeRestrictions), (matchValue = isEmpty(model.autoZip.OutstandingZips), matchValue ? displayMode : ((displayMode.deleteButtonVisibility.tag === 0) ? (new FileComponentVisibility(new ComponentVisibility(2, [undefined]), displayMode.downloadButtonVisibility, displayMode.uploadComponentVisibility, displayMode.uploadButtonVisibility)) : displayMode)), (bind$0040 = model.fileUpload, new Model(bind$0040.Specs, bind$0040.DisplayContent, bind$0040.Message, bind$0040.FileHandling, bind$0040.UploadSelection, bind$0040.FailedUploads, bind$0040.FileManagementsInProgress, filter((file) => !endsWith(file.FileName, ".csv"), model.fileUpload.PersistedFiles), bind$0040.DownloadableFiles, bind$0040.MaybeS3VmsToDelete, bind$0040.SignedUrls, bind$0040.OperationContext)), (arg_2) => {
        dispatchLocal(new LocalMsg(2, [arg_2]));
    }, undefined)];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

